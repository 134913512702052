import * as THREE from 'three'

import { CasterChange, getCasterChanges } from '@/api/caster-change'
import IpcManager from '@/IpcManager'
import FeatureFlags from '@/react/FeatureFlags'

import { Slider } from './Slider'
import BaseView from '../views/BaseView'
import UIView from '../views/UIView'

type CasterStateSliderData = {
  view: BaseView
  caseId?: string
  isVertical?: boolean
}

export class CasterStateSlider extends Slider {
  private data: CasterStateSliderData

  private changes: CasterChange[] | null = null

  private currentChangeTime: string | null = null

  public constructor (data: CasterStateSliderData) {
    super(
      data.view,
      data.isVertical
        ? {
          key: 'CasterStateSlider',
          orientation: 'vertical',
          barColor: '#c7e5ff',
          thumbColor: '#90EE90',
          anchorStartX: 'left',
          anchorStartY: 'bottom',
          positionStart: new THREE.Vector2(100, 100),
          anchorEndX: 'left',
          anchorEndY: 'top',
          positionEnd: new THREE.Vector2(100, 100),
          startValue: 1,
          showUpDownButtons: true,
          showDisplayValue: true,
          displayValueSize: 10,
          displayValueOffset: new THREE.Vector2(10, 0),
        }
        : {
          key: 'CasterStateSlider',
          orientation: 'horizontal',
          barColor: '#c7e5ff',
          thumbColor: '#6495ed',
          anchorStartX: 'left',
          anchorStartY: 'bottom',
          positionStart: new THREE.Vector2(125, 30),
          anchorEndX: 'right',
          anchorEndY: 'bottom',
          positionEnd: new THREE.Vector2(125, 30),
          startValue: 1,
          showUpDownButtons: true,
          showDisplayValue: true,
          displayValueSize: 10,
          displayValueOffset: new THREE.Vector2(0, 10),
          showSnapGaps: true,
        },
    )

    this.data = data

    this.loadChangeDates().then((updated) => {
      if (!updated) {
        return
      }

      this.setValue(this.options.startValue ?? 0, true)
    })
  }

  public override update () {
    this.loadChangeDates().then((updated) => {
      if (!updated) {
        return
      }

      this.setValue(this.value, true)
    })
  }

  private async loadChangeDates () {
    const { caseId, view } = this.data

    if (!caseId || !FeatureFlags.canViewStateSlider((<UIView> view).featureFlags)) {
      this.group.visible = false

      return false
    }

    const changes = await getCasterChanges(caseId) ?? this.changes

    if (!changes?.length || changes.length < 2) {
      this.group.visible = false

      return false
    }

    this.group.visible = true

    this.changes = changes

    const step = 1 / (this.changes.length - 1)
    const snapSteps = this.changes.map((_, index) => step * index)

    this.updateOptions({ snapSteps })

    return true
  }

  public override valueChanged (value: number, group: THREE.Group) {
    if (!this.changes?.length) {
      return
    }

    const index = Math.round(value * (this.changes.length - 1))

    const { changeTime } = this.changes[index] ?? {}

    if (!changeTime) {
      return
    }

    const displayDateTime = changeTime
      .replace('T', ' ')
      .substring(0, 19)

    this.setDisplayValue(displayDateTime, group)

    window.dispatchEvent(new CustomEvent(`${this.options.key}:Changed`, { detail: { changeTime } }))
    ;(window as any).currentCasterTime = new Date(changeTime)

    this.currentChangeTime = changeTime
  }

  public override draggingDone () {
    if (!this.currentChangeTime) {
      return
    }

    // Dispatch loading state event
    window.dispatchEvent(new CustomEvent('CasterStateLoading', { detail: { loading: true } }))

    window.dispatchEvent(
      new CustomEvent(`${this.options.key}:Done`, { detail: { changeTime: this.currentChangeTime } }),
    )

    // add 1 second to the current change time to ensure the right caster state is loaded
    const updatedDate = new Date(new Date(this.currentChangeTime).getTime() + 1000)

    if (this.data.view.views.mainView) {
      this.data.view.views.mainView.isLoadingState = true
    }

    IpcManager.send('loadCasterAtDate', { caseId: this.data.caseId, date: updatedDate })
  }

  public updateData (data: Partial<CasterStateSliderData>) {
    this.data = { ...this.data, ...data }
  }
}
