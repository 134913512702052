import type { ElementMaps, TagName } from '@/types/state'
import { ElementsUtil } from '@/Util/ElementsUtil'
import { Mapping } from '@/Util/mapping/Mapping'

type MathKeyMap = {
  [match: string]: string
}

export default class Util {
  public static getRollerChildren (parentRoller: RollerMountLog, elementMaps: ElementMaps) {
    const allChildren: string[] = []

    const rollerBodyMountLogsHash = ElementsUtil
      .getRollerBodyMountLogMapByRollerMountLogs(elementMaps, [ parentRoller ])

    const rollerBearingMountLogsHash = ElementsUtil
      .getRollerBearingMountLogMapByRollerMountLogs(elementMaps, [ parentRoller ])

    const rollerBodyPaths = Object
      .keys(rollerBodyMountLogsHash)
      .map((mountLogId) => Mapping.elementPathByMountLogId[mountLogId] ?? '')
      .filter(path => path)

    const rollerBearingPaths = Object
      .keys(rollerBearingMountLogsHash)
      .map((mountLogId) => Mapping.elementPathByMountLogId[mountLogId] ?? '')
      .filter(path => path)

    allChildren.push(...rollerBodyPaths)
    allChildren.push(...rollerBearingPaths)

    return allChildren
  }

  public static getPathFromPathArray (array: Array<any>) {
    const names = array.filter((_, i) => i % 2 === 0)
    const ids = array.filter((_, i) => i % 2 === 1)

    return names.map((name, index) => `${name}:${ids[index]}`).join('/')
  }

  public static getPathArrayFromPath (path: string) {
    return path.split('/').reduce((list: Array<any>, part: string) =>
      (parts => [
        ...list,
        parts[0],
        Number(parts[1]),
      ])(part.split(':')), [])
  }

  private static eventStorage: any = {}

  // eslint-disable-next-line @typescript-eslint/ban-types
  public static AddDelegatedEventListener (context: string, type: string, selector: string, handler: any) {
    const contextElement = context ? document.querySelector(context) : document

    const func = Util.eventStorage[`${context ?? ''}_${type}_${selector}`] = function (e: any) {
      for (let target = e.target; target && target !== this; target = target.parentNode) {
        if (target.matches(selector)) {
          handler.call(target, e)

          break
        }
      }
    }

    if (contextElement) {
      contextElement.addEventListener(type, func, false)
    }
  }

  public static RemoveDelegatedEventListener (context: string, type: string, selector: string) {
    const contextElement = context ? document.querySelector(context) : document

    if (contextElement) {
      contextElement.removeEventListener(type, Util.eventStorage[`${context ?? ''}_${type}_${selector}`], false)
    }
  }

  private static readonly mathKeyMap: MathKeyMap = Object.getOwnPropertyNames(Math).reduce(
    (map, key) => ({ ...map, [key.toLowerCase()]: key }),
    {},
  )

  private static readonly mathKeys = new RegExp(`(${Object.keys(Util.mathKeyMap).join('|')})`, 'gi')

  public static prepareFormula (value: string) {
    return value
      .replace(/^\./, '0.')
      .replaceAll(/([^\d]|^)\.(\d)/g, '$10.$2') // .1 => 0.1
      .replaceAll(/(\d)\.([^\d])/g, '$1$2') // 1. => 1
      .replaceAll(/([^\d])\.([^\d])/g, '$1$2') // . =>
      .replaceAll(/ /g, '') // get rid of all spaces
      // .replace(/[^y+\-*/\d.]/g, '') // only keep allowed characters
      .replaceAll(/^[+\-*/]/g, '') // no +-*/ start
      .replaceAll(/(\d*)\.(\d*)([\d.]*)/g, '$1#$2$3') // 5.5.5.5
      .replaceAll(/([^.]\d+)\.([^$])/g, '$1$2') // 5.5.5.5
      .replaceAll(/#/g, '.') // 5.5.5.5
      .replaceAll(/([^\d]|^)0(\d)/g, '$1$2') // 08 => 8
      .replaceAll(/([y+\-*/])\1+/g, '$1') // no duplication
      .replaceAll(/[+\-*/.]([+*/])/g, '$1') // e.g. '+ +' will be replaced
      .replaceAll(/(\))([\dy])/g, '$1*$2') // )8 => )*8
      .replaceAll(/(( |^)[\dy]+)(\()/gi, '$1*$3') // 8( => 8*(
      .replaceAll(/(\))(\()/g, '$1*$2') // )( => )*(
      .replaceAll(/(y)(\d)/g, '$1*$2') // y9 => y*9
      .replaceAll(/(\d)(y)/g, '$1*$2') // 9y => 9*y
      .replaceAll(/([+*\-/])/g, ' $1 ') // spaces around +*-/
      .replaceAll(/([+*/] -) /g, '$1') // keep '-' at the number
      .replace(/(\.\d+)\./, '$1')
      .replaceAll(/,/g, ', ') // comma space
      .replace(Util.mathKeys, match => Util.mathKeyMap[match.toLowerCase()] ?? '')
      .trim()
  }

  private static compileFormula (dynamicFormula: string) {
    return dynamicFormula ? dynamicFormula.replace(Util.mathKeys, match => `Math.${match}`) : 'y'
  }

  public static testFormula (dynamicFormula: string, error: string) {
    const value = 42
    const formula = Util.compileFormula(dynamicFormula)

    if (formula === 'y') {
      return `y = ${value}; f(y) = ${value}`
    }

    try {
      // eslint-disable-next-line no-eval
      const result = eval(formula.replaceAll(/y/g, value.toString()))

      if (Number.isNaN(result)) {
        throw new TypeError('not a number')
      }

      return `y = ${value}; f(y) = ${result}`
    }
    catch (e) {
      return `y = ${value}; f(y) = ${error}`
    }
  }

  public static handleFormula (value: number, dynamicFormula: string) {
    const formula = Util.compileFormula(dynamicFormula)

    if (formula === 'y') {
      return value
    }

    let result = value

    try {
      // eslint-disable-next-line no-eval
      result = eval(formula.replaceAll(/y/g, value.toString()))
    }
    catch (e) {}

    return result
  }

  public static bindMethods (obj: any, context: any) {
    Object
      .getOwnPropertyNames(Object.getPrototypeOf(obj))
      .forEach(key => {
        if (obj[key] instanceof Function && key !== 'constructor') {
          obj[key] = obj[key].bind(context)
        }
      })
  }

  public static hasElementType (elementType: Record<number | string, any>) {
    return Object.keys(elementType).length > 0
  }

  public static fromSnakeToCamelCase (str: string) {
    return str.replaceAll(/_([a-z])/g, (_match, letter) => letter.toUpperCase())
  }

  public static getChildType (type: TagName): TagName[] {
    switch (type) {
      case 'SegmentGroup':
        return [ 'Segment' ] // TODO: should this contain 'SupportPoint'?
      case 'Segment':
        return [ 'Roller', 'Nozzle', 'SensorPoint' ]
      case 'Roller':
        return [ 'RollerBody', 'RollerBearing', 'SensorPoint' ]
      case 'RollerBody':
        return [ 'SensorPoint' ]
      default:
        return []
    }
  }

  /**
   * @param type The parent type
   * @returns Only returns the child types that are eligible for real data creation
   */
  public static getChildTypeForRealData (type: TagName): TagName[] {
    switch (type) {
      case 'SegmentGroup':
        return [ 'SupportPoint', 'Segment' ]
      case 'Segment':
        return [ 'Roller', 'Nozzle' ]
      case 'Roller':
        return [ 'RollerBody', 'RollerBearing' ]
      default:
        return []
    }
  }

  public static isIterable (object: any) {
    if (!object || typeof object !== 'object') {
      return false
    }

    return typeof object[Symbol.iterator] === 'function'
  }

  public static getXMLDataBaseShimStrainAndRGCValues (XMLDataBase: CasterXMLDataBase) {
    const rgcValue = Object.keys(XMLDataBase).some((key) => key.startsWith('RGC') && XMLDataBase[key] === '1')
      ? '1'
      : '0'

    return {
      rgc: rgcValue,
      strain: XMLDataBase['Strain'] ?? '0',
      shim: XMLDataBase['Shim'] ?? '0',
    }
  }

  public static getCaseXmlContentTooltip (caseName: string, XMLDataBase: CasterXMLDataBase, isDefault?: boolean) {
    const { rgc, shim, strain } = Util.getXMLDataBaseShimStrainAndRGCValues(XMLDataBase)

    const tooltipArray = [
      `Case name: ${caseName} ${isDefault ? '(default)' : ''}`,
      `R (RGC-Manager): ${rgc}`,
      `A (Alignment-Manager): ${strain}`,
      `S (Shim-Manager): ${shim}`,
    ]

    return tooltipArray.join('\n')
  }

  public static checkNameAvailability (
    value: string,
    initialName: string,
    setState: (state: any) => void,
    checkCaseNameTimeout: NodeJS.Timeout | undefined,
    setCheckCaseNameTimeout: (timeout: NodeJS.Timeout) => void,
    checkCaseNameAvailability: (value: string, projectId?: string) => Promise<boolean>,
    currentProjectId?: string,
  ) {
    if (checkCaseNameTimeout) {
      clearTimeout(checkCaseNameTimeout)
    }

    if (!value) {
      setState({
        nameAvailable: true,
      })

      return
    }

    setState({
      nameAvailable: true,
    })

    if (checkCaseNameTimeout) {
      clearTimeout(checkCaseNameTimeout)
    }

    if (value === initialName) {
      setState({ nameAvailable: true })

      return
    }

    const timeout = setTimeout(async () => {
      const nameAvailable = await checkCaseNameAvailability(value, currentProjectId)

      setState({
        nameAvailable,
      })
    }, 300)

    setCheckCaseNameTimeout(timeout)
  }

  public static getCurrentSegmentGroupStartAndEndPasslnCoord (elementMaps: ElementMaps, segmentGroupNumericId: number) {
    const segmentGroupMountLogId = Mapping.mountLogIdByTypeAndNumericId.SegmentGroup[segmentGroupNumericId]

    if (!segmentGroupMountLogId) {
      return {
        startPasslnCoord: 0,
        endPasslnCoord: 0,
      }
    }

    const segmentGroupMountLog = elementMaps.SegmentGroupMountLog[segmentGroupMountLogId]
    const segmentGroupSlotId = segmentGroupMountLog?.slotId

    if (!segmentGroupSlotId) {
      return {
        startPasslnCoord: 0,
        endPasslnCoord: 0,
      }
    }

    const segmentGroupSlot = elementMaps.SegmentGroupSlot[segmentGroupSlotId]

    return {
      startPasslnCoord: segmentGroupSlot?.startPasslineCoord ?? 0,
      endPasslnCoord: segmentGroupSlot?.endPasslineCoord ?? 0,
    }
  }
}
