import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Component } from 'react'
import ReactLoading from 'react-loading'
import styled, { css } from 'styled-components'

export const SubmitButton = styled.button<{
  $small?: boolean | undefined
  disabled?: boolean | undefined
}>`${(
  { $small, disabled },
) =>
  css`
  background:  ${disabled ? '#474b4e' : '#BB1B1B'};
  color:       ${disabled ? '#999999' : '#FFFFFF'};
  font-size:   1em;
  margin:      10px 5px;
  padding:     0.25em 0;
  border:      2px solid ${disabled ? '#474b4e' : '#BB1B1B'};
  width:       ${$small ? '40%' : '90%'};
  outline:     none;
  cursor:      ${disabled ? 'not-allowed' : 'pointer'};
  user-select: none;
  transition:  .2s;
  position:    relative;
  min-height:  27px;
  text-align:  center;
  
  &:hover {
    transition: .2s;
    border:     ${disabled ? '2px solid #474b4e' : '2px solid #A71A1A'};
    background: ${disabled ? '' : '#A71A1A'};
  }
`}`

const IconCenter = styled.div`
  position: absolute;
  margin: 0 auto;
  display: inline-block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

type Props = {
  onClick?: (event: any) => void
  value: string
  className?: string
  small?: boolean
  disabled?: boolean
  hasChanges?: boolean
  loading?: boolean
  error?: boolean
  title?: string
}

type State = {  
  success: boolean
  error: boolean
}

class Button extends Component<Props, State> {
  public override state: State = {
    success: false,
    error: false,
  }

  private timeout: NodeJS.Timeout | null = null

  public override componentDidUpdate (prevProps: any) {
    if (this.props.error) {
      this.setState({ error: true })

      this.timeout = setTimeout(() => {
        this.setState({ error: false })
      }, 2500)
    }
    else if (!this.props.loading && prevProps.loading) {
      this.setState({ success: true })

      this.timeout = setTimeout(() => {
        this.setState({ success: false })
      }, 2500)
    }
  }

  public override componentWillUnmount () {
    if (this.timeout !== null) {
      clearTimeout(this.timeout)
    }
  }
  
  public override render () {
    const { onClick: handleClick, value, small, className, disabled, loading, title } = this.props ?? {}
    const { success, error } = this.state

    return (
      <SubmitButton
        className={className ?? ''}
        title={title ?? value}
        onClick={handleClick}
        value={value}
        $small={small}
        disabled={Boolean(disabled && !loading && !success)}
      >
        {
          loading || error || success
            ? (
              <IconCenter>
                {
                  error
                    ? <FontAwesomeIcon icon={faTimes} />
                    : (success
                      ? <FontAwesomeIcon icon={faCheck} />
                      : <ReactLoading type='spin' color='#FFFFFF' height={16} width={16} />)
                }
              </IconCenter>
            )
            : value
        }
      </SubmitButton>
    )
  }
}

export default Button
