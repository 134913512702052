import * as THREE from 'three'

export default class DataPointPointerFactory {
  /**
   * Creates a pointer based on the specified type
   */
  public static createPointer (
    type: 'arrow' | 'donut' | 'square' | 'triangle',
    container: THREE.Object3D,
    sensorPoint: THREE.Object3D,
    color: number = 0xff0000,
    arrowLength: number = 0.5,
  ): THREE.Group | null {
    const position = sensorPoint.position

    switch (type) {
      case 'arrow':
        return this.createPointerArrow(container, position, color, arrowLength)
      case 'square':
        return this.createPointerSquare(container, position, color, arrowLength)
      case 'triangle':
        return this.createPointerTriangle(container, position, color, arrowLength)
      case 'donut':
        return this.createPointerDonut(container, position, color, arrowLength)
      default:
        return null
    }
  }

  /**
   * Creates an arrow-shaped pointer
   */
  private static createPointerArrow (
    container: THREE.Object3D,
    position: THREE.Vector3,
    color: number,
    arrowLength: number,
  ): THREE.Group {
    const arrowGroup = new THREE.Group()

    arrowGroup.position.copy(position)

    // Define arrow proportions
    const shaftWidth = arrowLength * 0.15
    const headWidth = arrowLength * 0.4
    const shaftLength = arrowLength * 0.6
    const depth = arrowLength * 0.1

    // Create a 2D shape in the YZ plane, pointing along +Z
    const arrowShape = new THREE.Shape()

    // Start at bottom-left of shaft (in YZ plane)
    arrowShape.moveTo(0, -shaftWidth / 2) // Y, Z coordinates
    // To bottom-right of shaft
    arrowShape.lineTo(0, shaftWidth / 2)
    // To top-right of shaft
    arrowShape.lineTo(shaftLength, shaftWidth / 2)
    // To right edge of head
    arrowShape.lineTo(shaftLength, headWidth / 2)
    // To tip of arrow
    arrowShape.lineTo(arrowLength, 0)
    // To left edge of head
    arrowShape.lineTo(shaftLength, -headWidth / 2)
    // To top-left of shaft
    arrowShape.lineTo(shaftLength, -shaftWidth / 2)
    // Close the shape
    arrowShape.lineTo(0, -shaftWidth / 2)
    arrowShape.closePath()

    const extrudeSettings: THREE.ExtrudeGeometryOptions = {
      depth, // Arrow thickness (in X direction now)
      bevelEnabled: false,
    }

    const arrowGeometry = new THREE.ExtrudeGeometry(arrowShape, extrudeSettings)

    arrowGeometry.computeVertexNormals()

    arrowGeometry.translate(0, 0, -depth / 2)

    const material = new THREE.MeshStandardMaterial({
      color,
      metalness: 0.5,
      roughness: 0.5,
      emissive: color,
      emissiveIntensity: 0.2,
    })
    const arrowMesh = new THREE.Mesh(arrowGeometry, material)

    arrowMesh.name = 'arrow'
    arrowGroup.add(arrowMesh)
    container.add(arrowGroup)

    return arrowGroup
  }

  /**
   * Creates a square-shaped pointer
   */
  private static createPointerSquare (
    container: THREE.Object3D,
    position: THREE.Vector3,
    color: number,
    arrowLength: number,
  ): THREE.Group {
    const squareGroup = new THREE.Group()

    squareGroup.position.copy(position)

    // Create a square shape
    const squareShape = new THREE.Shape()
    const size = arrowLength * 0.5 // Make square size proportional to arrow length

    squareShape.moveTo(-size, -size)
    squareShape.lineTo(size, -size)
    squareShape.lineTo(size, size)
    squareShape.lineTo(-size, size)
    squareShape.closePath()

    const extrudeSettings: THREE.ExtrudeGeometryOptions = {
      depth: 0.05,
      bevelEnabled: false,
    }

    const squareGeometry = new THREE.ExtrudeGeometry(squareShape, extrudeSettings)

    squareGeometry.computeVertexNormals()

    const material = new THREE.MeshStandardMaterial({
      color,
      metalness: 0.5,
      roughness: 0.5,
      emissive: color,
      emissiveIntensity: 0.2,
    })

    const squareMesh = new THREE.Mesh(squareGeometry, material)

    squareMesh.name = 'square'

    squareGroup.add(squareMesh)
    container.add(squareGroup)

    return squareGroup
  }

  /**
   * Creates a triangle-shaped pointer
   */
  private static createPointerTriangle (
    container: THREE.Object3D,
    position: THREE.Vector3,
    color: number,
    arrowLength: number,
  ): THREE.Group {
    const triangleGroup = new THREE.Group()

    triangleGroup.position.copy(position)

    // Create a triangle shape
    const triangleShape = new THREE.Shape()
    const width = arrowLength * 0.4 // Width of the triangle base

    // Create a 2D shape in the YZ plane, pointing along +Z (just like the arrow)
    triangleShape.moveTo(arrowLength, 0) // Tip of the triangle at positive Z
    triangleShape.lineTo(0, width / 2) // Bottom right (Y is up)
    triangleShape.lineTo(0, -width / 2) // Bottom left (Y is down)
    triangleShape.closePath()

    const extrudeSettings: THREE.ExtrudeGeometryOptions = {
      depth: 0.05, // Triangle thickness (in X direction, same as arrow)
      bevelEnabled: false,
    }

    const triangleGeometry = new THREE.ExtrudeGeometry(triangleShape, extrudeSettings)

    triangleGeometry.computeVertexNormals()

    const material = new THREE.MeshStandardMaterial({
      color,
      metalness: 0.5,
      roughness: 0.5,
      emissive: color,
      emissiveIntensity: 0.2,
    })

    const triangleMesh = new THREE.Mesh(triangleGeometry, material)

    triangleMesh.name = 'triangle'

    triangleGroup.add(triangleMesh)
    container.add(triangleGroup)

    return triangleGroup
  }

  /**
   * Creates a donut-shaped pointer
   */
  private static createPointerDonut (
    container: THREE.Object3D,
    position: THREE.Vector3,
    color: number,
    arrowLength: number,
  ): THREE.Group {
    const donutGroup = new THREE.Group()

    donutGroup.position.copy(position)

    // Create a torus (donut) geometry
    const radius = arrowLength * 0.3 // Outer radius
    const tubeRadius = arrowLength * 0.1 // Thickness of the ring
    const radialSegments = 16 // Number of segments around the radius
    const tubularSegments = 32 // Number of segments around the tube

    const donutGeometry = new THREE.TorusGeometry(
      radius,
      tubeRadius,
      radialSegments,
      tubularSegments,
    )

    const material = new THREE.MeshStandardMaterial({
      color,
      metalness: 0.5,
      roughness: 0.5,
      emissive: color,
      emissiveIntensity: 0.2,
    })

    const donutMesh = new THREE.Mesh(donutGeometry, material)

    donutMesh.name = 'donut'

    donutGroup.add(donutMesh)
    container.add(donutGroup)

    return donutGroup
  }
}
