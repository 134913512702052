/* eslint-disable camelcase */
import {
  BackSide,
  BufferAttribute,
  BufferGeometry,
  CircleGeometry,
  Color,
  Line,
  LOD,
  Material,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  PlaneGeometry,
  ShaderMaterial,
  ShapeGeometry,
  Texture,
  Vector2,
  Vector3,
} from 'three'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader'
import { toCreasedNormals } from 'three/examples/jsm/utils/BufferGeometryUtils'

import type { CasterElementNames } from '@/types/data'
import { StrandSides } from '@/types/elements/enum'
import type { TagName } from '@/types/state'
import type { CompareCasterInformation } from '@/types/visualization'
import { Memoize } from '@/Util/decorators/Memoize'

import fontRobotoMedium from '../fonts/roboto-medium.json'

export default class Util {
  public static RollerMode = 2

  public static readonly RAD = Math.PI / 180

  public static readonly DEG = 180 / Math.PI

  public static readonly RAD90 = 90 * Util.RAD

  public static readonly RAD180 = 180 * Util.RAD

  public static readonly RAD270 = 270 * Util.RAD

  public static readonly RAD45 = 45 * Util.RAD

  public static readonly xAxis = new Vector3(1, 0, 0)

  public static readonly yAxis = new Vector3(0, 1, 0)

  public static readonly zAxis = new Vector3(0, 0, 1)

  private static readonly sidesAngles = {
    right: 0,
    loose: 90,
    left: 180,
    fixed: 270,
  }

  private static readonly anglesSides = {
    0: StrandSides.Right,
    90: StrandSides.Loose,
    180: StrandSides.Left,
    270: StrandSides.Fixed,
  }

  public static readonly sides = [
    StrandSides.Fixed,
    StrandSides.Loose,
    StrandSides.Left,
    StrandSides.Right,
  ]

  public static readonly wideSides = [
    StrandSides.Fixed,
    StrandSides.Loose,
  ]

  public static readonly phantomTypes = [
    'Nozzle',
    'Roller',
    'RollerBearing',
    'RollerBody',
  ]

  private static readonly fallbackMaterial = new MeshBasicMaterial({ color: '#ff00ff' })

  private static readonly fontMaterial = new MeshBasicMaterial({ color: '#ffffff' })

  private static readonly fontBackFaceMaterial = new MeshBasicMaterial({ color: '#ffffff', side: BackSide })

  public static readonly fontRobotoMedium = new FontLoader().parse(fontRobotoMedium)

  private static shapeFontCache: Record<string, Mesh<BufferGeometry, MeshBasicMaterial> | undefined> = {}

  private static canvasFontCache: Record<string, Mesh<PlaneGeometry, MeshBasicMaterial> | undefined> = {}

  private static circleCache: Record<string, CircleGeometry | undefined> = {}

  private static readonly nextPowerOf2 = (n: number) => Math.pow(2, Math.ceil(Math.log(n) / Math.log(2)))

  @Memoize()
  private static drawText (text: string, size: number, color: string) {
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    const font = `Bold ${size}px Roboto, Arial`

    if (!context) {
      // eslint-disable-next-line no-console
      console.warn('Util.drawText: context is null')

      return null
    }

    context.font = font

    const width = context.measureText(text).width
    const height = size * 1.25

    canvas.width = Util.nextPowerOf2(width)
    canvas.height = Util.nextPowerOf2(height)

    context.textBaseline = 'middle'
    context.fillStyle = color
    context.font = font
    context.fillText(text, 1, size / 2 + size * 0.125)

    return { canvas, width, height }
  }

  private static internalGetText (
    text: string,
    color: string,
    size: number,
    targetHeight: number,
  ): Mesh<PlaneGeometry, MeshBasicMaterial> | null {
    const drawnText = Util.drawText(text, size, color)

    if (!drawnText) {
      return null
    }

    const { canvas, width, height } = drawnText
    const texture = new Texture(canvas)

    texture.needsUpdate = true
    texture.repeat.set(width / canvas.width, height / canvas.height)
    texture.offset.set(0, 1 - height / canvas.height)
    texture.anisotropy = 8

    const s = height / (targetHeight || size)
    const newWidth = width / s
    const newHeight = targetHeight || (height / s)
    const plane = new Mesh(
      new PlaneGeometry(newWidth, newHeight),
      new MeshBasicMaterial({ map: texture, transparent: true }),
    )

    plane.userData['width'] = newWidth
    plane.userData['height'] = newHeight

    return plane
  }

  public static getText (
    text: string,
    size: number,
    useShape = false,
    centerHeight = false,
    centerWidth = true,
    color = '#FFFFFF',
    backFace = false,
  ): Mesh<BufferGeometry, MeshBasicMaterial> | null {
    if (!useShape) {
      const key = `${size}_${text}`

      if (!Util.canvasFontCache[key]) {
        const nSize = size * 2
        const mesh = Util.internalGetText(text, color, Math.max(nSize, 48), nSize)

        if (mesh) {
          Util.canvasFontCache[key] = mesh
        }
      }

      const textPlane = Util.canvasFontCache[key]?.clone()

      if (!textPlane) {
        return null
      }

      if (!centerHeight) {
        textPlane.geometry.translate(0, textPlane.userData['height'] / 2, 0)
      }

      return textPlane
    }

    const key = `${centerHeight ? 1 : 0}_${size}_${text}_${backFace ? 'bf' : 'nbf'}`

    if (!Util.shapeFontCache[key]) {
      const textShape = new BufferGeometry()
      const shapes = Util.fontRobotoMedium.generateShapes(text, size)
      const geometry = new ShapeGeometry(shapes, 2) // no Buffer!

      geometry.computeBoundingBox()

      const { max, min } = geometry.boundingBox ?? {}
      const xDiff = max?.x ?? 0 - (min?.x ?? 0)
      const yDiff = max?.y ?? 0 - (min?.y ?? 0)

      const xMid = centerWidth ? -0.5 * xDiff : 0
      const yMid = centerHeight ? -0.5 * yDiff : 0

      geometry.translate(xMid, yMid, 0)

      // TODO: remove old code
      // textShape.fromGeometry(geometry) // TODO: does fromGeometry exist?
      textShape.copy(geometry) // TODO: do we still have to do this?

      Util.shapeFontCache[key] = new Mesh<BufferGeometry, MeshBasicMaterial>(
        textShape,
        backFace ? Util.fontBackFaceMaterial : Util.fontMaterial,
      )
    }

    return Util.shapeFontCache[key]?.clone() ?? null
  }

  public static closest (target: number, numbers: number[]) {
    return (numbers.length > 0)
      ? numbers.reduce((prev, curr) => (Math.abs(curr - target) < Math.abs(prev - target) ? curr : prev))
      : 0
  }

  public static sides2Angles (side: string) {
    return (Util.sidesAngles as any)[side] !== undefined ? (Util.sidesAngles as any)[side] : Number(side)
  }

  public static angles2Sides (angle: string) {
    return (Util.anglesSides as any)[angle] || angle
  }

  private static getRotation (a: number, b: number) {
    let rot = Math.atan2(a, b)

    rot += Math.PI

    if (rot < 0) {
      rot += Math.PI * 2
    }

    if (rot >= Math.PI * 2) {
      rot -= Math.PI * 2
    }

    return rot
  }

  public static getCameraRotations (pos: Vector3) {
    const y = Util.getRotation(pos.x, pos.z)
    const p = pos.clone()
    const yAxis = new Vector3(0, 1, 0)

    p.applyAxisAngle(yAxis, -y)

    return {
      x: Util.getRotation(p.z, p.y) - Math.PI / 2,
      y,
    }
  }

  public static getShortestRotation (angle: number) {
    if (angle > Math.PI * 1.01) {
      return angle - Math.PI * 2
    }

    if (angle < -Math.PI * 1.01) {
      return angle + Math.PI * 2
    }

    return angle
  }

  public static getElementInfo (path: string): { type: TagName, id: number } {
    // TODO: use this path check!
    // if (!path) {
    //   return null // TODO: make sure this is used correctly everywhere
    // }

    // takes 0.32 ms
    const element = path.substring(path.lastIndexOf('/') + 1)
    const colon = element.indexOf(':')
    const type = element.substring(0, colon) as TagName
    const id = Number(element.substring(colon + 1))

    // TODO: string operation are way faster than array operations! by 10x !? ANALYZE!
    // takes 2.21 ms because of Minor GC after split ~ 15 MB collected
    // const [ type, id ] = path.split('/').splice(-1)[0].split(':')

    // TODO: maybe we can change the type or add a fullType so we support the ElementName type

    return {
      type,
      id: Number(id),
    }
  }

  public static getCompareElementByName (
    name: string | null,
    caseId: string,
    elementType: 'DataLine' | 'DataPoint',
    compareCasterInformation: CompareCasterInformation,
  ) {
    return compareCasterInformation[caseId]?.[elementType]?.[name ?? ''] ?? null
  }

  public static getCompareSupportPointByNameAndSegmentGroupPasslineCoord (
    element: any,
    passlineCoord: number,
    caseId: string,
    compareCasterInformation: CompareCasterInformation,
  ) {
    const { name } = element

    return compareCasterInformation[caseId]?.['SupportPoint']?.[`${name}_${passlineCoord}`] ?? null
  }

  public static getCompareSegmentGroupByPassLnCoord (
    element: any,
    caseId: string,
    compareCasterInformation: CompareCasterInformation,
  ) {
    if (!Number.isNaN(element.passlineCoord)) {
      return null
    }

    return compareCasterInformation?.[caseId]?.['SegmentGroup']?.[element.passlineCoord]
  }

  public static getCompareElementByWidthAndPassLnCoordAndSide (
    referenceElement: any,
    side: StrandSide | null,
    caseId: string,
    elementType: CasterElementNames,
    compareCasterInformation: CompareCasterInformation,
  ) {
    const { passlineCoord, widthCoord } = referenceElement

    return compareCasterInformation[caseId]?.[elementType]?.[`${side}_${passlineCoord}_${widthCoord}`] ?? null
  }

  public static getParentInfo (path: string): { path: string, type: TagName, id: number } {
    // TODO: use this path check!
    // if (!path) {
    //   return null // TODO: make sure this is used correctly everywhere
    // }

    const parentPath = path.substring(0, path.lastIndexOf('/'))
    const { type, id } = parentPath ? (Util.getElementInfo(parentPath) ?? {}) : {} as any

    // if (!type || !id) {
    //   return null
    // }

    return {
      path: parentPath,
      type,
      id,
    }
  }

  public static getTriangleMesh (a: Vector3, b: Vector3, c: Vector3, material?: Material) {
    // https://stackoverflow.com/a/75680564/7015138

    const vertices = new Float32Array([
      a.x,
      a.y,
      a.z,
      b.x,
      b.y,
      b.z,
      c.x,
      c.y,
      c.z,
    ])

    const geometry = new BufferGeometry()

    geometry.setAttribute('position', new BufferAttribute(vertices, 3))

    return new Mesh(geometry, material ?? Util.fallbackMaterial)
  }

  private static disposeObject (object: any) {
    if (!object) {
      return
    }

    if (object instanceof Mesh || object instanceof Line) {
      object.geometry?.dispose()
      object.material?.dispose()
    }

    if (object instanceof Object3D) {
      object.children?.forEach((child: any) => Util.disposeObject(child))
    }
  }

  // FIXME: this is too slow
  public static addOrReplaceInList (oldElement: any, element: any, list: any[]) {
    if (oldElement) {
      const index = list.indexOf(oldElement)

      if (index !== -1) {
        list.splice(index, 1)
      }
    }

    list.push(element)
  }

  public static addOrReplace (container: any, element: any, list?: any) {
    const oldElement = container.getObjectByName(element.name) // TODO: use & create element registry

    if (oldElement) {
      element.visible = oldElement.visible

      Util.disposeObject(oldElement)

      container.remove(oldElement)
    }

    container.add(element)

    if (list) {
      Util.addOrReplaceInList(oldElement, element, list)
    }
  }

  private static readonly tooltipMarkerMaterial = new MeshBasicMaterial({ color: '#458cff' })

  private static readonly snapMaterial = new MeshBasicMaterial({
    color: '#555555',
    transparent: true,
    opacity: 0.01,
  })

  private static getCircle (
    radius: number,
    position: Vector3,
    rotateX: boolean,
    material: Material,
    segments = 8,
  ) {
    const cacheKey = `${String(radius)}_${String(segments)}`

    Util.circleCache[cacheKey] = Util.circleCache[cacheKey] ?? new CircleGeometry(radius, segments)

    const geometry = Util.circleCache[cacheKey]
    const circle = new Mesh(geometry, material)

    if (rotateX) {
      circle.rotateX(-90 * Util.RAD)
    }

    circle.position.copy(position)

    return circle
  }

  private static internalCreateTooltipMarker (
    container: any,
    tooltipObjects: any[],
    name: string,
    position: Vector3,
    tooltip: Tooltip,
    _type?: string,
    radius = 0.01,
  ) {
    const tooltipMarker = Util.getCircle(radius, position, true, Util.tooltipMarkerMaterial)

    tooltipMarker.name = `TooltipMarker_${name}`
    tooltipMarker.userData['type'] = 'TooltipMarker'
    tooltipMarker.userData['tooltip'] = tooltip

    Util.addOrReplace(container, tooltipMarker, tooltipObjects)
  }

  public static createTooltipMarker (
    container: any,
    tooltipObjects: any[],
    name: string,
    position: Vector3,
    tooltip: any,
    hasSnap = true,
  ) {
    const pos = position.clone()

    pos.y += 0.0002

    Util.internalCreateTooltipMarker(container, tooltipObjects, name, pos, tooltip)

    if (hasSnap) {
      const pos = position.clone()

      pos.y -= 0.0002

      const snap = Util.getCircle(0.15, pos, true, Util.snapMaterial, 5)

      snap.name = `TooltipMarkerSnap_${name}`
      snap.userData['type'] = 'TooltipMarkerSnap'
      snap.userData['markerName'] = `TooltipMarker_${name}`

      Util.addOrReplace(container, snap, tooltipObjects)
    }
  }

  public static getV2 (v3: Vector3) {
    return new Vector2(v3.x, v3.y)
  }

  public static getV3 (v2: Vector2) {
    return new Vector3(v2.x, v2.y, 0)
  }

  public static flipXZ (v: Vector3) {
    const x = v.x

    v.x = v.z
    v.z = x
  }

  public static isPhantom (type: TagName, sectionDetail?: boolean) {
    const phantomTypes: TagName[] = [ 'Nozzle', 'Roller', 'RollerBody', 'RollerBearing' ]
    const phantomTypesSectionDetail: TagName[] = [ 'Nozzle', 'RollerBody', 'RollerBearing' ]

    return !sectionDetail ? phantomTypes.includes(type) : phantomTypesSectionDetail.includes(type)
  }

  private static lodVisible (lod: any) {
    let visible = false

    for (let i = 0; i < lod.children.length; i++) {
      visible = visible || (lod.children[i].visible && lod.children[i].material.visible)
    }

    return visible
  }

  private static internalIsVisible (object: any) {
    if (object.visible) {
      if (object.parent) {
        return Util.isVisible(object.parent)
      }

      return true
    }

    return false
  }

  public static isVisible (object: any): boolean {
    const isLOD = object.parent instanceof LOD

    return Util.internalIsVisible(object) && (!isLOD || (isLOD && Util.lodVisible(object.parent)))
  }

  public static getLine (points: Vector3[], material: Material) {
    const geometry = new BufferGeometry().setFromPoints(points)

    return new Line(geometry, material)
  }

  public static drawLine (
    container: any,
    x1: number,
    y1: number,
    z1: number,
    x2: number,
    y2: number,
    z2: number,
    name: string,
    material: Material,
  ) {
    const line = Util.getLine([ new Vector3(x1, y1, z1), new Vector3(x2, y2, z2) ], material)

    // TODO: remove old code

    // const geometry = new THREE.Geometry()

    // geometry.vertices.push(
    //   new Vector3(x1, y1, z1),
    //   new Vector3(x2, y2, z2),
    // )

    // const line = new THREE.Line(geometry, material)

    line.name = name

    Util.addOrReplace(container, line)
  }

  private static getColorChannel (c: number, percent: number) {
    return ((0 | (1 << 8) + c + (256 - c) * percent / 100).toString(16)).substring(1)
  }

  public static updateColor (hex: string, percent: number) {
    hex = hex.replace(/^\s*#|\s*$/g, '')

    if (hex.length === 3) {
      hex = hex.replace(/(.)/g, '$1$1')
    }

    const r = parseInt(hex.substring(0, 2), 16)
    const g = parseInt(hex.substring(2, 4), 16)
    const b = parseInt(hex.substring(4, 6), 16)

    return `#${Util.getColorChannel(r, percent)}${Util.getColorChannel(g, percent)}${Util.getColorChannel(b, percent)}`
  }

  // function that returns the amount of space separated values in a string'
  public static getNumberOfValues (string: string) {
    return string.split(' ').length
  }

  public static getSmoothedGeometry<T extends BufferGeometry> (geometry: T, angleInDeg: number): T {
    return toCreasedNormals(geometry, angleInDeg * Util.RAD) as T
  }

  public static getShadedMaterial (baseColor: number, lineColor: number) {
    return new ShaderMaterial({
      uniforms: {
        baseColor: { value: new Color(baseColor).convertLinearToSRGB() }, // Base color
        lineColor: { value: new Color(lineColor).convertLinearToSRGB() }, // Color of the bars
        angle: { value: -Math.PI * 0.85 }, // Angle of the bars
        width: { value: 0.5 }, // Width of the bars
        gap: { value: 0.6 }, // Distance between the bars
        repeat: { value: new Vector2(5, 1) }, // Number of repetitions of the pattern
        mixColor: { value: new Color(0x000000).convertLinearToSRGB() }, // Color for mixing
        mixAmount: { value: 0.2 }, // Mixing amount (0.0 = no mixing, 1.0 = full mixing)
      },
      fragmentShader: /* glsl */ `
        precision mediump float;
        uniform vec3 baseColor;
        uniform vec3 lineColor;
        uniform float angle;
        uniform float width;
        uniform float gap;
        uniform vec2 repeat;
        uniform vec3 mixColor;
        uniform float mixAmount;
        varying vec2 vUv;
        void main() {
          vec2 uv = vUv * repeat;
          uv = vec2(
            uv.x * cos(angle) - uv.y * sin(angle),
            uv.x * sin(angle) + uv.y * cos(angle)
          );
          float line = mod(uv.x, width + gap) < width ? 1.0 : 0.0;
          vec3 color = mix(baseColor, lineColor, line);
          color = mix(color, mixColor, mixAmount);
          gl_FragColor = vec4(color, 1.0);
        }
      `,
      vertexShader: /* glsl */ `
        varying vec2 vUv;
        void main() {
          vUv = uv;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `,
    })
  }
}
