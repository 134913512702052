import { faFile } from '@fortawesome/free-regular-svg-icons'
import { faEllipsis, faFilter } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { enqueueSnackbar } from 'notistack'
import { createRef, PureComponent } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import { useConfig } from '@/config'
import EditSegmentGroupDetailFilterDialog from '@/react/dialogs/EditSegmentGroupDetailFilterDialog'
import HistoryDetailDialog from '@/react/dialogs/HistoryDetailDialog'
import { TreeViewID } from '@/react/driver/DriverID'
import FeatureFlags from '@/react/FeatureFlags'
import ApiClient from '@/store/apiClient'
import * as ApplicationActions from '@/store/application/main/actions'
import { updateElement } from '@/store/elements/actions'
import { getElementMapsObject } from '@/store/elements/logic'
import Teleport from '@/Teleport'
import Util from '@/three/logic/Util'
import type { DefaultState, ElementMaps, ElementName } from '@/types/state'
import type { Translation } from '@/types/translation'
import { Vector2D } from '@/types/visualization'
import { ElementMapsUtil } from '@/Util/ElementMapsUtil'
import { Mapping } from '@/Util/mapping/Mapping'

import {
  Actions,
  Elements,
  OptionsButton,
  RenameInput,
  Spacer,
} from '../ElementGroupStyles'
import ElementsActionsMenu from '../ElementsActionsMenu'
import ChildrenSelector from './ChildrenSelector'

const connector = connect((state: DefaultState) => ({
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
  currentSimulationCase: state.application.main.currentSimulationCase,
  ...getElementMapsObject(state),
}), {
  updateElement,
  openDialog: ApplicationActions.openDialog,
  setActiveDetailDialogFilter: ApplicationActions.setActiveDetailDialogFilter,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  type?: string
  elementName: string
  element: any
  selected: boolean
  childrenElement: any
  name: string
  visible: boolean
  active: boolean
  target: boolean
  hasChildren: boolean
  depth: number
  fullPath: string
  onClick: (event: any, path: string, element?: any, name?: string) => void
  onFilter: (ctrl: boolean, name: string, fullPath: string) => void
  onTarget: (name: string, fullPath: string) => void
  t: Translation
}

type State = {
  isRenaming: boolean
  element: any
  nameValue: string
  showActions: boolean
  clickPosition: Vector2D | null
}

class ElementsComponent extends PureComponent<Props, State> {
  private readonly inputRef: React.RefObject<HTMLInputElement>

  public constructor (props: Props) {
    super(props)
    this.inputRef = createRef()
    this.state = {
      isRenaming: false,
      element: props.element,
      nameValue: String(props.elementName || props.name),
      showActions: false,
      clickPosition: null,
    }
  }

  public override componentDidUpdate (prevProps: Props) {
    if (prevProps.element !== this.props.element) {
      this.setState({
        element: this.props.element,
        isRenaming: false,
        showActions: false,
        clickPosition: null,
      })
    }
  }

  private readonly handleClick = (event: any) => {
    event.stopPropagation()

    const { onClick, name, element, hasChildren, fullPath } = this.props

    hasChildren ? onClick(event, fullPath, element, `${name}:${element.id}`) : onClick(event, fullPath)
  }

  private readonly handleShowActions = (event: any) => {
    event.stopPropagation()
    this.setState({ showActions: !this.state.showActions, clickPosition: { x: event.clientX, y: event.clientY } })
  }

  private readonly handleBlur = (event?: any) => {
    // stop the event from bubbling up to the tree view and opening children or selecting an element
    event?.stopPropagation()

    this.setState({ showActions: false, isRenaming: false })
  }

  private readonly handleKeyUp = (e: any) => {
    if (e.key === 'Enter') {
      this.setState({ isRenaming: false })
      this.handleSubmitNameChange(e.target.value)
    }
  }

  private readonly handleRenameInputClick = (e: any) => {
    e.stopPropagation()
  }

  private readonly handleRenameInputChange = (e: any) => {
    this.setState({ nameValue: e.target.value })
  }

  private readonly handleSubmitNameChange = async (newName: string) => {
    const { fullPath, updateElement } = this.props
    const { type, id } = Util.getElementInfo(fullPath)

    if (!type) {
      return
    }

    if (!newName) {
      enqueueSnackbar('Error: empty new name', { variant: 'error', autoHideDuration: 2000 })

      return
    }

    const typeURL = type === 'SegmentGroup' ? 'segment-groups' : 'segments'
    let mountLogId = Mapping.mountLogIdByTypeAndNumericId[type][id]

    if (type === 'SegmentGroup') {
      const elementMaps = getElementMapsObject(this.props)

      mountLogId = ElementMapsUtil.getFullCasterElementByMountLogId(
        'SegmentGroup',
        mountLogId ?? '',
        elementMaps,
      )?.slotId
    }

    if (!mountLogId) {
      enqueueSnackbar('Error: could not find mountLogId', { variant: 'error', autoHideDuration: 2000 })

      return
    }

    try {
      await ApiClient.patch(
        `${useConfig().apiBaseURL}/${typeURL}/${mountLogId}/rename`,
        { data: { name: newName } },
      )
      // update name in redux

      let mountLogType = `${type}MountLog` as keyof ElementMaps

      if (mountLogType !== 'SegmentMountLog' && mountLogType !== 'SegmentGroupMountLog') {
        return
      }

      if (mountLogType === 'SegmentGroupMountLog') {
        mountLogType = 'SegmentGroupSlot' as keyof ElementMaps
      }

      updateElement(mountLogType, mountLogId, { name: newName })
    }
    catch (error: any) {
      // display error
      enqueueSnackbar('Error: could not rename element', { variant: 'error', autoHideDuration: 2000 })
      // eslint-disable-next-line no-console
      console.error('error', error)
    }
  }

  private readonly handleSetFilter = (event: any) => {
    event.stopPropagation()

    const { onFilter, name, fullPath } = this.props

    const ctrl = event.ctrlKey || event.metaKey

    onFilter(ctrl, name, fullPath)
  }

  private readonly onTarget = (event: any) => {
    event.stopPropagation()

    const { onTarget, name, fullPath } = this.props

    onTarget(name, fullPath)
  }

  private readonly onSetRenaming = (e: any) => {
    e.stopPropagation()

    if (this.state.isRenaming) {
      this.setState({ isRenaming: false, showActions: false })
    }
    else {
      this.setState({ isRenaming: true, showActions: false })

      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          if (this.inputRef && this.inputRef.current) {
            this.inputRef.current.focus()
          }
        })
      })
    }
  }

  private readonly onOpenTableDialog = () => {
    const { element, setActiveDetailDialogFilter, openDialog } = this.props

    const mountLogId = Mapping.mountLogIdByTypeAndNumericId.SegmentGroup[element.id]

    if (!mountLogId) {
      return
    }

    setActiveDetailDialogFilter(element.historyDetailDialogFilter, mountLogId)

    if (!element.historyDetailDialogFilter) {
      openDialog(EditSegmentGroupDetailFilterDialog.NAME)

      return
    }

    openDialog(HistoryDetailDialog.NAME)
  }

  private readonly getElementNameField = () => {
    const { isRenaming } = this.state
    const { hasChildren, visible, element, t, active } = this.props
    const arrow = visible
      ? <Spacer onClick={this.handleClick}>&#9662;</Spacer>
      : <Spacer onClick={this.handleClick}>&#9656;</Spacer>

    return (
      <div
        className='elementName'
        onClick={this.handleClick}
        title={this.getElementName() + this.getElementDescription()}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        {hasChildren ? arrow : <Spacer />}
        {
          isRenaming && (
            <RenameInput
              id={TreeViewID.RenameSegmentGroupInput}
              onChange={this.handleRenameInputChange}
              onClick={this.handleRenameInputClick}
              onBlur={this.handleBlur}
              onKeyUp={this.handleKeyUp}
              ref={this.inputRef}
              value={this.state.nameValue}
            />
          )
        }
        <span style={
          { 
            overflow: 'hidden', 
            textOverflow: 'ellipsis', 
            whiteSpace: 'nowrap',
            flex: 1,
            marginRight: active ? '5px' : '0',
          }
        }
        >
          {`${!isRenaming ? this.getElementName() : ''}${this.getElementDescription()}`}
        </span>
        {
          element?.eDocHRef && (
            <FontAwesomeIcon
              icon={faFile}
              className={TreeViewID.EDocHRefButton}
              style={{ paddingLeft: '10px', cursor: 'pointer', flexShrink: 0 }}
              onClick={() => window.open(element.eDocHRef)}
              title={t('treeView.openEDoc')}
            />
          )
        }
        {
          active && (
            <FontAwesomeIcon
              icon={faFilter}
              title='Filter'
              style={{ cursor: 'pointer', color: '#BB1B1B', marginLeft: '5px', flexShrink: 0 }}
              onClick={this.handleSetFilter}
            />
          )
        }
      </div>
    )
  }

  private readonly getElementName = () => {
    const { elementName, name } = this.props

    const tagName = ElementMapsUtil.getTagName(name as ElementName)

    return String(elementName || tagName)
  }

  private readonly getElementDescription = () => {
    const { element, type, name } = this.props

    if (type === 'Segment' && Boolean(name)) {
      const side = element.side

      return ` [${side}]`
    }
    else if (type === 'SegmentGroup') {
      return ''
    }

    return `:${element.id}`
  }
  
  public override render () {
    const {
      selected,
      target,
      active,
      depth,
      type,
      fullPath,
    } = this.props

    return (
      <Elements
        $selected={selected}
        onClick={this.handleClick}
        $spacer={depth * 15}
        $isActionsMenuOpen={this.state.showActions}
      >
        {this.getElementNameField()}
        <Teleport to='body'>
          {
            this.state.showActions && (
              <ElementsActionsMenu
                mousePosition={this.state.clickPosition}
                onClose={this.handleBlur}
                onSetFilter={this.handleSetFilter}
                handleJumpToElement={this.onTarget}
                handleOpenHistoryDetailDialog={this.onOpenTableDialog}
                active={active}
                target={target}
                type={type ?? ''}
                isRenaming={this.state.isRenaming}
                handleSetRenaming={this.onSetRenaming}
              />
            )
          }
        </Teleport>
        <Actions>
          <ChildrenSelector path={fullPath} />
          <OptionsButton onClick={this.handleShowActions}>
            <FontAwesomeIcon icon={faEllipsis} title='Show Actions' />
          </OptionsButton>
        </Actions>
      </Elements>
    )
  }
}

export default compose<any>(withTranslation('caster'), connector)(ElementsComponent)
