import { faCheck, faJedi, faSyncAlt, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import isEqual from 'lodash/isEqual'
import { useEffect, useRef, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import { ThemeProvider } from 'styled-components'
import { v4 as uuid } from 'uuid'

import { getPanelData } from '@/api/grafana-api'
import { LiveDataSocketHandler } from '@/api/live-data-socket-handler'
import { NetworkStatus } from '@/api/network-event'
import { setVisualizationConfig } from '@/api/visualization-config'
import { getCurrentDashboardEntry } from '@/App/util'
import { useConfig } from '@/config'
import IpcManager from '@/IpcManager'
import { CaseInfo } from '@/react/CasterTreeContent'
import { ProjectDataDialog } from '@/react/dialogs/project/ProjectDataDialog'
import FeatureFlags from '@/react/FeatureFlags'
import ApiClient from '@/store/apiClient'
import * as ApplicationActions from '@/store/application/main/actions'
import { AppState } from '@/store/application/main/consts'
import * as CasterDataServerActions from '@/store/casterDataServer'
import { getElementMapsObject } from '@/store/elements/logic'
import * as GrafanaDataActions from '@/store/grafanaData/actions'
import { LiveDataCache } from '@/store/liveData/LiveCDSDataCache'
import { useIsLiveDataEnabled } from '@/store/liveData/useLiveCDSData'
import * as TemporalDataActions from '@/store/temporalData/actions'
import { getReferenceDate } from '@/store/timestamps'
import * as VisualizationActions from '@/store/visualization/actions'
import FilterHandler from '@/three/logic/FilterHandler'
import type { DefaultState, ElementMaps, TagName } from '@/types/state'
import type { Translation } from '@/types/translation'
import { ElementMapsUtil } from '@/Util/ElementMapsUtil'
import { ElementsUtil } from '@/Util/ElementsUtil'
import { NumericIdMapping } from '@/Util/mapping/NumericIdMapping'
import { ObjectUtil } from '@/Util/ObjectUtil'

import StyleConfig from '../config/StyleConfig'
import ConfigDialogContent from '../ConfigDialogContent'
import ContextMenu from '../ContextMenu'
import AddDerivedPlotDialog from '../Dialogs/AddDerivedPlotDialog'
import AddPlotDialog from '../Dialogs/AddPlotDialog'
import DeleteDashboardDialog from '../Dialogs/DeleteDashboardDialog'
import DeletePlotDialog from '../Dialogs/DeletePlotDialog'
import { Dialog, DialogBackground } from '../Dialogs/DialogStyles'
import EditDashboardDialog from '../Dialogs/EditDashboardDialog'
import PlotExportDialog from '../Dialogs/PlotExportDialog'
import SelectSourceDialog from '../Dialogs/SelectSourceDialog'
import View from '../View'
import { ViewCompareLogic } from '../ViewCompareLogic'
import CasterDataServerHandler from './casterDataServerHandler'
import { DashboardWrapper, NetworkStatusDisplay, NoData } from './styles'
import TemporalDataHandler from './temporalDataHandler'

const connector = connect((state: DefaultState) => ({
  viewsObject: state.visualization.viewsObject,
  appState: state.application.main.appState,
  currentProject: state.application.main.currentProject,
  currentSimulationCase: state.application.main.currentSimulationCase,
  openConfigDialogWindow: state.visualization.openConfigDialogWindow,
  openDeleteDialogWindow: state.visualization.openDeleteDialogWindow,
  openAddPlotDialogWindow: state.visualization.openAddPlotDialogWindow,
  openDashboardWindow: state.visualization.openDashboardWindow,
  openDerivePlotDialog: state.visualization.openDerivePlotDialog,
  dashboardToDelete: state.visualization.dashboardToDelete,
  data: state.visualization.data,
  plotConfigs: state.visualization.plotConfigs,
  tileConfigs: state.visualization.tileConfigs,
  loadingStatus: state.visualization.loadingStatus,
  dataSource: state.visualization.dataSource,
  plotExport: state.visualization.plotExport,
  isEditModeOn: state.visualization.isEditModeOn,
  networkStatus: state.application.main.networkStatus,
  darkTheme: state.application.main.darkTheme,
  visualizationMetaInformation: state.visualization.visualizationMetaInformation,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
  isLoggedIn: FeatureFlags.isLoggedIn(state),
  selectedComparisonCaseIds: state.visualization.selectedComparisonCaseIds,
  timestamps: state.timestamps,
  currentDashboard: state.visualization.currentDashboard,
  plotsCompareCasterInformation: state.visualization.plotsCompareCasterInformation,
  authenticationData: state.application.main.authenticationData,
  temporalData: state.temporalData,
  grafanaData: state.grafanaData,
  cdsDataByUUID: state.casterDataServer.cdsDataByUUID,
  ...getElementMapsObject(state),
}), {
  setTemporalData: TemporalDataActions.setTemporalData,
  setGrafanaData: GrafanaDataActions.setGrafanaData,
  // setConfig: VisualizationActions.setConfig,
  setDataSources: VisualizationActions.setDataSources,
  setSecondarySize: VisualizationActions.setSecondarySize,
  setLoadingButtonStatus: VisualizationActions.setLoadingButtonStatus,
  openSelectSourceDialog: VisualizationActions.openSelectSourceDialog,
  openPlotExportDialog: VisualizationActions.openPlotExportDialog,
  setAppState: ApplicationActions.setAppState,
  openDialog: ApplicationActions.openDialog,
  setPlotsCompareCasterInformation: VisualizationActions.setPlotsCompareCasterInformation,
  addCDSDataByUUID: CasterDataServerActions.addCDSDataByUUID,
})

type PropsFromRedux = ConnectedProps<typeof connector>

export interface SimpleDashboardProps extends PropsFromRedux {
  dashboardId: string
  t: Translation
  casesInfo: CaseInfo[]
}

function Dashboard (props: SimpleDashboardProps) {
  const setVisualizationConfigTimeoutId = useRef<number | undefined>(undefined)
  const [ currentMainView, setCurrentMainView ] = useState<string | null | undefined>(null)
  const isLiveDataEnabled = useIsLiveDataEnabled()
  const prevProps = useRef<SimpleDashboardProps>(props)
  const prevMainView = useRef<string | null | undefined>(null)
  const prevLiveDataEnabled = useRef<boolean>(false)

  const handleAppStateChange = (prevAppState: AppState) => {
    const {
      visualizationMetaInformation,
      openDialog,
      currentProject,
      currentSimulationCase,
      appState,
      isLoggedIn,
    } = props

    const { config } = visualizationMetaInformation?.[appState] ?? {}

    if (
      (isLoggedIn && prevAppState !== appState) &&
      (currentProject?.id && currentSimulationCase?.id) &&
      (appState === AppState.Caster && !config)
    ) {
      openDialog(ProjectDataDialog.NAME)
    }
  }

  const handleCloseDataSourceDialog = () => {
    const { openSelectSourceDialog } = props

    openSelectSourceDialog(false)
  }

  const handleClosePlotExportDialog = () => {
    const { openPlotExportDialog } = props

    openPlotExportDialog(false)
  }

  const handleOpenProjectDialog = () => {
    // TODO: handle open new select dashboard dialog
    const { openDialog } = props

    openDialog(ProjectDataDialog.NAME)
  }

  const handleFixData = () => {
    ApiClient.get(`${'Network.URI(deprecated)'}/visualization_fix/data`)
  }

  const handleToggleLiveData = () => {
    LiveDataCache.setIsLiveDataEnabled(!isLiveDataEnabled)
  }

  const updateVisualizationConfig = async () => {
    const { appState, visualizationMetaInformation, plotConfigs, tileConfigs, viewsObject } = props

    const dashboardConfigId = visualizationMetaInformation?.[appState]?.config
    const data = { viewsObject, plotConfigs, tileConfigs }

    await setVisualizationConfig(dashboardConfigId, data)
  }

  const getSomePlotHasFilter = (plotIds: string[], plotConfigs: Record<string, any>) => {
    for (const plotId of plotIds) {
      if (plotId.includes('merged')) {
        const plotConfig = plotConfigs[plotId]
        const individualPlotConfigIds = (plotConfig.configIds ?? []) as string[]
  
        if (individualPlotConfigIds.some(id => plotConfigs[id]?.filter)) {
          return true
        }
      }
  
      if (plotConfigs[plotId]?.filter) {
        return true
      }
    }
  
    return false
  }
  
  const getCompareCasterInformation = async () => {
    const {
      selectedComparisonCaseIds,
      timestamps,
      plotConfigs,
      currentDashboard,
      viewsObject,
      tileConfigs,
      plotsCompareCasterInformation,
      currentProject,
      setPlotsCompareCasterInformation,
    } = props
  
    if (selectedComparisonCaseIds.length === 0) {
      return
    }
  
    const { viewId, dashboardId } = getCurrentDashboardEntry(currentDashboard, viewsObject)
  
    if (!viewId || !dashboardId) {
      return
    }
  
    const tileIds = viewsObject[viewId]?.dashboards?.[dashboardId]?.tileIds as string[]
  
    if (!tileIds?.length) {
      return
    }
  
    const plotIds = tileIds.map(tileId => tileConfigs[tileId]?.configId).filter(Boolean) as string[]
  
    if (plotIds.length === 0) {
      return
    }
  
    const types: string[] = []
    const references: string[] = []
    const somePlotHasFilter = getSomePlotHasFilter(plotIds, plotConfigs)
  
    for (const plotId of plotIds) {
      const plotConfig = plotConfigs[plotId ?? '']
  
      if (!plotConfig) {
        continue
      }
  
      const { filter, selectedX } = plotConfig
  
      if (filter) {
        const filterTypes = FilterHandler.getFilterElementTypes(filter)
  
        for (let i = 0; i < filterTypes.length; i++) {
          const type = filterTypes[i]
  
          if (type && !types.includes(type)) {
            types.push(type)
          }
        }
  
        if (filter.includes('#ref')) {
          // split filter by #ref and split by any character that isn't a letter, number or ':'
          const reference = filter.split('ref=')[1]?.split(/[^a-zA-Z0-9:]/)[0]
  
          if (reference && !references.includes(reference)) {
            references.push(reference)
          }
        }
      }
  
      if (!selectedX) { // then its a merged plot
        for (const config of plotConfig.configs) {
          const [ type ] = config.selectedX.split('|')
  
          if (!type || types.includes(type)) {
            continue
          }
  
          types.push(type)
        }
      }
      else {
        const [ type ] = selectedX.split('|')
  
        if (!type || types.includes(type)) {
          continue
        }
  
        types.push(type)
      }
    }
  
    const cleanTypes = types.filter(type => ElementsUtil.typeIsOfTypeCasterElementName(type)) as TagName[]
    const typesToBeRequestedPerCase: Record<string, TagName[]> = {}
  
    selectedComparisonCaseIds.forEach((caseId: string) => {
      if (caseId.startsWith('cds_')) {
        return
      }
  
      const currentCaseInfo = plotsCompareCasterInformation[caseId]
  
      if (!currentCaseInfo) {
        typesToBeRequestedPerCase[caseId] = [ ...cleanTypes ]
  
        return
      }
  
      typesToBeRequestedPerCase[caseId] = typesToBeRequestedPerCase[caseId] ?? []
  
      for (const type of cleanTypes) {
        if (!ElementsUtil.typeIsOfTypeCasterElementName(type)) {
          return
        }
  
        const mountLogMap = ElementMapsUtil.getMountLogMapByTagName(currentCaseInfo as ElementMaps, type)
        const slotMap = ElementMapsUtil.getSlotMapByTagName(currentCaseInfo as ElementMaps, type)
  
        if ((Object.keys(mountLogMap ?? {}).length === 0) || (Object.keys(slotMap ?? {}).length === 0)) {
          typesToBeRequestedPerCase[caseId] = [ ...cleanTypes ]
  
          return
        }
  
        const requiredMapsPerType = ElementsUtil.getMountLogMapKeysByTagName(type)
  
        if (
          requiredMapsPerType.some(mapKey => !currentCaseInfo[mapKey]) &&
          !typesToBeRequestedPerCase[caseId].includes(type)
        ) {
          typesToBeRequestedPerCase[caseId].push(type)
        }
      }
    })
  
    if (
      !Object.keys(typesToBeRequestedPerCase).length ||
      Object.values(typesToBeRequestedPerCase).every(types => types.length === 0)
    ) {
      return
    }
  
    const data = await ApiClient
      .get(`${useConfig().apiBaseURL}/casters/compare-plots`, {
        params: {
          date: getReferenceDate(timestamps).toISOString(),
          typesToBeRequestedPerCase,
          projectId: currentProject.id,
          dataLineRefs: references,
        },
      })
  
    const normalizedData = ElementsUtil.normalizeCompareCasterInformation(plotsCompareCasterInformation, data)
  
    for (const caseId in normalizedData) {
      const elementMaps = normalizedData[caseId] as ElementMaps
  
      NumericIdMapping.build(elementMaps, caseId)
  
      if (somePlotHasFilter) {
        ViewCompareLogic.buildComparePaths(elementMaps, caseId)
      }
    }
  
    setPlotsCompareCasterInformation(normalizedData)
  }

  const getTemporalData = async () => {
    //  go through all plots and see if they have a temporal data source
    //  if they do, request the data
    await TemporalDataHandler.getTemporalData(props)
  }

  // proof if there is a plot that has a cds data source
  const fetchCDSData = async () => {
    const { addCDSDataByUUID } = props
    const data = await CasterDataServerHandler.fetchCDSDataByUUIDs(props, false)

    if (data && Object.keys(data).length > 0) {
      addCDSDataByUUID(data)
    }
  }

  const getGrafanaData = async () => {
    const { tileConfigs, plotConfigs, currentDashboard, viewsObject, grafanaData, setGrafanaData } = props
    const { viewId, dashboardId } = getCurrentDashboardEntry(currentDashboard, viewsObject)
    const { tileIds } = viewsObject?.[viewId ?? '']?.dashboards?.[dashboardId ?? ''] ?? {}
    const plotConfigIds = tileIds?.map(tileId => tileConfigs[tileId]?.configId)?.filter(Boolean)
    const grafanaPlotConfigs = plotConfigIds
      ?.filter(plotConfigId => plotConfigs[plotConfigId ?? '']?.type === 'grafana')
      ?.map(plotConfigId => plotConfigs[plotConfigId ?? '']) ?? []
    const grafanaMergePlotConfigs = plotConfigIds
      ?.filter(plotConfigId => (plotConfigs[plotConfigId ?? '']?.configIds?.length ?? 0) > 1)
      ?.map(plotConfigId => plotConfigs[plotConfigId ?? '']?.configIds?.map(id => plotConfigs[id]))
      ?.flat()
      ?.filter(plotConfig => plotConfig?.type === 'grafana') ?? []

    const allGrafanaPlotConfigs = [ ...grafanaPlotConfigs, ...grafanaMergePlotConfigs ]
    const promises = []
    const requestedKeys: string[] = []

    for (const plotConfig of allGrafanaPlotConfigs) {
      const { dashboardId, panelId } = plotConfig?.data ?? {}

      if (!dashboardId || !panelId) {
        continue
      }

      const key = `${dashboardId}:${panelId}`

      if (grafanaData[key] || requestedKeys.includes(key)) {
        continue
      }

      requestedKeys.push(key)
      promises.push(getPanelData(dashboardId, panelId))
    }

    const dataList = await Promise.all(promises)
    const newGrafanaData: Record<string, any> = {}

    for (let i = 0; i < requestedKeys.length; i++) {
      const data = dataList[i]

      if (!data) {
        continue
      }

      const key = requestedKeys[i]

      if (!key || grafanaData[key] || newGrafanaData[key]) {
        continue
      }

      newGrafanaData[key] = data
    }

    if (Object.keys(newGrafanaData).length > 0) {
      setGrafanaData(newGrafanaData)
    }
  }

  const getSplitLayout = (currentId: string) => {
    const { dashboardId, viewsObject, isEditModeOn, casesInfo } = props
    const currentSplitData = viewsObject[currentId]?.split

    if (!currentSplitData) {
      return (
        <div data-id={currentId} className={isEditModeOn ? 'editable' : ''}>
          <View
            viewId={currentId}
            view={viewsObject[currentId]}
            simple
            dashboardId={dashboardId}
            casesInfo={casesInfo}
            onToggleLiveData={handleToggleLiveData}
            isLiveDataEnabled={isLiveDataEnabled}
          />
        </div>
      )
    }

    return null
  }

  useEffect(() => {
    const { viewsObject, setLoadingButtonStatus } = props
    const viewsObjectKeys = Object.keys(viewsObject ?? {})

    if (viewsObjectKeys.length > 0) {
      setCurrentMainView(viewsObjectKeys[0])
    }

    if (viewsObjectKeys.length === 0) {
      const viewId = `view_${uuid()}`

      setCurrentMainView(viewId)
    }

    IpcManager.internal.on('setLoading', (_event: any, loadingStatus: boolean, type: string) => {
      setLoadingButtonStatus(loadingStatus, type)
    })

    getTemporalData()
      .then(() => {})
      .catch(() => {})
    getGrafanaData()
      .then(() => {})
      .catch(() => {})

    fetchCDSData()
    handleAppStateChange(AppState.ParamDashboard)
    getCompareCasterInformation()

    LiveDataSocketHandler.handleComponentMount(
      (liveEnabled: boolean) => LiveDataCache.setIsLiveDataEnabled(liveEnabled),
      (data: Record<string, any>) => LiveDataCache.setData(data),
    )

    prevProps.current = props
    prevMainView.current = currentMainView

    // Anything in here is fired on component unmount.
    return () => {
      // Clear any pending timeouts
      if (setVisualizationConfigTimeoutId.current) {
        clearTimeout(setVisualizationConfigTimeoutId.current)
      }

      // Remove IPC listener
      IpcManager.internal.removeListener('setLoading')
    }
  }, [])

  useEffect(() => {
    const {
      viewsObject,
      featureFlags,
      setAppState,
      tileConfigs,
      appState,
      selectedComparisonCaseIds,
      currentDashboard,
    } = props
    const viewsObjectKeys = Object.keys(viewsObject ?? {})

    if ((viewsObjectKeys.length === 0) && !prevMainView.current) {
      const viewId = `view_${uuid()}`

      setCurrentMainView(viewId)
    }

    if (
      !ObjectUtil.isEqualWithoutUndefined(prevProps.current.viewsObject, viewsObject) && 
      (Object.keys(tileConfigs ?? {}).length > 0)
    ) {
      clearTimeout(setVisualizationConfigTimeoutId.current)

      setVisualizationConfigTimeoutId.current = window.setTimeout(updateVisualizationConfig, 1000)
    }

    if (
      (appState === AppState.ParamDashboard && !FeatureFlags.canViewParameterDashboard(featureFlags)) ||
      (appState === AppState.ResultDashboard && !FeatureFlags.canViewResultDashboard(featureFlags))
    ) {
      setAppState(AppState.Caster)
    }

    if ((currentMainView && !viewsObjectKeys.includes(currentMainView)) && (viewsObjectKeys.length > 0)) {
      setCurrentMainView(viewsObjectKeys[0])
    }

    handleAppStateChange(prevProps.current.appState)

    const prevDashboard = getCurrentDashboardEntry(prevProps.current.currentDashboard, prevProps.current.viewsObject)

    getTemporalData()
      .then(() => {})
      .catch(() => {})
    getGrafanaData()
      .then(() => {})
      .catch(() => {})
    fetchCDSData()

    // Use LiveDataSocketHandler to handle component lifecycle for live data fetching
    LiveDataSocketHandler.handleComponentUpdate(
      prevLiveDataEnabled.current,
      isLiveDataEnabled,
      CasterDataServerHandler,
      (data: Record<string, any>) => LiveDataCache.setData(data),
    )

    if (
      (
        selectedComparisonCaseIds.length > prevProps.current.selectedComparisonCaseIds.length &&
        !isEqual(prevProps.current.selectedComparisonCaseIds, selectedComparisonCaseIds)
      ) ||
      (
        currentDashboard?.['dashboardId'] !== prevDashboard?.dashboardId &&
        (selectedComparisonCaseIds.length > 0)
      )
    ) {
      getCompareCasterInformation()
    }

    // update prevProps
    prevProps.current = props
    prevLiveDataEnabled.current = isLiveDataEnabled
  }, [ props, isLiveDataEnabled, currentMainView ])

  const {
    openConfigDialogWindow,
    openDeleteDialogWindow,
    openAddPlotDialogWindow,
    openDerivePlotDialog,
    dashboardToDelete,
    loadingStatus,
    dataSource,
    plotExport,
    openDashboardWindow,
    networkStatus,
    darkTheme,
    // isEditModeOn,
    plotConfigs,
    t,
    isLoggedIn,
    appState,
    visualizationMetaInformation,
  } = props

  let icon
  const maxDashboardWidth = window.innerWidth - 390

  switch (networkStatus) {
    case NetworkStatus.Connected:
      icon = <FontAwesomeIcon icon={faCheck} fixedWidth />
      break
    case NetworkStatus.Connecting:
      icon = <FontAwesomeIcon icon={faSyncAlt} fixedWidth spin />
      break
    case NetworkStatus.Disconnected:
      icon = <FontAwesomeIcon icon={faTimes} fixedWidth />
      break
    default:
      icon = <FontAwesomeIcon icon={faJedi} fixedWidth title='!sith' />
      break
  }

  const isParamDB = appState === AppState.ParamDashboard
  const isResultDB = appState === AppState.ResultDashboard
  const { config } = visualizationMetaInformation?.[appState] ?? {}
  const noData = plotConfigs && Object.keys(plotConfigs).length === 0

  if (isLoggedIn && ((noData && isResultDB) || (!config && isParamDB))) {
    return (
      <ThemeProvider theme={darkTheme ? StyleConfig.darkTheme : StyleConfig.lightTheme}>
        <div>
          <DashboardWrapper>
            <NoData onClick={handleOpenProjectDialog}>
              {dataSource && <SelectSourceDialog onCloseDataSourceDialog={handleCloseDataSourceDialog} />}
              {t('dashboard.noData.heading')}
              <br />
              {t('dashboard.noData.secondHeading')}
            </NoData>
          </DashboardWrapper>
        </div>
      </ThemeProvider>
    )
  }

  return (
    <ThemeProvider theme={darkTheme ? StyleConfig.darkTheme : StyleConfig.lightTheme}>
      <div>
        <DashboardWrapper
          id='DashboardWrapper'
          $maxDashboardWidth={maxDashboardWidth}
        >
          {dataSource && <SelectSourceDialog onCloseDataSourceDialog={handleCloseDataSourceDialog} />}
          {plotExport && <PlotExportDialog onClosePlotExportDialog={handleClosePlotExportDialog} />}
          {
            openConfigDialogWindow &&
              (
                <div>
                  <DialogBackground />
                  <Dialog $width='850px' $height='450px' $noBottomBorder>
                    <ConfigDialogContent />
                  </Dialog>
                </div>
              )
          }
          {openDeleteDialogWindow && <DeletePlotDialog />}
          {openAddPlotDialogWindow && <AddPlotDialog />}
          {openDerivePlotDialog && <AddDerivedPlotDialog />}
          {openDashboardWindow && <EditDashboardDialog />}
          {Object.keys(dashboardToDelete ?? {}).length > 0 && <DeleteDashboardDialog />}
          {!loadingStatus.openVisualizationConfig && currentMainView && getSplitLayout(currentMainView)}
          <ContextMenu />
          <NetworkStatusDisplay title={networkStatus} className={networkStatus} onClick={handleFixData}>
            {icon}
          </NetworkStatusDisplay>
        </DashboardWrapper>
      </div>
    </ThemeProvider>
  )
}

export default compose<any>(withTranslation('visualization'), connector)(Dashboard)
