import { NetworkStatus } from '@/api/network-event'
import { getVisualizationConfigsByAppState } from '@/api/visualization-config'
import { MenuID } from '@/react/driver/DriverID'
import FeatureFlags from '@/react/FeatureFlags'
import { ApplicationMainActionsEnum, AppState } from '@/store/application/main/consts'
import type { Translation } from '@/types/translation'

import { MenuBuilderProps } from '..'
import { I } from '../../ApplicationActions'

export default class Menu {
  private static isUpdating = false

  private static casterDashboardConfigs: VisualizationConfig[] | null = null

  private static openDashBoardDisabledValues: Record<string, boolean> = {}

  private static openDashBoardConfigInfo: Record<string, { configId: string, tabIndex: string }> = {}

  public static evaluateActiveKey (activeKey: string, caster: Caster | null) {
    if (!activeKey) {
      return true
    }

    const xmlDataBase = caster?.additionalData?.XMLDataBase ?? {}

    if (!xmlDataBase) {
      return false
    }

    // // https://stackoverflow.com/questions/18473326/javascript-break-sentence-by-words
    // // to get all words
    const keys = activeKey.match(/\b(\w+)\b/g) ?? []

    for (const key of keys) {
      const value = ![ '0', undefined ].includes(xmlDataBase[key])

      // TODO: review security, it should be secure enough, if word is not in keys, then everything should be false or
      // true, no room for malicious functions or code
      // replace all occurrences of key with value ( match whole word only )
      activeKey = activeKey.replace(new RegExp(`\\b${key}\\b`, 'g'), String(value))
    }

    let result

    try {
      // eslint-disable-next-line no-eval
      result = eval(activeKey)
    }
    catch (error: any) {
      // eslint-disable-next-line no-console
      console.log('Evaluation Error:', activeKey, error)

      return false
    }

    return result
  }
  
  private static getDefaultFileMenu (menuEntries: any) {
    return [
      {
        id: MenuID.MenuFile.OpenProject,
        label: (t: Translation) => t('openProject'),
        shortcut: 'Ctrl+O',
        action: 'openProjectDialog',
        visible: (permissions: Record<string, boolean>, state: MenuBuilderProps) => (
          !state.isConsistencyCheck &&
          FeatureFlags.canViewOpenProjectMenuOption(permissions)
        ),
        disabled: ({ featureFlags, isConsistencyCheck }: MenuBuilderProps) => (
          !FeatureFlags.canUseOpenProjectMenuOption(featureFlags) ||
          isConsistencyCheck
        ),
      },
      {
        id: MenuID.MenuFile.NewProject,
        label: (t: Translation) => t('newProject'),
        shortcut: 'Ctrl+Shift+O',
        action: 'openNewProjectDialog',
        visible: (permissions: Record<string, boolean>, state: MenuBuilderProps) => (
          FeatureFlags.canViewCreateProjectMenuOption(permissions) &&
          !state.isConsistencyCheck
        ),
        disabled: ({ featureFlags, authenticationData, isConsistencyCheck }: MenuBuilderProps) => {
          const requiredObject = { application: { main: { authenticationData } } }

          return (
            !FeatureFlags.isLoggedIn(requiredObject as any) ||
            isConsistencyCheck ||
            !FeatureFlags.canUseCreateProjectMenuOption(featureFlags)
          )
        },
      },
      {
        visible: (permissions: Record<string, boolean>, state: MenuBuilderProps) => (
          FeatureFlags.canViewOpenProjectMenuOption(permissions) ||
          FeatureFlags.canViewCreateProjectMenuOption(permissions) ||
          !state.isConsistencyCheck
        ),
        type: 'separator',
      },
      {
        id: MenuID.MenuFile.UploadCaster,
        label: (t: Translation) => t('uploadCaster'),
        action: 'uploadCaster',
        visible: (permissions: Record<string, boolean>) => (
          FeatureFlags.usesSlimVersion(permissions) && // TODO: can be removed?
            FeatureFlags.canViewUploadCasterXMLMenuOption(permissions)
        ),
        disabled: ({ featureFlags }: MenuBuilderProps) => (
          !FeatureFlags.canUseUploadCasterXMLMenuOption(featureFlags)
        ),
      },
      {
        id: MenuID.MenuFile.UploadCatalog,
        label: (t: Translation) => t('uploadCatalog'),
        action: 'uploadCatalog',
        visible: (permissions: Record<string, boolean>) => (
          FeatureFlags.usesSlimVersion(permissions) && // TODO: can be removed?
            FeatureFlags.canViewUploadNozzleCatalogMenuOption(permissions)
        ),
        disabled: ({ featureFlags }: MenuBuilderProps) => (
          !FeatureFlags.canUseUploadNozzleCatalogMenuOption(featureFlags)
        ),
      },
      ...menuEntries,
      {
        type: 'separator',
      },
      {
        id: MenuID.MenuFile.LoadCaster,
        label: (t: Translation) => t('loadCaster'),
        action: 'loadCaster',
        visible: (permissions: Record<string, boolean>) => (
          FeatureFlags.usesSlimVersion(permissions) && // TODO: can be removed?
            FeatureFlags.canViewLoadCasterMenuOption(permissions)
        ),
        disabled: ({ currentSimulationCase, featureFlags, uploadLoading }: MenuBuilderProps) => (
          !currentSimulationCase?.currentCasterId ||
            uploadLoading.CasterDataIsLoading ||
            !FeatureFlags.canUseLoadCasterMenuOption(featureFlags)
        ),
      },
      {
        type: 'separator',
        visible: (permissions: Record<string, boolean>) => FeatureFlags.usesSlimVersion(permissions),
      },
      {
        id: MenuID.MenuFile.SelectCasterDashboard,
        label: (t: Translation) => t('selectDashboard'),
        action: 'openSelectDashboard',
        visible: (permissions: Record<string, boolean>) =>
          FeatureFlags.canViewSelectCasterDashboardMenuOption(permissions),
        disabled: ({ featureFlags }: MenuBuilderProps) => (
          !FeatureFlags.canUseSelectCasterDashboardMenuOption(featureFlags)
        ),
      },
      {
        id: MenuID.MenuFile.SendToConsistencyCheck,
        label: (t: Translation) => t('consistencyCheck'),
        action: 'sendCasterToConsistencyCheck',
        visible: (permissions: Record<string, boolean>, state: any) =>
          FeatureFlags.canViewSendToConsistencyCheckOption(permissions) &&
            Object.keys(state.CCElement ?? {}).length > 0,
        disabled: ({ featureFlags, Caster }: MenuBuilderProps) => (
          !Caster ||
            !FeatureFlags.canUseSendToConsistencyCheckOption(featureFlags)
        ),
      },
    ]
  }
  
  private static getDefaultEditMenu (menuEntries: any) {
    return [
      {
        id: MenuID.MenuEdit.ManageDynamicDataSources,
        label: (t: Translation) => t('manageDynamicDataSourcesDialog'),
        action: 'openManageDynamicDataSourcesDialog',
        visible: (permissions: Record<string, boolean>, { appState }: MenuBuilderProps) => 
          // TODO: add specific FF
          FeatureFlags.canViewManageDynamicDataMenuOption(permissions) &&
            appState === AppState.Caster,        
        disabled: ({ featureFlags, appState, visualizationMetaInformation }: MenuBuilderProps) => {
          const { config } = visualizationMetaInformation?.[AppState.Caster] ?? {}

          return (
            !config ||
            !FeatureFlags.canUseManageDynamicDataMenuOption(featureFlags, visualizationMetaInformation, appState)
          )
        },
      },
      {
        id: MenuID.MenuEdit.ManageExternalDataSources,
        label: (t: Translation) => t('manageExternalDataSourcesDialog'),
        action: 'openManageExternalDataSourcesDialog',
        visible: (permissions: Record<string, boolean>, { appState }: MenuBuilderProps) => 
          // TODO: add specific FF
          FeatureFlags.canViewEditExternalDataSourcesMenuOption(permissions) &&
            appState === AppState.Caster,        
        disabled: ({ featureFlags, visualizationMetaInformation }: MenuBuilderProps) => {
          const { config } = visualizationMetaInformation?.[AppState.Caster] ?? {}

          return (
            !config ||
            !FeatureFlags.canUseEditExternalDataSourcesMenuOption(featureFlags)
          )
        },
      },
      {
        id: MenuID.MenuEdit.ConfigurePlots,
        label: (t: Translation) => t('configurePlots'),
        action: ApplicationMainActionsEnum.configurePlots,
        disabled: (state: MenuBuilderProps) => (
          state.networkStatus !== NetworkStatus.Connected ||
          Object.keys(state.plotConfigs ?? {}).length === 0 ||
          !state.isEditModeOn ||
          !FeatureFlags.canUseConfigurePlotsMenuOption(
            state.featureFlags,
            state.visualizationMetaInformation,
            state.appState,
          )
        ),
        visible: (permissions: any) => FeatureFlags.canViewConfigurePlotsMenuOption(permissions),
      },
      {
        id: MenuID.MenuEdit.ResetAllShim,
        label: (t: Translation) => t('resetAllShim'),
        action: 'resetAllShim',
        disabled: ({ currentSimulationCase, Caster, featureFlags }: MenuBuilderProps) => (
          !FeatureFlags.canUseFullyResetShimMenuOption(featureFlags) ||
          !currentSimulationCase?.currentCasterId ||
          Caster?.additionalData?.XMLDataBase?.['ShimSupportPoint'] !== '1'
        ),
        visible: (permissions: any) => (
          FeatureFlags.canViewModulesMenuOption(permissions) &&
          FeatureFlags.canViewFullyResetShimMenuOption(permissions)
        ),
      },
      {
        id: MenuID.MenuEdit.CreateRealDataCase,
        label: (t: Translation) => t('createRealDataCase'),
        action: 'openCreateRealDataCaseDialog',
        // TODO: define FeatureFlags for this
        visible: (permissions: any) => FeatureFlags.canViewCreateRealDataCaseMenuOption(permissions),
        disabled: ({ currentSimulationCase, featureFlags }: MenuBuilderProps) => (
          !currentSimulationCase?.currentCasterId ||
          // if blueprintId is set, it's a real data case
          Boolean(currentSimulationCase?.blueprintId) ||
          !FeatureFlags.canUseCreateRealDataCaseMenuOption(featureFlags)
        ),
      },
      {
        id: MenuID.MenuEdit.ShareState,
        label: (t: Translation) => t('shareState'),
        action: 'openShareStateDialog',
        visible: (permissions: any) => FeatureFlags.canViewShareStateMenuOption(permissions),
        disabled: ({ featureFlags }: MenuBuilderProps) => (
          !FeatureFlags.canUseShareStateMenuOption(featureFlags)
        ),
      },
      ...menuEntries,
    ]
  }

  private static getViewMenu () {
    return [
      {
        id: MenuID.MenuView.ToggleFullScreen,
        label: (t: Translation) => t('toggleFullScreen'),
        shortcut: 'F11',
        doNotPreventDefault: false,
        action: 'toggleFullScreenReact',
        visible: (permissions: any) => FeatureFlags.canViewToggleFullScreenMenuOption(permissions),
        disabled: ({ featureFlags }: MenuBuilderProps) => (
          !FeatureFlags.canUseToggleFullScreenMenuOption(featureFlags)
        ),
      },
      {
        id: MenuID.MenuView.ExitFullScreen,
        label: (t: Translation) => t('exitFullScreen'),
        shortcut: 'ESC',
        action: 'exitFullScreenReact',
        visible: (permissions: any) => FeatureFlags.canViewExitFullScreenMenuOption(permissions),
        disabled: ({ featureFlags }: MenuBuilderProps) => (
          !FeatureFlags.canUseExitFullScreenMenuOption(featureFlags)
        ),
      },
      {
        id: MenuID.MenuView.Reload3D,
        label: (t: Translation) => t('kill3D'),
        action: 'kill3D',
        visible: (permissions: any) => FeatureFlags.canViewRecreate3DMenuOption(permissions),
        disabled: ({ Caster, featureFlags }: MenuBuilderProps) => (
          !Caster ||
          !FeatureFlags.canUseRecreate3DMenuOption(featureFlags)
        ),
      },
    ]
  }

  // TODO: do we need to check for other configs?
  // private static readonly checkIfDashboardConfigExists = async (state: MenuBuilderProps, configId: string) => {
  //   const featureFlags = FeatureFlags.getRealFeatureFlags(state)

  //   if (!FeatureFlags.canViewCasterDashboard(featureFlags)) {
  //     return false
  //   }

  //   const { authenticationData } = state.application.main

  //   let config: VisualizationConfig | string | null = null

  //   if (authenticationData.casterDashboardConfigs?.find(config => config.id === configId)) {
  //     const configObject = authenticationData.casterDashboardConfigs.find(config => config.id === configId)

  //     config = await getGroupVisualizationConfigFile(configObject?.id ?? '', true)
  //   }
    
  //   if (!config) {
  //     config = await getVisualizationConfig(configId, { emptySelectedComparisonCasters: 1 }, true)
  //   }

  //   return Boolean(config)
  // }

  private static readonly generateOpenDashBoardDisabledValue = (
    state: MenuBuilderProps,
    definition: ExecutableDefinition,
  ) => {
    const { configName, xmlDataBaseAttributes } = definition

    if (!configName || !xmlDataBaseAttributes) {
      return true
    }

    const hasRequiredData = xmlDataBaseAttributes
      ?.some((attribute) => state.Caster?.additionalData?.XMLDataBase?.[attribute] === '1')

    if (!hasRequiredData) {
      return true
    }

    const configNames = configName.split(',')

    for (const configName of configNames) {
      const [ name, tab ] = configName.trim().split('#')

      const config = Menu.casterDashboardConfigs?.find(({ name: configName }) => configName === name)

      // TODO: do we need to check for other configs? await Menu.checkIfDashboardConfigExists(state, config.id)

      if (config) {
        Menu.openDashBoardConfigInfo[definition.id] = {
          configId: config.id,
          tabIndex: String(tab ?? 1),
        }

        return false
      }
    }

    return true
  }

  private static readonly generateOpenDashBoardDisabledValues = (
    state: MenuBuilderProps,
    executableDefinitions: ExecutableDefinition[],
  ) => {
    Menu.openDashBoardDisabledValues = {}

    for (const definition of executableDefinitions) {
      Menu.openDashBoardDisabledValues[definition.id] = Menu.generateOpenDashBoardDisabledValue(state, definition)
    }
  }

  private static getRunMenu (executableDefinitions: ExecutableDefinition[], permissions?: any) {
    return {
      id: 'caster-menu-run',
      disabled: () => !FeatureFlags.canUseModulesMenuOption(permissions),
      visible: (_permissions: Record<string, boolean>, { isConsistencyCheck }: MenuBuilderProps) =>
        !isConsistencyCheck && FeatureFlags.canViewModulesMenuOption(permissions),
      label: (t: Translation) => t('modules'),
      submenu: (executableDefinitions.length > 0)
        ? executableDefinitions.map((definition) => ({
          id: `caster-menu-run-${definition.name.toLowerCase().replace(/\s+/g, '-')}`,
          label: () => definition.name,
          action: definition.isDownloadXMLModule ? 'openDownloadXMLDialog' : 'openExecutableDialog',
          params: () => definition,
          disabled: ({ Caster, currentSimulationCase }: MenuBuilderProps) =>
            !currentSimulationCase?.id ||
            !this.evaluateActiveKey(definition.activeKey ?? '', Caster),
          submenu: [
            {
              id: `caster-menu-run-${definition.name.toLowerCase().replace(/\s+/g, '-')}-execute`,
              label: (t: Translation) => t('run'),
              action: 'openExecutableDialog',
              params: () => definition,
            },
            {
              id: `caster-menu-run-${definition.name.toLowerCase().replace(/\s+/g, '-')}-dashboard`,
              label: (t: Translation) => t('viewDashboard'),
              action: 'openDashboard',
              params: () => Menu.openDashBoardConfigInfo[definition.id],
              disabled: () => Menu.openDashBoardDisabledValues[definition.id] ?? true,
            },
          ],
        }))
        : [ { label: (t: Translation) => t('noExecutables'), disabled: () => true } ],
    }
  }

  private static getCasterMenu (featureFlags: any) {
    return [
      {
        id: MenuID.MenuFile.ID,
        label: (t: Translation) => t('file'),
        visible: (permissions: any) => FeatureFlags.canViewCaster(permissions),
        submenu: Menu.getDefaultFileMenu([
          {
            id: MenuID.MenuFile.SaveCasterXML,
            label: (t: Translation) => t('download'),
            shortcut: 'Ctrl+S',
            action: 'saveCasterXML',
            visible: (permissions: any) => FeatureFlags.canViewDownloadCasterXMLMenuOption(permissions),
            params: (state: MenuBuilderProps) => state.currentSimulationCase.id,
            disabled: ({ currentSimulationCase }: MenuBuilderProps) => (
              !currentSimulationCase?.id ||
              !FeatureFlags.canUseDownloadCasterXMLMenuOption(featureFlags)
            ),
          },
        ]),
      },
      {
        id: MenuID.MenuEdit.ID,
        label: (t: Translation) => t('edit'),
        submenu: Menu.getDefaultEditMenu([]),
      },
      {
        id: MenuID.MenuView.ID,
        label: (t: Translation) => t('view'),
        submenu: Menu.getViewMenu(),
      },
    ]
  }

  private static readonly dashboardMenu = [
    {
      id: MenuID.MenuFile.ID,
      label: (t: Translation) => t('file'),
      visible: (_permissions: any) => true,
      submenu: Menu.getDefaultFileMenu([
        { // this is here to bind the shortcut and prevent default
          // TODO: is this necessary?
          id: MenuID.MenuFile.SaveVisualizationChanges,
          label: (_t: Translation) => '',
          visible: () => false,
          shortcut: 'Ctrl+S',
          action: 'saveVisChanges',
        },
      ]),
    },
    {
      id: MenuID.MenuEdit.ID,
      label: (t: Translation) => t('edit'),
      submenu: Menu.getDefaultEditMenu([
        {
          id: MenuID.MenuEdit.ToggleEditMode,
          label: (t: Translation, { isEditModeOn }: MenuBuilderProps) =>
            t('editMode', { state: isEditModeOn ? 'On' : 'Off' }),
          shortcut: 'F7',
          action: ApplicationMainActionsEnum.editMode,
          visible: (permissions: any) => FeatureFlags.canViewToggleEditMode(permissions),
          disabled: ({ featureFlags }: MenuBuilderProps) =>
            !FeatureFlags.canUseToggleEditMode(featureFlags),
        },
        {
          id: MenuID.MenuEdit.ExportPlots,
          label: (t: Translation) => t('exportPlots'),
          action: ApplicationMainActionsEnum.exportPlots,
          visible: (permissions: any) => FeatureFlags.canViewExportPlots(permissions),
          disabled: (state: MenuBuilderProps) => (
            state.networkStatus !== NetworkStatus.Connected ||
            (
              !FeatureFlags.canUseExportPlots(state.featureFlags) ||
              Object.keys(state.plotConfigs ?? {}).length === 0
            )
          ),
        },
        {
          id: MenuID.MenuEdit.MapCommands,
          label: (t: Translation) => t('mapCommands'),
          action: ApplicationMainActionsEnum.mapCommands,
          // TODO: Store commands in redux
          visible: (permissions: any) => FeatureFlags.canViewCommandFileMapping(permissions),
          disabled: ({ featureFlags }: MenuBuilderProps) =>
            !FeatureFlags.canUseCommandFileMapping(featureFlags),
        },
      ]),
    },
  ]

  private static readonly applicationMenu = [
    {
      id: MenuID.MenuHelp.ID,
      label: 'pe-7s-help1',
      submenu: [
        {
          id: MenuID.MenuHelp.about,
          label: (t: Translation) => t('about'),
          action: 'openAboutDialog',
        },
      ],
    },
    {
      id: MenuID.MenuUser.ID,
      label: (_t: Translation, authData: AuthData) => (
        authData?.name
          ? (authData?.name ?? ' ')[0]?.toUpperCase()
          : <I className='pe-7s-user' />
      ),
      submenu: [
        {
          label: (
            _t: Translation,
            authData: AuthData,
          ) => authData?.name ?? 'offline',
        },
        {
          id: MenuID.MenuUser.Settings,
          label: (t: Translation) => t('settings'),
          disabled: () => true,
        },
        {
          type: 'separator',
          visible: (_permission: any, authData: AuthData) =>
            (authData?.name ?? '').length > 0,
        },
        {
          id: MenuID.MenuUser.Logout,
          label: (t: Translation) => t('logout'),
          visible: (_permission: any, authData: AuthData) =>
            (authData?.name ?? '').length > 0,
          action: 'ACTION_RESET_STORE',
        },
      ],
    },
  ]
  
  public static menu (
    appState: AppState,
    executableDefinitions: ExecutableDefinition[],
    permissions?: Record<string, boolean>,
  ) {
    const showRunMenu = permissions?.['Menu_Modules_View']

    return appState !== AppState.Caster
      ? Menu.dashboardMenu
      : (showRunMenu
        ? [ ...Menu.getCasterMenu(permissions), Menu.getRunMenu(executableDefinitions ?? [], permissions) ]
        : Menu.getCasterMenu(permissions))
  }
  
  public static getApplicationMenu () {
    return Menu.applicationMenu
  }

  public static async update (
    state: MenuBuilderProps,
    executableDefinitions: ExecutableDefinition[],
    menuBuilderInstance: React.Component,
  ) {
    const { currentProject, Caster } = state

    if (
      (Menu.isUpdating)
    ) {
      return
    }

    Menu.isUpdating = true

    try {
      if (Caster?.id) {
        Menu.casterDashboardConfigs = await getVisualizationConfigsByAppState(currentProject.id, AppState.Caster)
      }

      Menu.generateOpenDashBoardDisabledValues(state, executableDefinitions)

      menuBuilderInstance.forceUpdate()
    }
    catch (error) {
      // eslint-disable-next-line no-console
      console.error('MenuBuilder', 'update', error)  
    }

    Menu.isUpdating = false
  }
}
