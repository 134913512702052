import { Group, Object3D } from 'three'

import MainView from '@/three/views/MainView'

export type BaseObjects = Record<string, Object3D | undefined>

export type SetValuesData<Slot extends BaseSlot | null, MountLog extends BaseMountLog | null> = {
  elementData: MountLog & Slot & { id: number }
  path: string
  isDeleted: boolean
  isHidden: boolean
  isPhantom: boolean
  view: MainView
  isLiveCDSDataEnabled?: boolean
  liveCDSData?: Record<string, number>
}

// TODO: it would be nice if Parent could extend BaseObject
// TODO: but that causes Segment to extend BaseObject<SegmentGroup> which does not work
export default class BaseObject<
  Slot extends BaseSlot | null,
  MountLog extends BaseMountLog | null,
  Parent,
  Objects extends BaseObjects,
> {
  public container: Group

  public parent: Parent

  public objects: Objects

  protected isHidden: boolean

  public path?: string

  protected valuesHash: string | null = null

  public constructor (container: Group, parent: Parent) {
    this.container = container
    this.parent = parent
    this.objects = {} as Objects
    this.isHidden = false
  }

  public static getPasslineHeight (
    passlineSections: FullCasterElement<PasslineSectionSlot, PasslineSectionMountLog>[],
  ) {
    if (passlineSections.length === 0) {
      return 0
    }

    // return the biggest divided by 1000
    return Math.max(...passlineSections.map(passlineSection => passlineSection.passlineCoord ?? 0)) / 1000
  }

  protected getValuesHash (data: SetValuesData<Slot, MountLog>): string | null {
    const { slotHash, mountLogHash } = data.elementData

    if (!slotHash || !mountLogHash) {
      return null
    }

    const additional = { isDeleted: data.isDeleted }

    return `${slotHash}_${mountLogHash}_${JSON.stringify(additional)}`
  }

  protected shouldUpdate (data: SetValuesData<Slot, MountLog>): boolean {
    if (data.isPhantom) {
      return true
    }

    const currentValuesHash = this.getValuesHash(data)

    return currentValuesHash ? (this.valuesHash !== currentValuesHash || data.isDeleted) : true
  }

  protected updateValuesHash (data: SetValuesData<Slot, MountLog>) {
    this.valuesHash = this.getValuesHash(data)
  }

  public setValues (data: SetValuesData<Slot, MountLog>) {
    if (!this.shouldUpdate(data)) {
      // console.log('Skip setting values', this.constructor.name)

      return
    }

    this.updateValuesHash(data)
    this.internalSetValues(data)
  }

  protected internalSetValues (data: SetValuesData<Slot, MountLog>) {
    this.path = data.path
    this.isHidden = data.isHidden ?? false

    if (this.isHidden) {
      this.hide()
    }
  }

  public show (_skipRollerChildren = false) {
    if (this.isHidden) {
      return
    }

    Object.values(this.objects).forEach((object: any) => {
      object.visible = true
    })

    this.container.visible = true

    if (this.parent && this.parent instanceof BaseObject) {
      this.parent.show()
    }
  }

  public hide () {
    Object.values(this.objects).forEach((object: any) => {
      object.visible = false
    })
  }

  public setSelected (_isSelected: boolean) {
    // do nothing
  }

  public dispose () {
    // remove from phantom object
    if (this.container.parent && this.container.parent.remove) {
      this.container.parent.remove(this.container)
    }
  }
}
