import * as THREE from 'three'

import { StrandSides } from '@/types/elements/enum'

import BaseObject, { BaseObjects, SetValuesData } from './BaseObject'
import { Const } from './Const'
import Mold from './Mold'
import PasslineCurve from './PasslineCurve'
import ThreeSegment from './Segment'
import Util from '../logic/Util'

interface Objects extends BaseObjects {
  nozzle: THREE.Mesh
}

export default class ThreeNozzle extends BaseObject<NozzleSlot, NozzleMountLog, ThreeSegment, Objects> {
  private static Geometry3DCache: Record<string, THREE.ConeGeometry> = {}

  private static Geometry3DMeasureCache: Record<number, THREE.Box3> = {}

  private static Geometry3DCacheNextId = 1

  private static Geometry2DCache: Record<string, THREE.BufferGeometry> = {}

  private static readonly selectedMaterial =
    new THREE.MeshStandardMaterial({ color: '#7eda41', roughness: 0.5, metalness: 0.5 })

  private static readonly selectedMaterialBasic = new THREE.MeshBasicMaterial({ color: '#7eda41' })

  // eslint-disable-next-line max-len
  private static readonly phantomMaterial = new THREE.MeshStandardMaterial({
    color: '#7eda41',
    transparent: true,
    opacity: 0.6,
    roughness: 0.5,
    metalness: 0.5,
  })

  private static readonly phantomMaterialBasic =
    new THREE.MeshBasicMaterial({ color: '#7eda41', transparent: true, opacity: 0.6 })

  // eslint-disable-next-line max-len
  private static readonly deletedMaterial = new THREE.MeshStandardMaterial({
    color: '#da131b',
    transparent: true,
    opacity: 0.6,
    roughness: 0.5,
    metalness: 0.5,
  })

  private static readonly deletedMaterialBasic =
    new THREE.MeshBasicMaterial({ color: '#da131b', transparent: true, opacity: 0.6 })

  // eslint-disable-next-line max-len
  private static readonly selectedDeletedMaterial = new THREE.MeshStandardMaterial({
    color: '#da4515',
    transparent: true,
    opacity: 0.4,
    roughness: 0.5,
    metalness: 0.5,
  })

  private static readonly selectedDeletedMaterialBasic = new THREE.MeshBasicMaterial({
    color: '#da4515',
    transparent: true,
    opacity: 0.4,
  })

  private readonly clickableObjects: THREE.Object3D[]

  private readonly tooltipObjects: THREE.Object3D[]

  private wCoord: number

  public plCoord: number

  private loopColorIndex = 0

  private sectionDetail?: boolean

  private isPhantom?: boolean

  private static readonly loopColoredMaterials = Const.colors.map(color =>
    new THREE.MeshStandardMaterial({ color, roughness: 0.5, metalness: 0.5 }))

  private static readonly loopColoredMaterialsBasic = Const.colors.map(color =>
    new THREE.MeshBasicMaterial({
      color,
      transparent: true,
      opacity: 0.6,
    }))

  private static readonly loopColoredMaterialsLine = Const.colors.map(color =>
    new THREE.LineBasicMaterial({
      color: Util.updateColor(color, 66),
      linewidth: 1,
    }))

  private static readonly loopColorHelper = Const.colors.length

  private static readonly coneRadius = 0.5

  private static readonly rectangularScale = ThreeNozzle.coneRadius / Math.sqrt(Math.pow(ThreeNozzle.coneRadius, 2) / 2)
  // rectangularScale is about Math.sqrt(2) if coneRadius === 0.5

  public override dispose (): void {
    super.dispose()

    if (!this.container || !this.container.children) {
      return
    }

    for (const child of this.container.children) {
      if (!(child instanceof THREE.Mesh)) {
        continue
      }

      if (child.material && child.material.dispose) {
        child.material.dispose()
      }

      if (child.geometry && child.geometry.dispose) {
        child.geometry.dispose()
      }

      this.container.remove(child)
    }

    this.objects = {} as Objects
  }
  
  private static getSections (format: string, ratio: number) {
    switch (format) {
      case 'conic':
        return ratio > 0.5 ? 8 : 4 // 8 % 2 === 0 (see rectangularScale)
      case 'rectangular':
        return 4
      default:
        return 4
    }
  }
  
  public static getB (a: number, beta: number) {
    const sinAlpha = Math.sin((90 - beta) * Util.RAD)
    const sinBeta = Math.sin(beta * Util.RAD)

    return (a / sinAlpha) * sinBeta
  }
  
  private static get3DGeometry (nozzleData: any) {
    const {
      angleLength,
      angleWidth,
      format,
      height,
    } = nozzleData

    const realHeight = height / 1000

    let scale = 1

    if (format === 'rectangular') {
      scale = ThreeNozzle.rectangularScale
    }

    const diameterWidth = ThreeNozzle.getB(realHeight, angleWidth / 2) * 2 * scale

    const segments = ThreeNozzle.getSections(format, Math.abs(angleLength / angleWidth))

    const geometryKey = `${ThreeNozzle.coneRadius}_${realHeight}_${angleLength}_${angleWidth}_${segments}_${format}`
    let geometry = ThreeNozzle.Geometry3DCache[geometryKey]

    if (!geometry) {
      geometry = new THREE.ConeGeometry(ThreeNozzle.coneRadius, realHeight, segments) // no Buffer!

      geometry = Util.getSmoothedGeometry(geometry, 80)

      if (format === 'rectangular') {
        geometry.rotateY(45 * Util.RAD)
      }

      const diameterLength = ThreeNozzle.getB(realHeight, angleLength / 2) * 2 * scale

      geometry.applyMatrix4(new THREE.Matrix4().makeScale(diameterWidth ?? 0.00001, 1, diameterLength ?? 0.00001))

      geometry = Util.getSmoothedGeometry(geometry, 80)

      geometry.translate(0, realHeight / 2, 0)

      geometry.userData['id'] = ThreeNozzle.Geometry3DCacheNextId++

      ThreeNozzle.Geometry3DCache[geometryKey] = geometry
    }

    return geometry
  }
  
  private static get2DGeometry (nozzleData: any) {
    const {
      angleWidth,
      format,
      height,
    } = nozzleData

    const scaledHeight = height / 1000

    const geometryKey = `${ThreeNozzle.coneRadius}_${scaledHeight}_${angleWidth}_${format}`
    let geometry = ThreeNozzle.Geometry2DCache[geometryKey]

    if (!geometry) {
      let scale = 1

      if (format === 'rectangular') {
        scale = ThreeNozzle.rectangularScale
      }

      const diameterWidth = ThreeNozzle.getB(scaledHeight, angleWidth / 2) * 2 * scale
      const dia = diameterWidth / scale / 2

      const a = new THREE.Vector3(dia, 0, 0)
      const b = new THREE.Vector3(-dia, 0, 0)
      const c = new THREE.Vector3(0, 0, -scaledHeight)
      const mesh = Util.getTriangleMesh(a, c, b)

      geometry = mesh.geometry

      ThreeNozzle.Geometry2DCache[geometryKey] = geometry
    }

    return geometry
  }

  public constructor (container: any, parent: any, clickableObjects: any, tooltipObjects: any) {
    super(container, parent)

    this.clickableObjects = clickableObjects
    this.tooltipObjects = tooltipObjects

    this.wCoord = 0
    this.plCoord = 0
  }

  protected override internalSetValues (data: SetValuesData<NozzleSlot, NozzleMountLog>) {
    super.internalSetValues(data)

    const { elementData } = data

    this.loopColorIndex = (elementData as any).colorIndex ?? elementData.coolLoopIndex ?? 0
    this.sectionDetail = data.view.sectionDetail
    this.isPhantom = data.isPhantom

    const {
      angleWidth,
      format,
      height,
      passlineCoord,
      widthCoord,
    } = elementData
    
    const realHeight = (height ?? 0) / 1000
    
    this.wCoord = (widthCoord ?? 0) / 1000
    this.plCoord = (passlineCoord ?? 0) / 1000

    let scale = 1

    if (format === 'rectangular') {
      scale = ThreeNozzle.rectangularScale
    }

    const diameterWidth = ThreeNozzle.getB(realHeight, (angleWidth ?? 0) / 2) * 2 * scale
    const colorIndex = this.loopColorIndex % ThreeNozzle.loopColorHelper

    let oldNozzle

    if (!this.sectionDetail) {
      const geometry = ThreeNozzle.get3DGeometry(elementData)
      const material = !this.isPhantom
        ? ThreeNozzle.loopColoredMaterials[colorIndex]
        : ThreeNozzle.phantomMaterial

      oldNozzle = this.objects.nozzle

      this.objects.nozzle = new THREE.Mesh(geometry, material)
      this.objects.nozzle.userData = oldNozzle?.userData ?? {}
    }
    else if (this.sectionDetail) {
      // get rid of the scale if it's other than 1
      const dia = diameterWidth / scale / 2

      const a = new THREE.Vector3(dia, 0, 0)
      const b = new THREE.Vector3(-dia, 0, 0)
      const c = new THREE.Vector3(0, 0, -realHeight)

      const geometry = ThreeNozzle.get2DGeometry(elementData)
      const material = !this.isPhantom
        ? ThreeNozzle.loopColoredMaterialsBasic[colorIndex]
        : ThreeNozzle.phantomMaterialBasic

      oldNozzle = this.objects.nozzle

      this.objects.nozzle = new THREE.Mesh(geometry, material)
      this.objects.nozzle.userData = oldNozzle?.userData ?? {}

      const { nozzle } = this.objects

      const wireFrame = new THREE.Group()
      const lineMaterial = ThreeNozzle.loopColoredMaterialsLine[colorIndex]!

      Util.drawLine(wireFrame, a.x, a.y, a.z, b.x, b.y, b.z, 'WireFrameLineAB', lineMaterial)
      Util.drawLine(wireFrame, b.x, b.y, b.z, c.x, c.y, c.z, 'WireFrameLineBC', lineMaterial)
      Util.drawLine(wireFrame, c.x, c.y, c.z, a.x, a.y, a.z, 'WireFrameLineCA', lineMaterial)

      wireFrame.position.y += 0.0001
      wireFrame.name = 'WireFrame'

      Util.addOrReplace(nozzle, wireFrame)

      if (!this.isPhantom) {
        const offset = dia * 1000
        const { id } = Util.getElementInfo(data.path)
        const { side } = this.container.userData ?? {}

        const s = side === StrandSides.Loose || side === StrandSides.Right ? -1 : 1

        Util.createTooltipMarker(
          nozzle,
          this.tooltipObjects,
          `tooltipMarkerHeight_${side}_nozzle_${id}`,
          c,
          (
            <div>
              <b>Nozzle:{id}</b>
              <br />
              <br />
              width_coord: {widthCoord}
              <br />
              height: {realHeight}
              <br />
              Side: {side}
            </div>
          ),
        )

        Util.createTooltipMarker(
          nozzle,
          this.tooltipObjects,
          `tooltipMarkerR_${side}_nozzle_${id}`,
          b,
          (
            <div>
              <b>Nozzle:{id}</b>
              <br />
              <br />
              width_coord: {(Number(widthCoord) - offset * s).toFixed(2)}
              <br />
              Side: {side}
            </div>
          ),
        )

        Util.createTooltipMarker(
          nozzle,
          this.tooltipObjects,
          `tooltipMarkerL_${side}_nozzle_${id}`,
          a,
          (
            <div>
              <b>Nozzle:{id}</b>
              <br />
              <br />
              width_coord: {(Number(widthCoord) + offset * s).toFixed(2)}
              <br />
              Side: {side}
            </div>
          ),
        )
      }
    }

    const { nozzle } = this.objects

    nozzle.name = `${data.path}${this.isPhantom ? '_Phantom' : ''}`
    nozzle.userData['type'] = 'Nozzle'

    Util.addOrReplaceInList(oldNozzle, nozzle, this.clickableObjects)
    Util.addOrReplace(this.container, nozzle)

    this.updateTransform()

    // TODO: does this work? then remove old code
    // nozzle.geometryNeedsUpdate = true
    nozzle.geometry.getAttribute('position').needsUpdate = true
    nozzle.userData['path'] = data.path

    // FIXME: is this ever used? is the element and phantom not removed right away?
    nozzle.userData['deleted'] = data.isDeleted

    this.setSelected(nozzle.userData['selected'])
  }
  
  public updateTransform () {
    const { nozzle } = this.objects
    const { side } = this.container.userData ?? {}
    const { position, angleX, normal } = PasslineCurve.getInfoAtPlCoord(
      this.plCoord ?? 0,
      this.sectionDetail ? true : undefined,
    )

    const newPosition = new THREE.Vector3(0, 0, 0)
    const newRotation = new THREE.Euler(0, 0, 0, 'XYZ')
    const newSectionRotation = new THREE.Euler(0, 0, 0, 'XYZ')

    const { FixedSide, LooseSide, NarrowFaceRight, NarrowFaceLeft } = Mold.sideDistance

    switch (side) {
      case StrandSides.Fixed:
        newPosition.set(this.wCoord, position.y, position.z + FixedSide.x)
        newPosition.add(normal.clone().setLength(FixedSide.z))
        newRotation.set(-Util.RAD90 - angleX, 0, 0)
        break
      case StrandSides.Loose:
        newPosition.set(this.wCoord, position.y, position.z + LooseSide.x)
        newPosition.add(normal.clone().setLength(LooseSide.z))
        newRotation.set(Util.RAD90 - angleX, 0, 0)
        newSectionRotation.y = Util.RAD180
        break
      case StrandSides.Right:
        newPosition.set(NarrowFaceRight.x, position.y, position.z)
        newPosition.add(normal.clone().setLength(NarrowFaceRight.z - this.wCoord))
        newRotation.set(-Util.RAD90 - angleX, 0, Util.RAD90)
        newSectionRotation.y = Util.RAD90
        break
      case StrandSides.Left:
        newPosition.set(NarrowFaceLeft.x, position.y, position.z)
        newPosition.add(normal.clone().setLength(NarrowFaceLeft.z - this.wCoord))
        newRotation.set(-Util.RAD90 - angleX, 0, -Util.RAD90)
        newSectionRotation.y = -Util.RAD90
        break
      default:
    }

    nozzle.position.copy(newPosition)
    nozzle.rotation.copy(!this.sectionDetail ? newRotation : newSectionRotation)
  }

  public getMeasures () {
    const { geometry, position: { y } } = this.objects.nozzle
    const { id } = geometry.userData

    if (id && !ThreeNozzle.Geometry3DMeasureCache[id]) {
      geometry.computeBoundingBox()

      if (geometry.boundingBox) {
        ThreeNozzle.Geometry3DMeasureCache[id] = geometry.boundingBox
      }
    }

    const { min, max } = ThreeNozzle.Geometry3DMeasureCache[id] ?? { min: { z: 0 }, max: { z: 0 } }

    return {
      heightMin: y + min.z,
      heightMax: y + max.z,
    }
  }
  
  public override setSelected (isSelected: boolean) {
    const { deleted } = this.objects.nozzle.userData

    if (this.isPhantom) {
      return
    }

    this.objects.nozzle.userData['selected'] = isSelected

    if (!this.sectionDetail) {
      this.objects.nozzle.material = isSelected
        ? (deleted ? ThreeNozzle.selectedDeletedMaterial : ThreeNozzle.selectedMaterial)
        : (deleted
          ? ThreeNozzle.deletedMaterial
          : ThreeNozzle.loopColoredMaterials[(this.loopColorIndex ?? 0) % ThreeNozzle.loopColorHelper]) ??
          ThreeNozzle.loopColoredMaterials[0]!
    }
    else {
      this.objects.nozzle.material = isSelected
        ? (deleted ? ThreeNozzle.selectedDeletedMaterialBasic : ThreeNozzle.selectedMaterialBasic)
        : (deleted
          ? ThreeNozzle.deletedMaterialBasic
          : ThreeNozzle.loopColoredMaterialsBasic[(this.loopColorIndex ?? 0) % ThreeNozzle.loopColorHelper]) ??
          ThreeNozzle.loopColoredMaterialsBasic[0]!
    }
  }
}
