import ThreeBase from './ThreeBase'

export default class ThreeManager {
  private static baseInstance?: ThreeBase | null

  private static ref = null

  public static init (ref: any) {
    ThreeManager.ref = ref
  }

  public static get base () {
    if (!ThreeManager.baseInstance) {
      ThreeManager.baseInstance = new ThreeBase()
    }

    return ThreeManager.baseInstance
  }

  public static killBase () {
    ThreeManager.baseInstance?.kill()

    delete ThreeManager.baseInstance

    ThreeManager.baseInstance = null
  }

  public static reInit () {
    if (ThreeManager.ref && !ThreeManager.base.isInitialized) {
      ThreeManager.baseInstance?.init(ThreeManager.ref)
    }
  }

  public static cleanViews (views: string[]) {
    ThreeManager.base.cleanViews(views)
  }

  public static toggleRollerVisibility () {
    ThreeManager.base.views.mainView?.toggleRollerVisibility()
  }

  public static toggleStrandBending () {
    ThreeManager.base.toggleStrandBending()
  }

  public static toggleSectionView () {
    ThreeManager.base.views.uiView?.handleToggleSectionView()
  }

  public static toggleLiveDataIn3D () {
    ThreeManager.base.views.mainView?.toggleLiveDataIn3D()
  }

  public static centerCamera (shiftClick: boolean) {
    if (shiftClick) {
      // FIXME: The height of the camera after this is called is wrong
      ThreeManager.base.views.mainView?.setCenter()

      return
    }

    ThreeManager.base.jumpToFiltered(true)
  }

  public static getAmountOfJumpableElements () {
    return ThreeManager.base.views.uiView?.getAmountOfJumpableElements() ?? 0
  }
}
