import { useEffect, useRef, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import styled, { css } from 'styled-components'
import { v4 as uuid } from 'uuid'

import * as ApplicationActions from '@/store/application/main/actions'
import { AppState } from '@/store/application/main/consts'
import DataActions from '@/store/data/actions'
import { getElementMapsObject } from '@/store/elements/logic'
import * as FilterActions from '@/store/filter/actions'
import { useIsLiveDataEnabled, useLiveData } from '@/store/liveData/useLiveCDSData'
import { getReferenceDate } from '@/store/timestamps'
import * as UtilActions from '@/store/util/actions'
import ThreeManager from '@/three/ThreeManager'
import type { CasterElementNames, CreateValid, EditElements, ParentIdMap } from '@/types/data'
import type { DefaultState, ElementMaps } from '@/types/state'

import { PlaybackWindow } from './casterDataServer/PlaybackWindow'
import { ProjectDataDialog } from './dialogs/project/ProjectDataDialog'
import FeatureFlags from './FeatureFlags'
import CasterLoadingOverlay from './specific/CasterLoadingOverlay'
import UpdatesDisplay from './UpdatesDisplay'

const CasterFrame = styled.div<{
  $CasterTree: boolean
  $CasterDialog: boolean
  $CasterDashboard: boolean
  $blur: boolean
  $casterDashboardWidth: number
  $currentCasterDialogWidth: number
}>`${({
  $CasterTree,
  $CasterDialog,
  $CasterDashboard,
  $blur,
  $casterDashboardWidth,
  $currentCasterDialogWidth,
}: any) =>
  css`
  position: absolute;
  top: 50px;
  right: ${$CasterDialog ? '335px' : '0'};
  bottom: 0;
  left: ${$CasterTree ? ($CasterDashboard ? `${$casterDashboardWidth}px` : '280px') : '0'};
  filter: blur(${$blur ? '5px' : '0'});
  overflow: hidden;
  background-color: #000000;
  text-align: center;
  width: calc(100vw - ${$CasterDialog ? `${$currentCasterDialogWidth || 335}px` : '0px'} -
  ${$CasterTree ? ($CasterDashboard ? `${$casterDashboardWidth}px` : '280px') : '0px'});

  > canvas {
    pointer-events: all !important;
  }
`}`

interface TooltipContainerProps {
  $mousePos: {
    x: number
    y: number
  }
}

const TooltipContainer = styled.div.attrs<TooltipContainerProps>(({ $mousePos }) => ({
  style: {
    top: `${$mousePos.y}px`,
    left: `${$mousePos.x}px`,
  },
}))<TooltipContainerProps>`
  position: absolute;
  text-align: left;
  color: #FFFFFF;
  font-size: 14px;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  pointer-events: none;
  z-index: 200;
`

const Tooltip = styled.div<{ key: string, $position: Positions }>(({ $position }) => {
  let transform = ''

  switch ($position) {
    case 'top':
      transform = 'translate(-50%, -20px)'
      break
    case 'right':
      transform = 'translate(20px, -50%)'
      break
    case 'bottom':
      transform = 'translate(-50%, 20px)'
      break
    case 'left':
      transform = 'translate(-20px, -50%)'
      break
  }

  // eslint-disable-next-line no-implicit-coercion -- without the outer template string, the highlighting breaks...
  return `${css`
    transform: ${transform};
    display: inline-block;
    background-color: rgba(100, 100, 100, 0.95);
    padding: 10px;
    border: 1px solid rgba(150, 150, 150, 0.95);
    border-radius: 5px;
    margin: 5px;
    white-space: nowrap;
    user-select: none;
  `}`
})

const connector = connect(({
  data,
  application,
  filter,
  visualization,
  util,
  timestamps,
  ...remainingState
}: DefaultState) => ({
  additionalData: data.additionalData,
  amountOfComparisonCasterColumns: visualization.amountOfComparisonCasterColumns,
  appState: application.main.appState,
  createValid: data.createValid,
  currentCasterDialogWidth: visualization.currentCasterDialogWidth,
  casterDashboardWidth: visualization.casterDashboardWidth,
  currentProject: application.main.currentProject,
  casterDashboardTabIndex: visualization.casterDashboardTabIndex,
  currentSimulationCase: application.main.currentSimulationCase,
  dirtyDeletePaths: data.dirtyDeletePaths,
  dirtyPaths: data.dirtyPaths,
  editElements: data.editElements,
  editValues: data.editValues,
  featureFlags: FeatureFlags.getRealFeatureFlags({ application } as DefaultState),
  hasChanges: data.hasChanges,
  hidePaths: data.hidePaths,
  isLoggedIn: FeatureFlags.isLoggedIn({ application } as DefaultState),
  newCopiedElementsToDraw: data.newCopiedElementsToDraw,
  openDialogs: application.main.openDialogs,
  parentPath: data.parentPath,
  rollerChildren: util.rollerChildren,
  selectedPaths: data.selectedPaths,
  target: filter.target,
  term: filter.term,
  termDisabled: filter.termDisabled,
  updatesCount: data.updatesCount,
  timestamps,
  loadingStatus: application.main.loadingStatus,
  openAppDialogs: application.main.openAppDialogs,
  ...getElementMapsObject(remainingState as DefaultState),
}), {
  clearDirtyPaths: DataActions.clearDirtyPaths,
  openDialog: ApplicationActions.openDialog,
  removeDeletePaths: DataActions.removeDeletePaths,
  resetTarget: FilterActions.resetTarget,
  setSelectedElementPaths: DataActions.setSelectedElementPaths,
  setLoadingStatus: ApplicationActions.setLoadingStatus,
  setNewCopiedElementsToDraw: DataActions.setNewCopiedElementsToDraw,
  setRollerVisible: UtilActions.setRollerVisible,
  setTerm: FilterActions.setTerm,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  isNewCaster: boolean
  redraw: boolean
  blur: boolean
}

export type PassedData = {
  additionalData: any
  amountOfComparisonCasterColumns: number
  clearDirtyPaths: typeof DataActions.clearDirtyPaths
  createValid: CreateValid
  dirtyDeletePaths: string[]
  dirtyPaths: string[]
  editElements: EditElements
  // eslint-disable-next-line @typescript-eslint/ban-types
  editValues: Record<CasterElementNames | 'General', any>
  elementMaps: ElementMaps
  featureFlags: Record<string, boolean>
  hasChanges: boolean
  hidePaths: string[]
  isNewCaster: boolean
  newCopiedElementsToDraw: boolean
  parentPath: ParentIdMap
  redraw: boolean
  removeDeletePaths: typeof DataActions.removeDeletePaths
  resetTarget: typeof FilterActions.resetTarget
  rollerChildren: number
  selectedPaths: Set<string>
  setSelectedElementPaths: (selectedData?: any, multiSelect?: boolean, massSelect?: boolean) => void
  setLoadingStatus: typeof ApplicationActions.setLoadingStatus
  setNewCopiedElementsToDraw: typeof DataActions.setNewCopiedElementsToDraw
  setRollerVisible: typeof UtilActions.setRollerVisible
  setTerm: typeof FilterActions.setTerm
  target: string
  term: string
  termDisabled: boolean
  setTooltip: (tooltip: Tooltip | null) => void
  tooltip: Tooltip | null
  referenceCasterDate: Date
  sgStartPasslnCoord: number
  sgEndPasslnCoord: number
  liveCDSData: Record<string, number>
  isLiveCDSDataEnabled: boolean
}

function CasterContainer (props: Props) {
  const prevAppState = useRef<AppState | null>(null)
  const mountRef = useRef<any>(null)
  const [ mousePos, setMousePosState ] = useState({ x: 0, y: 0 })
  const [ sectionViewTooltip, setSectionViewTooltip ] = useState<Tooltip | null>(null)
  const [ sgStartPasslnCoord, setSgStartPasslnCoord ] = useState(0)
  const [ sgEndPasslnCoord, setSgEndPasslnCoord ] = useState(0)
  const [ isStateLoading, setIsStateLoading ] = useState(false)
  const liveCDSData = useLiveData()
  const isLiveCDSDataEnabled = useIsLiveDataEnabled()

  const handleCurrentSegmentGroupChanged = (event: CustomEvent) => {
    const { startPasslnCoord, endPasslnCoord } = event.detail

    setSgStartPasslnCoord(startPasslnCoord)
    setSgEndPasslnCoord(endPasslnCoord)
  }

  const handleCasterStateLoading = (event: CustomEvent) => {
    const { loading } = event.detail

    setIsStateLoading(loading)

    if (loading) {
      document.body.style.cursor = 'wait'
    }
    else {
      document.body.style.cursor = 'default'
    }
  }

  const setMountRef = (ref: any) => {
    if (!ref || mountRef.current) {
      return
    }

    mountRef.current = ref

    ThreeManager.init(mountRef.current)
    ThreeManager.base.init(mountRef.current)
  }

  const setTooltip = (sectionViewTooltip: Tooltip | null) => {
    setSectionViewTooltip(sectionViewTooltip)
  }

  // Tried to use event as MouseEvent type but there were missing properties
  const setMousePos = (event: MouseEvent): any => {
    if (!mountRef.current) {
      return
    }

    setMousePosState({
      x: event.clientX,
      y: event.clientY - 30,
    })
  }

  useEffect(() => {
    ThreeManager.base.mount()

    window.addEventListener('pointermove', setMousePos, true)
    ;(window as any).addEventListener('CurrentSegmentGroupChanged', handleCurrentSegmentGroupChanged)
    ;(window as any).addEventListener('CasterStateLoading', handleCasterStateLoading)

    return () => {
      ThreeManager.base.unmount()
      ThreeManager.killBase()

      window.removeEventListener('pointermove', setMousePos, true)
      ;(window as any).removeEventListener(
        'CurrentSegmentGroupChanged',
        handleCurrentSegmentGroupChanged,
      )
      ;(window as any).removeEventListener('CasterStateLoading', handleCasterStateLoading)
    }
  }, [])

  useEffect(() => {
    const {
      currentProject,
      currentSimulationCase,
      openDialog,
      appState,
      isLoggedIn,
      featureFlags,
      loadingStatus,
      additionalData,
      amountOfComparisonCasterColumns,
      clearDirtyPaths,
      createValid,
      dirtyDeletePaths,
      dirtyPaths,
      editElements,
      editValues,
      hasChanges,
      hidePaths,
      isNewCaster,
      newCopiedElementsToDraw,
      parentPath,
      redraw,
      removeDeletePaths,
      resetTarget,
      rollerChildren,
      selectedPaths,
      setSelectedElementPaths,
      setLoadingStatus,
      setNewCopiedElementsToDraw,
      setRollerVisible,
      setTerm,
      target,
      term,
      termDisabled,
      timestamps,
    } = props

    if (
      prevAppState.current !== appState &&
      appState === AppState.Caster &&
      isLoggedIn &&
      currentProject?.id &&
      currentSimulationCase?.id &&
      !loadingStatus && // true when caster is loading
      !FeatureFlags.usesSlimVersion(featureFlags)
    ) {
      openDialog(ProjectDataDialog.NAME)
    }

    if (prevAppState.current !== appState) {
      prevAppState.current = appState
    }

    const elementMaps = getElementMapsObject(props)

    const data: PassedData = {
      additionalData,
      amountOfComparisonCasterColumns,
      clearDirtyPaths,
      createValid,
      dirtyDeletePaths,
      dirtyPaths,
      editElements,
      editValues,
      elementMaps,
      featureFlags,
      hasChanges,
      hidePaths,
      isNewCaster,
      newCopiedElementsToDraw,
      parentPath,
      redraw,
      removeDeletePaths,
      resetTarget,
      rollerChildren,
      selectedPaths,
      setSelectedElementPaths,
      setLoadingStatus,
      setNewCopiedElementsToDraw,
      setRollerVisible,
      setTerm,
      target,
      term,
      termDisabled,
      tooltip: sectionViewTooltip,
      setTooltip,
      referenceCasterDate: getReferenceDate(timestamps),
      sgStartPasslnCoord,
      sgEndPasslnCoord,
      liveCDSData,
      isLiveCDSDataEnabled,
    }

    ThreeManager.base.setData(data)
  }, [ props, liveCDSData, isLiveCDSDataEnabled ])

  const {
    openDialogs,
    blur,
    updatesCount,
    isLoggedIn,
    casterDashboardTabIndex,
    casterDashboardWidth,
    currentCasterDialogWidth,
    featureFlags,
    openAppDialogs,
  } = props

  const allowedDialogs = [ PlaybackWindow.NAME ]
  const filteredOpenAppDialogs = openAppDialogs.filter(dialog => !allowedDialogs.includes(dialog))
  const { tooltip, position } = sectionViewTooltip ?? {}

  return (
    <>
      <CasterFrame
        ref={setMountRef}
        $blur={blur}
        $CasterTree={openDialogs.includes('CasterTree')}
        $CasterDialog={openDialogs.includes('CasterDialog') || openDialogs.includes('PartsWarehouse')}
        $CasterDashboard={casterDashboardTabIndex > 0}
        $casterDashboardWidth={Math.min(casterDashboardWidth ?? 500, window.innerWidth - 390)}
        $currentCasterDialogWidth={currentCasterDialogWidth}
      >
        {isLoggedIn && FeatureFlags.canToggleLiveMode(featureFlags) && <UpdatesDisplay updatesCount={updatesCount} />}
        {isStateLoading && <CasterLoadingOverlay />}
      </CasterFrame>
      {
        sectionViewTooltip && !filteredOpenAppDialogs.length && (
          <TooltipContainer $mousePos={mousePos}>
            <Tooltip key={uuid()} $position={position}>{tooltip}</Tooltip>
          </TooltipContainer>
        )
      }
    </>
  )
}

export default compose<any>(connector)(CasterContainer)
