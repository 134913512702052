import { connectToLiveData, disconnectFromLiveData } from './caster-data-server'
export class LiveDataSocketHandler {
  private static liveCDSDataCallback: ((data: Record<string, any>) => void) | null = null

  private static liveCDSDataActive = false

  public static readonly LiveCDSDataReceived = 'live-cds-data-received'

  /**
   * Start fetching live CDS data via WebSocket
   *
   * This method establishes a WebSocket connection to fetch live data from the Caster Data Server.
   * If the WebSocket connection fails, it automatically falls back to polling.
   *
   * The callback function should update component state for optimal performance rather than
   * dispatching Redux actions, which can cause unnecessary re-renders.
   *
   * @param plant The plant to fetch data for (e.g., 'NucorBrandenburg')
   * @param uuids Array of UUIDs to fetch data for
   * @param callback Function to call when new data is received, typically updates component state
   */
  public static startLiveCDSDataFetching (
    plant: string,
    callback: (data: Record<string, any>) => void,
  ) {
    // Close any existing WebSocket
    LiveDataSocketHandler.liveCDSDataCallback = callback
    LiveDataSocketHandler.liveCDSDataActive = true

    const events = {
      onConnect: () => {
        // Do nothing
      },
      onData: (data: Record<string, any>) => {
        if (LiveDataSocketHandler.liveCDSDataCallback) {
          LiveDataSocketHandler.liveCDSDataCallback(data)
        }
      },
      onError: () => {
        // Do nothing
      },
      onDisconnect: () => {
        // Do nothing
      },
    }

    connectToLiveData(plant, events)
  }

  /**
   * Stop fetching live CDS data
   */
  public static stopLiveCDSDataFetching () {
    LiveDataSocketHandler.liveCDSDataActive = false
    disconnectFromLiveData()
  }

  /**
   * Start fetching live CDS data for a dashboard component
   *
   * This method is a convenience wrapper that gets UUIDs from the CasterDataServerHandler
   * and starts fetching live data for those UUIDs.
   *
   * @param cdsHandler The CasterDataServerHandler class
   * @param props The component props
   * @param updateState Function to update component state with live data
   */
  public static startLiveCDSDataFetchingForDashboard (
    cdsHandler: any,
    updateState: (data: Record<string, any>) => void,
  ) {
    LiveDataSocketHandler.startLiveCDSDataFetching(
      cdsHandler.plant,
      (data) => {
        // Always update component state
        updateState(data)
        // We no longer update Redux store as we're using component state for better performance
      },
    )
  }

  /**
   * Handle component lifecycle for live data fetching
   *
   * This method should be called in componentDidUpdate to handle changes in live mode or dashboard configuration.
   *
   * @param prevLiveEnabled Previous value of liveDataEnabled
   * @param currentLiveEnabled Current value of liveDataEnabled
   * @param cdsHandler The CasterDataServerHandler class
   * @param props The component props
   * @param prevProps The previous component props
   * @param updateState Function to update component state with live data
   * @param _updateReduxStore Function to update Redux store with live data (unused, kept for backward compatibility)
   */
  public static handleComponentUpdate (
    prevLiveEnabled: boolean,
    currentLiveEnabled: boolean,
    cdsHandler: any,
    updateState: (data: Record<string, any>) => void,
  ): void {
    // Handle changes in live mode
    if (prevLiveEnabled !== currentLiveEnabled) {
      if (currentLiveEnabled) {
        // Live mode was enabled
        // const uuids = cdsHandler.getCDSUUIDsToFetch(props, true)

        // if (uuids.length === 0) {
        //   return
        // }

        LiveDataSocketHandler.startLiveCDSDataFetchingForDashboard(
          cdsHandler,
          updateState,
        )
      }
      else {
        // Live mode was disabled
        LiveDataSocketHandler.stopLiveCDSDataFetching()
      }
    }

    // do not check for now, because we fetch all data
    // If live mode is enabled, check if we need to restart due to configuration changes
    // if (currentLiveEnabled) {
    //   // Check for changes that would require restarting the WebSocket
    //   // recalculate uuids, if there are new uuids, stop the live data socket and start a new one
    //   const uuids = cdsHandler.getCDSUUIDsToFetch(props, true)

    //   if (uuids.length === 0) {
    //     return
    //   }

    //   const oldUuids = LiveDataSocketHandler.liveCDSDataUUIDs
    //   const shouldRestart = uuids.length && uuids.every((uuid: string) => oldUuids?.includes(uuid))

    //   if (!shouldRestart) {
    //     LiveDataSocketHandler.stopLiveCDSDataFetching()
    //     LiveDataSocketHandler.startLiveCDSDataFetchingForDashboard(
    //       cdsHandler,
    //       uuids,
    //       updateState,
    //     )
    //   }
    // }
  }

  public static handleComponentMount (
    updateLiveEnabled: (liveEnabled: boolean) => void,
    onDataCallback: (data: Record<string, any>) => void,
  ) {
    if (LiveDataSocketHandler.liveCDSDataActive) {
      updateLiveEnabled(true)
      LiveDataSocketHandler.liveCDSDataCallback = onDataCallback
    }
  }
}
