import { PureComponent, ReactNode } from 'react'

import PlotWrapper from '@/react/visualization/PlotWrapper'
import type { PlotConfigMap, TileConfigMap } from '@/types/visualization'

type Props = {
  /**
   * PlotConfig ID
   */
  configId: string
  tileId: string
  plotConfigs: PlotConfigMap
  tileConfigs: TileConfigMap
  viewId: string
  forceUpdateHandler: () => void
}

export class StaticPlot extends PureComponent<Props> {
  private readonly getShapeIds = (type: string, tileId: string, tileConfigs: TileConfigMap): string[] => {
    if (type !== 'line' && type !== 'area' && type !== 'bar') {
      return []
    }

    const shapeIds = tileConfigs[tileId]?.shapeIds ?? []

    return shapeIds.map(shapeId => shapeId.id).filter(shapeId => shapeId !== undefined && shapeId !== '')
  }

  private getXDomain (data: any): [number, number] {
    const { xDomainMin: xMin, xDomainMax: xMax } = data
    const xDomain = data.xValues
      ? [
        xMin !== undefined && String(xMin).length ? Number(xMin) : data.xValues[0],
        xMax !== undefined && String(xMax).length ? Number(xMax) : data.xValues[data.xValues.length - 1],
      ]
      : [ 0, 0 ]

    return xDomain as [number, number]
  }

  private getYDomain (data: any): [number, number] {
    const { yDomainMin: yMin, yDomainMax: yMax } = data
    const yDomain = data.yValueRange
      ? [
        yMin !== undefined && String(yMin).length ? Number(yMin) : data.yValueRange[0],
        yMax !== undefined && String(yMax).length ? Number(yMax) : data.yValueRange[1],
      ]
      : [ 0, 0 ]

    return yDomain as [number, number]
  }

  public override render (): ReactNode | null {
    const { configId: plotConfigId, tileId, plotConfigs, tileConfigs, viewId, forceUpdateHandler } = this.props
    const data = plotConfigs[plotConfigId]
    const tileData = tileConfigs[tileId]
    const tileConfig = tileConfigs[tileId]

    if (!data || tileData === undefined) {
      return null
    }

    const plotType = tileConfig?.type ?? 'line'
    const shapeIds = this.getShapeIds(plotType, tileId, tileConfigs)
    const xDomain = this.getXDomain(data)
    const yDomain = this.getYDomain(data)

    return (
      <PlotWrapper
        tileId={tileId}
        key={tileId}
        configId={plotConfigId}
        type={plotType}
        xDomain={xDomain}
        yDomain={yDomain}
        xRange={data.xRangeMin && data.xRangeMax ? [ data.xRangeMin, data.xRangeMax ] : [ 0, 0 ]}
        xValues={data.xValues}
        yValueRange={data.yValueRange}
        valueRange={data.vRangeMin && data.vRangeMax ? [ data.vRangeMin, data.vRangeMax ] : [ 0, 0 ]}
        shapeIds={shapeIds}
        radiusDomain={[ data.radiusMin, data.radiusMax ]}
        radius0={data.radius0}
        radius={data.radius}
        frequency={data.frequency}
        xLabel={tileData.xLabel ?? ''}
        yLabel={tileData.yLabel ?? ''}
        viewId={viewId}
        flipAxes={tileData.flipAxes}
        forceUpdateHandler={forceUpdateHandler}
      />
    )
  }
}
