/* eslint-env browser */

import { PureComponent } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import { CaseInfo } from '@/react/CasterTreeContent'
import type { DefaultState } from '@/types/state'

import { Wrapper } from './Dashboard/styles'
import Tabs from './Tabs'
import ViewGrid from './ViewGrid'
import ViewTile from './ViewTile'

const connector = connect(({
  visualization,
}: DefaultState) => ({
  currentDashboard: visualization.currentDashboard,
  viewsObject: visualization.viewsObject,
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  viewId: string
  simple: boolean
  casesInfo: CaseInfo[]
  isLiveDataEnabled?: boolean
  onToggleLiveData?: () => void
}

type State = {
  tileIdsWithoutData: string[]
}

export class View extends PureComponent<Props, State> {
  public override state: State = {
    tileIdsWithoutData: [],
  }

  public override render () {
    const {
      viewsObject,
      viewId,
      currentDashboard,
      simple,
      casesInfo,
      isLiveDataEnabled,
      onToggleLiveData,
    } = this.props

    const object = viewsObject[viewId]
    const dashboards = object?.dashboards

    const showTabs = true // isEditModeOn || Object.keys(dashboards).length > 1
    const dashboardId = currentDashboard[viewId]
    const dashboard = dashboards?.[dashboardId ?? '']
    const currentLayout = dashboard?.layout ?? []
    const oldLayout = dashboard?.oldLayout ?? []
    const viewDashboards = object?.dashboards
    const currentDashboardTiles = viewDashboards?.[dashboardId ?? '']

    const tiles = currentDashboardTiles?.tileIds
      ?.map(tileId => (
        <ViewTile 
          tileId={tileId} 
          key={tileId} 
          viewId={viewId} 
          isLiveDataEnabled={isLiveDataEnabled}
        />
      )) ?? []

    return (
      <Wrapper>
        {(showTabs && !simple) && <Tabs viewId={viewId} />}
        <ViewGrid
          viewId={viewId}
          showTabs={showTabs}
          currentDashboard={currentDashboard[viewId]}
          layout={currentLayout}
          oldLayout={oldLayout}
          dashboard={dashboard ?? {}}
          simple={simple}
          casesInfo={casesInfo}
          isLiveDataEnabled={isLiveDataEnabled}
          onToggleLiveData={onToggleLiveData}
        >
          {tiles}
        </ViewGrid>
      </Wrapper>
    )
  }
}

export default compose<any>(connector)(View)
