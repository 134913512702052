export class Logger {
  private readonly context: string

  public constructor (context: string) {
    this.context = context
  }

  public info (message: string, ...args: any[]): void {
    // eslint-disable-next-line no-console
    console.info(`[${this.context}] ${message}`, ...args)
  }

  public warn (message: string, ...args: any[]): void {
    // eslint-disable-next-line no-console
    console.warn(`[${this.context}] ${message}`, ...args)
  }

  public error (message: string, ...args: any[]): void {
    // eslint-disable-next-line no-console
    console.error(`[${this.context}] ${message}`, ...args)
  }

  public debug (message: string, ...args: any[]): void {
    // eslint-disable-next-line no-console
    console.debug(`[${this.context}] ${message}`, ...args)
  }
}
