import type { IconDefinition } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Checkbox, FormControl, MenuItem, Select } from '@mui/material'
import { withTheme } from '@mui/styles'
import { Component, isValidElement } from 'react'
import { compose } from 'redux'
import styled, { css } from 'styled-components'
import tinycolor from 'tinycolor2'

import Delete from '@/react/specific/Delete'

const MdFormControl = styled(FormControl)`${({ disabled }) =>
  css`
  width: 100%;
  ${disabled && css`cursor: not-allowed;`}

  > div {
    ${disabled && css`pointer-events: none;`}
  }
`}`

const Badge = styled.div`${({ theme }) =>
  css`
  margin: 0 5px;
  border: 1px solid ${theme['colors'].swatch13};
  border-radius: 5px;
  font-size: 14px!important;
  padding: 0 5px;
  color: ${theme['colors'].swatch13};
  text-transform: capitalize;
`}`

const ActionsWrapper = styled.div`
  display: none;
  align-items: center;
`

type OptionProps = { $notRemovable?: boolean, $spaceBetween?: boolean }

const Option = styled(MenuItem)<OptionProps>`${({ theme, $notRemovable, $spaceBetween }) =>
  css`
  display: flex;
  ${$spaceBetween && 'justify-content: space-between !important;'}

  span {
    ${!$spaceBetween && 'flex: 15;'};
  }

  div.default-actions {
    display: flex;
    flex: 1;
    ${!$notRemovable && 'display: inline-flex !important;'};
    font-size: 24px;
    margin: -10px 0;

    > * {
      margin-top: 4px;
    }
  }

  &[data-selected-helper="true"] ${Badge} {
    color: ${theme['colors'].swatch15};
  }

  &[role='option'] {
    ${ActionsWrapper} {
      display: flex;
    }
  }
`}`

const Actions = styled.div<{ $nextToCheckbox: boolean }>`${({ theme, $nextToCheckbox }) =>
  css`
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 15px;
  padding: ${$nextToCheckbox ? '0 15px' : '0 0 0 15px'};
  margin: -3px 0;

  .action {
    padding-top: 2px;
    font-size: 17px;

    &:hover {
      color: ${theme['primary'].light}
    }
  }
`}`

const StyledCheckbox = styled(Checkbox)`
  height: 10px;
  width: 10px;
  padding-right: 5px;
  > span:hover {
    color: rgb(33 218 184 / 80%) !important;
    background-color: transparent !important;
  }
`

const OptGroup = styled(Option as any)`${({ theme, disabled }) =>
  css`
  outline: none;
  font-family: 'Roboto', sans-serif;
  border-top: 1px solid ${theme['primary'].font}!important;
  padding-left: 16px!important;
  font-weight: 600!important;
  ${disabled && css`pointer-events: none !important;` as any}
  
  span {
    padding-left: 5px;
  }
  
  & ~ ${Option} {
    padding-left: 30px;
  }
`}`

const Default = styled.div<{ $active: boolean }>`${({ theme, $active }) =>
  css`
  margin: 0 5px;
  ${$active ? 'color: #e0b60f;' : ''}

  &:hover {
    color: ${$active ? tinycolor('#e0b60f').darken(10).toString() : theme['primary'].light}
  }
`}`

const Download = styled.div`${({ theme }) =>
  css`
  margin: 0 5px;
  
  &:hover {
    color: ${theme['primary'].light}
  }
`}`

const Edit = styled.div`${({ theme }) =>
  css`
  margin: 0 5px;
  
  &:hover {
    color: ${theme['primary'].light}
  }
`}`

export type Action = {
  key: string
  title: string
  icon: IconDefinition
  condition?: (id: string) => boolean
}

type OptionObject = {
  key: string
  value: string
  selectedDisplayValue?: string
  title?: string
  badge?: string
  group?: string
  disabled?: boolean
  notRemovable?: boolean
  hideActions?: boolean
  isDefault?: boolean
  notEditable?: boolean
  checked?: boolean
}

type Option = OptionObject | boolean | string

type Props = {
  name: string
  value?: string[] | boolean | number | string
  selectors: Option[]
  onChange?: (e: any) => void
  onEdit?: (e: any) => void
  onDelete?: (name: string, key: string) => void
  onDownload?: (key: string) => void
  onSetDefault?: (key: string) => void
  theme?: any
  classes?: any
  noDeleteCurrent?: boolean
  noDeleteGiven?: Array<string>
  half?: boolean
  oneThird?: boolean
  twoThirds?: boolean
  disabled?: boolean
  error?: string
  title?: string // TODO: is this used?
  placeholder?: string // TODO: is this used?
  multiple?: boolean
  checkbox?: boolean
  onCheckboxClick?: (e: any, comparisonCasterName: string) => void
  renderValue?: any
  selectedIds?: string[]
  optionStyles?: any
  spaceBetween?: boolean
  MenuProps?: any
  actions?: Action[]
  onActionClick?: (key: Action['key'], id: string) => void
}

class SelectPlot extends Component<Props> {
  private readonly handleEdit = (key: string) => {
    const { onEdit } = this.props

    if (onEdit) {
      onEdit(key)
    }
  }

  private readonly handleDelete = (key: string) => {
    const { onChange, name, onDelete } = this.props

    if (onDelete) {
      onDelete(name, key)
    }

    if (onChange) {
      onChange({ target: { name, value: '' } })
    }
  }

  private readonly handleDownload = (key: string) => {
    const { onDownload } = this.props

    if (onDownload) {
      onDownload(key)
    }
  }

  private readonly handleSetDefault = (key: string) => {
    const { onSetDefault } = this.props

    if (onSetDefault) {
      onSetDefault(key)
    }
  }

  private readonly getIsChecked = (ids: any[], option: any) =>
    Boolean(ids.includes(option.key ?? option) || option.checked) 

  private readonly getTooltipText = (option: Option): string => {
    if (option instanceof Object) {
      const title = option.title ?? option.value

      if (isValidElement(title)) {
        return ''
      }

      return String(title)
    }

    if (isValidElement(option)) {
      return ''
    }

    return String(option)
  }

  private readonly getLabel = (option: Option) => {
    if (option instanceof Object) {
      if (isValidElement(option.value)) {
        return option.value
      }

      return String(option.value)
    }

    if (isValidElement(option)) {
      return option
    }

    return String(option)
  }

  public override render () {
    const {
      value,
      selectors,
      name,
      error,
      noDeleteCurrent,
      noDeleteGiven,
      checkbox,
      selectedIds,
      optionStyles,
      spaceBetween,
      actions,
      onActionClick,
      onChange,
      onDelete,
      onEdit,
      onDownload,
      onSetDefault,
      onCheckboxClick,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      theme, // we cannot provide this to the Select component, or it will throw an error
      ...restProps
    } = this.props

    let groups = selectors.map(selector => typeof selector === 'object' ? selector.group : undefined)

    groups = groups.filter((group, index) => groups.indexOf(group) === index)

    const selectedOption = selectors.find(selector => value === (selector as OptionObject).key)
    const selectedDisplayValue = (selectedOption as OptionObject)?.selectedDisplayValue

    return (
      <MdFormControl error={Boolean(error)} disabled={restProps.disabled ?? false}>
        <Select
          onChange={onChange}
          name={name}
          displayEmpty
          error={Boolean(error)}
          value={value ?? ''}
          sx={{ fieldset: { border: 'none' } }}
          renderValue={() => selectedDisplayValue ?? (selectedOption as any)?.value}
          {...restProps}
        >
          {
            groups && (groups.length > 0) && groups[0]
              ? groups.map(group => {
                if (group !== 'disabled') {
                  const Groupname = (
                    <OptGroup key={group} disabled>
                      {group}
                    </OptGroup>
                  )

                  // TODO: translate badge
                  const Options = selectors
                    .filter(selector => (selector as OptionObject).group === group)
                    .map((option) => {
                      const optionObj = option as Partial<OptionObject>
                      const optionVal = option as Exclude<Option, OptionObject>

                      return (
                        <Option
                          key={optionObj.key ?? String(optionVal)}
                          value={optionObj.key ?? String(optionVal)}
                          title={this.getTooltipText(option)}
                          disabled={optionObj.disabled ?? false}
                          data-selected-helper={value === (optionObj.key ?? optionVal)}
                        >
                          <span>{this.getLabel(option)}</span>
                          {
                            optionObj.badge && (
                              <div style={{ display: 'none' }}>
                                <Badge>{optionObj.badge}</Badge>
                              </div>
                            )
                          }
                        </Option>
                      )
                    })

                  return [ Groupname, ...Options ]
                }
                else {
                  return selectors.filter(selector => (selector as OptionObject).group === group).map((option) => {
                    const optionObj = option as Partial<OptionObject>
                    const optionVal = option as Exclude<Option, OptionObject>

                    return (
                      <Option
                        key={optionObj.key ?? String(optionVal)}
                        value={optionObj.key ?? String(optionVal)}
                        title={this.getTooltipText(option)}
                        disabled
                        data-selected-helper={value === (optionObj.key ?? optionVal)}
                      >
                        <span>{this.getLabel(option)}</span>
                        {
                          optionObj.badge && (
                            <div style={{ display: 'none' }}>
                              <Badge>{optionObj.badge}</Badge>
                            </div>
                          )
                        }
                      </Option>
                    )
                  })
                }
              })
              : selectors.map((option) => {
                const optionObj = option as Partial<OptionObject>
                const optionVal = option as Exclude<Option, OptionObject>

                const key = optionObj.key ?? String(optionVal)

                return (
                  <Option
                    key={key}
                    value={key}
                    disabled={optionObj.disabled ?? false}
                    $notRemovable={optionObj.notRemovable ?? false}
                    style={optionStyles ?? {}}
                    $spaceBetween={spaceBetween ?? false}
                    title={optionObj.title ?? ''}
                  >
                    <span>{this.getLabel(option)}</span>
                    {
                      !optionObj.hideActions && (
                        <ActionsWrapper onClick={event => event.stopPropagation()}>
                          <div className='default-actions'>
                            {
                              onSetDefault && (
                                <Default
                                  onClick={() => this.handleSetDefault(key)}
                                  title={optionObj.isDefault ? 'Remove default' : 'Set as default'}
                                  $active={optionObj.isDefault ?? false}
                                >
                                  <i className='pe-7s-pin' />
                                </Default>
                              )
                            }
                            {
                              !optionObj.notRemovable && onDelete && (
                                <Delete
                                  disabled={
                                    (Boolean(noDeleteCurrent) && value === (optionObj.key ?? option)) ||
                                    Boolean(noDeleteGiven?.includes(key))
                                  }
                                  onClick={() => this.handleDelete(key)}
                                />
                              )
                            }
                            {
                              onDownload && (
                                <Download onClick={() => this.handleDownload(key)}>
                                  <i className='pe-7s-cloud-download' />
                                </Download>
                              )
                            }
                            {
                              !optionObj.notEditable && onEdit &&
                              (
                                <Edit onClick={() => this.handleEdit(key)}>
                                  <i className='pe-7s-pen' />
                                </Edit>
                              )
                            }
                          </div>
                          {
                            Boolean(actions?.some(action => !action.condition || action.condition(key))) && (
                              <Actions
                                onClick={event => event.stopPropagation()}
                                $nextToCheckbox={Boolean(checkbox && Array.isArray(value))}
                              >
                                {
                                  actions!.map((action) => (
                                    (!action.condition || action.condition(key)) && (
                                      <div
                                        className='action'
                                        key={action.key}
                                        onClick={() => onActionClick && onActionClick(action.key, key)}
                                        title={action.title}
                                      >
                                        <FontAwesomeIcon icon={action.icon} />
                                      </div>
                                    )
                                  ))
                                }
                              </Actions>
                            )
                          }
                          {
                            checkbox && Array.isArray(value) && (
                              <StyledCheckbox
                                disabled={optionObj.disabled}
                                checked={this.getIsChecked(selectedIds ?? [], option)}
                                onChange={(e) => onCheckboxClick?.(e.target.checked, key)}
                                onClick={event => event.stopPropagation()}
                              />
                            )
                          }
                        </ActionsWrapper>
                      )
                    }
                  </Option>
                )
              })
          }
        </Select>
      </MdFormControl>
    )
  }
}

export default compose<any>(withTheme)(SelectPlot)
