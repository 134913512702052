import { useEffect, useState } from 'react'

import { LiveDataCache } from '@/store/liveData/LiveCDSDataCache'

export function useLiveData () {
  const [ data, setData ] = useState(LiveDataCache.getData())

  useEffect(() => {
    const unsubscribe = LiveDataCache.subscribe(() => {
      setData(LiveDataCache.getData())
    })

    return () => {
      unsubscribe()
    }
  }, [])

  return data
}

export function useIsLiveDataEnabled () {
  const [ isLiveDataEnabled, setIsLiveDataEnabled ] = useState(false)

  useEffect(() => {
    const unsubscribe = LiveDataCache.subscribe(() => {
      setIsLiveDataEnabled(LiveDataCache.getIsLiveDataEnabled())
    })

    return () => {
      unsubscribe()
    }
  }, [])

  return isLiveDataEnabled
}
