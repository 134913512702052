import { filterActions } from 'redux-ignore'

import { CDSData } from '@/api/caster-data-server'

enum CasterDataServerActionsEnum {
  ACTION_ADD_CDS_TIMESTAMP = 'casterDataServer/ACTION_ADD_CDS_TIMESTAMP',
  ACTION_UPDATE_CDS_TIMESTAMP = 'casterDataServer/ACTION_UPDATE_CDS_TIMESTAMP',
  ACTION_REMOVE_CDS_TIMESTAMP = 'casterDataServer/ACTION_REMOVE_CDS_TIMESTAMP',
  ACTION_SET_CDS_TIMESTAMP = 'casterDataServer/ACTION_SET_CDS_TIMESTAMP',
  ACTION_SET_PLAYER_TIMESTAMP_ID = 'casterDataServer/ACTION_SET_PLAYER_TIMESTAMP_ID',
  ACTION_REMOVE_PLAYER_TIMESTAMP_ID = 'casterDataServer/ACTION_REMOVE_PLAYER_TIMESTAMP_ID',
  ACTION_ADD_CDS_DATA_BY_UUID = 'casterDataServer/ACTION_ADD_CDS_DATA_BY_UUID',
  ACTION_SET_PLANT_DATA_AVAILABLE = 'casterDataServer/ACTION_SET_PLANT_DATA_AVAILABLE',
}

type CDSAction = { type: CasterDataServerActionsEnum }

type AddTimestampDataAction = CDSAction & { compareEntry: CompareEntry }

type UpdateTimestampDataAction = CDSAction & { compareEntry: CompareEntry }

type RemoveTimestampDataAction = CDSAction & { id: string }

type SetTimestampDataAction = CDSAction & { timestamp: number, data: CDSData }

type SetPlayerTimestampIdAction = CDSAction & { id: string }

type RemovePlayerTimestampIdAction = CDSAction

type AddCDSDataByUUIDAction = CDSAction & { data: Record<string, [number, number][]> }

type SetPlantDataAvailableAction = CDSAction & { plantDataAvailable: boolean }

export function setPlayerTimestampId (id: string): SetPlayerTimestampIdAction {
  return {
    type: CasterDataServerActionsEnum.ACTION_SET_PLAYER_TIMESTAMP_ID,
    id,
  }
}

export function removePlayerTimestampId (): RemovePlayerTimestampIdAction {
  return {
    type: CasterDataServerActionsEnum.ACTION_REMOVE_PLAYER_TIMESTAMP_ID,
  }
}

export function addTimestamp (compareEntry: CompareEntry): AddTimestampDataAction {
  return {
    type: CasterDataServerActionsEnum.ACTION_ADD_CDS_TIMESTAMP,
    compareEntry,
  }
}

export function updateTimestamp (compareEntry: CompareEntry): UpdateTimestampDataAction {
  return {
    type: CasterDataServerActionsEnum.ACTION_UPDATE_CDS_TIMESTAMP,
    compareEntry,
  }
}

export function removeTimestampAndData (id: string): RemoveTimestampDataAction {
  return {
    type: CasterDataServerActionsEnum.ACTION_REMOVE_CDS_TIMESTAMP,
    id,
  }
}

export function setTimestampData (timestamp: number, data: CDSData): SetTimestampDataAction {
  return {
    type: CasterDataServerActionsEnum.ACTION_SET_CDS_TIMESTAMP,
    timestamp,
    data,
  }
}

export function addCDSDataByUUID (data: Record<string, [number, number][]>): AddCDSDataByUUIDAction {
  return {
    type: CasterDataServerActionsEnum.ACTION_ADD_CDS_DATA_BY_UUID,
    data,
  }
}

export function setPlantDataAvailable (plantDataAvailable: boolean): SetPlantDataAvailableAction {
  return {
    type: CasterDataServerActionsEnum.ACTION_SET_PLANT_DATA_AVAILABLE,
    plantDataAvailable,
  }
}

const initialState: CasterDataServerState = {
  compareEntries: [],
  timestampData: {},
  playerTimestampId: null,
  cdsDataByUUID: {},
  plantDataAvailable: false,
}

const actionHandlers: Record<string, (state: CasterDataServerState, action: any) => CasterDataServerState> = {
  [CasterDataServerActionsEnum.ACTION_ADD_CDS_TIMESTAMP]: (
    state: CasterDataServerState,
    action: AddTimestampDataAction,
  ) => ({
    ...state,
    compareEntries: [ ...state.compareEntries, action.compareEntry ],
  }),

  [CasterDataServerActionsEnum.ACTION_UPDATE_CDS_TIMESTAMP]: (
    state: CasterDataServerState,
    action: UpdateTimestampDataAction,
  ) => ({
    ...state,
    compareEntries: state
      .compareEntries
      .map(compareEntry => compareEntry.id === action.compareEntry.id ? action.compareEntry : compareEntry),
  }),

  [CasterDataServerActionsEnum.ACTION_REMOVE_CDS_TIMESTAMP]: (
    state: CasterDataServerState,
    action: RemoveTimestampDataAction,
  ) => ({
    ...state,
    compareEntries: state.compareEntries.filter(({ id }) => id !== action.id),
    timestampData: Object.keys(state.timestampData).reduce((acc, key) => {
      const timestamp = Number(key)
      const compareEntry = state.compareEntries.find(compareEntry => compareEntry.timestamp === timestamp)

      if (timestamp !== compareEntry?.timestamp && state.timestampData[timestamp]) {
        acc[timestamp] = state.timestampData[timestamp]
      }

      return acc
    }, {} as CasterDataServerState['timestampData']),
  }),

  [CasterDataServerActionsEnum.ACTION_SET_CDS_TIMESTAMP]: (
    state: CasterDataServerState,
    action: SetTimestampDataAction,
  ) => ({
    ...state,
    timestampData: {
      ...state.timestampData,
      [action.timestamp]: action.data,
    },
  }),

  [CasterDataServerActionsEnum.ACTION_SET_PLAYER_TIMESTAMP_ID]: (
    state: CasterDataServerState,
    action: SetPlayerTimestampIdAction,
  ) => ({ ...state, playerTimestampId: action.id }),

  [CasterDataServerActionsEnum.ACTION_REMOVE_PLAYER_TIMESTAMP_ID]: (
    state: CasterDataServerState,
  ) => ({ ...state, playerTimestampId: null }),

  [CasterDataServerActionsEnum.ACTION_ADD_CDS_DATA_BY_UUID]: (
    state: CasterDataServerState,
    action: AddCDSDataByUUIDAction,
  ) => ({ ...state, cdsDataByUUID: { ...state.cdsDataByUUID, ...action.data } }),

  [CasterDataServerActionsEnum.ACTION_SET_PLANT_DATA_AVAILABLE]: (
    state: CasterDataServerState,
    action: SetPlantDataAvailableAction,
  ) => ({ ...state, plantDataAvailable: action.plantDataAvailable }),
}

function cdsReducers<T extends CDSAction> (state = initialState, action: T) {
  const handler = actionHandlers[action.type]

  return handler ? handler(state, action) : state
}

export default filterActions(cdsReducers, [
  CasterDataServerActionsEnum.ACTION_ADD_CDS_TIMESTAMP,
  CasterDataServerActionsEnum.ACTION_UPDATE_CDS_TIMESTAMP,
  CasterDataServerActionsEnum.ACTION_REMOVE_CDS_TIMESTAMP,
  CasterDataServerActionsEnum.ACTION_SET_CDS_TIMESTAMP,
  CasterDataServerActionsEnum.ACTION_SET_PLAYER_TIMESTAMP_ID,
  CasterDataServerActionsEnum.ACTION_REMOVE_PLAYER_TIMESTAMP_ID,
  CasterDataServerActionsEnum.ACTION_ADD_CDS_DATA_BY_UUID,
  CasterDataServerActionsEnum.ACTION_SET_PLANT_DATA_AVAILABLE,
]) as typeof cdsReducers
