import { io, Socket } from 'socket.io-client'

import { request } from '@/api'
import { useConfig } from '@/config'
import { Logger } from '@/util/logger'

export type CDSTimespan = [number, number]

export type CDSData = Record<string, number>

export type CDSDataByUUID = [number, number][]

export interface ScanStatus {
  scan_id: string
  initiated: number
  started: number
  completed: number
}

export interface CDSWebSocketEvents {
  onConnect?: () => void
  onDisconnect?: () => void
  onData?: (data: CDSData) => void
  onError?: (error: { message: string }) => void
}

const PATH = 'caster-data-server'
const logger = new Logger('CasterDataServer')
let socket: Socket | null = null

export function getTimespans (plant: string): Promise<CDSTimespan[] | null> {
  // TODO: translate message
  return request('get', `${PATH}/timespans/${plant}`, 'Failed to get timespans')
}

export function getData (plant: string, timestamp: number): Promise<CDSData | null> {
  // TODO: translate message
  return request('get', `${PATH}/data/${plant}/${timestamp}`, 'Failed to get data')
}

export function getDataByUUIDs (plant: string, uuids: string[]): Promise<Record<string, CDSDataByUUID> | null> {
  return request('get', `${PATH}/data/${plant}`, 'Failed to get data', { params: { uuids } })
}

export function getAllScanStatus (plant: string): Promise<ScanStatus[] | null> {
  return request('get', `${PATH}/scanstatus/${plant}`, 'Failed to get all scan status')
}

export function getScanStatus (plant: string, scanId: string): Promise<ScanStatus | null> {
  return request('get', `${PATH}/scanstatus/${plant}/${scanId}`, 'Failed to get scan status')
}

export function pingPlant (plant: string): Promise<string | null> {
  return request('get', `${PATH}/ping/${plant}`, 'Failed to ping plant')
}

export function connectToLiveData (plant: string, events: CDSWebSocketEvents = {}): void {
  if (socket) {
    logger.warn('Already connected to CDS')

    return
  }

  socket = io(`${useConfig().apiBaseURL}/cds`, {
    transports: [ 'websocket' ],
  })

  socket.on('connect', () => {
    logger.info('Connected to CDS WebSocket')
    socket?.emit('connect_cds', { plant })
  })

  socket.on('cds_connected', () => {
    logger.info('Connected to CDS data stream')
    events.onConnect?.()
  })

  socket.on('cds_disconnected', () => {
    logger.info('Disconnected from CDS data stream')
    events.onDisconnect?.()
  })

  socket.on('cds_data', (data: CDSData) => {
    events.onData?.(data)
  })

  socket.on('cds_error', (error: { message: string }) => {
    logger.error('CDS error:', error.message)
    events.onError?.(error)
  })

  socket.on('disconnect', () => {
    logger.info('Disconnected from CDS WebSocket')
    events.onDisconnect?.()
    socket = null
  })
}

export function disconnectFromLiveData (): void {
  if (!socket) {
    logger.warn('Not connected to CDS')

    return
  }

  socket.emit('disconnect_cds')
  socket.disconnect()
  socket = null
}

// TODO: remove this when CAS-531 is done
// Add to window context for testing
declare global {
  interface Window {
    connectToLiveData: typeof connectToLiveData
    disconnectFromLiveData: typeof disconnectFromLiveData
  }
}

window.connectToLiveData = connectToLiveData
window.disconnectFromLiveData = disconnectFromLiveData
