import { faRedo, faUpload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import hoistStatics from 'hoist-non-react-statics'
import hotkeys from 'hotkeys-js'
import cloneDeep from 'lodash/cloneDeep'
import type { Moment } from 'moment'
import moment from 'moment'
import { enqueueSnackbar } from 'notistack'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import styled, { css } from 'styled-components'
import { v4 as uuid } from 'uuid'

import {
  CDSTimespan,
  getData,
  getTimespans,
  getScanStatus,
  type ScanStatus,
  getAllScanStatus,
} from '@/api/caster-data-server'
import TimeUtil from '@/logic/TimeUtil'
import Util from '@/logicHandlers/ServerLogic/actions/Util'
import Button from '@/react/components/Button'
import { DateTimePickerComponent } from '@/react/specific/DateTimePicker'
import { Form } from '@/react/visualization/dashboard/Dialogs/DialogStyles'
import * as ApplicationActions from '@/store/application/main/actions'
import * as CasterDataServerActions from '@/store/casterDataServer'
import * as VisualizationActions from '@/store/visualization/actions'
import type { DefaultState } from '@/types/state'
import { Identifiable } from '@/Util/decorators/Identifiable'

import BaseDialog from './BaseDialog'
import { Spinner } from '../visualization/PlotWrapper/EditBoxWrapper/styles'

const T = 'casterDataServerDialog'

const IconWrapper = styled.div<{ disabled?: boolean }>`
  color: ${({ theme }) => theme['colors'].swatch9};
  margin-right: 10px;
  margin-bottom: 15px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  font-size: 20px;
`

const InputWrapper = styled.div`${({ theme }) =>
  css`
    display: flex;
    flex-direction: row;
    align-items: end;
    margin-top: 17px;
    white-space: nowrap;
    flex: 1;

    &:not(:last-of-type) {
      margin-right: 20px;
    }

    textarea,
    input {
      caret-color: ${theme['input'].color};
    }
  `}`

const Timespan = styled.div`${({ theme }) =>
  css`
  color: ${theme['mainFontColor']};
  
  &:not(:last-child) {
    margin-bottom: 16px;
  }

  .timespan-label {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
  }
`}`

const DateTime = styled.div`
  cursor: pointer;
`

const TimespanBar = styled.div`${({ theme }) =>
  css`
  width: 100%;
  height: 8px;
  background-color: ${theme['mainFontColor']};
  cursor: text;
  position: relative;

  &.show-tooltip::after {
    content: attr(data-tooltip);
    position: absolute;
    top: -32px;
    left: var(--left);
    padding: 4px 8px;
    background-color: ${theme['mainBackground']};
    color: ${theme['mainFontColor']};
    font-size: 12px;
    font-weight: bold;
    border-radius: 4px;
    transform: translate(-50%, 0);
    white-space: nowrap;
  }
`}`

const UploadingFileMessageContainer = styled.div`
  color: ${({ theme }) => theme['colors'].swatch9};
  position: relative;
  top: 20px;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: space-around;
`

const connector = connect((state: DefaultState) => ({
  selectedComparisonCaseIds: state.visualization.selectedComparisonCaseIds,
}), {
  closeDialog: ApplicationActions.closeDialog,
  addTimestamp: CasterDataServerActions.addTimestamp,
  setTimestampData: CasterDataServerActions.setTimestampData,
  setSelectedComparisonCaseIds: VisualizationActions.setSelectedComparisonCaseIds,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t(key: string, params?: Record<string, unknown>): string
}

type State = {
  plants: string[]
  selectedPlant: string
  timespans: CDSTimespan[]
  selectedTimestamp: number | null
  currentLeftPercentage: number
  currentTooltip: string
  uploadingFiles: boolean
  loading: boolean
  currentScanId: string | null
  currentScanStatus: ScanStatus | null
  pollingInterval: NodeJS.Timeout | null
}

export class CasterDataServerDialog extends Component<Props, State> {
  @Identifiable('CasterDataServerDialog') public static readonly NAME: string

  public override state: State = {
    plants: [] as string[], // TODO: get from server
    selectedPlant: 'NucorBrandenburg', // TODO: do not hard-code
    timespans: [] as CDSTimespan[],
    selectedTimestamp: null,
    currentLeftPercentage: 0,
    currentTooltip: '',
    uploadingFiles: false,
    loading: true,
    currentScanId: null,
    currentScanStatus: null,
    pollingInterval: null,
  }

  public override async componentDidMount () {
    const { selectedPlant } = this.state

    hotkeys('Escape', this.handleClose)

    const timespans = await getTimespans(selectedPlant)

    await this.checkScanStatuses()

    if (timespans?.[0]?.[0]) {
      this.setState({ timespans, selectedTimestamp: timespans[0][0] })
    }

    this.setState({ loading: false })
  }

  public override componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Escape', this.handleClose)
    this.stopPolling()
  }

  private readonly handleClose = () => {
    const { closeDialog } = this.props

    closeDialog(CasterDataServerDialog.NAME)
  }

  private readonly handleSelectTimestamp = (moment: Moment | null) => {
    if (!moment?.isValid()) {
      return
    }

    const selectedTimestamp = moment.toDate().getTime()

    this.setState({ selectedTimestamp })
  }

  private readonly handleTimespanBarClick = (event: any, timespan: CDSTimespan) => {
    this.setState({ selectedTimestamp: this.getHoveredTimespan(event, timespan) })
  }

  private readonly handleTimespanDateClick = (timestamp: number) => {
    this.setState({ selectedTimestamp: timestamp })
  }

  private readonly handleSubmit = async () => {
    const { selectedTimestamp, timespans } = this.state
    const {
      addTimestamp,
      setTimestampData,
      closeDialog,
      selectedComparisonCaseIds,
      setSelectedComparisonCaseIds,
    } = this.props

    if (!selectedTimestamp) {
      // eslint-disable-next-line no-console
      console.error('No selected timestamp')

      return
    }

    const id = uuid()
    const timespan = timespans.find(timespan => selectedTimestamp >= timespan[0] && selectedTimestamp <= timespan[1])

    if (!timespan) {
      // eslint-disable-next-line no-console
      console.error('No timespan found for selected timestamp', selectedTimestamp)

      return
    }

    const data = await getData('NucorBrandenburg', selectedTimestamp)

    if (!data) {
      // eslint-disable-next-line no-console
      console.error('No data found for selected timestamp', selectedTimestamp)

      return
    }

    const newSelectedComparisonCaseIds = cloneDeep(selectedComparisonCaseIds)

    newSelectedComparisonCaseIds.push(`cds_timestamp_${id}`)

    setSelectedComparisonCaseIds(newSelectedComparisonCaseIds)

    addTimestamp({ id, timestamp: selectedTimestamp, timespan })

    setTimestampData(selectedTimestamp, data)

    closeDialog(CasterDataServerDialog.NAME)
  }

  private readonly handleMouseOverBar = (event: any, timespan: CDSTimespan) => {
    const target = event.target as HTMLDivElement
    const x = event.clientX - target.getBoundingClientRect().left
    const width = target.offsetWidth

    this.setState({
      currentLeftPercentage: x / width,
      currentTooltip: TimeUtil.getDisplayDateTime(this.getHoveredTimespan(event, timespan)),
    })
  }

  private readonly handleUploadIBAFile = async () => {
    if (this.state.uploadingFiles) {
      return
    }

    const result = await Util.openUploadFileDialogMultiple(
      '.dat',
      '/caster-data-server/upload-iba-file/NucorBrandenburg',
      'post',
      undefined,
      (loading: boolean) => this.setState({ uploadingFiles: loading }),
    )

    this.setState({ uploadingFiles: false })

    if (!result?.success) {
      enqueueSnackbar('Failed to upload IBA file', { variant: 'error' })

      return
    }

    if (result.scanStatus) {
      this.setState({ currentScanStatus: result.scanStatus })
      this.startPolling(result.scanStatus.scan_id)
    }
  }

  private readonly handleReloadSources = async () => {
    const { selectedPlant, uploadingFiles } = this.state

    if (uploadingFiles) {
      return
    }

    const timespans = await getTimespans(selectedPlant)

    if (timespans?.[0]?.[0]) {
      this.setState({ timespans, selectedTimestamp: timespans[0][0] })
    }
  }

  private readonly checkScanStatuses = async () => {
    const { selectedPlant } = this.state
    const scanStatuses = await getAllScanStatus(selectedPlant)

    const runningScanStatus = scanStatuses?.find(status => status.completed === -1)

    if (runningScanStatus) {
      this.setState({ currentScanStatus: runningScanStatus })
      this.startPolling(runningScanStatus.scan_id)
    }
  }

  private readonly startPolling = (scanId: string) => {
    this.stopPolling()

    const interval = setInterval(async () => {
      const status = await getScanStatus(this.state.selectedPlant, scanId)
      
      if (status) {
        this.setState({ currentScanStatus: status })

        if (status.completed !== -1) {
          this.stopPolling()
          this.handleReloadSources()
          this.checkScanStatuses()
        }
      }
    }, 250)

    this.setState({ pollingInterval: interval, currentScanId: scanId })
  }

  private readonly stopPolling = () => {
    const { pollingInterval } = this.state

    if (pollingInterval) {
      clearInterval(pollingInterval)
      this.setState({ pollingInterval: null, currentScanId: null, currentScanStatus: null })
    }
  }

  private readonly getHoveredTimespan = (event: any, timespan: CDSTimespan) => {
    const target = event.target as HTMLDivElement

    const x = event.clientX - target.getBoundingClientRect().left
    const width = target.offsetWidth
    const percentage = x / width
    const timespanLength = timespan[1] - timespan[0]
    const timespanOffset = Math.round(timespanLength * percentage)

    return timespan[0] + timespanOffset
  }
  
  public override render () {
    const { 
      timespans,
      selectedTimestamp,
      currentTooltip,
      currentLeftPercentage,
      uploadingFiles,
      currentScanStatus,
    } = this.state
    const { t } = this.props

    const validSelectedTimestamp = selectedTimestamp &&
      timespans.some(timespan => selectedTimestamp >= timespan[0] && selectedTimestamp <= timespan[1])

    if (this.state.loading) {
      return (
        <BaseDialog
          title={t(`${T}.title`)}
          icon='pe-7s-server'
          header={t(`${T}.header`)}
          onClose={this.handleClose}
          medium
        >
          <Spinner />
        </BaseDialog>
      )
    }

    return (
      <BaseDialog
        title={t(`${T}.title`)}
        icon='pe-7s-server'
        header={t(`${T}.header`)}
        onClose={this.handleClose}
        medium
      >
        <Form>
          <div>
            {
              timespans.map((timespan) => (
                <Timespan key={timespan.join('|')}>
                  <div className='timespan-label'>
                    <DateTime onClick={() => this.handleTimespanDateClick(timespan[0])}>
                      {TimeUtil.getDisplayDateTime(timespan[0])}
                    </DateTime>
                    <DateTime onClick={() => this.handleTimespanDateClick(timespan[1])}>
                      {TimeUtil.getDisplayDateTime(timespan[1])}
                    </DateTime>
                  </div>
                  <TimespanBar
                    className='timespan-bar'
                    data-tooltip={currentTooltip}
                    style={{ '--left': `${(currentLeftPercentage ?? 0) * 100}%` } as React.CSSProperties}
                    onClick={event => this.handleTimespanBarClick(event, timespan)}
                    onMouseOver={(event: any) => event.target.classList.add('show-tooltip')}
                    onMouseOut={(event: any) => event.target.classList.remove('show-tooltip')}
                    onMouseMove={event => this.handleMouseOverBar(event, timespan)}
                  />
                </Timespan>
              ))
            }
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <InputWrapper>
              <IconWrapper disabled={this.state.uploadingFiles}>
                <FontAwesomeIcon icon={faUpload} title='Upload IBA file' onClick={this.handleUploadIBAFile} />
              </IconWrapper>
              <IconWrapper disabled={this.state.uploadingFiles}>
                <FontAwesomeIcon icon={faRedo} title='Reload data' onClick={this.handleReloadSources} />
              </IconWrapper>
              <DateTimePickerComponent
                label={t(`${T}.timestamp`)}
                onChange={this.handleSelectTimestamp}
                value={moment(selectedTimestamp ?? Date.now())}
                half
              />
              <Button
                type='primary'
                disabled={!validSelectedTimestamp || uploadingFiles}
                onClick={this.handleSubmit}
                icon='pe-7s-check'
                isRef
                half
                nextToDateTime
              >
                {t(`${T}.submit`)}
              </Button>
            </InputWrapper>
          </div>
          {
            uploadingFiles && (
              <UploadingFileMessageContainer>
                <Spinner />
                <span>Uploading files...</span>
              </UploadingFileMessageContainer>
            )
          }
          {
            currentScanStatus && currentScanStatus.completed === -1 && (
              <UploadingFileMessageContainer>
                <Spinner />
                <span>Scanning files... Started at {TimeUtil.getDisplayDateTime(currentScanStatus.initiated)}</span>
              </UploadingFileMessageContainer>
            )
          }
        </Form>
      </BaseDialog>
    )
  }
}

const composedComponent = compose<any>(withTranslation('application'), connector)(CasterDataServerDialog)

export default hoistStatics(composedComponent, CasterDataServerDialog)
