/* eslint-env browser */
import { Buffer } from 'buffer'
import fileDialog from 'file-dialog'
// TODO: make sure saveSync is working properly
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import { saveSync } from 'save-file'

import { useConfig } from '@/config'
import ApiClient from '@/store/apiClient'

// const saveSync = require('save-file').saveSync

export default class Util {
  public static async openUploadFileDialog (
    fileExtension: string[] | string,
    uploadPath: string,
    verb: 'patch' | 'post',
    prepareCallback?: (data?: any) => void,
    setLoadingStatus?: (loading: boolean) => void,
  ) {
    const files: FileList = await fileDialog({ accept: fileExtension as string })

    if (setLoadingStatus) {
      setLoadingStatus(true)
    }

    if (!files || !files[0]) {
      throw new Error('No file selected')
    }

    const data = new FormData()

    data.append('file', files[0])

    if (prepareCallback) {
      prepareCallback(data)
    }

    const response = await ApiClient[verb](`${useConfig().apiBaseURL}${uploadPath}`, { data })

    return {
      ...response,
      fileName: files[0].name,
    }
  }

  // openUploadFileDialog but allow multiple files
  public static async openUploadFileDialogMultiple (
    fileExtension: string[] | string,
    uploadPath: string,
    verb: 'patch' | 'post',
    prepareCallback?: (data?: any) => void,
    setLoadingStatus?: (loading: boolean) => void,
  ) {
    const files: FileList = await fileDialog({ accept: fileExtension as string, multiple: true })

    if (setLoadingStatus) {
      setLoadingStatus(true)
    }

    if (!files || !files[0]) {
      throw new Error('No files selected')
    }

    const data = new FormData()

    for (let i = 0; i < files.length; i++) {
      const file = files[i]

      if (!file) {
        continue
      }

      data.append('file', file)
    }

    if (prepareCallback) {
      prepareCallback(data)
    }

    const response = await ApiClient[verb](`${useConfig().apiBaseURL}${uploadPath}`, { data })

    return {
      ...response,
      files,
    }
  }

  public static openDownloadFileDialogBase64 (fileData: string, fileName: string): Promise<void> {
    const buffer = Buffer.from(fileData, 'base64')

    return this.openDownloadFileDialog(buffer, fileName)
  }

  public static openDownloadFileDialog (data: Buffer | string, fileName: string): Promise<void> {
    const buffer = data instanceof Buffer ? data : Buffer.from(String(data), 'utf8')

    // don't use await save(buffer, fileName) since it does not resolve properly
    saveSync(buffer, fileName)

    return Promise.resolve()
  }
}
