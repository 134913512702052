import { enqueueSnackbar } from 'notistack'

import { getGroupVisualizationConfigFile, getVisualizationConfigById } from '@/api/visualization-config'
import { useConfig } from '@/config'
import IpcManager from '@/IpcManager'
import { Info } from '@/logic/Info'
import Util from '@/logicHandlers/ServerLogic/actions/Util'
import { CasterHandleDataDialog } from '@/react/dialogs/caster/CasterHandleDataDialog'
import FeatureFlags from '@/react/FeatureFlags'
import ApiClient from '@/store/apiClient'
import { AppState } from '@/store/application/main/consts'
import ThreeManager from '@/three/ThreeManager'

import type { Props } from './CasterColumn'

type NeededPropsToLoadConfigById = {
  setConfig: Props['setConfig']
  setCasterDashboardTabIndex: Props['setCasterDashboardTabIndex']
  setVisualizationMetaInformation: Props['setVisualizationMetaInformation']
  appState: Props['appState']
  authenticationData: Props['authenticationData']
  featureFlags: Props['featureFlags']
}

export class CasterColumnLogic {
  public static async handleLoadCasterDashboardConfigById (
    props: NeededPropsToLoadConfigById,
    dashboardConfigId: string,
    tabIndex: string,
  ) {
    const {
      setConfig,
      setCasterDashboardTabIndex,
      setVisualizationMetaInformation,
      appState,
      authenticationData,
      featureFlags,
    } = props

    if (!FeatureFlags.canViewCasterDashboard(featureFlags)) {
      return
    }

    if (authenticationData.casterDashboardConfigs?.find(config => config.id === dashboardConfigId)) {
      const configObject = authenticationData.casterDashboardConfigs.find(config => config.id === dashboardConfigId)
      const visualizationConfigFile = await getGroupVisualizationConfigFile(configObject?.id ?? '')

      if (!visualizationConfigFile) {
        return
      }

      const { viewsObject, plotConfigs, tileConfigs, dataSources, externalDataSources } = visualizationConfigFile

      if (!viewsObject || !plotConfigs || !tileConfigs) {
        // eslint-disable-next-line no-console
        console.error('Error opening dashboard, file could be malformed')

        return
      }

      const visualizationConfig = {
        data: {
          viewsObject,
          plotConfigs,
          tileConfigs,
        },
        dataSources,
        externalDataSources,
      } as VisualizationConfig

      setConfig(visualizationConfig)
      setCasterDashboardTabIndex(1)
      setVisualizationMetaInformation('config', dashboardConfigId, appState)

      return
    }

    const visualizationConfig = await getVisualizationConfigById(
      dashboardConfigId,
      { emptySelectedComparisonCasters: 1 },
    )

    if (!visualizationConfig) {
      // this.setState({ loading: { openVis: false } })

      return
    }

    const tabIndexNumber = Number(tabIndex)

    Info.setRecentlyUsedInfo({ casterDashboardConfigId: dashboardConfigId, casterDashboardTabIndex: tabIndexNumber })

    // FIXME: dashboard configs?
    // if (!dashboardConfigId.startsWith('group-')) {
    //   const config = currentSimulationCase.visualizationConfigList.find(config => config.id === dashboardConfigId)

    //   if (!config) {
    //     return
    //   }
    // }

    setVisualizationMetaInformation('config', dashboardConfigId, appState)

    setConfig(visualizationConfig)

    const validTabIndex = CasterColumnLogic.getValidTabIndex(tabIndex, visualizationConfig.data.viewsObject)

    setCasterDashboardTabIndex(validTabIndex)
  }

  private static getValidTabIndex (tabIndexString: string, viewsObject: any) {
    const tabIndex = Number(tabIndexString)

    const currentViewObject = viewsObject[(Object.keys(viewsObject) ?? [])[0] ?? ''] ?? {}
    const numberOfTabs = (Object.keys(currentViewObject.dashboards ?? {})).length

    if (!numberOfTabs) {
      return 0
    }

    if (tabIndexString === '' || Number.isNaN(tabIndex)) {
      return 1
    }

    return numberOfTabs ? (tabIndex > numberOfTabs ? 1 : tabIndex) : 0
  }

  public static openCaster (props: Props) {
    const { currentSimulationCase, onClose } = props

    IpcManager.loadCurrentCaster({ simulationCase: currentSimulationCase, caseId: null })

    if (onClose) {
      onClose()
    }
  }

  public static handleUploadCasterData (
    action: 'mergeNozzles' | 'replaceCaster' | 'replaceNozzles' = 'replaceCaster',
    props: Props,
    _force = false,
    setLoadingStatus?: (loading: boolean) => void,
  ): void {
    const {
      currentSimulationCase,
      setCurrentSimulationCase,
      resetReducer,
      resetAllElements,
      setError,
      setFileUploadLoadingStatus,
      closeDialog,
      featureFlags,
      setAppState,
      setCasterDashboardTabIndex,
      casterDashboardTabIndex,
    } = props

    // FIXME: we currently only allow uploading a new caster, merge is not yet implemented
    // if (!force && currentSimulationCase.currentCaster) {
    //   openDialog(CasterHandleDataDialog.NAME)

    //   return
    // }

    closeDialog(CasterHandleDataDialog.NAME)

    if (!currentSimulationCase || !currentSimulationCase.id) {
      return
    }

    Util
      .openUploadFileDialog(
        '.xml',
        `/import/caster-xml/${currentSimulationCase.id}`,
        'patch',
        (formData: any) => {
          setAppState(AppState.Caster)
          IpcManager.both.send('setLoading', null, true, 'VisualizationIsLoading')

          if (casterDashboardTabIndex !== 0) {
            setCasterDashboardTabIndex(0)
          }

          resetReducer(true, true)
          resetAllElements()
          setError('CasterData')
          setFileUploadLoadingStatus('CasterDataIsLoading', true)

          formData.append('action', action)
          ThreeManager.cleanViews([ 'MainView', 'SectionView' ])
        },
        setLoadingStatus,
      )
      .then(() => {
        // setCurrentSimulationCase(simulationCase)

        setError('CasterData')
        setFileUploadLoadingStatus('CasterDataIsLoading', false)

        if (FeatureFlags.usesSlimVersion(featureFlags)) {
          CasterColumnLogic.openCaster(props)

          return
        }

        // FIXME: cleanup, put this in a separate function, network folder ...
        ApiClient
          .get(
            `${useConfig().apiBaseURL}/cases/${currentSimulationCase.id}`,
            { params: { withCurrentCaster: true } },
          )
          .then(({ case_ }) => {
            if (!case_) {
              throw new Error('No case found')
            }

            setCurrentSimulationCase(case_)
          })
          .catch((_response) => {
            // TODO: handle error
            // console.log(response)
          })
          .finally(() => {
            if (setLoadingStatus) {
              setLoadingStatus(false)
            }
          })
      })
      .catch(({ status }) => {
        if (setLoadingStatus) {
          setLoadingStatus(false)
        }

        enqueueSnackbar(
          'Error uploading xml file, please upload another',
          { variant: 'error', autoHideDuration: 4000 },
        )
        setError('CasterData', status)
        setFileUploadLoadingStatus('CasterDataIsLoading', false)
      })
  }
}
