import {
  faArrowLeft,
  faArrowRight,
  faArrowsAltH,
  faMinus,
  faPause,
  faPlay,
  faPlus,
  faStepBackward,
  faStepForward,
  faSyncAlt,
  faTimes, 
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import hoistStatics from 'hoist-non-react-statics'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import styled, { css } from 'styled-components'

import { getData } from '@/api/caster-data-server'
import TimeUtil from '@/logic/TimeUtil'
import * as ApplicationActions from '@/store/application/main/actions'
import * as CasterDataServerActions from '@/store/casterDataServer'
import type { DefaultState } from '@/types/state'
import { Identifiable } from '@/Util/decorators/Identifiable'

type FloatingWindowContainerProps = {
  x: number
  y: number
  width: number
  height: number
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const FloatingWindowContainer = styled.div.attrs<FloatingWindowContainerProps>((props) => ({
  style: {
    top: `${props.y}px`,
    left: `${props.x}px`,
    width: `${props.width}px`,
    height: `${props.height}px`,
    backgroundColor: '#22282e', // props.theme['mainBackground'],
    borderColor: '#4b535c', // props.theme['borderColor'],
    color: '#fff', // props.theme['mainFontColor'],
  },
}))<FloatingWindowContainerProps>`
  position: fixed;
  z-index: 101;
  border: 1px solid;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
`

type HeaderProps = {
  $hideBorder: boolean
  $factor: number
}

const HeaderBar = styled.div.attrs<HeaderProps>(({ $factor, $hideBorder }) => ({
  style: {
    borderBottom: $hideBorder ? 'none' : '1px solid #4b535c', // props.theme['borderColor'],
    lineHeight: `${$factor * 25}px`,
    height: `${$factor * 25}px`,
    padding: `0 ${$factor * 8}px`,
    fontSize: `${$factor * 12}px`,
  },
}))<HeaderProps>`
  cursor: move;
  display: flex;
  justify-content: space-between;

  * {
    align-self: center;
  }

  svg {
    cursor: pointer;
    margin-left: 10px;
  }
`

const Content = styled.div`${() =>
  css`
  padding: 8px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .select-action {
    display: flex;
    align-items: center;

    svg {
      margin-right: 5px;
    }

    select {
      background-color: #4b535c;
      color: #fff;
      border: none;
      font-size: 12px;
      outline: none;
      padding: 2px 2px;
    }
  }
`}`

const Spaced = styled.div`${() =>
  css`
  display: flex;
  justify-content: space-between;
  height: 25px;

  > * {
    align-self: center;
  }
`}`

type TimeLineProps = {
  $current: number
  $factor: number
}

const TimeLine = styled.div<TimeLineProps>`${({ $current, $factor }) => css`
  height: ${$factor * 10}px;
  background-color: #4b535c;
  margin-bottom: ${$factor * 5}px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: -${$factor * 2}px;
    height: ${$factor * 14}px;
    width: ${$factor * 2}px;
    left: calc(${$current * 100 * $factor}% - 1px);
    background-color: #fff;
  }
`}`

type TickProps = {
  $left: string
  $factor: number
}

const Tick = styled.div.attrs<TickProps>((props) => ({
  style: {
    left: props.$left,
    height: `${props.$factor * 10}px`,
    width: `${Number(props.$factor)}px`,
  },
}))<TickProps>`
  position: absolute;
  height: 10px;
  width: 1px;
  background-color: #fff;
  opacity: 0.3
`

const Actions = styled.div`${() =>
  css`
  display: flex;
  justify-content: flex-end;
`}`

const IconButton = styled.div<{ $factor: number }>`
  ${(props) => css`
    width: ${props.$factor * 20}px;
    height: ${props.$factor * 20}px;
    border-radius: 2px;
    font-size: ${props.$factor}em;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: #4b535c;
    cursor: pointer;
    margin-left: ${props.$factor * 5}px;
  `}
`

const connector = connect((state: DefaultState) => ({
  compareEntries: state.casterDataServer.compareEntries,
  timestampData: state.casterDataServer.timestampData,
  playerTimestampId: state.casterDataServer.playerTimestampId,
}), {
  updateTimestamp: CasterDataServerActions.updateTimestamp,
  setTimestampData: CasterDataServerActions.setTimestampData,
  closeDialog: ApplicationActions.closeDialog,
})

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux

type State = {
  factor: number
  x: number
  y: number
  width: number
  height: number
  showContent: boolean
  playing: boolean
  forwards: boolean
  stepPercentage: number
  durationPerStep: number
  dragging: boolean
  previousX: number
  previousY: number
}

export class PlaybackWindow extends Component<Props, State> {
  @Identifiable('PlaybackWindow') public static readonly NAME: string

  private readonly baseWidth = 400

  private readonly baseHeight = 105

  public override state: State = {
    x: 550,
    factor: 1,
    y: 100,
    width: 400,
    height: 105,
    showContent: true,
    playing: false,
    forwards: true,
    stepPercentage: 0.05,
    durationPerStep: 1000,
    dragging: false,
    previousX: 0,
    previousY: 0,
  }

  private playIntervalHandle: number | null = null

  // Maximum number of timestamps to prefetch in each direction (before/after)
  private readonly prefetchCount = 3

  // Set to track timestamps that are being fetched to prevent duplicate requests
  private readonly prefetchingTimestamps = new Set<number>()

  public override componentDidMount (): void {
    document.addEventListener('mousemove', this.handleMouseMove)
    
    this.prefetchInitialData()
  }

  public override componentDidUpdate (prevProps: Props): void {
    // if the IDs are different, reset the playback
    if (prevProps.playerTimestampId !== this.props.playerTimestampId) {
      this.setState({ playing: false, forwards: true })

      this.stopPlaybackIfRunning()
    }
  }

  public override componentWillUnmount (): void {
    document.removeEventListener('mousemove', this.handleMouseMove)

    this.stopPlaybackIfRunning()
  }

  private readonly handleMouseMove = (event: any) => {
    const { dragging } = this.state

    if (!dragging) {
      return
    }

    const { x, y, previousX: dragStartX, previousY: dragStartY } = this.state

    // TODO: make sure window is not dragged outside of viewport

    this.setState({
      x: x + event.clientX - dragStartX,
      y: y + event.clientY - dragStartY,
      previousX: event.clientX,
      previousY: event.clientY,
    })
  }

  private readonly handleStartDrag = (event: any) => {
    this.setState({ dragging: true, previousX: event.clientX, previousY: event.clientY })
  }

  private readonly handleEndDrag = () => {
    this.setState({ dragging: false })
  }

  private readonly handleInterval = () => {
    const { forwards } = this.state

    if (forwards) {
      this.handleNext()

      return
    }

    this.handlePrevious()
  }

  private readonly handlePlayPause = () => {
    const isPlaying = this.state.playing

    this.setState({ playing: !isPlaying })

    this.stopPlaybackIfRunning()

    if (!isPlaying) {
      this.playIntervalHandle = window.setInterval(this.handleInterval, this.state.durationPerStep)
    }
  }

  private readonly handlePrevious = () => {
    const current = this.getCurrentPercentageRounded()

    this.updateTimestamp(this.keepInRange(current - this.state.stepPercentage))
  }

  private readonly handleNext = () => {
    const current = this.getCurrentPercentageRounded()

    this.updateTimestamp(this.keepInRange(current + this.state.stepPercentage))
  }

  private readonly handleToggleDirection = () => {
    this.setState({ forwards: !this.state.forwards })
  }

  private readonly handleStepChange = (event: any) => {
    const step = parseFloat(event.target.value)

    this.setState({ stepPercentage: step })
  }

  private readonly handleSpeedChange = (event: any) => {
    const speed = parseInt(event.target.value, 10)

    this.setState({ durationPerStep: speed })

    if (this.playIntervalHandle !== null) {
      clearInterval(this.playIntervalHandle)

      this.playIntervalHandle = window.setInterval(this.handleInterval, speed)
    }
  }

  private readonly handleClose = () => {
    const { closeDialog } = this.props

    this.stopPlaybackIfRunning()

    closeDialog(PlaybackWindow.NAME)
  }

  private readonly handleResize = (event: any) => {
    const factor = Number(event.target.value)
    const newWidth = factor * this.baseWidth
    const newHeight = factor * this.baseHeight

    this.setState({ width: newWidth, height: newHeight, factor })
  }

  private readonly stopPlaybackIfRunning = () => {
    if (this.playIntervalHandle !== null) {
      clearInterval(this.playIntervalHandle)

      this.playIntervalHandle = null
    }
  }

  private readonly prefetchInitialData = () => {
    const compareEntry = this.getCompareEntry()
    
    if (!compareEntry) {
      return
    }

    this.handleNext()
  }

  private readonly updateTimestamp = (percentage: number) => {
    const { updateTimestamp, setTimestampData, timestampData } = this.props
    const compareEntry = this.getCompareEntry()

    if (!compareEntry) {
      // eslint-disable-next-line no-console
      console.error('No compare entry found')

      return
    }

    const newTimestamp = compareEntry.timespan[0] + (compareEntry.timespan[1] - compareEntry.timespan[0]) * percentage

    if (timestampData[newTimestamp]) {
      updateTimestamp({ ...compareEntry, timestamp: newTimestamp })

      this.prefetchAdjacentTimestamps(newTimestamp, compareEntry.timespan)

      return
    }

    getData('NucorBrandenburg', newTimestamp)
      .then(data => {
        if (!data) {
          // eslint-disable-next-line no-console
          console.error('No data found for selected timestamp', newTimestamp)

          return
        }

        setTimestampData(newTimestamp, data)
        updateTimestamp({ ...compareEntry, timestamp: newTimestamp })

        this.prefetchAdjacentTimestamps(newTimestamp, compareEntry.timespan)
      })
  }

  /**
   * Prefetch data for timestamps before and after the current timestamp
   * Handles wrapping around the timespan boundaries
   */
  private readonly prefetchAdjacentTimestamps = (currentTimestamp: number, timespan: [number, number]) => {
    if (!timespan || timespan.length < 2) {
      return
    }
    
    const stepPercentage = this.state.stepPercentage
    const totalRange = timespan[1] - timespan[0]
    const timestampStep = totalRange * stepPercentage
    const { forwards } = this.state
    
    // Prefetch timestamps in an alternating pattern
    for (let i = 1; i <= this.prefetchCount; i++) {
      // Calculate regular timestamps before and after current
      let beforeTimestamp = currentTimestamp - (i * timestampStep)
      let afterTimestamp = currentTimestamp + (i * timestampStep)
      
      // Handle wrap-around for before timestamp
      if (beforeTimestamp < timespan[0]) {
        // Calculate wrapped timestamp from the end
        const wrapOffset = timespan[0] - beforeTimestamp

        beforeTimestamp = timespan[1] - wrapOffset
      }
      
      // Handle wrap-around for after timestamp
      if (afterTimestamp > timespan[1]) {
        // Calculate wrapped timestamp from the start
        const wrapOffset = afterTimestamp - timespan[1]

        afterTimestamp = timespan[0] + wrapOffset
      }
      
      // Determine priority timestamps based on playback direction
      // Prioritize fetching in the direction of playback
      const firstPriorityTimestamp = forwards ? afterTimestamp : beforeTimestamp
      const secondPriorityTimestamp = forwards ? beforeTimestamp : afterTimestamp
      
      this.prefetchTimestamp(firstPriorityTimestamp)
      
      this.prefetchTimestamp(secondPriorityTimestamp)
    }
  }
  
  /**
   * Prefetch data for a specific timestamp if not already available
   */
  private readonly prefetchTimestamp = (timestamp: number) => {
    const { timestampData, setTimestampData } = this.props
    
    // Skip if already loaded or currently being fetched
    if (timestampData[timestamp] || this.prefetchingTimestamps.has(timestamp)) {
      return
    }
    
    // Mark as being fetched
    this.prefetchingTimestamps.add(timestamp)
    
    getData('NucorBrandenburg', timestamp)
      .then(data => {
        // Remove from prefetching set regardless of result
        this.prefetchingTimestamps.delete(timestamp)
        
        if (!data) {
          // eslint-disable-next-line no-console
          console.debug('No data found when prefetching timestamp', timestamp)

          return
        }
        
        // Store the fetched data
        setTimestampData(timestamp, data)
      })
      .catch(() => {
        // Remove from prefetching set on error
        this.prefetchingTimestamps.delete(timestamp)
      })
  }

  private readonly getCompareEntry = () => {
    const { playerTimestampId, compareEntries } = this.props

    if (!compareEntries[0]) {
      return null
    }

    if (playerTimestampId) {
      return compareEntries.find(entry => entry.id === playerTimestampId)
    }

    return compareEntries[0]
  }

  private readonly getCurrentPercentage = () => {
    const compareEntry = this.getCompareEntry()

    if (!compareEntry) {
      return 0
    }

    const { timestamp, timespan } = compareEntry

    return (timestamp - timespan[0]) / (timespan[1] - timespan[0])
  }

  private readonly getCurrentPercentageRounded = () => {
    const { stepPercentage } = this.state

    return Math.round(this.getCurrentPercentage() / stepPercentage) * stepPercentage
  }

  private readonly keepInRange = (value: number) => {
    if (value > 1) {
      return 0
    }

    if (value < 0) {
      return 1
    }

    return value
  }

  private readonly renderHeader = () => {
    const { showContent } = this.state
    const { playerTimestampId, compareEntries } = this.props

    const dataIndex = compareEntries?.findIndex(entry => entry.id === playerTimestampId) ?? 0

    return (
      <HeaderBar
        $factor={this.state.factor}
        $hideBorder={!showContent}
        onMouseDown={this.handleStartDrag}
        onMouseUp={this.handleEndDrag}
      >
        <div>CDS{dataIndex + 1} Data Playback</div>
        <div style={{ display: 'flex' }}>
          <select onChange={this.handleResize} style={{ marginRight: '5px' }}>
            <option value={1}>100%</option>
            <option value={1.25}>125%</option>
            <option value={1.50}>150%</option>
            <option value={1.75}>175%</option>
            <option value={2}>200%</option>
          </select>
          <div>
            <FontAwesomeIcon
              icon={showContent ? faMinus : faPlus}
              onClick={() => this.setState({ showContent: !showContent })}
            />
            <FontAwesomeIcon icon={faTimes} onClick={this.handleClose} />
          </div>
        </div>
      </HeaderBar>
    )
  }

  public override render () {
    const { x, y, width, height, showContent, playing, forwards, stepPercentage, durationPerStep, factor } = this.state
    const compareEntry = this.getCompareEntry()

    return (
      <FloatingWindowContainer x={x} y={y} width={showContent ? width : 200} height={showContent ? height : 25}>
        {this.renderHeader()}
        {
          !compareEntry && showContent && (
            <Content>
              No data available
            </Content>
          )
        }
        {
          compareEntry && showContent &&
            <Content style={{ justifyContent: 'space-between', height: '100%', padding: `${8 * factor}px` }}>
              <Spaced>
                <div style={{ fontSize: `${factor}em` }} title='Start'>
                  {TimeUtil.getDisplayDateTime(compareEntry.timespan[0])}
                </div>
                <div style={{ fontSize: `${factor}em` }} title='Current'>
                  {TimeUtil.getDisplayDateTime(compareEntry.timestamp)}
                </div>
                <div style={{ fontSize: `${factor}em` }} title='End'>
                  {TimeUtil.getDisplayDateTime(compareEntry.timespan[1])}
                </div>
              </Spaced>
              <TimeLine $factor={factor} $current={this.getCurrentPercentage()}>
                {
                  Array.from({ length: Math.round(1 / stepPercentage) - 1 }).map((_, index) => (
                    <Tick $factor={factor} key={index} $left={`${(index + 1) * 100 * stepPercentage}%`} />
                  ))
                }
              </TimeLine>
              <Spaced>
                <div className='select-action' title='Step Size'>
                  <FontAwesomeIcon icon={faArrowsAltH} style={{ fontSize: `${factor}em` }} />
                  <select value={stepPercentage} onChange={this.handleStepChange} style={{ fontSize: `${factor}em` }}>
                    {
                      Array.from({ length: 10 }).map((_, index) => (
                        <option key={`step${index}`} value={(index + 1) * 0.05}>
                          {(index + 1) * 5} %
                        </option>
                      ))
                    }
                  </select>
                </div>
                <div className='select-action' title='Refresh Speed'>
                  <FontAwesomeIcon icon={faSyncAlt} style={{ fontSize: `${factor}em` }} />
                  <select value={durationPerStep} onChange={this.handleSpeedChange} style={{ fontSize: `${factor}em` }}>
                    {
                      Array.from({ length: 10 }).map((_, index) => (
                        <option key={`speed${index}`} value={(index + 1) * 500}>
                          {((index + 1) * 0.5).toFixed(1)} s
                        </option>
                      ))
                    }
                  </select>
                </div>
                <Actions>
                  <IconButton $factor={factor} onClick={this.handlePrevious} title='Step Backward'>
                    <FontAwesomeIcon icon={faStepBackward} />
                  </IconButton>
                  <IconButton $factor={factor} onClick={this.handlePlayPause} title={playing ? 'Pause' : 'Play'}>
                    <FontAwesomeIcon icon={playing ? faPause : faPlay} />
                  </IconButton>
                  <IconButton $factor={factor} onClick={this.handleNext} title='Step Forward'>
                    <FontAwesomeIcon icon={faStepForward} />
                  </IconButton>
                  <IconButton
                    $factor={factor}
                    onClick={this.handleToggleDirection}
                    title={`Play Direction: ${forwards ? 'Forwards' : 'Backwards'}`}
                  >
                    <FontAwesomeIcon icon={forwards ? faArrowRight : faArrowLeft} />
                  </IconButton>
                </Actions>
              </Spaced>
            </Content>
        }
      </FloatingWindowContainer>
    )
  }
}

const composedComponent = compose<any>(withTranslation('application'), connector)(PlaybackWindow)

export default hoistStatics(composedComponent, PlaybackWindow)
