import isEqual from 'lodash/isEqual'
import { closeSnackbar, enqueueSnackbar } from 'notistack'

import { NetworkStatus } from '@/api/network-event'
import { useConfig } from '@/config'
import IpcManager from '@/IpcManager'
import { ExecutionStepState } from '@/react/dialogs/executables/enums'
import { ExecutableDialog } from '@/react/dialogs/executables/ExecutableDialog'
import { ProjectDataDialog } from '@/react/dialogs/project/ProjectDataDialog'
import { ProjectMatrixDialog } from '@/react/dialogs/ProjectMatrixDialog'
import ApiClient from '@/store/apiClient'
import { AppState } from '@/store/application/main/consts'
import type { Update } from '@/types/state'

import type { Props } from '.'

export default class Handlers {
  private readonly props: Props

  private readonly handlers: Handlers

  public constructor (args: { props: Props }) {
    this.props = args.props
    this.handlers = this
  }

  public handleNetworkStatus (status: NetworkStatus) {
    const { networkStatus, storeNetworkStatus } = this.props

    if (networkStatus !== status) {
      storeNetworkStatus(status)
    }
  }

  public handleMetaData (meta: any) {
    const { setPlotsMeta } = this.props

    setPlotsMeta(meta)
  }

  public handleNotAuthenticated () {
    const { setAuthenticationData } = this.props

    setAuthenticationData()
  }

  public handleShowMore () {
    const {
      currentCasterDialogWidth,
      amountOfComparisonCasterColumns,
      setCurrentCasterDialogWidth,
      setAmountOfComparisonCasterColumns,
      selectedComparisonCaseIds,
    } = this.props

    if (amountOfComparisonCasterColumns >= selectedComparisonCaseIds.length) {
      return
    }

    if (amountOfComparisonCasterColumns) {
      setCurrentCasterDialogWidth(currentCasterDialogWidth + 77)
    }

    setAmountOfComparisonCasterColumns(amountOfComparisonCasterColumns + 1)
  }

  public handleShowLess () {
    const {
      currentCasterDialogWidth,
      amountOfComparisonCasterColumns,
      setCurrentCasterDialogWidth,
      setAmountOfComparisonCasterColumns,
    } = this.props

    if (!amountOfComparisonCasterColumns) {
      return
    }

    if (amountOfComparisonCasterColumns === 1) {
      this.handlers.handleSetDefaultView()

      return
    }

    setCurrentCasterDialogWidth(currentCasterDialogWidth - 77)
    setAmountOfComparisonCasterColumns(amountOfComparisonCasterColumns - 1)
  }

  public handleSetDefaultView () {
    const { setCurrentCasterDialogWidth, setAmountOfComparisonCasterColumns } = this.props

    setCurrentCasterDialogWidth(335)
    setAmountOfComparisonCasterColumns(0)
  }

  // FIXME: use this with new simulation or delete it
  public simulationUpdateCallback ({ simulationCaseId, state }: { simulationCaseId: string, state: 'data' | 'done' }) {
    const { openAppDialogs, currentProject, currentSimulationCase, t } = this.props
    const { id } = currentSimulationCase ?? {}

    if (state && (simulationCaseId === id || openAppDialogs.includes(ProjectMatrixDialog.NAME))) {
      this
        .handlers
        .updateSimulationCase(simulationCaseId)
        .then(() => {
          if (state === 'data') {
            enqueueSnackbar(t('simulation.dataUpdate'), { autoHideDuration: 3000, variant: 'success' })

            return
          }

          // state === 'done'
          enqueueSnackbar(t('simulation.completed'), { autoHideDuration: 3000, variant: 'success' })
        })
        .catch(() => {
          enqueueSnackbar(t('simulation.completionError'), { autoHideDuration: 4000, variant: 'error' })
        })
    }
    else if (state) { // this is only reached when the server sends an update for not selected simulation cases!
      const { name: simulationCaseName } = currentProject
        ?.simulationCases
        .find((simulationCase: any) => simulationCase.id === simulationCaseId) ?? {}
      const name = simulationCaseName ?? simulationCaseId ?? ''

      if (!name) {
        // eslint-disable-next-line no-console
        console.error('Could not get simulation case name')
      }

      if (state === 'data') {
        enqueueSnackbar(t('simulation.dataUpdateOther', { name }), { autoHideDuration: 4000, variant: 'success' })

        return
      }

      // state === 'done'
      enqueueSnackbar(t('simulation.completedOther', { name }), { autoHideDuration: 4000, variant: 'success' })
    }
  }

  public updateNotificationCallback ({ update }: { update: Update }) {
    const { liveMode, updateNotification, applyUpdates } = this.props

    updateNotification(
      Math.max(
        Object.keys(update.addedMountLogsMap ?? {}).length,
        update.unmountedMountLogIds.length,
      ),
      update,
    )

    if (liveMode) {
      // FIXME: if this is too fast use requestAnimationFrame
      applyUpdates()
    }
  }

  public executionDoneCallback ({
    simulationCaseId,
    definitionId,
    caseId,
    stepId,
    errorType,
    newCaseId,
    loadNewCase,
    configId,
    configNotFoundError,
    errorMessage,
    tabIndex,
    chosenCaseOptionId,
    userId,
    execution,
  }: {
    simulationCaseId: string
    definitionId: string
    caseId: string
    stepId?: string
    errorType?: string
    newCaseId?: string
    loadNewCase?: boolean
    configId?: string
    configNotFoundError?: boolean
    errorMessage?: string
    tabIndex?: string
    execution: ExecutionEntity
    chosenCaseOptionId: string
    userId: string
  }) {
    const {
      currentSimulationCase,
      t,
      setAppState,
      resetReducer,
      setExecutionState,
      executableDefinitions,
      authenticationData,
      addMetadataToCurrentProjectCasesMetadata,
      setExecutionData,
      setCasterDashboardTabIndex,
      casterDashboardTabIndex,
    } = this.props

    if (currentSimulationCase?.id !== simulationCaseId) {
      return
    }

    if (newCaseId && authenticationData.id !== userId) {
      return
    }

    if (casterDashboardTabIndex !== 0) {
      setCasterDashboardTabIndex(0)
    }

    let splitCaseId: string | undefined

    let autoLoad = false

    const executableDefinition = executableDefinitions
      .find(definition => definition.id === definitionId)

    // could not use case because it is a reserved word
    let chosenOption: any, currentStep: any
    const currentCase: Case | undefined = executableDefinition?.cases?.find(defCase => {
      if (defCase.id === splitCaseId && defCase.steps) {
        return true
      }

      return defCase.id === caseId
    })

    if (currentCase?.steps && !stepId) {
      enqueueSnackbar('No stepId provided', { autoHideDuration: 4000, variant: 'error' })

      return
    }

    if (currentCase?.steps) {
      currentStep = currentCase.steps?.find?.(step => step.id === stepId)

      if (currentStep?.caseOptions) {
        chosenOption = currentStep.caseOptions.find((option: any) => option.id === chosenCaseOptionId)
      }
    }

    const selection = chosenOption?.selectionFilter ||
      currentStep?.selectionFilter ||
      currentCase?.selectionFilter ||
      ''
    const param = (chosenOption?.parameters ?? currentStep?.parameters ?? currentCase?.parameters)
      ?.find((param: any) => param.value === 'caster.xml')

    if (param && param.autoLoad3D !== false) {
      autoLoad = true
    }

    if (errorType) {
      if (errorType === 'file_not_found') {
        enqueueSnackbar(errorMessage, { autoHideDuration: 4000, variant: 'error' })
      }
      else {
        enqueueSnackbar(t(`execution.error.${errorType}`), { autoHideDuration: 4000, variant: 'error' })
      }

      setExecutionState(simulationCaseId, definitionId, caseId, ExecutionStepState.Error, stepId)

      return
    }

    setExecutionData(execution)
    setExecutionState(simulationCaseId, definitionId, caseId, ExecutionStepState.Success, stepId)

    if (newCaseId) {
      if (!loadNewCase) {
        enqueueSnackbar(t('execution.completed'), { autoHideDuration: 3000, variant: 'success' })
        this
          .handlers
          .updateSimulationCase(simulationCaseId)
          .then((case_) => {
            addMetadataToCurrentProjectCasesMetadata({ id: case_.id, createdAt: new Date(case_.createdAt) })
          })
          .catch(() => enqueueSnackbar(t('execution.completionError'), { autoHideDuration: 4000, variant: 'error' }))

        return
      }

      this
        .handlers
        .updateSimulationCase(newCaseId)
        .then((case_) => {
          if (!errorType) {
            addMetadataToCurrentProjectCasesMetadata({ id: case_.id, createdAt: new Date(case_.createdAt) })
            enqueueSnackbar(t('execution.completed'), { autoHideDuration: 3000, variant: 'success' })

            if (autoLoad) {
              resetReducer(false, true)
              setAppState(AppState.Caster)

              this.handlers.handleOpenCaster(
                newCaseId,
                false,
                selection,
                { configId, tabIndex },
                configNotFoundError,
              )
            }
          }
        })
        .catch(() => enqueueSnackbar(t('execution.completionError'), { autoHideDuration: 4000, variant: 'error' }))
    }
    else {
      this
        .handlers
        .updateSimulationCase(simulationCaseId)
        .then(() => {
          if (!errorType) {
            enqueueSnackbar(t('execution.completed'), { autoHideDuration: 3000, variant: 'success' })

            if (autoLoad) {
              resetReducer(true, true)
              setAppState(AppState.Caster)
              this.handlers.handleOpenCaster(
                simulationCaseId,
                // true,
                false, // TODO: maybe true if another dashboard is already open
                selection,
                { configId, tabIndex },
                configNotFoundError,
              )
            }
          }
        })
        .catch(() => enqueueSnackbar(t('execution.completionError'), { autoHideDuration: 4000, variant: 'error' }))
    }
  }

  private handleOpenCaster (
    simulationCaseId: string,
    skipVisualization = false,
    selection?: string,
    { configId, tabIndex }: { configId?: string | undefined, tabIndex?: string | undefined } = {},
    configNotFoundError = false,
  ) {
    const {
      closeDialog,
      // addComparisonCaster, //FIXME
    } = this.props

    closeDialog(ExecutableDialog.NAME)
    closeDialog(ProjectDataDialog.NAME)
    // this.setState({ loading: { openCaster: false } })

    IpcManager.loadCurrentCaster({
      simulationCase: null,
      caseId: simulationCaseId,
      configIdFromExec: configId,
      tab: tabIndex ? parseInt(tabIndex) : undefined,
      selection,
      skipVisualization,
      configNotFoundError,
      setSelectionCallback: (selection: string) => {
        ;(this as any).selectionQueue = selection
      },
    })
  }

  public handleFullScreenNotification (isFullScreen: boolean) {
    const { t } = this.props

    if (!isFullScreen || !window.isElectron) {
      return closeSnackbar('exitFullScreen')
    }

    enqueueSnackbar(t('howToExitFullScreen'), { autoHideDuration: 3000, preventDuplicate: true, key: 'exitFullScreen' })
  }

  private async handleLockCasterRequest (lock: boolean) {
    const { authenticationData, currentSimulationCase, currentProject, setCaseLocks } = this.props

    if (!authenticationData?.featureFlags || !currentProject?.id || (lock && !currentSimulationCase?.id)) {
      return
    }

    try {
      const locks = lock
        ? await ApiClient.post(`${useConfig().apiBaseURL}/case-lock/${currentSimulationCase.id}/${currentProject.id}`)
        : await ApiClient.del(`${useConfig().apiBaseURL}/case-lock/${currentProject.id}`)

      setCaseLocks(locks ?? [])
    }
    catch (error: any) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }

  public async handleLockCaster (prevProps?: Props) {
    const { appState, currentSimulationCase } = this.props

    if (
      prevProps?.currentSimulationCase?.id !== currentSimulationCase?.id ||
      !isEqual(prevProps?.currentSimulationCase?.currentCasterId, currentSimulationCase?.currentCasterId) ||
      (
        prevProps?.appState !== appState &&
        (
          appState === AppState.Caster ||
          prevProps?.appState === AppState.Caster
        )
      )
    ) {
      await this.handlers.handleLockCasterRequest(false)

      if (appState === AppState.Caster && currentSimulationCase?.currentCasterId) {
        await this.handlers.handleLockCasterRequest(true)
      }
    }
  }

  public handleToggleDialog (target: string) {
    const { setOpenDialogs } = this.props

    setOpenDialogs(target)
  }

  private updateSimulationCase (simulationCaseId: string) {
    const { setCurrentSimulationCase } = this.props

    return ApiClient
      .get(
        `${useConfig().apiBaseURL}/cases/${simulationCaseId}`,
        { params: { withCurrentCaster: true } },
      )
      .then(({ case_ }) => {
        setCurrentSimulationCase(case_)

        return case_ as SimulationCase
      })
  }
}
