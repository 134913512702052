import { faCopy } from '@fortawesome/free-regular-svg-icons'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import {
  Collapse,
  IconButton, 
  Table, 
  TableBody, 
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow,
} from '@mui/material'
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions'
import hoistStatics from 'hoist-non-react-statics'
import hotkeys from 'hotkeys-js'
import { enqueueSnackbar } from 'notistack'
import { Component, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import styled, { css } from 'styled-components'

import { type ShareStateForList, createState, deleteState, getStatesForList } from '@/api/state'
import IpcManager from '@/IpcManager'
import { Info, RecentlyUsedInfo } from '@/logic/Info'
import * as ApplicationActions from '@/store/application/main/actions'
import type { DefaultState } from '@/types/state'
import { Identifiable } from '@/Util/decorators/Identifiable'

import BaseDialog from './BaseDialog'
import Button from '../components/Button'
import { DialogID } from '../driver/DriverID'
import { Form, Text } from '../visualization/dashboard/Dialogs/DialogStyles'

const LinkDisplay = styled.div`${({ theme }) => css`
  .id {
    user-select: text;
  }

  svg {
    cursor: pointer;

    &:hover {
      color: ${theme['primary'].font};
    }
  }
`}`

const ClickableTableRow = styled(TableRow)<{ theme: Theme }>`${({ theme }) => css`
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${theme.colors.swatch6};
  }

  '& > *': { borderBottom: 'unset' }
`}`

function displayDate (date: Date | string) {
  return `${new Date(date).toISOString().replace('T', ' ').substring(0, 19)} UTC`
}

const connector = connect((state: DefaultState) => ({
  casterDashboardTabIndex: state.visualization.casterDashboardTabIndex,
  Caster: state.Caster,
}), {
  closeDialog: ApplicationActions.closeDialog,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t (key: string, params?: Record<string, unknown>): string
}

type State = {
  loading: boolean
  states: ShareStateForList[]
  rowsPerPage: number
  page: number
}

const T = 'shareStateDialog'

type RowProps = {
  state: ShareStateForList
  t (key: string, params?: Record<string, unknown>): string
  handleUpdate (): Promise<void>
  closeDialog: (dialog: string) => void
  className: string
}

function Row (props: RowProps) {
  const [ open, setOpen ] = useState(false)
  const { state, t, handleUpdate } = props
  const { id, projectName, caseName, configName, assigneeEmail, createdAt, expiresAt, requiresProjectShare } = state
  const data = (state.data ?? {}) as RecentlyUsedInfo

  const link = `${window.location.origin}?state-share=${id}`

  async function handleDelete (e: React.MouseEvent) {
    e.stopPropagation()

    if (!window.confirm(t(`${T}.message.deleteConfirm`))) {
      return
    }

    await deleteState(id)

    await handleUpdate()
  }

  async function handleCopyLink (e: React.MouseEvent) {
    e.stopPropagation()

    try {
      // this only works in secure contexts (https)
      await navigator.clipboard.writeText(link)

      enqueueSnackbar(t(`${T}.message.linkCopied`), { autoHideDuration: 3000, variant: 'success' })
    }
    catch (error: any) {
      // eslint-disable-next-line no-console
      console.error(error?.message)

      enqueueSnackbar(t(`${T}.message.cannotCopyLink`), { autoHideDuration: 3000, variant: 'error' })
    }
  }

  function handleRowClick () {
    const { closeDialog, className } = props

    IpcManager.loadCaseFromStateEntry(data)

    closeDialog(className)
  }

  return (
    <>
      <ClickableTableRow onClick={handleRowClick}>
        <TableCell>
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={
              (event) => {
                event.stopPropagation()
                setOpen(!open)
              }
            }
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{projectName}</TableCell>
        <TableCell>{caseName}</TableCell>
        <TableCell>{configName}</TableCell>
        <TableCell>{String(data.casterDashboardTabIndex ?? 'n/a')}</TableCell>
        <TableCell>
          {assigneeEmail && t(`${T}.message.onlyUser`, { email: assigneeEmail })}
          {!assigneeEmail && requiresProjectShare && t(`${T}.message.everyoneProject`)}
          {!assigneeEmail && !requiresProjectShare && t(`${T}.message.everyoneLink`)}
        </TableCell>
        <TableCell>{expiresAt ? displayDate(expiresAt) : t(`${T}.message.never`)}</TableCell>
        <TableCell>{displayDate(createdAt)}</TableCell>
        <TableCell>
          <FontAwesomeIcon
            icon={faTrash}
            onClick={handleDelete}
            title={t(`${T}.label.delete`)}
            style={{ cursor: 'pointer' }}
          />
          &nbsp;&nbsp;
          <FontAwesomeIcon
            icon={faCopy}
            onClick={handleCopyLink}
            title={t(`${T}.label.copyLink`)}
            style={{ cursor: 'pointer' }}
          />
        </TableCell>
      </ClickableTableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
              <TableBody>
                <TableRow>
                  <TableCell>{t(`${T}.label.filter`)}</TableCell>
                  <TableCell>{String(data.filterTerm)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t(`${T}.label.sectionViewOpen`)}</TableCell>
                  <TableCell>{data.isSectionActive ? t(`${T}.message.yes`) : t(`${T}.message.no`)}</TableCell>
                </TableRow>
                {
                  Boolean(data.isSectionActive) && (
                    <>
                      <TableRow>
                        <TableCell>{t(`${T}.label.sliderMode`)}</TableCell>
                        <TableCell>{String(data.sectionViewSliderJumpOver)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{t(`${T}.label.sliderTarget`)}</TableCell>
                        <TableCell>{String(data.sectionViewSliderTargetHeight)}</TableCell>
                      </TableRow>
                    </>
                  )
                }
                <TableRow>
                  <TableCell>{t(`${T}.label.cameraPosition`)}</TableCell>
                  <TableCell>{JSON.stringify(data.casterCameraPosition)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t(`${T}.label.cameraLookAt`)}</TableCell>
                  <TableCell>{JSON.stringify(data.casterCameraLookAt)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t(`${T}.label.link`)}</TableCell>
                  <TableCell>
                    <LinkDisplay>
                      <span className='id'>{link}</span>&nbsp;
                      <FontAwesomeIcon icon={faCopy} onClick={handleCopyLink} />
                    </LinkDisplay>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export class ShareStateDialog extends Component<Props, State> {
  @Identifiable('ShareStateDialog') public static readonly NAME: string

  public override state: State = {
    loading: false,
    states: [],
    rowsPerPage: 5,
    page: 0,
  }

  public override componentDidMount () {
    hotkeys('Escape', this.handleClose)

    this.loadStates().then(() => {}).catch(() => {})
  }

  public override componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Escape', this.handleClose)
  }

  private readonly handleClose = () => {
    const { closeDialog } = this.props

    closeDialog(ShareStateDialog.NAME)
  }

  private readonly handleCreateState = async () => {
    const recentlyUsedInfo = Info.getRecentlyUsedInfo()

    const state = await createState({
      data: recentlyUsedInfo,
      assigneeEmail: null,
      expiresAt: null,
      requiresProjectShare: true,
    })

    if (!state) {
      return
    }

    await this.loadStates()
  }

  private readonly loadStates = async () => {
    this.setState({ loading: true })

    const states = await getStatesForList()
  
    if (!states) {
      this.setState({ loading: false })

      return
    }

    this.setState({ states, loading: false })
  }

  public override render () {
    const { loading, states, rowsPerPage, page } = this.state
    const { t, closeDialog } = this.props

    return (
      <BaseDialog
        id={DialogID.ShareStateDialog.ID}
        title={t(`${T}.title`)}
        icon='pe-7s-user'
        header={t(`${T}.header`)}
        onClose={this.handleClose}
        fullWidth
      >
        <Form>
          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell>&nbsp;</TableCell>
                <TableCell>{t(`${T}.label.project`)}</TableCell>
                <TableCell>{t(`${T}.label.case`)}</TableCell>
                <TableCell>{t(`${T}.label.dashboard`)}</TableCell>
                <TableCell>{t(`${T}.label.tab`)}</TableCell>
                <TableCell>{t(`${T}.label.access`)}</TableCell>
                <TableCell>{t(`${T}.label.expires`)}</TableCell>
                <TableCell>{t(`${T}.label.created`)}</TableCell>
                <TableCell>&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                loading && (
                  <TableRow>
                    <TableCell colSpan={9} style={{ textAlign: 'center' }}>
                      <Text>{t(`${T}.message.loading`)}</Text>
                    </TableCell>
                  </TableRow>
                )
              }
              {
                (rowsPerPage > 0
                  ? states.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : states
                ).map((state) => (
                  <Row
                    key={state.id}
                    state={state}
                    t={t}
                    handleUpdate={this.loadStates}
                    closeDialog={closeDialog}
                    className={ShareStateDialog.NAME}
                  />
                ))
              }
              {
                !states?.length && !loading && (
                  <TableRow>
                    <TableCell colSpan={9} style={{ textAlign: 'center' }}>
                      <Text>{t(`${T}.message.noStates`)}</Text>
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[ 5, 10, 25, { label: t(`${T}.label.all`), value: -1 } ]}
                  colSpan={9}
                  count={states.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={(_, page) => this.setState({ page })}
                  onRowsPerPageChange={(event) => this.setState({ rowsPerPage: Number(event.target.value) })}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
          <Button id={DialogID.ShareStateDialog.CreateButton} onClick={this.handleCreateState}>
            {t(`${T}.button.createShare`)}
          </Button>
        </Form>
      </BaseDialog>
    )
  }
}

const composedComponent = compose<any>(withTranslation('application'), connector)(ShareStateDialog)

export default hoistStatics(composedComponent, ShareStateDialog)
