import { faClock } from '@fortawesome/free-regular-svg-icons'
import { faDatabase, faPlay, faRss } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { enqueueSnackbar } from 'notistack'
import { Children, PureComponent } from 'react'
import GridLayout from 'react-grid-layout'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import styled from 'styled-components'

import TimeUtil from '@/logic/TimeUtil'
import Util from '@/logic/Util'
import { PlaybackWindow } from '@/react/casterDataServer/PlaybackWindow'
import CompareXMLDataBaseInfoSpan from '@/react/components/Compare/CompareXMLDataBaseInfoSpan'
import { CasterDataServerDialog } from '@/react/dialogs/CasterDataServerDialog'
import { SelectCompareCasterTimeDialog } from '@/react/dialogs/SelectCompareCasterTimeDialog'
import FeatureFlags from '@/react/FeatureFlags'
import type { ResizeData } from '@/react/ResizeDetector'
import Input from '@/react/specific/Input'
import * as ApplicationActions from '@/store/application/main/actions'
import * as CasterDataServerActions from '@/store/casterDataServer'
import * as VisualizationActions from '@/store/visualization/actions'
import ThreeBase from '@/three/ThreeBase'
import ThreeManager from '@/three/ThreeManager'
import type { DefaultState } from '@/types/state'
import type { Translation } from '@/types/translation'
import type { CurrentDashboard } from '@/types/visualization'

import {
  AddPlotGridItem,
  ComparisonCasterMenu,
  DashboardMenu,
  DashboardMenuAction,
  GridScrollbar,
  GridViewContainer,
  ScrollHint,
} from '../Dashboard/styles'
import GridItemContainer from '../GridItemContainer'
import Logic from './Logic'

enum Action {
  OpenPlaybackWindow = 'openPlaybackWindow',
  OpenDateTimePicker = 'openDateTimePicker',
}

const StyledCompareCasterDataText = styled.span`
  display: flex;
  justify-content: center;
  padding-right: 15px;
  font-size: 14px !important;
`

export const StyledCaseNameSpan = styled.span<{ $width?: number }>`
  display: inline-block;
  ${props => props.$width && `width: ${props.$width}px;`}
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const AmountOfSelectedCompareCasters = ({ count }: { count: number }) => (
  <StyledCompareCasterDataText>
    Compare Caster Data: {count} selected
  </StyledCompareCasterDataText>
)

const connector = connect((state: DefaultState) => ({
  plotConfigs: state.visualization.plotConfigs,
  tileConfigs: state.visualization.tileConfigs,
  viewsObject: state.visualization.viewsObject,
  isEditModeOn: state.visualization.isEditModeOn,
  visualizationState: state.visualization,
  currentProject: state.application.main.currentProject,
  comparisonCasters: state.ComparisonCasters,
  currentSimulationCase: state.application.main.currentSimulationCase,
  currentProjectCasesMetadata: state.application.main.currentProjectCasesMetadata,
  openDialogs: state.application.main.openDialogs,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
  appState: state.application.main.appState,
  visualizationMetaInformation: state.visualization.visualizationMetaInformation,
  currentCasterDialogWidth: state.visualization.currentCasterDialogWidth,
  amountOfComparisonCasterColumns: state.visualization.amountOfComparisonCasterColumns,
  selectedComparisonCaseIds: state.visualization.selectedComparisonCaseIds,
  compareEntries: state.casterDataServer.compareEntries,
  comparisonTimestamps: state.visualization.comparisonTimestamps,
  playerTimestampId: state.casterDataServer.playerTimestampId,
  plantDataAvailable: state.casterDataServer.plantDataAvailable,
  caster: state.Caster,
}), {
  setConfig: VisualizationActions.setConfig,
  showAddPlotDialog: VisualizationActions.showAddPlotDialog,
  showEditDashboardDialog: VisualizationActions.showEditDashboardDialog,
  showDeleteDashboardDialog: VisualizationActions.showDeleteDashboardDialog,
  closeDialog: ApplicationActions.closeDialog,
  openDialog: ApplicationActions.openDialog,
  setCurrentCasterDialogWidth: VisualizationActions.setCurrentCasterDialogWidth,
  setAmountOfComparisonCasterColumns: VisualizationActions.setAmountOfComparisonCasterColumns,
  openPlotExportDialog: VisualizationActions.openPlotExportDialog,
  setSelectedComparisonCaseIds: VisualizationActions.setSelectedComparisonCaseIds,
  removeTimestampAndData: CasterDataServerActions.removeTimestampAndData,
  setComparisonTimestamps: VisualizationActions.setComparisonTimestamps,
  setPlayerTimestampId: CasterDataServerActions.setPlayerTimestampId,
  removePlayerTimestampId: CasterDataServerActions.removePlayerTimestampId,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  viewId: string
  children: any
  layout: Array<any>
  oldLayout: Array<any>
  dashboard: CurrentDashboard
  tileId: string
  currentDashboard: string
  showTabs: boolean
  simple: boolean
  t: Translation
  casesInfo: CaseInfo[]
  isLiveDataEnabled?: boolean
  onToggleLiveData?: () => void
}

type CaseInfo = {
  name: string
  id: string
  caster: Caster | null
}

// TODO: Handle via Reducer because this may be buggy
type State = {
  gridLayoutClassName: string
  size: { width: number, height: number }
}

export class ViewGrid extends PureComponent<Props, State> {
  private wrapperRef?: HTMLElement

  private waitingForComparisonCaster: string | null = null

  private slowdown3DTimeout: number | null = null

  public override state: State = {
    gridLayoutClassName: 'layout animated',
    size: { width: 0, height: 0 },
  }

  public override componentDidMount () {
    this.handleSlowdown3D()
  }

  public override componentDidUpdate () {
    this.handleSlowdown3D()

    const { comparisonCasters, viewsObject, viewId } = this.props
    const { size } = this.state

    this.handleResize(size as ResizeData)

    const { selectedComparisonCasters } = viewsObject[viewId] ?? {}

    if (
      this.waitingForComparisonCaster &&
      comparisonCasters[this.waitingForComparisonCaster] &&
      selectedComparisonCasters?.includes(this.waitingForComparisonCaster)
    ) {
      this.waitingForComparisonCaster = null
    }
  }

  private readonly handleSlowdown3D = () => {
    ThreeManager.base.setFpsTarget(0)

    if (this.slowdown3DTimeout) {
      window.clearTimeout(this.slowdown3DTimeout)
    }

    this.slowdown3DTimeout = window.setTimeout(() => {
      ThreeManager.base.setFpsTarget(ThreeBase.LowFPS)
    }, 500)
  }

  private readonly handleLayoutChange = (layout: Array<any>) => {
    const {
      layout: layouts,
      viewId,
      currentDashboard,
      visualizationState,
      setConfig,
      plotConfigs,
      tileConfigs,
    } = this.props

    if (layout && (layout.length > 0) && this.layoutChanged(layouts, layout)) {
      const viewsObject = Logic.getLayout(
        visualizationState,
        { layout, viewId, dashboardId: currentDashboard },
      )

      const data = { viewsObject, plotConfigs, tileConfigs }

      setConfig({ data } as VisualizationConfig, false)
    }
  }

  private readonly handleEditDashboard = () => {
    const { viewId, currentDashboard: dashboardId, showEditDashboardDialog } = this.props

    showEditDashboardDialog({ viewId, dashboardId }, true)
  }

  private readonly handleAddPlot = () => {
    const { viewId, currentDashboard, showAddPlotDialog } = this.props

    if (viewId && currentDashboard) {
      showAddPlotDialog(viewId, true)
    }
  }

  private readonly handleExportPlot = () => {
    const { openPlotExportDialog } = this.props

    openPlotExportDialog()
  }

  private readonly handleWheel = (event: any) => {
    if (!this.wrapperRef) {
      return
    }

    const target = this.wrapperRef

    if (!event.shiftKey) {
      target.scrollTop += event.deltaY
    }

    if (event.shiftKey) {
      target.scrollLeft += event.deltaY
    }

    if (target.scrollTop === 0) {
      target.setAttribute('data-arrow-up', 'off')
    }
    else {
      target.setAttribute('data-arrow-up', 'on')
    }

    if (target.offsetHeight + target.scrollTop === target.scrollHeight) {
      target.setAttribute('data-arrow-down', 'off')
    }
    else {
      target.setAttribute('data-arrow-down', 'on')
    }

    if (target.scrollLeft === 0) {
      target.setAttribute('data-arrow-left', 'off')
    }
    else {
      target.setAttribute('data-arrow-left', 'on')
    }

    if (target.offsetWidth + target.scrollLeft === target.scrollWidth) {
      target.setAttribute('data-arrow-right', 'off')
    }
    else {
      target.setAttribute('data-arrow-right', 'on')
    }
  }

  private readonly handleWrapperRef = (ref: any) => {
    if (ref) {
      this.wrapperRef = ref
    }
  }

  private readonly handleResize = ({ width, height }: ResizeData) => {
    this.handleWheel({
      target: this.wrapperRef,
      deltaY: 0,
    })
    
    const { size } = this.state

    if (size.width === width && size.height === height) {
      return
    }

    if (width === null && height === null) {
      return
    }

    requestAnimationFrame(() => {
      this.setState({ size: { width: width ?? 0, height: height ?? 0 } })
    })
  }

  private readonly handleDeleteDashboard = (_event: any) => {
    const { showDeleteDashboardDialog, viewId, currentDashboard } = this.props

    showDeleteDashboardDialog(viewId, currentDashboard)
  }

  private readonly handleComparisonCasterSelectViaCheckbox = (_checked: boolean, comparisonCasterId: string) => {
    // query the simulation case with the compareCasterId
    // then add to state
    const {
      currentSimulationCase,
      featureFlags,
      visualizationMetaInformation,
      appState,
      currentCasterDialogWidth,
      amountOfComparisonCasterColumns,
      setAmountOfComparisonCasterColumns,
      setCurrentCasterDialogWidth,
      // addComparisonCaster, //FIXME
      selectedComparisonCaseIds,
      setSelectedComparisonCaseIds,
    } = this.props

    if (!FeatureFlags.canEditCasterComparison(featureFlags, visualizationMetaInformation, appState)) {
      return
    }

    if (comparisonCasterId === currentSimulationCase.id || this.waitingForComparisonCaster) {
      return
    }

    const index = selectedComparisonCaseIds.indexOf(comparisonCasterId)
    const newSelectedComparisonCasterIds = [ ...selectedComparisonCaseIds ]

    if (index === -1) {
      if (newSelectedComparisonCasterIds.length >= 5) {
        enqueueSnackbar('Only 5 casters selectable simultaneously', { autoHideDuration: 4000, variant: 'warning' })

        return
      }

      newSelectedComparisonCasterIds.push(comparisonCasterId)

      setSelectedComparisonCaseIds(newSelectedComparisonCasterIds)

      return
    }

    if (newSelectedComparisonCasterIds.length === amountOfComparisonCasterColumns) {
      setAmountOfComparisonCasterColumns(amountOfComparisonCasterColumns - 1)

      if (currentCasterDialogWidth !== 335) {
        setCurrentCasterDialogWidth(currentCasterDialogWidth - 77)
      }
    }

    setSelectedComparisonCaseIds(newSelectedComparisonCasterIds.filter(id => id !== comparisonCasterId))
  }

  private readonly handleComparisonEntryDelete = (_name: string, key: string) => {
    const {
      setComparisonTimestamps,
      comparisonTimestamps,
      selectedComparisonCaseIds,
      removeTimestampAndData,
      setSelectedComparisonCaseIds,
      playerTimestampId,
      removePlayerTimestampId,
    } = this.props

    if (!key?.startsWith('cds_timestamp_')) {
      // is case with timestamp
      setComparisonTimestamps(comparisonTimestamps.filter(idAndTimestamp => idAndTimestamp !== key))

      const isSelected = selectedComparisonCaseIds.includes(key)

      if (isSelected) {
        setSelectedComparisonCaseIds(selectedComparisonCaseIds.filter(id => id !== key))
      }

      return
    }

    setSelectedComparisonCaseIds(selectedComparisonCaseIds.filter(id => id !== key))
    removeTimestampAndData(key.replace('cds_timestamp_', ''))

    if (playerTimestampId === key.replace('cds_timestamp_', '')) {
      removePlayerTimestampId()
    }
  }

  private readonly handleComparisonCasterSelectViaOption = (event: any) => {
    if (!event.target.value || !event.target.value.length) {
      return
    }

    this.handleComparisonCasterSelectViaCheckbox(false, event.target.value[event.target.value.length - 1])
  }

  private readonly handleOpenCasterDataServerDialog = () => {
    const { openDialog } = this.props

    openDialog(CasterDataServerDialog.NAME)
  }

  private readonly handleToggleLiveData = () => {
    const { onToggleLiveData } = this.props
    
    if (onToggleLiveData) {
      onToggleLiveData()
    }
  }

  private readonly handleOpenPlaybackWindow = (id: string) => {
    const { closeDialog, openDialog, setPlayerTimestampId } = this.props

    closeDialog(PlaybackWindow.NAME)
    openDialog(PlaybackWindow.NAME, { id: id.replace('cds_timestamp_', '') })
    setPlayerTimestampId(id.replace('cds_timestamp_', ''))

    // close the dropdown
    document.querySelector<HTMLDivElement>('#menu-comparisonCaster > div:nth-child(1)')?.click()
  }

  private readonly handleOpenDateTimePicker = (id: string) => {
    const { openDialog, casesInfo } = this.props

    const timestampBeingEditCaseInfo = casesInfo.find(({ id: caseId }) => caseId === id)

    if (!timestampBeingEditCaseInfo) {
      return
    }

    openDialog(SelectCompareCasterTimeDialog.NAME, { timestampBeingEditCaseInfo })
  }

  private readonly handleActionClick = (actionKey: string, id: string) => {
    switch (actionKey) {
      case Action.OpenPlaybackWindow:
        this.handleOpenPlaybackWindow(id)
        break
      case Action.OpenDateTimePicker:
        this.handleOpenDateTimePicker(id)
        break
    }
  }
  
  private readonly layoutChanged = (oldLayout: Array<any>, newLayout: Array<any>) => {
    // the rest of the keys come always as undefined and are not in the oldLayout
    const keysToCompare = [ 'i', 'x', 'y', 'w', 'h', 'minH', 'moved', 'static' ]

    if (oldLayout.length !== newLayout.length) {
      return true
    }

    for (let i = 0; i < oldLayout.length; i++) {
      const oldItem = oldLayout[i]
      const newItem = newLayout[i]

      for (const key of keysToCompare) {
        if (oldItem[key] !== newItem[key]) {
          return true
        }
      }
    }

    return false
  }

  public override render () {
    const {
      viewId,
      viewsObject,
      children,
      layout,
      currentDashboard,
      tileConfigs,
      isEditModeOn,
      simple,
      t,
      currentSimulationCase,
      openDialogs,
      featureFlags,
      appState,
      visualizationMetaInformation,
      selectedComparisonCaseIds,
      compareEntries,
      comparisonTimestamps,
      currentProjectCasesMetadata,
      plantDataAvailable,
      isLiveDataEnabled = false,
    } = this.props

    const { gridLayoutClassName, size } = this.state
    const { casesInfo } = this.props

    // FIXME: rework
    const cases: Selector[] = casesInfo
      .sort((a, b) => {
        const createdAtA = currentProjectCasesMetadata.find(({ id }) => id === a.id)?.createdAt
        const createdAtB = currentProjectCasesMetadata.find(({ id }) => id === b.id)?.createdAt

        if (createdAtA && createdAtB) {
          return createdAtA.getTime() - createdAtB.getTime()
        }
        else if (createdAtA) {
          return -1
        }
        else if (createdAtB) {
          return 1
        }

        return 0
      })
      .map((case_, index) => {
        const XMLDataBase = (case_.caster ?? {} as Caster)?.additionalData?.XMLDataBase ?? {}
        const tooltip = Util.getCaseXmlContentTooltip(case_.name, XMLDataBase)

        if (case_.id === currentSimulationCase.id) {
          const referenceName = (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <span className='dropdown-selectors' style={{ width: '50px' }}>{`C${index + 1}(R)`}</span>
              <CompareXMLDataBaseInfoSpan XMLDataBase={XMLDataBase} marginRight='15px' />
              <StyledCaseNameSpan $width={160}>
                {case_.name}
              </StyledCaseNameSpan>
            </div>
          )

          return {
            key: case_.id,
            value: referenceName,
            title: tooltip,
            disabled: true,
            checked: true,
            notRemovable: true,
          }
        }

        const name = (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <span className='dropdown-selectors' style={{ width: '50px' }}>{`C${index + 1}`}</span>
            <CompareXMLDataBaseInfoSpan XMLDataBase={XMLDataBase} marginRight='15px' />
            <StyledCaseNameSpan $width={160}>
              {case_.name}
            </StyledCaseNameSpan>
          </div>
        )

        return {
          key: case_.id,
          value: name,
          title: tooltip,
          disabled: !FeatureFlags.canEditCasterComparison(featureFlags, visualizationMetaInformation, appState),
          notRemovable: true,
        }
      })

    for (let i = 0; i < compareEntries.length; i++) {
      const compareEntry = compareEntries[i]

      if (!compareEntry) {
        continue
      }

      cases.push({
        key: `cds_timestamp_${compareEntry.id}`,
        value: (
          <>
            <span className='dropdown-selectors'>CDS{i + 1}</span>
            <span>{TimeUtil.getDisplayDateTime(compareEntry.timestamp)}</span>
          </>
        ),
        title: `Caster Data Server @ ${TimeUtil.getDisplayDateTime(compareEntry.timestamp)}`,
      } as Selector)
    }

    for (let i = 0; i < comparisonTimestamps.length; i++) {
      const idAndTimestamp = comparisonTimestamps[i]

      if (!idAndTimestamp) {
        continue
      }

      const [ caseId, timestamp ] = idAndTimestamp.split('_')
      const parsedTimestamp = parseInt(timestamp ?? '0', 10)

      const caseIndex = casesInfo.findIndex(({ id }) => id === caseId) + 1

      cases.push({
        key: idAndTimestamp,
        value: (
          <>
            <span className='dropdown-selectors'>{`C${caseIndex}`}</span>
            <span>{TimeUtil.getDisplayDateTime(parsedTimestamp)}</span>
          </>
        ),
      })
    }

    let resizeAllowed = true
    const childLength = Children.count(children)

    let totalHeight = 0

    for (const plotLayout of layout) {
      totalHeight += plotLayout.h
    }

    if (totalHeight > Logic.GRID_ROWS) {
      resizeAllowed = false
    }

    const minimalSplitWidth = Logic.GRID_STEP_SIZE * 10 // TODO: this needs to be checked/fixed

    const { name } = viewsObject[viewId]?.dashboards?.[currentDashboard] ?? {}
    const selectedComparisonCastersNames = selectedComparisonCaseIds
      .map((comparisonCasterId: string) => casesInfo.find(({ id }) => id === comparisonCasterId)?.name ?? '')

    const canDeleteTab = FeatureFlags.canDeleteTabInCurrentDashboardType(
      featureFlags,
      visualizationMetaInformation,
      appState,
    )
    const canEditDashboardOptions = FeatureFlags.canEditDashboardOptionsInCurrentDashboardType(
      featureFlags,
      visualizationMetaInformation,
      appState,
    )
    const canMovePlot = FeatureFlags.canMovePlotsInCurrentDashboardType(
      featureFlags,
      visualizationMetaInformation,
      appState,
    )
    const canResizePlot = FeatureFlags.canResizePlotsInCurrentDashboardType(
      featureFlags,
      visualizationMetaInformation,
      appState,
    )
    const canAddPlot = FeatureFlags.canAddPlotsInCurrentDashboardType(
      featureFlags,
      visualizationMetaInformation,
      appState,
    )
    const canViewExportPlotButton = FeatureFlags.canViewExportPlots(featureFlags)
    const canExportPlot = FeatureFlags.canUseExportPlots(featureFlags)
    const canOpenCasterDataServerDialog = FeatureFlags.canOpenCasterDataServerDialog(featureFlags) && plantDataAvailable
    const canToggleLiveData = FeatureFlags.canToggleLiveData(featureFlags) && plantDataAvailable

    return (
      <GridViewContainer $simple={simple} onResize={this.handleResize}>
        <DashboardMenu
          title={`${name}; ${childLength} ${childLength === 1 ? 'Plot' : 'Plots'}`}
          $isEditModeOn={isEditModeOn}
          $showPlotInfo={size.width > minimalSplitWidth + 140}
          $infoAction={size.width <= minimalSplitWidth}
        >
          {size.width > minimalSplitWidth && <span>{name}</span>}
          {
            size.width > minimalSplitWidth &&
            (
              <span>
                {childLength} {t('viewGrid.counter', { pl: childLength === 1 ? '' : 's' })}
              </span>
            )
          }
          {
            simple && canDeleteTab && (
              <DashboardMenuAction onClick={this.handleDeleteDashboard}>
                <i className='pe-7s-trash pe-fw' title={t('viewGrid.closeTab')} />
              </DashboardMenuAction>
            )
          }
          {
            isEditModeOn && canEditDashboardOptions && (
              <DashboardMenuAction onClick={this.handleEditDashboard}>
                <i className='pe-7s-config pe-fw' title={t('viewGrid.options')} />
              </DashboardMenuAction>
            )
          }
          {
            canViewExportPlotButton &&
            (
              <DashboardMenuAction
                onClick={this.handleExportPlot}
                $disabled={!canExportPlot}
              >
                <i
                  className='pe-7s-download pe-fw'
                  title={`${t('viewGrid.export')}${!canExportPlot ? ' - Insufficient permissions' : ''}`}
                />
              </DashboardMenuAction>
            )
          }
          {
            canAddPlot &&
            (
              <DashboardMenuAction onClick={this.handleAddPlot} $doRotate>
                <i className='pe-7s-close pe-fw' title={t('viewGrid.add')} />
              </DashboardMenuAction>
            )
          }
          {
            canAddPlot && size.width <= minimalSplitWidth && (
              <DashboardMenuAction onClick={this.handleAddPlot}>
                <i className='pe-7s-info pe-fw' />
              </DashboardMenuAction>
            )
          }
        </DashboardMenu>
        {
          FeatureFlags.canViewCasterComparison(featureFlags) &&
          openDialogs.includes('CasterTree') &&
          (
            <ComparisonCasterMenu>
              <div className='actions'>
                {/* TODO: check if caster has xy_uuid fields, otherwise hide this action */}
                {
                  canToggleLiveData && (
                    <FontAwesomeIcon
                      icon={faRss}
                      fixedWidth
                      title={t('viewGrid.toggleLiveData')}
                      onClick={this.handleToggleLiveData}
                      style={{ color: isLiveDataEnabled ? 'green' : 'white', marginRight: '10px' }}
                    />
                  )
                }
                {
                  canOpenCasterDataServerDialog && (
                    <FontAwesomeIcon
                      icon={faDatabase}
                      fixedWidth
                      title={t('viewGrid.openCasterDataServerDialog')}
                      onClick={this.handleOpenCasterDataServerDialog}
                    />
                  )
                }
              </div>
              {
                cases.length > 1 &&
                (
                  <div className='selector' style={{ width: '100%' }}>
                    <Input
                      value={selectedComparisonCastersNames ?? []}
                      renderValue={
                        () =>
                          <AmountOfSelectedCompareCasters count={selectedComparisonCaseIds.length} /> as any
                      }
                      style={{ background: 'none' }}
                      noMarginTop
                      name='comparisonCaster'
                      type='select'
                      selectors={cases}
                      selectedIds={selectedComparisonCaseIds}
                      onChange={this.handleComparisonCasterSelectViaOption}
                      onCheckboxClick={this.handleComparisonCasterSelectViaCheckbox}
                      onDelete={this.handleComparisonEntryDelete}
                      spaceBetween
                      optionStyles={{ fontSize: '14px', width: '400px', 'pointer-events': 'auto' }}
                      checkbox
                      multiple
                      actions={
                        [
                          {
                            key: Action.OpenPlaybackWindow,
                            icon: faPlay,
                            title: 'Open Playback Dialog',
                            condition: id => id.startsWith('cds_timestamp_'),
                          },
                          {
                            key: Action.OpenDateTimePicker,
                            icon: faClock,
                            title: 'Select Time',
                            condition: id => (
                              FeatureFlags.canOpenCasterDateTimePickerDialog(featureFlags) &&
                              casesInfo.some(({ id: caseId }) => caseId === id)
                            ),
                          },
                        ]
                      }
                      onActionClick={this.handleActionClick}
                    />
                  </div>
                )
              }
            </ComparisonCasterMenu>
          )
        }
        <GridScrollbar
          ref={this.handleWrapperRef}
          onWheel={this.handleWheel}
          className='grid-layout-scroll-container'
        >
          <div className='button-left scroll-btn'>
            <div className='scroll-hint-trigger' />
            <ScrollHint>Use shift + mousewheel to scroll</ScrollHint>
          </div>
          <div className='button-right scroll-btn'>
            <div className='scroll-hint-trigger' />
            <ScrollHint>Use shift + mousewheel to scroll</ScrollHint>
          </div>
          <div className='button-up scroll-btn'>
            <div className='scroll-hint-trigger' />
            <ScrollHint>Use mousewheel to scroll</ScrollHint>
          </div>
          <div className='button-down scroll-btn'>
            <div className='scroll-hint-trigger' />
            <ScrollHint>Use mousewheel to scroll</ScrollHint>
          </div>
          <GridLayout
            className={gridLayoutClassName}
            rowHeight={Logic.GRID_STEP_SIZE}
            cols={Logic.GRID_COLUMNS}
            onLayoutChange={this.handleLayoutChange}
            layout={layout}
            width={Logic.FULL_HD_VIEW_WIDTH}
            draggableHandle='.header_bar'
            isDraggable={canMovePlot}
            isResizable={canResizePlot && resizeAllowed}
            margin={[ 1, 1 ]}
            autoSize
          >
            {
              Children.map(children, child => (
                <div key={child.props.id} style={{ position: 'relative' }}>
                  <GridItemContainer
                    tileId={child.props.tileId}
                    tileConfig={tileConfigs[child.key]}
                  >
                    {child}
                  </GridItemContainer>
                </div>
              ))
            }
          </GridLayout>
          {
            childLength === 0 && canAddPlot && (
              <AddPlotGridItem onClick={this.handleAddPlot}>
                <i className='pe-7s-plus pe-va' onClick={this.handleAddPlot} title={t('viewGrid.add')} />
                {t('viewGrid.addTile')}
              </AddPlotGridItem>
            )
          }
        </GridScrollbar>
      </GridViewContainer>
    )
  }
}

export default compose<any>(withTranslation('visualization'), connector)(ViewGrid)
