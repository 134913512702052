import { useState } from 'react'

import Input from '@/react/specific/Input'
import {
  Button,
  Dialog,
  DialogBackground,
  Form,
  Header,
  I,
  Title,
} from '@/react/visualization/dashboard/Dialogs/DialogStyles'

type Props = {
  fileId: string
  fileName: string
  onClose: () => void
  onSubmit: (newFileName: string, fileId: string) => void
}

export default function EditDocumentationFileNameDialog ({ fileId, fileName, onClose, onSubmit }: Props) {
  const [ newFileName, setNewFileName ] = useState<string>(fileName)

  return (
    <div>
      <DialogBackground />
      <Dialog $height='250px' $width='400px'>
        <Header title='Edit Documentation File Name'>
          <Title>Edit Documentation File Name</Title>
          <I
            className='pe-7s-close'
            onClick={onClose}
            title='Close'
          />
        </Header>
        <Form>
          <Input
            label='New File Name'
            name='fileName'
            type='text'
            value={newFileName}
            onChange={(e) => setNewFileName(e.target.value)}
          />
          <Button
            onClick={() => onSubmit(newFileName, fileId)}
          >
            Submit
          </Button>
        </Form>
      </Dialog>
    </div>
  )
}
