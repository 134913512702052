import { Component } from 'react'

import FeatureFlags from '@/react/FeatureFlags'
import InputComponent from '@/react/specific/Input'
import type { ArrayOfTranslations, Translation } from '@/types/translation'

import { ExpandableSection } from './ExpandableSection'
import StepComponent from './Step'

interface Props {
  stepIndex: number
  step: Step
  caseValues: Record<string, any>
  dynamicOptions: Record<string, Option[]>
  featureFlags: Record<string, boolean>
  handleInputChange: (event: Event & { target: HTMLInputElement }, stepNumber?: number) => void
  loading: boolean
  t: ArrayOfTranslations & Translation
  handleUploadFile: (id: string, stepId?: string) => void
  expanded: boolean
  handleExpandStep: (index: number) => void
  handleRunStep: (stepDefinitionId: string) => void
  valid: boolean
  disabled: boolean
  lastStep: boolean
  executionSteps: ExecutionStepEntity[] | null
  executionStepId?: string | undefined
  stepDefinitionId: string
  getParameterByRef: (ref: string) => any
  onCaseOptionChange: (stepIndex: number, caseOptionIndex: number) => void
  getChosenCaseOption: (step: Step, stepIndex: number) => {
    chosenCaseOption: CaseOption | null
    selectors: { key: string, value: string, disabled?: boolean | undefined }[]
  }
  uploadingFileParameterId: string
}

class StepWithOptions extends Component<Props> {
  private readonly handleToggleStep = () => {
    const { handleExpandStep, stepIndex } = this.props

    handleExpandStep(stepIndex)
  }

  private readonly handleCaseOptionChange = (e: any) => {
    const { stepIndex, onCaseOptionChange, step } = this.props

    const optionIndex = step.caseOptions?.findIndex((option: any) => option.name === e.target.value) ?? 0

    onCaseOptionChange(stepIndex, optionIndex)
  }

  public override render () {
    const {
      step,
      stepIndex,
      dynamicOptions,
      caseValues,
      loading,
      t,
      featureFlags,
      expanded,
      valid,
      handleRunStep,
      disabled,
      lastStep,
      getParameterByRef,
      handleInputChange,
      handleUploadFile,
      getChosenCaseOption,
      uploadingFileParameterId,
    } = this.props
    const { caseOptions, caseDropdownLabel = 'Case Selector' } = step

    if (!caseOptions) {
      return null
    }

    const { chosenCaseOption, selectors } = getChosenCaseOption(step, stepIndex)

    return (
      <ExpandableSection
        expanded={expanded}
        sectionName={step.name || `Step ${stepIndex}`}
        sectionIndex={stepIndex}
        onToggleStep={this.handleToggleStep}
      >
        {
          expanded && (
            <>
              <InputComponent
                key={`${step.id}-caseOptions-Selector`}
                name={step.id}
                type='select'
                label={caseDropdownLabel}
                title={caseDropdownLabel}
                value={chosenCaseOption ? chosenCaseOption.name : 'default'}
                selectors={selectors}
                onChange={this.handleCaseOptionChange}
                disabled={disabled || loading || !FeatureFlags.canEditExecutableDialog(featureFlags)}
              />
              {
                chosenCaseOption
                  ? (
                    <StepComponent
                      handleRunStep={handleRunStep}
                      valid={valid}
                      disabled={disabled}
                      step={chosenCaseOption}
                      stepIndex={stepIndex}
                      key={`${step.id}-step`}
                      caseValues={caseValues}
                      dynamicOptions={dynamicOptions}
                      featureFlags={featureFlags ?? {}}
                      loading={loading}
                      t={t}
                      handleInputChange={handleInputChange}
                      handleUploadFile={handleUploadFile}
                      expanded
                      lastStep={lastStep}
                      getParameterByRef={getParameterByRef}
                      executionSteps={this.props.executionSteps}
                      executionStepId={this.props.executionStepId}
                      stepDefinitionId={this.props.stepDefinitionId}
                      uploadingFileParameterId={uploadingFileParameterId}
                    />
                  )
                  : null
              }
            </>
          )
        }
      </ExpandableSection>
    )
  }
}

export default StepWithOptions
