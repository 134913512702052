import { isEqual } from 'lodash'
import * as THREE from 'three'

import DrawUtil from '@/three/logic/DrawUtil'
import Util from '@/three/logic/Util'
import type { ElementCacheKey } from '@/three/objects'
import CoordinateAxes from '@/three/objects/CoordinateAxes'
import ThreeNozzle from '@/three/objects/Nozzle'
import ThreeSegment from '@/three/objects/Segment'
import { StrandSides } from '@/types/elements/enum'
import { ElementMapsUtil } from '@/Util/ElementMapsUtil'
import { ElementsUtil } from '@/Util/ElementsUtil'
import { Mapping } from '@/Util/mapping/Mapping'

import MainView from '.'
import CalculationUtil from './CalculationUtil'
import ConditionUtil from './ConditionUtil'
import Getters from './Getters'
import MainUtil from './MainHandlers'

export default class DrawHandlers {
  public static handleRedrawView (view: MainView, data: any) {
    const { isRedrawing, elementMapsChanged, hasChanges } = view // redraw,

    const caster = view.elementMaps.Caster

    if (!caster) {
      return
    }

    // TODO: rework this it's messy...
    // TODO: rework redraw
    if (ConditionUtil.shouldBeRedrawing(true, caster, data, hasChanges, isRedrawing, elementMapsChanged)) {
      view.isRedrawing = true
      view.sceneReady = false // TODO: check if this is correct here

      if (view.caster) {
        view.scene.remove(view.caster)

        delete view.caster
      }

      view.reset()

      if (view.isNewCaster) {
        view.views?.uiView?.reset()
      }
    }
  }

  public static drawStrandGuide (view: MainView, selectedMountLogIds: string[], oldSelectedPaths: Set<string>) {
    if (!view.caster) {
      return
    }

    const caster = view.caster

    const {
      SegmentGroupSlot,
      SupportPointSlot,
      SegmentSlot,
      NozzleSlot,
      RollerSlot,
      RollerBodySlot,
      RollerBearingSlot,
      DataLineSlot,
      StrandDataPointSlot,
      SegmentDataPointSlot,
      RollerDataPointSlot,
      RollerBodyDataPointSlot,
      RollerSensorPointSlot,
      RollerBodySensorPointSlot,
      SegmentSensorPointSlot,
    } = view.elementMaps

    let container = caster

    ThreeSegment.reset()

    const elementPathByMountLogId = {} as Record<string, string>
    const mountLogIdByElementPath = {} as Record<string, string>

    const moldMountLog = Object.values(view.elementMaps.MoldMountLog)[0]
    const moldSlot = view.elementMaps.MoldSlot[moldMountLog?.slotId ?? '']
    const moldFaceMountLogs = Object
      .values(view.elementMaps.MoldFaceMountLog)
      .filter(moldFace => moldFace.moldMountLogId === moldMountLog?.id)

    if (moldFaceMountLogs && (moldFaceMountLogs.length > 0) && moldSlot) {
      const moldFacesGroup = view.caster.getObjectByName('moldFaces')

      moldFaceMountLogs.forEach((moldFaceMountLog, index) => {
        const face = new THREE.Group()

        face.name = moldFaceMountLog.side ?? 'MoldFaceMissingSide'
        face.userData['side'] = moldFaceMountLog.side
        face.userData['type'] = 'MoldFace'

        const newRotation = new THREE.Euler(0, 0, 0, 'XYZ')

        switch (moldFaceMountLog.side) {
          case StrandSides.Right:
            newRotation.set(0, -Util.RAD90, 0)
            break
          case StrandSides.Left:
            newRotation.set(0, -Util.RAD90, 0)
            break
          default:
        }

        face.rotation.copy(newRotation)

        Util.addOrReplace(moldFacesGroup, face)

        for (const dataPointMountLogId of moldFaceMountLog.dataPointMountLogs) {
          const dataPointMountLog = view.elementMaps.MoldFaceDataPointMountLog[dataPointMountLogId]
          const numericId = Mapping.numericIdByMountLogId[dataPointMountLog?.id ?? '']

          if (!dataPointMountLog || Number.isNaN(numericId)) {
            continue
          }

          const path = `Mold:${index}/DataPoint:${numericId}`

          elementPathByMountLogId[dataPointMountLog.id] = path
          mountLogIdByElementPath[path] = dataPointMountLog.id

          const elementInfo = view.elementMaps.MoldFaceDataPointSlot[dataPointMountLog?.slotId ?? '']
          const dataPoint = elementInfo && numericId !== undefined
            ? ElementMapsUtil.getFullCasterElement(elementInfo, dataPointMountLog, numericId)
            : null

          if (
            dataPoint &&
            !(
              view.elementList.DataPoint &&
              view.elementList.DataPoint[path] &&
              view.dirtyList &&
              !view.dirtyList.includes(path)
            )
          ) {
            DrawHandlers.handleDrawable(
              view,
              face,
              path,
              'DataPoint',
              dataPoint,
              view.isLiveCDSDataEnabled,
              view.liveCDSData,
            )
          }
        }
      })
    }

    // Draw order 1- SegmentGroup, 2- Segments, 3- Nozzles, 4- Rollers, 5- RollerBodies, 6- RollerBearings
    // SegmentGroups
    const segmentGroupMountLogMap = ElementsUtil.getSegmentGroupMountLogs(view.elementMaps)

    const segmentGroupMountLogs = Object.values(segmentGroupMountLogMap)

    segmentGroupMountLogs.forEach((segmentGroupMountLog) => {
      const numericId = Mapping.numericIdByMountLogId[segmentGroupMountLog.id]
      const segmentGroup = SegmentGroupSlot[segmentGroupMountLog?.slotId ?? '']
      const element = segmentGroup && numericId !== undefined
        ? ElementMapsUtil.getFullCasterElement(segmentGroup, segmentGroupMountLog, numericId)
        : null

      if (!element) {
        return
      }

      const path = `SegmentGroup:${Mapping.numericIdByMountLogId[segmentGroupMountLog.id]}`

      elementPathByMountLogId[segmentGroupMountLog.id] = path
      mountLogIdByElementPath[path] = segmentGroupMountLog.id

      container = Getters.getContainer(view, container, caster, path, 'SegmentGroup', element)
      DrawHandlers.handleDrawable(
        view,
        container,
        path,
        'SegmentGroup',
        element,
        view.isLiveCDSDataEnabled,
        view.liveCDSData,
      )
    })

    // DataPoints in Strand
    const dataPointMountLogMap = view.elementMaps.StrandDataPointMountLog

    const dataPointMountLogs = Object.values(dataPointMountLogMap)

    for (const dataPointMountLog of dataPointMountLogs) {
      const numericId = Mapping.numericIdByMountLogId[dataPointMountLog.id]
      const dataPoint = StrandDataPointSlot[dataPointMountLog?.slotId ?? '']
      const element = dataPoint && numericId !== undefined
        ? ElementMapsUtil.getFullCasterElement(dataPoint, dataPointMountLog, numericId)
        : null

      if (!element) {
        continue
      }

      const path = `DataPoint:${Mapping.numericIdByMountLogId[dataPointMountLog.id]}`

      elementPathByMountLogId[dataPointMountLog.id] = path
      mountLogIdByElementPath[path] = dataPointMountLog.id

      const strandContainer = Getters.getContainer(view, container, caster, '', 'Strand', null)

      DrawHandlers.handleDrawable(
        view,
        strandContainer,
        path,
        'DataPoint',
        element,
        view.isLiveCDSDataEnabled,
        view.liveCDSData,
      )
    }

    // Segments
    const segmentMountLogMap = ElementsUtil.getSegmentMountLogMapBySegmentGroupMountLogs(
      view.elementMaps,
      segmentGroupMountLogs,
    )
    const segmentMountLogs = Object.values(segmentMountLogMap)
    const sortedSegmentMountLogs = segmentMountLogs.sort((a, b) => {
      const slotA = view.elementMaps.SegmentSlot?.[a.slotId]
      const slotB = view.elementMaps.SegmentSlot?.[b.slotId]

      return (slotA?.passlineCoord ?? 0) - (slotB?.passlineCoord ?? 0)
    })

    for (const segmentMountLog of sortedSegmentMountLogs) {
      const { nozzleMountLogs, rollerMountLogs, dataPointMountLogs } = segmentMountLog

      if (!nozzleMountLogs?.length && !rollerMountLogs?.length && !dataPointMountLogs?.length) {
        continue
      }

      const numericId = Mapping.numericIdByMountLogId[segmentMountLog.id]
      const segment = SegmentSlot[segmentMountLog?.slotId ?? '']
      const element = segment && numericId !== undefined
        ? ElementMapsUtil.getFullCasterElement(segment, segmentMountLog, numericId)
        : null

      if (!element) {
        continue
      }

      // TODO: how can i get the segment group mount log id??
      const segmentGroupMountLogId = segmentMountLog.segmentGroupMountLogId
      const parentPath = elementPathByMountLogId[segmentGroupMountLogId ?? '']

      if (!parentPath) {
        continue
      }

      const path = `${parentPath}/Segment:${Mapping.numericIdByMountLogId[segmentMountLog.id]}`

      elementPathByMountLogId[segmentMountLog.id] = path
      mountLogIdByElementPath[path] = segmentMountLog.id

      container = view.containerList.SegmentGroup[parentPath]
      container = Getters.getContainer(view, container, caster, path, 'Segment', element)
      DrawHandlers.handleDrawable(
        view,
        container,
        path,
        'Segment',
        element,
        view.isLiveCDSDataEnabled,
        view.liveCDSData,
      )
    }

    // DataPoints in Segments
    const segmentDataPointMountLogMap = ElementsUtil.getSegmentDataPointMountLogMapBySegmentMountLogs(
      view.elementMaps,
      segmentMountLogs,
    )

    const segmentDataPointMountLogs = Object.values(segmentDataPointMountLogMap)

    for (const segmentDataPointMountLog of segmentDataPointMountLogs) {
      const numericId = Mapping.numericIdByMountLogId[segmentDataPointMountLog.id]
      const dataPoint = SegmentDataPointSlot[segmentDataPointMountLog?.slotId ?? '']
      const element = dataPoint && numericId !== undefined
        ? ElementMapsUtil.getFullCasterElement(dataPoint, segmentDataPointMountLog, numericId)
        : null

      if (!element) {
        continue
      }

      const parentPath = elementPathByMountLogId[segmentDataPointMountLog.segmentMountLogId ?? '']

      if (!parentPath) {
        continue
      }

      const path = `${parentPath}/DataPoint:${Mapping.numericIdByMountLogId[segmentDataPointMountLog.id]}`

      elementPathByMountLogId[segmentDataPointMountLog.id] = path
      mountLogIdByElementPath[path] = segmentDataPointMountLog.id

      container = view.containerList.Segment[parentPath]
      DrawHandlers.handleDrawable(
        view,
        container,
        path,
        'DataPoint',
        element,
        view.isLiveCDSDataEnabled,
        view.liveCDSData,
      )
    }

    // Nozzles
    const nozzleMountLogMap = ElementsUtil.getNozzleMountLogMapBySegmentMountLogs(
      view.elementMaps,
      segmentMountLogs,
    )
    const nozzleMountLogs = Object.values(nozzleMountLogMap)

    for (const nozzleMountLog of nozzleMountLogs) {
      const numericId = Mapping.numericIdByMountLogId[nozzleMountLog.id]
      const nozzle = NozzleSlot[nozzleMountLog?.slotId ?? '']
      const element = nozzle && numericId !== undefined
        ? ElementMapsUtil.getFullCasterElement(nozzle, nozzleMountLog, numericId)
        : null

      if (!element) {
        continue
      }

      const parentPath = elementPathByMountLogId[nozzleMountLog.segmentMountLogId ?? '']

      if (!parentPath) {
        continue
      }

      const path = `${parentPath}/Nozzle:${Mapping.numericIdByMountLogId[nozzleMountLog.id]}`

      elementPathByMountLogId[nozzleMountLog.id] = path
      mountLogIdByElementPath[path] = nozzleMountLog.id

      container = view.containerList.Segment[parentPath]
      DrawHandlers.handleDrawable(
        view,
        container,
        path,
        'Nozzle',
        element,
        view.isLiveCDSDataEnabled,
        view.liveCDSData,
      )
    }

    // Rollers
    const rollerMountLogMap = ElementsUtil.getRollerMountLogMapBySegmentMountLogs(
      view.elementMaps,
      segmentMountLogs,
    )

    const rollerMountLogs = Object.values(rollerMountLogMap)

    for (const rollerMountLog of rollerMountLogs) {
      const numericId = Mapping.numericIdByMountLogId[rollerMountLog.id]
      const roller = RollerSlot[rollerMountLog?.slotId ?? '']
      const element = roller && numericId !== undefined
        ? ElementMapsUtil.getFullCasterElement(roller, rollerMountLog, numericId)
        : null

      if (!element) {
        continue
      }

      const parentPath = elementPathByMountLogId[rollerMountLog.segmentMountLogId ?? '']

      if (!parentPath) {
        continue
      }

      const path = `${parentPath}/Roller:${Mapping.numericIdByMountLogId[rollerMountLog.id]}`

      elementPathByMountLogId[rollerMountLog.id] = path
      mountLogIdByElementPath[path] = rollerMountLog.id

      container = view.containerList.Segment[parentPath]
      DrawHandlers.handleDrawable(
        view,
        container,
        path,
        'Roller',
        element,
        view.isLiveCDSDataEnabled,
        view.liveCDSData,
      )
    }

    // RollerBodies
    const rollerBodyMountLogMap = ElementsUtil.getRollerBodyMountLogMapByRollerMountLogs(
      view.elementMaps,
      rollerMountLogs,
    )

    const rollerBodyMountLogs = Object.values(rollerBodyMountLogMap)

    for (const rollerBodyMountLog of rollerBodyMountLogs) {
      const numericId = Mapping.numericIdByMountLogId[rollerBodyMountLog.id]
      const rollerBody = RollerBodySlot[rollerBodyMountLog?.slotId ?? '']
      const element = rollerBody && numericId !== undefined
        ? ElementMapsUtil.getFullCasterElement(rollerBody, rollerBodyMountLog, numericId)
        : null

      if (!element) {
        continue
      }

      const parentPath = elementPathByMountLogId[rollerBodyMountLog.rollerMountLogId ?? '']

      if (!parentPath) {
        continue
      }

      const path = `${parentPath}/RollerBody:${Mapping.numericIdByMountLogId[rollerBodyMountLog.id]}`

      elementPathByMountLogId[rollerBodyMountLog.id] = path
      mountLogIdByElementPath[path] = rollerBodyMountLog.id

      const { path: segmentPath } = Util.getParentInfo(parentPath)

      container = view.containerList.Segment[segmentPath]
      container = Getters.getContainer(view, container, caster, parentPath, 'Roller', element)
      DrawHandlers.handleDrawable(
        view,
        container,
        path,
        'RollerBody',
        element,
        view.isLiveCDSDataEnabled,
        view.liveCDSData,
      )
    }

    // RollerBearings
    const rollerBearingMountLogMap = ElementsUtil.getRollerBearingMountLogMapByRollerMountLogs(
      view.elementMaps,
      rollerMountLogs,
    )

    const rollerBearingMountLogs = Object.values(rollerBearingMountLogMap)

    for (const rollerBearingMountLog of rollerBearingMountLogs) {
      const numericId = Mapping.numericIdByMountLogId[rollerBearingMountLog.id]
      const rollerBearing = RollerBearingSlot[rollerBearingMountLog?.slotId ?? '']
      const element = rollerBearing && numericId !== undefined
        ? ElementMapsUtil.getFullCasterElement(rollerBearing, rollerBearingMountLog, numericId)
        : null

      if (!element) {
        continue
      }

      const parentPath = elementPathByMountLogId[rollerBearingMountLog.rollerMountLogId ?? '']

      if (!parentPath) {
        continue
      }

      const path = `${parentPath}/RollerBearing:${Mapping.numericIdByMountLogId[rollerBearingMountLog.id]}`

      elementPathByMountLogId[rollerBearingMountLog.id] = path
      mountLogIdByElementPath[path] = rollerBearingMountLog.id

      const { path: segmentPath } = Util.getParentInfo(parentPath)

      container = view.containerList.Segment[segmentPath]
      container = Getters.getContainer(view, container, caster, parentPath, 'Roller', element)
      DrawHandlers.handleDrawable(
        view,
        container,
        path,
        'RollerBearing',
        element,
        view.isLiveCDSDataEnabled,
        view.liveCDSData,
      )
    }

    // DataPoints in Rollers
    const rollerDataPointMountLogMap = ElementsUtil.getRollerDataPointMountLogMapByRollerMountLogs(
      view.elementMaps,
      rollerMountLogs,
    )

    const rollerDataPointMountLogs = Object.values(rollerDataPointMountLogMap)

    for (const rollerDataPointMountLog of rollerDataPointMountLogs) {
      const numericId = Mapping.numericIdByMountLogId[rollerDataPointMountLog.id]
      const dataPoint = RollerDataPointSlot[rollerDataPointMountLog?.slotId ?? '']
      const element = dataPoint && numericId !== undefined
        ? ElementMapsUtil.getFullCasterElement(dataPoint, rollerDataPointMountLog, numericId)
        : null

      if (!element) {
        continue
      }

      const parentPath = elementPathByMountLogId[rollerDataPointMountLog.rollerMountLogId ?? '']

      if (!parentPath) {
        continue
      }

      const path = `${parentPath}/DataPoint:${Mapping.numericIdByMountLogId[rollerDataPointMountLog.id]}`

      elementPathByMountLogId[rollerDataPointMountLog.id] = path
      mountLogIdByElementPath[path] = rollerDataPointMountLog.id

      const { path: segmentPath } = Util.getParentInfo(parentPath)

      container = view.containerList.Roller[segmentPath]
      container = Getters.getContainer(view, container, caster, parentPath, 'Roller', element)
      DrawHandlers.handleDrawable(
        view,
        container,
        path,
        'DataPoint',
        element,
        view.isLiveCDSDataEnabled,
        view.liveCDSData,
      )
    }

    // SensorPoints in Rollers
    const rollerSensorPointMountLogMap = ElementsUtil.getRollerSensorPointMountLogMapByRollerMountLogs(
      view.elementMaps,
      rollerMountLogs,
    )

    const rollerSensorPointMountLogs = Object.values(rollerSensorPointMountLogMap)

    for (const rollerSensorPointMountLog of rollerSensorPointMountLogs) {
      const numericId = Mapping.numericIdByMountLogId[rollerSensorPointMountLog.id]
      const sensorPoint = RollerSensorPointSlot[rollerSensorPointMountLog?.slotId ?? '']
      const element = sensorPoint && numericId !== undefined
        ? ElementMapsUtil.getFullCasterElement(sensorPoint, rollerSensorPointMountLog, numericId)
        : null

      if (!element) {
        continue
      }

      const parentPath = elementPathByMountLogId[rollerSensorPointMountLog.rollerMountLogId ?? '']

      if (!parentPath) {
        continue
      }

      const path = `${parentPath}/SensorPoint:${Mapping.numericIdByMountLogId[rollerSensorPointMountLog.id]}`

      elementPathByMountLogId[rollerSensorPointMountLog.id] = path
      mountLogIdByElementPath[path] = rollerSensorPointMountLog.id

      const { path: segmentPath } = Util.getParentInfo(parentPath)

      container = view.containerList.Roller[segmentPath]
      container = Getters.getContainer(view, container, caster, parentPath, 'Roller', element)
      DrawHandlers.handleDrawable(
        view,
        container,
        path,
        'SensorPoint',
        element,
        view.isLiveCDSDataEnabled,
        view.liveCDSData,
      )
    }

    // DataPoints in Roller Bodies
    const rollerBodyDataPointMountLogMap = ElementsUtil.getRollerBodyDataPointMountLogMapByRollerBodyMountLogs(
      view.elementMaps,
      rollerBodyMountLogs,
    )

    const rollerBodyDataPointMountLogs = Object.values(rollerBodyDataPointMountLogMap)

    for (const rollerBodyDataPointMountLog of rollerBodyDataPointMountLogs) {
      const numericId = Mapping.numericIdByMountLogId[rollerBodyDataPointMountLog.id]
      const dataPoint = RollerBodyDataPointSlot[rollerBodyDataPointMountLog?.slotId ?? '']
      const element = dataPoint && numericId !== undefined
        ? ElementMapsUtil.getFullCasterElement(dataPoint, rollerBodyDataPointMountLog, numericId)
        : null

      if (!element) {
        continue
      }

      const parentPath = elementPathByMountLogId[rollerBodyDataPointMountLog.rollerBodyMountLogId ?? '']

      if (!parentPath) {
        continue
      }

      const path = `${parentPath}/DataPoint:${Mapping.numericIdByMountLogId[rollerBodyDataPointMountLog.id]}`

      elementPathByMountLogId[rollerBodyDataPointMountLog.id] = path
      mountLogIdByElementPath[path] = rollerBodyDataPointMountLog.id

      const { path: rollerPath } = Util.getParentInfo(parentPath)

      container = view.containerList.Roller[rollerPath]
      container = Getters.getContainer(view, container, caster, parentPath, 'RollerBody', element)
      DrawHandlers.handleDrawable(
        view,
        container,
        path,
        'DataPoint',
        element,
        view.isLiveCDSDataEnabled,
        view.liveCDSData,
      )
    }

    const threeSegmentHash = view.elementList.Segment

    if (!threeSegmentHash) {
      return
    }

    Object.keys(view.containerList.Segment).forEach(path => {
      const data = view.containerList.Segment[path].userData

      threeSegmentHash[path]?.setValues({
        elementData: data,
        path,
        isDeleted: view.deleteList?.includes(path) ?? false,
        isHidden: view.hideList?.includes(path) ?? false,
        isPhantom: false,
        view,
      })
    })

    // sensor points in roller bodies
    const rollerBodySensorPointMountLogMap = ElementsUtil.getRollerBodySensorPointMountLogMapByRollerBodyMountLogs(
      view.elementMaps,
      rollerBodyMountLogs,
    )

    const rollerBodySensorPointMountLogs = Object.values(rollerBodySensorPointMountLogMap)

    for (const rollerBodySensorPointMountLog of rollerBodySensorPointMountLogs) {
      const numericId = Mapping.numericIdByMountLogId[rollerBodySensorPointMountLog.id]
      const sensorPoint = RollerBodySensorPointSlot[rollerBodySensorPointMountLog?.slotId ?? '']

      const element = sensorPoint && numericId !== undefined
        ? ElementMapsUtil.getFullCasterElement(sensorPoint, rollerBodySensorPointMountLog, numericId)
        : null

      if (!element) {
        continue
      }

      const parentPath = elementPathByMountLogId[rollerBodySensorPointMountLog.rollerBodyMountLogId ?? '']

      if (!parentPath) {
        continue
      }

      const path = `${parentPath}/SensorPoint:${Mapping.numericIdByMountLogId[rollerBodySensorPointMountLog.id]}`

      elementPathByMountLogId[rollerBodySensorPointMountLog.id] = path
      mountLogIdByElementPath[path] = rollerBodySensorPointMountLog.id

      const { path: rollerPath } = Util.getParentInfo(parentPath)

      container = view.containerList.Roller[rollerPath]
      container = Getters.getContainer(view, container, caster, parentPath, 'RollerBody', element)
      DrawHandlers.handleDrawable(
        view,
        container,
        path,
        'SensorPoint',
        element,
        view.isLiveCDSDataEnabled,
        view.liveCDSData,
      )
    }

    // sensor points in segments
    const segmentSensorPointMountLogMap = ElementsUtil.getSegmentSensorPointMountLogMapBySegmentMountLogs(
      view.elementMaps,
      segmentMountLogs,
    )

    const segmentSensorPointMountLogs = Object.values(segmentSensorPointMountLogMap)

    for (const segmentSensorPointMountLog of segmentSensorPointMountLogs) {
      const numericId = Mapping.numericIdByMountLogId[segmentSensorPointMountLog.id]
      const sensorPoint = SegmentSensorPointSlot[segmentSensorPointMountLog?.slotId ?? '']
      const element = sensorPoint && numericId !== undefined
        ? ElementMapsUtil.getFullCasterElement(sensorPoint, segmentSensorPointMountLog, numericId)
        : null

      if (!element) {
        continue
      }

      const parentPath = elementPathByMountLogId[segmentSensorPointMountLog.segmentMountLogId ?? '']

      if (!parentPath) {
        continue
      }

      const path = `${parentPath}/SensorPoint:${Mapping.numericIdByMountLogId[segmentSensorPointMountLog.id]}`

      elementPathByMountLogId[segmentSensorPointMountLog.id] = path
      mountLogIdByElementPath[path] = segmentSensorPointMountLog.id

      const { path: segmentPath } = Util.getParentInfo(parentPath)

      container = view.containerList.Segment[segmentPath]
      container = Getters.getContainer(view, container, caster, parentPath, 'Segment', element)
      DrawHandlers.handleDrawable(
        view,
        container,
        path,
        'SensorPoint',
        element,
        view.isLiveCDSDataEnabled,
        view.liveCDSData,
      )
    }

    // Segments Update
    for (const segmentMountLog of segmentMountLogs) {
      const { nozzleMountLogs, rollerMountLogs, dataPointMountLogs } = segmentMountLog

      if (!nozzleMountLogs?.length && !rollerMountLogs?.length && !dataPointMountLogs?.length) {
        continue
      }

      const segmentGroupMountLogId = segmentMountLog.segmentGroupMountLogId
      const parentPath = elementPathByMountLogId[segmentGroupMountLogId ?? '']

      if (!parentPath) {
        continue
      }

      const path = `${parentPath}/Segment:${Mapping.numericIdByMountLogId[segmentMountLog.id]}`

      view.elementList.Segment?.[path]?.updateTransform()
    }

    // SupportPoints
    const supportPointMountLogMap = ElementsUtil.getSupportPointMountLogMapBySegmentGroupMountLogs(
      view.elementMaps,
      segmentGroupMountLogs,
    )
    const supportPointMountLogs = Object.values(supportPointMountLogMap)

    for (const supportPointMountLog of supportPointMountLogs) {
      const numericId = Mapping.numericIdByMountLogId[supportPointMountLog.id]
      const supportPoint = SupportPointSlot[supportPointMountLog?.slotId ?? '']
      const element = supportPoint && numericId !== undefined
        ? ElementMapsUtil.getFullCasterElement(supportPoint, supportPointMountLog, numericId)
        : null

      if (!element) {
        continue
      }

      const parentPath = elementPathByMountLogId[supportPointMountLog.segmentGroupMountLogId ?? '']

      if (!parentPath) {
        continue
      }

      const path = `${parentPath}/SupportPoint:${Mapping.numericIdByMountLogId[supportPointMountLog.id]}`

      elementPathByMountLogId[supportPointMountLog.id] = path
      mountLogIdByElementPath[path] = supportPointMountLog.id

      container = Getters.getContainer(view, container, caster, parentPath, 'SegmentGroup', element)
      DrawHandlers.handleDrawable(
        view,
        container,
        path,
        'SupportPoint',
        element,
        view.isLiveCDSDataEnabled,
        view.liveCDSData,
      )
    }

    Mapping.elementPathByMountLogId = elementPathByMountLogId
    Mapping.mountLogIdByElementPath = mountLogIdByElementPath

    if (view.className !== 'SectionView' && DataLineSlot) {
      const StrandContainer = Getters.getContainer(view, container, caster, '', 'Strand', null)

      const dataLineMountLogs = ElementsUtil.getDataLineMountLogs(view.elementMaps)

      const mergedDataLinesAndMountLogs = Object.values(dataLineMountLogs).map(dataLineMountLogs => {
        const dataLine = DataLineSlot[dataLineMountLogs.slotId ?? '']
        const numericId = Mapping.numericIdByMountLogId[dataLineMountLogs.id]

        if (!dataLine || numericId === undefined) {
          return null
        }

        return ElementMapsUtil.getFullCasterElement(dataLine, dataLineMountLogs, numericId)
      })

      // DrawHandlers.buildDataLineIndex(mergedDataLinesAndMountLogs)

      for (let i = 0; i < mergedDataLinesAndMountLogs.length; i++) {
        const dataLine = mergedDataLinesAndMountLogs[i]

        if (!dataLine) {
          continue
        }

        const path = `DataLine:${dataLine.id}`

        DrawHandlers.handleDrawable(
          view,
          StrandContainer,
          path,
          'DataLine',
          dataLine,
          view.isLiveCDSDataEnabled,
          view.liveCDSData,
        )

        elementPathByMountLogId[dataLine.mountLogId] = path
        mountLogIdByElementPath[path] = dataLine.mountLogId
      }
    }

    if (!selectedMountLogIds.length) {
      return
    }

    const newSelectedPaths = selectedMountLogIds
      .filter(id => Mapping.elementPathByMountLogId[id])
      .map(id => Mapping.elementPathByMountLogId[id]) as string[]

    if (!isEqual(newSelectedPaths, Array.from(oldSelectedPaths.values()))) {
      view.setSelectedElementPaths?.(newSelectedPaths)
    }
  }

  // TODO: is this used?
  // static buildDataLineIndex (dataLines: any[]) {
  //   const dataLinePositionIndexes: any = {}

  //   dataLines.forEach(id => {
  //     const dataLine = DataLine[id]

  //     if (!dataLine) {
  //       return
  //     }

  //     const positionKey = `${dataLine.xCoords[0]}-${dataLine.thicknessCoord}-${dataLine.widthCoord}`

  //     if (dataLinePositionIndexes[positionKey] === undefined) {
  //       dataLinePositionIndexes[positionKey] = 0
  //       dataLine.index = 0
  //     }
  //     else {
  //       dataLine.index = ++dataLinePositionIndexes[positionKey]
  //     }
  //   })
  // }

  private static handleNoDrawableElement (
    type: string,
    path: string,
    container: THREE.Group,
    clickableObjects: any[],
    tooltipObjects: any[],
    sectionDetail: any,
    elementList: any,
    featureFlags?: Record<string, boolean>,
    isRealDataCase?: boolean,
  ) {
    let parent = null

    const isMoldOrStrandDataPoint = type === 'DataPoint' && !path.includes('Segment:')

    if (type !== 'SegmentGroup' && type !== 'DataLine' && !isMoldOrStrandDataPoint) {
      const parentInfo = Util.getParentInfo(path)

      if (!elementList[parentInfo.type]) {
        return
      }

      if (!parentInfo.path.includes('Mold')) {
        parent = elementList[parentInfo.type][parentInfo.path]
      }
    }

    elementList[type][path] = DrawUtil.getNewElementByType(
      type,
      parent,
      container,
      clickableObjects,
      tooltipObjects,
      sectionDetail,
      undefined,
      featureFlags,
      isRealDataCase,
    )
  }

  private static handleDrawableNozzle (path: string, element: any, view: MainView) {
    const { elementList, largestNozzle, widestNozzle, largestNarrowNozzle, widestNarrowNozzle } = view
    const keysToBeChanged: Pick<
      MainView,
      'largestNarrowNozzle' | 'largestNozzle' | 'widestNarrowNozzle' | 'widestNozzle'
    > = {} as any
    const height = element.height / 1000
    const widthCoord = element.widthCoord / 1000
    const angleWidth = element.angleWidth

    const b = ThreeNozzle.getB(height, angleWidth / 2)
    const wide = b + Math.abs(widthCoord)

    const { side } = elementList.Nozzle?.[path]?.parent?.container?.userData ?? {}

    if (side !== StrandSides.Left && side !== StrandSides.Right) {
      if (largestNozzle < height) {
        keysToBeChanged.largestNozzle = height
      }

      if (widestNozzle < wide) {
        keysToBeChanged.widestNozzle = wide
      }
    }
    else if (side === StrandSides.Left || side === StrandSides.Right) {
      if (largestNarrowNozzle < height) {
        keysToBeChanged.largestNarrowNozzle = height
      }

      if (widestNarrowNozzle < wide) {
        keysToBeChanged.widestNarrowNozzle = wide
      }
    }

    return keysToBeChanged
  }

  private static handleDrawableRoller (type: string, path: string, element: any, view: MainView) {
    const { elementList, largestRoller, widestRoller, largestNarrowRoller, widestNarrowRoller } = view

    const keysToBeChanged: Pick<
      MainView,
      'largestNarrowRoller' | 'largestRoller' | 'widestNarrowRoller' | 'widestRoller'
    > = {} as any
    const diameter = type !== 'RollerBearing' ? element.diameter / 1000 : 0
    const width = type === 'Roller'
      ? element.rollWidth / 2 / 1000
      : (element.widthCoord > 0
        ? element.width / 1000 + element.widthCoord / 1000
        : Math.abs(element.widthCoord / 1000))
    const { side } =
      elementList[type as 'Roller' | 'RollerBearing' | 'RollerBody']?.[path]?.parent?.container?.userData ?? {}

    if (side !== StrandSides.Left && side !== StrandSides.Right) {
      if (largestRoller < diameter) {
        keysToBeChanged.largestRoller = diameter
      }

      if (widestRoller < width) {
        keysToBeChanged.widestRoller = width
      }
    }
    else if (side === StrandSides.Left || side === StrandSides.Right) {
      if (largestNarrowRoller < diameter) {
        keysToBeChanged.largestNarrowRoller = diameter
      }

      if (widestNarrowRoller < width) {
        keysToBeChanged.widestNarrowRoller = width
      }
    }

    return keysToBeChanged
  }

  private static handleDrawable (
    view: MainView,
    container: THREE.Group,
    path: string,
    type: ElementCacheKey,
    element: any,
    isLiveCDSDataEnabled: boolean,
    liveCDSData: Record<string, number>,
  ) {
    if (!element) {
      return
    }

    if (!view.elementList[type]) {
      view.elementList[type] = {}
    }

    if (!view.elementList[type]?.[path]) {
      DrawHandlers.handleNoDrawableElement(
        type,
        path,
        container,
        view.clickableObjects,
        view.tooltipObjects,
        view.sectionDetail,
        view.elementList,
        view.featureFlags,
        Boolean(view.elementMaps.Caster?.blueprintId),
      )
    }

    if (!view.elementList[type]?.[path]) {
      return
    }

    const element2 = view.elementList[type]?.[path]

    if (type !== 'Segment' && element2) {
      element2.setValues({
        elementData: element as never, // TODO: fix the never type
        path,
        isDeleted: view.deleteList?.includes(path) ?? false,
        isHidden: view.hideList?.includes(path) ?? false,
        isPhantom: false,
        view,
        isLiveCDSDataEnabled,
        liveCDSData,
      })

      switch (type) {
        case 'Roller':
        case 'RollerBody':
        case 'RollerBearing':
          {
            const keysToBeChangedRoller = DrawHandlers.handleDrawableRoller(type, path, element, view)

            for (const key in keysToBeChangedRoller) {
              ;(view as any)[key] = keysToBeChangedRoller[key as keyof typeof keysToBeChangedRoller]
            }

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            element2.setVisibility(view.rollerChildren)
          }

          break

        case 'Nozzle':
          {
            const keysToBeChangedNozzle = DrawHandlers.handleDrawableNozzle(path, element, view)

            for (const key in keysToBeChangedNozzle) {
              ;(view as any)[key] = keysToBeChangedNozzle[key as keyof typeof keysToBeChangedNozzle]
            }
          }

          break
        default:
      }

      // use to optimize
      // this.time3[type] = (this.time3[type] ?? 0) + (Date.now() - start3)
    }
  }

  public static drawGridHelper (view: MainView) {
    if (!view.passlineCurve?.objects?.line) {
      return
    }

    const { geometry } = view.passlineCurve.objects.line

    geometry.computeBoundingBox()

    if (!geometry.boundingBox) {
      // eslint-disable-next-line no-console
      console.warn('No bounding box')

      return
    }

    const { min, max } = geometry.boundingBox

    const { length, calculatedPosition } = CalculationUtil.calcGridHelper(min, max)

    view.gridHelper = new THREE.GridHelper(length, length)
    view.gridHelper.name = 'GridHelper'
    view.gridHelper.position.set(
      calculatedPosition.x,
      calculatedPosition.y,
      calculatedPosition.z,
    )

    Util.addOrReplace(view.scene, view.gridHelper)
  }

  public static redrawSectionPlane (view: MainView, Caster: Caster) {
    if (!view.views || !view.views.sectionView || !view.elementMaps.PasslineSlot || !view.caster) {
      return
    }

    const sections = ElementsUtil.getPasslineSectionsByDate(view.elementMaps, view.referenceCasterDate)

    view.coordinate = new CoordinateAxes(view.caster)
    view.coordinateStraight = new CoordinateAxes(view.caster, true)

    const { moldSlot, moldMountLog } = ElementsUtil.getMoldAndMoldMountLogByDate(view.elementMaps)

    for (const side of Util.wideSides) {
      if (!moldSlot || !moldMountLog) {
        break
      }

      view.coordinate?.generateCoordinateAxes(Caster, sections, moldMountLog, side)
      view.coordinateStraight?.generateCoordinateAxes(Caster, sections, moldMountLog, side)
    }

    if (view.applyCurve) {
      view.coordinateStraight.hide()
    }
    else {
      view.coordinate.hide()
    }

    const sectionView = view.views.sectionView

    const sectionPlaneGeometry = CalculationUtil.getSectionPlaneGeometry(
      moldMountLog?.thickness ?? 0,
      moldMountLog?.width ?? 0,
      sectionView.largestNarrowNozzle,
      sectionView.largestNozzle,
      sectionView.widestNarrowNozzle,
      sectionView.widestNozzle,
      sectionView.largestNarrowRoller,
      sectionView.largestRoller,
      sectionView.widestNarrowRoller,
      sectionView.widestRoller,
    )

    view.sectionPlane.geometry = sectionPlaneGeometry
    view.sectionPlane.geometry.translate(-(moldMountLog?.thickness ?? 0) / 1000 / 2, 0, 0)

    view.views.sectionView.updatePlane = true

    if (!view.sectionDetail) {
      DrawHandlers.drawGridHelper(view)
    }
  }

  public static drawCaster (view: MainView) {
    const { moldSlot, moldMountLog } = ElementsUtil.getMoldAndMoldMountLogByDate(view.elementMaps)

    if (!moldSlot || !moldMountLog) {
      return
    }

    const { thickness: rawThickness } = moldMountLog
    const thickness = (rawThickness ?? 0) / 1000

    MainUtil.resetSegments(view.containerList)

    view.center2d = {
      x: -thickness / 2,
      y: view.plHeight - 1.5,
    }

    Object.values(view.sideLabels).forEach((label: any) => {
      label.visible = false
    })

    if (view.gridHelper) {
      view.gridHelper.visible = !view.sectionDetail
    }

    view.passlineCurve?.showStrand()
    view.passlineCurve?.setPosition(new THREE.Vector3(0, 0, 0))
    view.camera = view.perspectiveCamera
    view.sectionPlane.visible = !view.sectionDetail && Boolean(view.views?.uiView?.isSectionActive)

    view.setupControls()

    if (!view.sectionDetail && !view.isLoadingState && view.casterIdChanged) {
      view.jumpToFirst(false)
    }

    view.resize()
  }

  public static updateDataPoints (view: MainView) {
    Object.values(view?.elementList?.DataPoint ?? {}).forEach((dataPoint: any) => {
      dataPoint.updateValues(view.liveCDSData, view.showLiveData)
    })
  }

  public static handleToggleLiveDataIn3D (view: MainView) {
    DrawHandlers.updateDataPoints(view)
  }
}
