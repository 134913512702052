import { MountLogToKeyUUIDsMap } from '@/store/mountLogToKeyUUIDs'
import { CompareFilterHandler } from '@/three/logic/CompareFilterHandler'
import FilterHandler from '@/three/logic/FilterHandler'
import ThreeUtil from '@/three/logic/Util'
import type { FilterableElementType } from '@/types/filter'
import type { ElementMaps, TagName } from '@/types/state'
import type { PlotConfig, TileConfig, Vector2D } from '@/types/visualization'
import { ElementMapsUtil } from '@/Util/ElementMapsUtil'
import { Mapping } from '@/Util/mapping/Mapping'

import { ViewLogic } from './ViewLogic'

// Define the type for additionalData
type AdditionalData = {
  [key: string]: {
    uuid?: string
    [key: string]: any
  }
}

export class ViewCompareLogic {
  public static getXDomainForDynamicPlotsWithComparisonCasters (
    xDomain: Domain,
    tileConfig: TileConfig,
    dynamicData: Vector2D[][],
  ): number[] {
    if (!tileConfig.followPasslnCoord) {
      return xDomain
    }

    const linesXDomains: number[][] = []

    dynamicData.forEach(line => {
      if (line.length === 0 || !Array.isArray(line)) {
        return
      }

      if (!Array.isArray(line)) {
        return
      }

      linesXDomains.push(ViewLogic.getXDomain(xDomain, line.map(point => point.x), tileConfig))
    })

    const min = linesXDomains.reduce((acc, curr) => Math.min(acc, curr[0] ?? Infinity), Infinity)
    const max = linesXDomains.reduce((acc, curr) => Math.max(acc, curr[1] ?? -Infinity), -Infinity)

    return [ min, max ]
  }

  public static getYDomainForDynamicPlotsWithComparisonCasters (
    xDomain: Domain,
    yDomain: Domain,
    tileConfig: TileConfig,
    dynamicData: any[][],
  ): number[] {
    if (!tileConfig.followPasslnCoord) {
      return yDomain
    }

    const linesYDomains: number[][] = []

    dynamicData.forEach(line => {
      if (line.length === 0 || !Array.isArray(line)) {
        return
      }

      linesYDomains.push(ViewLogic.getYDomain(xDomain, yDomain, line))
    })

    const min = linesYDomains.reduce((acc, curr) => Math.min(acc, curr[0] ?? Infinity), Infinity) ?? yDomain[0]
    const max = linesYDomains.reduce((acc, curr) => Math.max(acc, curr[1] ?? -Infinity), -Infinity) ?? yDomain[1]

    return [ min, max ]
  }

  private static getAttributeValue<Slot extends BaseSlot, MountLog extends BaseMountLog> (
    element: FullCasterElement<Slot, MountLog>,
    attribute: string,
  ) {
    return element[attribute as keyof FullCasterElement<Slot, MountLog>] ?? element.additionalData[attribute]
  }

  private static getXYValues<Slot extends BaseSlot, MountLog extends BaseMountLog> (
    element: FullCasterElement<Slot, MountLog>,
    attrX: string,
    attrY: string,
  ) {
    const x = ViewCompareLogic.getAttributeValue(element, attrX)
    const y = ViewCompareLogic.getAttributeValue(element, attrY)

    return { x: Number(x), y: Number(y) }
  }

  private static getCompareElementFromPath<Slot extends BaseSlot, MountLog extends BaseMountLog> (
    path: string,
    elementMaps: ElementMaps,
    caseId: string,
  ) {
    if (!elementMaps) {
      return null
    }

    const { type, id: numericId } = ThreeUtil.getElementInfo(path)
    const id = Mapping.mountLogIdByCaseIdAndTypeAndNumericId[caseId]?.[type][numericId]
    const parentInfo = ThreeUtil.getParentInfo(path)
    const elementName = ElementMapsUtil.getElementName(type, parentInfo.type)
    const slotMap = elementMaps[`${elementName}Slot`]
    const mountLogMap = elementMaps[`${elementName}MountLog`]

    if (id && parentInfo.type === undefined && (type === 'SensorPoint' || type === 'DataPoint')) {
      for (
        const sensorPointInfo of type === 'SensorPoint'
          ? FilterHandler.sensorPointInfoArray
          : FilterHandler.dataPointInfoArray
      ) {
        const mountLogName = sensorPointInfo.mountLogType
        const slotName = mountLogName.replace('MountLog', 'Slot')
        const mountLog = elementMaps[mountLogName]?.[id] as unknown as MountLog

        if (mountLog) {
          const slot = (elementMaps as any)[slotName]?.[mountLog.slotId] as unknown as Slot

          return ElementMapsUtil.getFullCasterElement<Slot, MountLog>(slot, mountLog, numericId)
        }
      }
    }

    if (
      !id ||
      !type ||
      !slotMap ||
      !mountLogMap?.[id]
    ) {
      return null
    }

    const elementMountLog = mountLogMap[id] as unknown as MountLog
    const element = slotMap[elementMountLog.slotId] as Slot

    return ElementMapsUtil.getFullCasterElement<Slot, MountLog>(element, elementMountLog, numericId)
  }

  private static getElementsFromCompareDataLineWithFilter (
    elementMaps: ElementMaps,
    plotConfig: PlotConfig,
    caseId: string,
  ): Vector2D[] {
    const elementPathByMountLogId = Mapping.elementPathByMountLogIdByCaseId[caseId]
    const { filter } = plotConfig

    if (!elementPathByMountLogId || !filter) {
      return []
    }

    const hitElements = CompareFilterHandler.getFilteredElementsPerCompareCaseId(
      elementMaps,
      caseId,
      filter,
    )
    const returnedElements: any[] = []

    Object
      .entries(hitElements)
      .filter(([ _path, elType ]) => elType === 'DataLine')
      .forEach(([ path ]) => {
        const element = ViewCompareLogic.getCompareElementFromPath(
          path,
          elementMaps,
          caseId,
        )

        if (!element) {
          return
        }

        returnedElements.push(element)
      })

    return returnedElements
  }

  private static getPointsFromComparisonCasterWithFilter (
    elementMaps: ElementMaps,
    type: string,
    attrX: string,
    attrY: string,
    plotConfig: PlotConfig,
    caseId: string,
  ): Vector2D[] {
    const elementPathByMountLogId = Mapping.elementPathByMountLogIdByCaseId[caseId]
    const { filter } = plotConfig

    if (!elementPathByMountLogId || !filter) {
      return []
    }

    const points: Vector2D[] = []

    const hitElements = CompareFilterHandler.getFilteredElementsPerCompareCaseId(
      elementMaps,
      caseId,
      filter,
    )

    Object
      .entries(hitElements)
      .filter(([ _path, elType ]) => elType === type)
      .forEach(([ path ]) => {
        const fullElement = ViewCompareLogic.getCompareElementFromPath(
          path,
          elementMaps,
          caseId,
        )

        const numericId = Mapping.numericIdByCaseIdAndMountLogId[caseId]?.[fullElement?.mountLogId ?? ''] ?? -1

        if (!fullElement || numericId < 0) {
          return
        }

        fullElement.id = numericId

        const { x, y } = ViewCompareLogic.getXYValues(fullElement, attrX, attrY)

        if (!Number.isNaN(x) && !Number.isNaN(y)) {
          points.push({ x, y })
        }
      })

    return points.sort((a, b) => a.x - b.x)
  }

  public static getPointsFromComparisonCaster (
    elementMaps: ElementMaps,
    type: TagName,
    attrX: string,
    attrY: string,
    plotConfig: PlotConfig,
    caseId: string,
  ): Vector2D[] {
    if (plotConfig.filter) {
      return this.getPointsFromComparisonCasterWithFilter(
        elementMaps,
        type,
        attrX,
        attrY,
        plotConfig,
        caseId,
      )
    }

    const mountLogMap = ElementMapsUtil.getMountLogMapByTagName(elementMaps, type)
    const slotMap = ElementMapsUtil.getSlotMapByTagName(elementMaps, type)
    const points: Vector2D[] = []

    for (const mountLogId in mountLogMap) {
      const mountLog = mountLogMap[mountLogId]

      if (!mountLog) {
        continue
      }

      const slot = slotMap[mountLog.slotId]
      const numericId = Mapping.numericIdByCaseIdAndMountLogId[caseId]?.[mountLog.id]
      const fullElement = slot && numericId !== undefined
        ? ElementMapsUtil.getFullCasterElement(slot, mountLog, numericId)
        : null

      if (!fullElement) {
        continue
      }

      const { x, y } = ViewCompareLogic.getXYValues(fullElement, attrX, attrY)

      if (!Number.isNaN(x) && !Number.isNaN(y)) {
        points.push({ x, y })
      }
    }

    return points.sort((a, b) => a.x - b.x)
  }

  public static getPointsFromComparisonDataLine (
    elementMaps: ElementMaps,
    attrX: string,
    attrY: string,
    caseId: string,
    plotConfig: PlotConfig,
    hasRef = false,
  ) {
    const { filter } = plotConfig
    const elements: any[] = []

    if (!elementMaps) {
      return []
    }

    if (filter) {
      elements.push(...this.getElementsFromCompareDataLineWithFilter(
        elementMaps,
        plotConfig,
        caseId,
      ))
    }
    else {
      const mountLogs = Object.values(elementMaps.DataLineMountLog ?? {})

      mountLogs.forEach((mountLog) => {
        const elementInfo = elementMaps.DataLineSlot?.[mountLog?.slotId ?? '']
        const numericId = Mapping.numericIdByCaseIdAndMountLogId[caseId]?.[mountLog.id]

        if (!elementInfo || !numericId) {
          return
        }

        const element = ElementMapsUtil.getFullCasterElement(elementInfo, mountLog, numericId)

        elements.push(element)
      })
    }

    const lines: Array<Vector2D[]> = []

    for (const el of elements) {
      const xValues = ViewLogic.getElementKeyValue(el, attrX)
      const yValues = ViewLogic.getElementKeyValue(el, attrY)
      const parsedXValues = typeof xValues === 'string'
        ? xValues.trim().split(' ').map((stringCoord: string) => Number(stringCoord))
        : xValues
      const parsedYValues = typeof yValues === 'string'
        ? yValues.trim().split(' ').map((stringCoord: string) => Number(stringCoord))
        : yValues

      if (
        Array.isArray(parsedXValues) &&
        parsedXValues.length > 1 &&
        Array.isArray(parsedYValues) &&
        parsedYValues.length > 1
      ) {
        const line: Vector2D[] = []
        const amountOfPoints = Math.min(parsedXValues.length, parsedYValues.length)

        for (let i = 0; i < amountOfPoints; i++) {
          const x = parsedXValues[i]
          const y = parsedYValues[i]

          if (x !== undefined && y !== undefined) {
            line.push({ x, y })
          }
        }

        if (line.length > 0) {
          if (hasRef) {
            return line
          }

          lines.push(line)
        }
      }
    }

    return lines
  }

  public static buildComparePaths (
    elementMaps: ElementMaps,
    caseId: string,
  ) {
    const numericIdMaps = Mapping.numericIdByCaseIdAndMountLogId[caseId]

    const elementPathByMountLogId: Record<string, string> = {}

    if (!numericIdMaps) {
      return
    }

    // SegmentGroups
    const segmentGroupMountLogs = Object.values(elementMaps.SegmentGroupMountLog ?? {})

    segmentGroupMountLogs.forEach((segmentGroupMountLog) => {
      const numericId = numericIdMaps[segmentGroupMountLog.id]

      // FIXME: once we separated the two ID maps we only need to check if the value is undefined!
      if (typeof numericId !== 'number' || Number.isNaN(numericId)) {
        return
      }

      const path = `SegmentGroup:${numericId}`

      elementPathByMountLogId[segmentGroupMountLog.id] = path
    })

    // Segments
    const segmentMountLogMap = elementMaps.SegmentMountLog ?? {}
    const segmentMountLogs = Object.values(segmentMountLogMap)

    for (const segmentMountLog of segmentMountLogs) {
      const segmentGroupMountLogId = segmentMountLog.segmentGroupMountLogId
      const parentPath = elementPathByMountLogId[segmentGroupMountLogId ?? '']
      const numericId = numericIdMaps[segmentMountLog.id]
      const path = parentPath ? `${parentPath}/Segment:${numericId}` : `Segment:${numericId}`

      elementPathByMountLogId[segmentMountLog.id] = path
    }

    // Nozzles
    const nozzleMountLogMap = elementMaps.NozzleMountLog ?? {}
    const nozzleMountLogs = Object.values(nozzleMountLogMap)

    for (const nozzleMountLog of nozzleMountLogs) {
      const numericId = numericIdMaps[nozzleMountLog.id]

      // FIXME: once we separated the two ID maps we only need to check if the value is undefined!
      if (typeof numericId !== 'number' || Number.isNaN(numericId)) {
        continue
      }

      const parentPath = elementPathByMountLogId[nozzleMountLog.segmentMountLogId ?? '']
      const path = parentPath ? `${parentPath}/Nozzle:${numericId}` : `Nozzle:${numericId}`

      elementPathByMountLogId[nozzleMountLog.id] = path
    }

    // Rollers
    const rollerMountLogMap = elementMaps.RollerMountLog ?? {}
    const rollerMountLogs = Object.values(rollerMountLogMap)

    for (const rollerMountLog of rollerMountLogs) {
      const numericId = numericIdMaps[rollerMountLog.id]

      // FIXME: once we separated the two ID maps we only need to check if the value is undefined!
      if (typeof numericId !== 'number' || Number.isNaN(numericId)) {
        continue
      }

      const parentPath = elementPathByMountLogId[rollerMountLog.segmentMountLogId ?? '']
      const path = parentPath ? `${parentPath}/Roller:${numericId}` : `Roller:${numericId}`

      elementPathByMountLogId[rollerMountLog.id] = path
    }

    // RollerBodies
    const rollerBodyMountLogMap = elementMaps.RollerBodyMountLog ?? {}
    const rollerBodyMountLogs = Object.values(rollerBodyMountLogMap)

    for (const rollerBodyMountLog of rollerBodyMountLogs) {
      const numericId = numericIdMaps[rollerBodyMountLog.id]

      // FIXME: once we separated the two ID maps we only need to check if the value is undefined!
      if (typeof numericId !== 'number' || Number.isNaN(numericId)) {
        continue
      }

      const parentPath = elementPathByMountLogId[rollerBodyMountLog.rollerMountLogId ?? '']
      const path = parentPath ? `${parentPath}/RollerBody:${numericId}` : `RollerBody:${numericId}`

      elementPathByMountLogId[rollerBodyMountLog.id] = path
    }

    // RollerBearings
    const rollerBearingMountLogMap = elementMaps.RollerBearingMountLog ?? {}
    const rollerBearingMountLogs = Object.values(rollerBearingMountLogMap)

    for (const rollerBearingMountLog of rollerBearingMountLogs) {
      const numericId = numericIdMaps[rollerBearingMountLog.id]

      // FIXME: once we separated the two ID maps we only need to check if the value is undefined!
      if (typeof numericId !== 'number' || Number.isNaN(numericId)) {
        continue
      }

      const parentPath = elementPathByMountLogId[rollerBearingMountLog.rollerMountLogId ?? '']
      // eslint-disable-next-line max-len
      const path = parentPath ? `${parentPath}/RollerBearing:${numericId}` : `RollerBearing:${numericId}`

      elementPathByMountLogId[rollerBearingMountLog.id] = path
    }

    // DataPoints in Rollers
    const rollerDataPointMountLogMap = elementMaps.RollerDataPointMountLog ?? {}
    const rollerDataPointMountLogs = Object.values(rollerDataPointMountLogMap)

    for (const rollerDataPointMountLog of rollerDataPointMountLogs) {
      const numericId = numericIdMaps[rollerDataPointMountLog.id]

      // FIXME: once we separated the two ID maps we only need to check if the value is undefined!
      if (typeof numericId !== 'number' || Number.isNaN(numericId)) {
        continue
      }

      const parentPath = elementPathByMountLogId[rollerDataPointMountLog.rollerMountLogId ?? '']
      const path = parentPath ? `${parentPath}/DataPoint:${numericId}` : `DataPoint:${numericId}`

      elementPathByMountLogId[rollerDataPointMountLog.id] = path
    }

    // SensorPoints in Rollers
    const rollerSensorPointMountLogMap = elementMaps.RollerSensorPointMountLog ?? {}
    const rollerSensorPointMountLogs = Object.values(rollerSensorPointMountLogMap)

    for (const rollerSensorPointMountLog of rollerSensorPointMountLogs) {
      const numericId = numericIdMaps[rollerSensorPointMountLog.id]

      // FIXME: once we separated the two ID maps we only need to check if the value is undefined!
      if (typeof numericId !== 'number' || Number.isNaN(numericId)) {
        continue
      }

      const parentPath = elementPathByMountLogId[rollerSensorPointMountLog.rollerMountLogId ?? '']
      const path = parentPath ? `${parentPath}/SensorPoint:${numericId}` : `SensorPoint:${numericId}`

      elementPathByMountLogId[rollerSensorPointMountLog.id] = path
    }

    // DataPoints in Roller Bodies
    const rollerBodyDataPointMountLogMap = elementMaps.RollerBodyDataPointMountLog ?? {}
    const rollerBodyDataPointMountLogs = Object.values(rollerBodyDataPointMountLogMap)

    for (const rollerBodyDataPointMountLog of rollerBodyDataPointMountLogs) {
      const numericId = numericIdMaps[rollerBodyDataPointMountLog.id]

      // FIXME: once we separated the two ID maps we only need to check if the value is undefined!
      if (typeof numericId !== 'number' || Number.isNaN(numericId)) {
        continue
      }

      const parentPath = elementPathByMountLogId[rollerBodyDataPointMountLog.rollerBodyMountLogId ?? '']
      const path = parentPath ? `${parentPath}/DataPoint:${numericId}` : `DataPoint:${numericId}`

      elementPathByMountLogId[rollerBodyDataPointMountLog.id] = path
    }

    // sensor points in roller bodies
    // eslint-disable-next-line max-len
    const rollerBodySensorPointMountLogMap = elementMaps.RollerBodySensorPointMountLog ?? {}
    const rollerBodySensorPointMountLogs = Object.values(rollerBodySensorPointMountLogMap)

    for (const rollerBodySensorPointMountLog of rollerBodySensorPointMountLogs) {
      const numericId = numericIdMaps[rollerBodySensorPointMountLog.id]

      // FIXME: once we separated the two ID maps we only need to check if the value is undefined!
      if (typeof numericId !== 'number' || Number.isNaN(numericId)) {
        continue
      }

      const parentPath = elementPathByMountLogId[rollerBodySensorPointMountLog.rollerBodyMountLogId ?? '']
      const path = parentPath ? `${parentPath}/SensorPoint:${numericId}` : `SensorPoint:${numericId}`

      elementPathByMountLogId[rollerBodySensorPointMountLog.id] = path
    }

    // sensor points in segments
    const segmentSensorPointMountLogMap = elementMaps.SegmentSensorPointMountLog ?? {}
    const segmentSensorPointMountLogs = Object.values(segmentSensorPointMountLogMap)

    for (const segmentSensorPointMountLog of segmentSensorPointMountLogs) {
      const numericId = numericIdMaps[segmentSensorPointMountLog.id]

      // FIXME: once we separated the two ID maps we only need to check if the value is undefined!
      if (typeof numericId !== 'number' || Number.isNaN(numericId)) {
        continue
      }

      const parentPath = elementPathByMountLogId[segmentSensorPointMountLog.segmentMountLogId ?? '']
      const path = parentPath ? `${parentPath}/SensorPoint:${numericId}` : `SensorPoint:${numericId}`

      elementPathByMountLogId[segmentSensorPointMountLog.id] = path
    }

    // SupportPoints
    const supportPointMountLogMap = elementMaps.SupportPointMountLog ?? {}
    const supportPointMountLogs = Object.values(supportPointMountLogMap)

    for (const supportPointMountLog of supportPointMountLogs) {
      const numericId = numericIdMaps[supportPointMountLog.id]

      // FIXME: once we separated the two ID maps we only need to check if the value is undefined!
      if (typeof numericId !== 'number' || Number.isNaN(numericId)) {
        continue
      }

      const parentPath = elementPathByMountLogId[supportPointMountLog.segmentGroupMountLogId ?? '']
      const path = parentPath ? `${parentPath}/SupportPoint:${numericId}` : `SupportPoint:${numericId}`

      elementPathByMountLogId[supportPointMountLog.id] = path
    }

    // CoolingLoops
    const coolingLoopMountLogMap = elementMaps.CoolingLoopMountLog ?? {}
    const coolingLoopMountLogs = Object.values(coolingLoopMountLogMap)

    for (const coolingLoopMountLog of coolingLoopMountLogs) {
      const numericId = numericIdMaps[coolingLoopMountLog.id]

      // FIXME: once we separated the two ID maps we only need to check if the value is undefined!
      if (typeof numericId !== 'number' || Number.isNaN(numericId)) {
        continue
      }

      const path = `CoolingLoop:${numericId}`

      elementPathByMountLogId[coolingLoopMountLog.id] = path
    }

    Mapping.elementPathByMountLogIdByCaseId[caseId] = elementPathByMountLogId
  }

  public static getPointsFromDataServerData (
    type: TagName,
    elementPaths: string[],
    elementMaps: ElementMaps,
    attrX: string,
    attrY: string,
    timestamp: number,
    mountLogToKeyUUIDsMap: MountLogToKeyUUIDsMap,
    casterDataServer: CasterDataServerState,
  ) {
    const filteredPaths = elementPaths.filter(path => {
      const { type: pathType } = ThreeUtil.getElementInfo(path)

      return pathType === type
    })
    const points: Vector2D[] = []

    if (filteredPaths.length === 0) {
      return []
    }

    const timestampData = casterDataServer.timestampData[timestamp]

    if (!timestampData) {
      return []
    }

    const rawData = filteredPaths.map(path => ElementMapsUtil.getFullCasterElementByPath(path, elementMaps))

    for (const el of rawData) {
      if (!el) {
        continue
      }

      let x = Number(ViewLogic.getElementKeyValue(el, attrX as keyof typeof el))
      let y = Number(ViewLogic.getElementKeyValue(el, attrY as keyof typeof el))

      const attrXUUID = mountLogToKeyUUIDsMap[type as FilterableElementType]?.[attrX]?.[el.mountLogId]
      const attrYUUID = mountLogToKeyUUIDsMap[type as FilterableElementType]?.[attrY]?.[el.mountLogId]

      if (!attrXUUID && !attrYUUID) {
        continue
      }

      const dataSourceX = casterDataServer.timestampData[timestamp]?.[attrXUUID ?? '']
      const dataSourceY = casterDataServer.timestampData[timestamp]?.[attrYUUID ?? '']

      if (attrX === 'id') {
        const mountLogId = el.id
        const numericId = Mapping.numericIdByMountLogId[mountLogId]

        if (numericId !== undefined) {
          x = numericId
        }
      }
      else if (attrY === 'id') {
        const mountLogId = el.id
        const numericId = Mapping.numericIdByMountLogId[mountLogId]

        if (numericId !== undefined) {
          y = numericId
        }
      }

      if (
        (dataSourceX === undefined || Number.isNaN(dataSourceX)) &&
        (dataSourceY === undefined || Number.isNaN(dataSourceY))
      ) {
        continue
      }

      const xValue = dataSourceX ?? x
      const yValue = dataSourceY ?? y

      if (!Number.isNaN(xValue) && !Number.isNaN(yValue)) {
        points.push({ x: xValue, y: yValue })
      }
    }

    points.sort((a: any, b: any) => a.x - b.x)

    return points
  }

  public static getPointsFromCompareCDSByUUID (
    elementMaps: ElementMaps,
    filter: string,
    cdsDataByUUID: Record<string, [number, number][]>,
    attrY: string,
    caseId: string,
    idsToFetch: string[],
  ) {
    // Get filtered elements based on the filter
    const filteredElements = CompareFilterHandler.getFilteredElementsPerCompareCaseId(
      elementMaps,
      caseId,
      filter,
    )

    const points: Vector2D[] = []

    // Process each filtered element
    Object.entries(filteredElements).forEach(([ path, _type ]) => {
      const element = ViewCompareLogic.getCompareElementFromPath(path, elementMaps, caseId)

      if (!element) {
        return
      }

      // Get the UUID for the attribute from additionalData
      const additionalData = element.additionalData as AdditionalData
      const uuid = additionalData[attrY] as string | undefined

      if (!uuid) {
        return
      }

      // Get the data for this UUID
      const data = cdsDataByUUID[uuid]

      if (!data) {
        idsToFetch.push(uuid)

        return
      }

      // Convert the data to points, x is a timestamp in milliseconds
      data.forEach(([ x, y ]) => {
        points.push({ x: new Date(x).getTime(), y })
      })
    })

    // Sort points by x coordinate as dates
    return points.sort((a, b) => a.x - b.x)
  }

  public static getPointsFromLiveCDSData (
    elementPaths: string[],
    elementMaps: ElementMaps,
    attrX: string,
    attrY: string,
    liveCDSData: Record<string, any>,
  ) {
    const points: Vector2D[] = []

    const snakeCaseField = attrY.replace(/([A-Z])/g, '_$1').toLowerCase()
    const snakeCaseFieldUUID = `${snakeCaseField}_uuid`

    for (const elementPath of elementPaths) {
      const element = ElementMapsUtil.getFullCasterElementByPath(elementPath, elementMaps)

      if (!element) {
        continue
      }

      const x = element[attrX as keyof FullCasterElement<BaseSlot, BaseMountLog>] ??
        element?.additionalData?.[attrX] as number

      if (x === undefined || x === null) {
        continue
      }

      const yUUID = element?.additionalData?.[snakeCaseFieldUUID] as string

      if (!yUUID) {
        continue
      }

      const y = liveCDSData[yUUID] as number

      if (y === undefined || y === null) {
        continue
      }

      points.push({ x: Number(x), y: Number(y) })
    }

    return points
  }
}
