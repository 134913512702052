import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import Plotly from 'plotly.js-dist-min'
import { Component } from 'react'
import { connect, type ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import Util from '@/logic/Util'
import FeatureFlags from '@/react/FeatureFlags'
import { ViewLogic } from '@/react/visualization/dashboard/ViewLogic'
import VisUtil from '@/react/visualization/VisUtil'
import { getElementMapsObject } from '@/store/elements/logic'
import * as TileWarningsActions from '@/store/tileWarnings'
import * as VisualizationActions from '@/store/visualization/actions'
import type { DefaultState, TagName } from '@/types/state'
import type { ContextMenuData } from '@/types/visualization'

import { config } from './const'
// import Bar from './fast/Bar'
import FastBase from './fast/FastBase'
// import Fill from './fast/Fill'
// import Line from './fast/Line'
import Shape from './fast/Shape'
import Helpers from './Helpers'
import logic from './logic'
import { PlotInfo, Wrapper } from './styles'

type Definition = {
  id: string
  length: number
  bytes: any
  data: any
  dynamicData?: any[]
  isDataLine?: boolean
  lineIndex?: number
  comparisonLineIndex?: number
  isVerticalLine?: boolean
  isMergedDataLine?: boolean
}

const connector = connect(({
  application,
  ComparisonCasters,
  filter,
  tileWarnings,
  visualization,
  ...remainingState
}: DefaultState) => ({
  comparisonCasters: ComparisonCasters,
  currentProjectCasesMetadata: application.main.currentProjectCasesMetadata,
  currentSimulationCaseFromRedux: application.main.currentSimulationCase,
  data: visualization.data,
  elementMaps: getElementMapsObject(remainingState as DefaultState),
  featureFlags: FeatureFlags.getRealFeatureFlags({ application } as DefaultState),
  filterControlVariables: filter.filterControlVariables,
  isEditModeOn: visualization.isEditModeOn,
  plotConfigs: visualization.plotConfigs,
  tileConfigs: visualization.tileConfigs,
  tileWarnings,
  viewsObject: visualization.viewsObject,
  /*
    This is here so that the plots update after closing the plot list
    otherwise the plots don't resize until the user clicks on the plot
   */
  isPlotListOpened: visualization.isPlotListOpened,
}), {
  openContextMenu: VisualizationActions.openContextMenu,
  setTileWarnings: TileWarningsActions.setTileWarnings,
})

type PropsFromRedux = ConnectedProps<typeof connector>

export interface Props extends PropsFromRedux {
  tileId: string
  featureFlags: Record<string, boolean>
  type: 'area' | 'bar' | 'command' | 'contour' | 'edit_box' | 'gage' | 'line' | 'message' | 'pie' | 'radar' | 'text'
  message?: string
  key?: string
  /**
   * PlotConfig ID
   */
  configId?: string
  configIds?: string[]
  xDomain?: Domain
  yDomain?: Domain
  xRange?: Domain
  xValues?: number[]
  yValueRange?: Domain
  valueRange?: Domain
  shapeIds?: string[]
  radiusDomain?: Domain
  radius0?: number
  radius?: number
  frequency?: number
  xLabel?: string
  yLabel?: string
  viewId?: string
  flipAxes?: boolean
  viewOnly?: boolean
  additionalId?: string
  currentSimulationCase?: SimulationCase
  dynamicData?: Array<any>
  isDynamicData?: boolean
  dynamicDataList?: Array<Array<any>>
  isMergedDynamicData?: boolean
  hasNoData?: boolean
  isMultiLinePlot?: boolean
  shapeData?: any[]
  length?: number // TODO: verify this, this isn't being provided
  forceUpdateHandler?: () => void
  fullSize?: boolean
}

type State = {
  over: boolean
  overWrapper: boolean
  xIndex: number
  x: number
  hasNoData: boolean
}

class PlotlyWrapper extends Component<Props, State> {
  private pauseDraw: boolean

  private prevTileId?: string = undefined

  private prevDefinitions: Array<any> = []

  // private readonly value: Array<any>

  // private readonly line: Line

  // private readonly fill: Fill

  // private readonly bar: Bar

  private readonly shape: Shape

  // private isPlotUpToDate: boolean

  // private prevLayout: any

  private currentPasslnPosition: number

  private currentCasterTime: number

  private readonly filterControlVariableValues: Record<string, number | undefined> = {}

  public constructor (props: Props) {
    super(props)

    this.pauseDraw = false
    // this.value = []

    // this.line = new Line()
    // this.fill = new Fill()
    // this.bar = new Bar()
    this.shape = new Shape()
    // this.isPlotUpToDate = false
    // this.prevLayout = {}
    this.state = {
      over: false,
      overWrapper: false,
      x: -1,
      xIndex: -1,
      hasNoData: props.hasNoData ?? false,
    }

    // initialize plot variables
    this.currentPasslnPosition = (window as any).currentPasslnPosition ?? 0

    this.currentCasterTime = (window as any).currentCasterTime ?? new Date()

    if ((window as any).filterControlVariables) {
      this.filterControlVariableValues = { ...(window as any).filterControlVariables }
    }
  }

  public override componentDidMount () {
    logic.connect(
      {},
      this.props,
      this.setData,
      this.handleMouseOver,
      this.handleMouseOut,
      this.handleMouseDown,
      this.handleMouseUp,
      this.state.hasNoData,
    )

    const { filterControlVariables } = this.props

    window.addEventListener('CurrentPasslnPositionChanged', this.handlePlotVariableChanged)
    window.addEventListener('FinishedScrollingPasslnCoord', this.handlePlotVariableChanged)
    window.addEventListener('CurrentCasterTimeChanged', this.handlePlotVariableChanged)
    filterControlVariables.forEach(variable => {
      if (!(window as any).filterControlVariables) {
        ;(window as any).filterControlVariables = {}
      }

      window.addEventListener(`${variable}Changed`, this.handlePlotVariableChanged)
    })

    // Fix for initial plot loading - try multiple times to ensure plot is ready
    this.applyTimeAxisFixWithRetry(0)
  }

  private readonly applyTimeAxisFixWithRetry = (attempt: number) => {
    if (attempt > 3) {
      return // Try maximum 4 times
    }
    
    // Check if this is a time plot and fix its initial axis range
    if (this.props.configId && 
        this.props.plotConfigs?.[this.props.configId]?.selectedX?.includes('dataOverTimeByFieldUUID')) {
      const plotElement = document.getElementById(
        `plot_${this.props.tileId}${this.props.additionalId ? `_${this.props.additionalId}` : ''}`,
      )
      
      if (plotElement && (plotElement as any)._fullData && (plotElement as any)._fullData.length > 0) {
        this.handleResetAxesForTimeData(plotElement)
      }
      else {
        // Try again after a delay
        setTimeout(() => {
          this.applyTimeAxisFixWithRetry(attempt + 1)
        }, 500 * (attempt + 1)) // Increasingly longer delays
      }
    }
  }

  public override shouldComponentUpdate (nextProps: Readonly<Props>, nextState: Readonly<State>): boolean {
    return !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))
  }

  public override componentDidUpdate (prevProps: Props) {
    let hasNoData = this.state.hasNoData
 
    if (prevProps.hasNoData !== this.props.hasNoData) {
      hasNoData = this.props.hasNoData ?? false
      this.setState({ hasNoData })
    }

    logic.connect(
      prevProps,
      this.props,
      this.setData,
      this.handleMouseOver,
      this.handleMouseOut,
      this.handleMouseDown,
      this.handleMouseUp,
      hasNoData,
    )
  }

  public override componentWillUnmount () {
    const { tileId, additionalId, filterControlVariables } = this.props

    Util.RemoveDelegatedEventListener('', 'pointerdown', `#plot_${tileId}${additionalId ? `_${additionalId}` : ''}`)
    document.removeEventListener('pointerup', this.handleMouseUp, true)
    window.removeEventListener('FinishedScrollingPasslnCoord', this.handlePlotVariableChanged)

    window.removeEventListener('CurrentPasslnPositionChanged', this.handlePlotVariableChanged)
    window.removeEventListener('CurrentCasterTimeChanged', this.handlePlotVariableChanged)
    filterControlVariables.forEach(variable => {
      if (!(window as any).filterControlVariables) {
        ;(window as any).filterControlVariables = {}
      }

      window.removeEventListener(`${variable}Changed`, this.handlePlotVariableChanged)
    })
  }

  private readonly handlePlotVariableChanged = (event: any) => {
    const { currentPasslnPosition, nextValue, currentCasterTime } = event.detail ?? {}

    const eventType = event.type
    const stoppedScrolling = eventType === 'FinishedScrollingPasslnCoord'
    const {
      tileId,
      tileConfigs,
      plotConfigs,
      elementMaps,
      viewId = '',
      viewsObject,
      comparisonCasters,
      tileWarnings,
      setTileWarnings,
      featureFlags,
      currentProjectCasesMetadata,
      filterControlVariables,
    } = this.props

    const caseIds = currentProjectCasesMetadata.map(({ id }) => id)
    const plotConfig = plotConfigs[tileConfigs[tileId]?.configId ?? '']
    const canViewCasterComparison = FeatureFlags.canViewCasterComparison(featureFlags)
    const tileConfig = tileConfigs[tileId]

    if (plotConfig) {
      if (
        (ViewLogic.isDynamicDataSourceWithCurrentPassLnCoordInFilter(plotConfig) && stoppedScrolling) ||
        ViewLogic.isDynamicDataSourceWithFilterControlVariableInFilter(plotConfig, filterControlVariables)
      ) {
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            let definitions = this.prevDefinitions
            let hasNoData = this.state.hasNoData

            if (this.prevDefinitions.length > 0) {
              const comparisonCastersIds = (viewsObject[viewId]?.selectedComparisonCasters ?? [])
                .filter(id => caseIds.includes(id)) // filter out deleted casters

              definitions = cloneDeep(this.prevDefinitions)

              const [ type, attrY ] = plotConfig.selectedY.split('|') as [ TagName, string ]
              const [ , attrX ] = plotConfig.selectedX.split('|') as [ TagName, string ]

              const elements = ViewLogic
                .getDynamicElementsFromConfig(elementMaps, plotConfig, {}, type)

              if (!elements) {
                return
              }

              const dynamicData: any = type === 'DataLine'
                ? ViewLogic.getDynamicDataFromDataLines<DataLineSlot, DataLineMountLog>(
                  elements,
                  elementMaps,
                  'DataLine',
                  attrX,
                  attrY,
                  false,
                  true,
                )
                : ViewLogic.getDynamicDataFromElements(elements, elementMaps, type, attrX, attrY, false)

              if (!dynamicData) {
                return
              }

              hasNoData = !dynamicData.length

              this.setState({ hasNoData })

              if (!dynamicData.length) {
                // fallback data in order to show plot with no data but have plotly traces which can be used by FastBase
                dynamicData.push(...[ { x: 0, y: 0 }, { x: 0, y: 0 } ])
              }

              definitions[0] = definitions[0] ?? {}
              definitions[0].data = (canViewCasterComparison && (comparisonCastersIds.length > 0))
                ? dynamicData
                : dynamicData.map((el: any) => el.y)
              definitions[0].dynamicData = dynamicData
              definitions[0].length = dynamicData.length
              definitions[0].id = plotConfig.id

              // TODO: update X values!
              const comparisonCasterData: any[] = []
              const comparisonElements: string[][] = []

              if (canViewCasterComparison && comparisonCastersIds && comparisonCastersIds.length > 0) {
                for (const casterId of comparisonCastersIds) {
                  const data = comparisonCasters[casterId]

                  if (!data) {
                    continue
                  }

                  const casterIndex = caseIds.findIndex(key => casterId === key) + 1

                  data.casterName = `C${casterIndex}`

                  const elements = ViewLogic
                    .getDynamicElementsFromConfig(data.elementMaps, plotConfig, {}, type)

                  comparisonElements.push(elements)
                  comparisonCasterData.push(data)
                }

                for (let i = 0; i < comparisonCasterData.length; i++) {
                  const data = comparisonCasterData[i]
                  const elements = comparisonElements[i] ?? []
                  const compareDynamicData: any = type === 'DataLine'
                    ? ViewLogic.getDynamicDataFromDataLines<DataLineSlot, DataLineMountLog>(
                      elements,
                      data.elementMaps,
                      'DataLine',
                      attrX,
                      attrY,
                      false,
                      true,
                    )
                    : ViewLogic.getDynamicDataFromElements(
                      elements,
                      data.elementMaps,
                      type,
                      attrX,
                      attrY,
                      false,
                    )

                  if (tileWarnings && tileWarnings[plotConfig.id] && tileWarnings[plotConfig.id]?.[data.casterName]) {
                    const tileWarningsCopy = { ...tileWarnings }

                    delete tileWarningsCopy[plotConfig.id]![data.casterName]

                    setTileWarnings(tileWarningsCopy)
                  }

                  // add caster name info to every point of line
                  for (const el of compareDynamicData) {
                    ;(el as any).casterName = data.casterName
                  }

                  const j = i + 1

                  definitions[j] = definitions[j] ?? {}
                  definitions[j].data = compareDynamicData
                  definitions[j].dynamicData = compareDynamicData
                  definitions[j].length = compareDynamicData.length
                  definitions[j].id = plotConfig.id
                }
              }
            }

            this.setData(hasNoData, { tileId, definitions })
            this.props.forceUpdateHandler?.()
          })
        })
      }
      else if (
        (ViewLogic.isMergedDynamicDataSourceWithCurrentPassLnCoordInFilter(plotConfig) && stoppedScrolling) ||
        ViewLogic.isMergedDynamicDataSourceWithFilterControlVariableInFilter(
          plotConfig,
          filterControlVariables,
        )
      ) {
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            let definitions = this.prevDefinitions

            if (this.prevDefinitions.length > 0) {
              definitions = cloneDeep(this.prevDefinitions).filter(definition => definition.length > 0)

              // const xValues: number[] = []
              // const yValues: number[] = []
              const filteredElementCache = {}
              // const dynamicDataList: any = []
              const hasNoDataList: boolean[] = []

              let definitionCounter = 0

              plotConfig.configs?.forEach((config: any, index: number) => {
                index += definitionCounter

                const [ type, attrY ] = config.selectedY.split('|') as [ TagName, string ]
                const [ , attrX ] = config.selectedX.split('|') as [ TagName, string ]

                const elements = ViewLogic
                  .getDynamicElementsFromConfig(
                    elementMaps,
                    config,
                    filteredElementCache,
                    type,
                  )

                const dynamicData: any = type === 'DataLine'
                  ? ViewLogic.getDynamicDataFromDataLines<DataLineSlot, DataLineMountLog>(
                    elements,
                    elementMaps,
                    'DataLine',
                    attrX,
                    attrY,
                    false,
                    true,
                  )
                  : ViewLogic.getDynamicDataFromElements(elements, elementMaps, type, attrX, attrY, false)

                hasNoDataList.push(!dynamicData.length)

                if (!dynamicData.length) {
                  // Create plotly traces with fallback data for FastBase.
                  dynamicData.push(...[ { x: 0, y: 0 }, { x: 0, y: 0 } ])
                }

                // dynamicDataList.push(dynamicData)

                definitions[index] = definitions[index] ?? {}
                definitions[index].data = dynamicData.map((el: any) => el.y)
                definitions[index].dynamicData = dynamicData
                definitions[index].length = dynamicData.length
                definitions[index].id = config.id

                const comparisonCastersIds = (viewsObject[viewId]?.selectedComparisonCasters ?? [])
                  .filter(id => caseIds.includes(id)) // filter out deleted casters

                const comparisonCasterData: any[] = []
                const comparisonElements: string[][] = []

                if (canViewCasterComparison && comparisonCastersIds && comparisonCastersIds.length > 0) {
                  for (const casterId of comparisonCastersIds) {
                    const data = comparisonCasters[casterId]

                    if (!data) {
                      continue
                    }

                    const casterIndex = caseIds.findIndex(key => casterId === key) + 1

                    data.casterName = `C${casterIndex}`

                    const elements = ViewLogic
                      .getDynamicElementsFromConfig(data.elementMaps, config, {}, type)

                    comparisonElements.push(elements)
                    comparisonCasterData.push(data)
                  }

                  for (let i = 0; i < comparisonCasterData.length; i++) {
                    const data = comparisonCasterData[i]
                    const elements = comparisonElements[i] ?? []
                    const compareDynamicData: any = type === 'DataLine'
                      ? ViewLogic.getDynamicDataFromDataLines<DataLineSlot, DataLineMountLog>(
                        elements,
                        data.elementMaps,
                        type,
                        attrX,
                        attrY,
                        false,
                        true,
                      )
                      : ViewLogic.getDynamicDataFromElements(
                        elements,
                        data.elementMaps,
                        type,
                        attrX,
                        attrY,
                        false,
                      )

                    if (!compareDynamicData.length) {
                      compareDynamicData.push({ x: 0, y: 0 })
                    }
                    else {
                      // delete tile warning
                      if (tileWarnings && tileWarnings[config.id] && tileWarnings[config.id]?.[data.casterName]) {
                        const tileWarningsCopy = { ...tileWarnings }

                        delete tileWarningsCopy[config.id]![data.casterName]

                        setTileWarnings(tileWarningsCopy)
                      }
                    }

                    // add caster name info to every point of line
                    for (const el of compareDynamicData) {
                      ;(el as any).casterName = data.casterName
                    }

                    // dynamicDataList.push(compareDynamicData)

                    const j = i + 1

                    definitions[index + j] = definitions[index + j] ?? {}
                    definitions[index + j].data = compareDynamicData.map((el: any) => el.y)
                    definitions[index + j].dynamicData = compareDynamicData
                    definitions[index + j].length = compareDynamicData.length
                    definitions[index + j].id = config.id
                  }

                  definitionCounter += comparisonCasterData.length ?? 0
                }
              })

              const hasNoData = hasNoDataList.reduce((a, b) => a && b, true)

              this.setState({ hasNoData })
              this.setData(hasNoData, { tileId, definitions })
              this.props.forceUpdateHandler?.()
            }
          })
        })
      }
      else if (tileConfig?.followPasslnCoord && stoppedScrolling) {
        this.props.forceUpdateHandler?.()
      }
    }

    if (
      stoppedScrolling &&
      (
        ViewLogic.isDynamicDataSourceWithCurrentPassLnCoordInFilter(plotConfig) ||
        ViewLogic.isMergedDynamicDataSourceWithCurrentPassLnCoordInFilter(plotConfig)
      )
    ) {
      this.drawVerticalVariable('passlineCoord')

      return
    }

    if (eventType === 'CurrentPasslnPositionChanged') {
      this.currentPasslnPosition = currentPasslnPosition
    }
    else if (eventType === 'CurrentCasterTimeChanged') {
      this.currentCasterTime = currentCasterTime
    }
    else {
      const varName: string = eventType.split([ 'Changed' ])[0]

      ;(this as any).filterControlVariableValues[varName] = nextValue
    }

    // update vertical line passln coord
    this.handlePlotlyWrapperRelayout({ detail: { tileId, type: eventType } }, filterControlVariables)
  }

  private readonly handlePlotlyWrapperRelayout = (
    event: { detail: { tileId: string, type: string } },
    filterControlVariables: string[],
  ) => {
    const { tileId, additionalId, tileConfigs, shapeIds } = this.props

    if (
      event.detail.tileId !== tileId ||
      !tileConfigs[tileId] ||
      !shapeIds
    ) {
      return
    }

    if (shapeIds.includes('passlineCoord') && event.detail.type === 'CurrentPasslnPositionChanged') {
      const plot = document.querySelector(`#plot_${tileId}${additionalId ? `_${additionalId}` : ''}`) as any
      const passlineCoordShape = plot?.layout?.shapes?.find((shape: any) => shape.name === 'passlineCoord')

      if (passlineCoordShape) {
        passlineCoordShape.x0 = this.currentPasslnPosition
        passlineCoordShape.x1 = this.currentPasslnPosition
      }

      this.drawVerticalVariable('passlineCoord')
    }
    else if (shapeIds.includes('currentCasterTime') && event.detail.type === 'CurrentCasterTimeChanged') {
      const plot = document.querySelector(`#plot_${tileId}${additionalId ? `_${additionalId}` : ''}`) as any
      const currentCasterTimeShape = plot?.layout?.shapes?.find((shape: any) => shape.name === 'currentCasterTime')

      if (currentCasterTimeShape) {
        currentCasterTimeShape.x0 = this.currentCasterTime
        currentCasterTimeShape.x1 = this.currentCasterTime
      }

      this.drawVerticalVariable('currentCasterTime')
    }
    else if (shapeIds.some(shapeId => filterControlVariables.includes(shapeId))) {
      const variableChanged = event.detail.type.split('Changed')[0]

      if (variableChanged && filterControlVariables.includes(variableChanged)) {
        this.drawVerticalVariable(variableChanged)
      }
    }
  }

  private readonly handleMouseDown = (event: any) => {
    const { button, pageX, pageY } = event
    const { over, xIndex, x } = this.state
    const { configId, openContextMenu, viewId } = this.props

    this.pauseDraw = true

    if (button === 2 && over) { // right
      openContextMenu('plot', { xIndex, x, mousePos: { x: pageX, y: pageY }, configId, viewId } as ContextMenuData)
    }
  }

  private readonly handleMouseUp = () => {
    this.pauseDraw = false
  }

  private readonly handleMouseOver = (data: any) => {
    const { over, xIndex } = this.state

    if (data && data.length && (xIndex !== data[0].pointIndex || !over)) {
      this.setState({
        x: data[0].x,
        xIndex: data[0].pointIndex,
        over: true,
      })
    }
  }

  private readonly handleMouseOut = () => {
    this.setState({
      over: false,
    })
  }

  private readonly handleMouseEnter = () => {
    this.setState({
      overWrapper: true,
    })
  }

  private readonly handleMouseLeave = (event: any) => {
    const { clientX, clientY } = event
    const wrapperRect = event.target.getBoundingClientRect()
    const containerRect = event.target.closest('.grid-layout-scroll-container')?.getBoundingClientRect()

    if (
      !containerRect ||
      !logic.isInRect(clientX, clientY, wrapperRect) ||
      !logic.isInRect(clientX, clientY, containerRect) ||
      Array.prototype.includes.call(event.relatedTarget.classList ?? [], 'react-resizable-handle')
    ) {
      this.setState({
        overWrapper: false,
      })
    }
  }

  private readonly drawVerticalVariable = (variableName: string) => {
    const { tileId, additionalId, tileConfigs, flipAxes = false, xDomain = [ 0, 0 ] } = this.props
    const currentShape = tileConfigs[tileId]?.shapeIds?.find(shapeId => shapeId.id === variableName)
    const currentValue = variableName === 'passlineCoord'
      ? this.currentPasslnPosition
      : variableName === 'currentCasterTime'
        ? this.currentCasterTime
        : this.filterControlVariableValues[variableName] ?? 0

    const shapes = [
      Helpers.getLineShape({ flipAxes, currentVariablePosition: currentValue, currentShape }),
    ]

    document.querySelectorAll(`[id="plot_${tileId}${additionalId ? `_${additionalId}` : ''}"]`)
      .forEach((plot: any) => {
        this.shape.draw(plot, [], xDomain.sort((a, b) => a - b), [ 0, 0 ], false, { shapes })
      })
  }

  private readonly setData = (hasNoData: boolean, result: any = {}) => {
    let {
      tileId,
      definitions,
    }: {
      tileId: string
      definitions: Definition[]
    } = result
    const {
      currentSimulationCaseFromRedux,
      filterControlVariables,
      isMergedDynamicData,
      isDynamicData,
      dynamicData,
      dynamicDataList,
      currentProjectCasesMetadata,
      featureFlags,
      viewOnly,
      additionalId,
      fullSize,
    } = this.props

    const caseIds = currentProjectCasesMetadata.map(({ id }) => id)
    const currentCasterIndex = caseIds
      .findIndex(id => id === currentSimulationCaseFromRedux.id)

    const referenceCasterTooltip = `C${currentCasterIndex + 1}(R)`

    if (!tileId) {
      tileId = this.prevTileId ?? ''
      definitions = this.prevDefinitions
    }

    this.prevTileId = tileId
    this.prevDefinitions = definitions

    if (this.pauseDraw || !tileId || !definitions || (definitions.length === 0) || !definitions[0]?.data) {
      return
    }

    const { type, xLabel, yLabel, flipAxes = false, tileConfigs, shapeIds, plotConfigs } = this.props
    const configs: Array<any> = Object.values(plotConfigs)

    const defaultLayout = logic.getLayout(this.props)
    const defaultData = logic.getData(this.props)

    if (isMergedDynamicData && dynamicDataList?.[0]) {
      Helpers.updateDefaultData(defaultData, dynamicDataList[0])
    }

    if (isDynamicData && dynamicData) {
      Helpers.updateDefaultData(defaultData, dynamicData)
    }

    let data: any[] = []
    let newLayout: any = {}
    let hasCurrentPasslinePosition = false
    let hasCurrentCasterTime = false
    const hasVariables: Record<string, boolean> = {}

    for (let i = 0; i < definitions.length; i++) {
      const currentDefinition = definitions[i]

      // filter out empty data, dynamic merge plots have empty data!
      if (!currentDefinition || (!currentDefinition.length && !currentDefinition.isVerticalLine)) {
        continue
      }

      const dataConfigId = currentDefinition.id

      if (currentDefinition.isVerticalLine) {
        if (
          (shapeIds?.includes(dataConfigId)) ||
          ((shapeIds?.includes('passlineCoord') ||
            shapeIds?.includes('currentCasterTime') ||
            shapeIds?.some(shape => filterControlVariables.includes(shape))))
        ) {
          let shapes: unknown[] = []

          if (isMergedDynamicData) {
            const shapeData = this.props.shapeData ?? []
            const currentShape = tileConfigs[tileId]?.shapeIds?.find(shapeId => shapeId.id === dataConfigId)
            const currentShapeData = shapeData.find(data => data.shapeId === dataConfigId)?.data
            const xValues = currentShapeData?.map((el: any) => el.x) ?? []

            if (
              currentShape === undefined &&
              !(shapeIds?.includes('passlineCoord') ||
                shapeIds?.includes('currentCasterTime') ||
                shapeIds?.some(shape => filterControlVariables.includes(shape)))
            ) {
              return
            }

            if (currentShape?.type === 'rect') {
              shapes = []

              Helpers.pushRectShapes(xValues, flipAxes, shapes, currentShape)
            }
            else {
              shapes = (currentDefinition.data ?? [])
                .map((value: any) => (Helpers.getLineShape({ flipAxes, currentShape, value: value.x }, true)))
            }

            newLayout.shapes = [
              ...(newLayout.shapes ?? []),
              ...shapes,
            ]

            continue
          }

          const shapeData = this.props.shapeData ?? []
          const currentShape = tileConfigs[tileId]?.shapeIds?.find(shapeId => shapeId.id === dataConfigId)
          const currentShapeData = shapeData.find(data => data.shapeId === dataConfigId)?.data
          const xValues = currentShapeData?.map((el: any) => el.x) ?? []

          if (
            currentShape === undefined &&
            !(shapeIds?.includes('passlineCoord') ||
              shapeIds?.includes('currentCasterTime') ||
              shapeIds?.some(shape => filterControlVariables.includes(shape)))
          ) {
            return
          }

          if (currentShape?.type === 'rect') {
            shapes = []

            Helpers.pushRectShapes(xValues, flipAxes, shapes, currentShape)
          }
          else {
            shapes = currentShapeData
              .map((value: any) => (Helpers.getLineShape({ flipAxes, currentShape, value: value.x }, true)))
          }

          newLayout.shapes = [
            ...(newLayout.shapes ?? []),
            ...shapes,
          ]
        }

        continue
      }

      const rawValues = !this.props.isMultiLinePlot
        ? currentDefinition.data
        : Object.values(currentDefinition.data ?? {}).map((data: any) => data.y)
      const config = plotConfigs[dataConfigId]
      const uid = `uid-${dataConfigId}-${tileId}`

      switch (type) {
        case 'area':
        case 'line':
        case 'radar':
        case 'bar':
          {
            const { additionalYAxes, legendOptions } = tileConfigs[tileId] ?? {}

            if (!config || config.key.startsWith('xCoordinates_')) {
              continue
            }

            let x = []
            let casterNames = []

            const xConfigs = configs.filter(c => c.group === config.group && c.key.startsWith('xCoordinates_'))[0]

            if (xConfigs && xConfigs.key) {
              x = definitions.find((def: any) => def.id === xConfigs.id)?.data
            }

            let fallbackColor = ''

            if (isMergedDynamicData && dynamicDataList) {
              const { configId } = tileConfigs[tileId] ?? {}
              const { configIds } = plotConfigs[configId ?? ''] ?? {}
              const index = configIds?.findIndex(id => id === dataConfigId) ?? 0

              fallbackColor = VisUtil.TRACE_COLORS[
                (index + (currentDefinition.lineIndex ?? 0)) % VisUtil.TRACE_COLORS.length
              ] ?? ''

              if (currentDefinition.isDataLine) {
                const { lineIndex, dynamicData } = currentDefinition
                const correspondingDataList = dynamicDataList[index]?.[lineIndex ?? 0] ?? []

                const dataList = correspondingDataList.length
                  ? correspondingDataList
                  : (dynamicData ?? [])

                x = dataList.map((el: any) => el.x)
                casterNames = dataList.map((el: any) => el.casterName ?? '')
              }
              else if (currentDefinition.isMergedDataLine) {
                const dataList = dynamicDataList[index]?.[currentDefinition.lineIndex ?? 0][0] ?? []

                x = dataList.map((el: any) => el.x)
                casterNames = dataList.map((el: any) => el.casterName ?? '')
              }
              else {
                x = currentDefinition.dynamicData ?? dynamicDataList[index]?.map((el: any) => el.x) ?? []
              }
            }

            if (isDynamicData && this.props.dynamicData && !this.props.isMultiLinePlot) {
              x = (currentDefinition.dynamicData ?? this.props.dynamicData).map((el: any) => el.x)
            }

            if (isDynamicData && this.props.dynamicData && this.props.isMultiLinePlot) {
              x = (currentDefinition.dynamicData ?? this.props.dynamicData[i])?.map((el: any) => el.x) ?? []
              casterNames = (currentDefinition.dynamicData ?? this.props.dynamicData[i])
                ?.map((el: any) => el.casterName ?? '') ?? []
            }

            if (
              shapeIds &&
            (
              shapeIds.includes(dataConfigId) ||
              shapeIds.includes('passlineCoord') ||
              shapeIds.includes('currentCasterTime') ||
              shapeIds.some(shape => filterControlVariables.includes(shape))
            )
            ) {
              let shapes: unknown[] = []

              if (shapeIds?.includes('passlineCoord')) {
                if (!hasCurrentPasslinePosition) {
                  const { currentPasslnPosition } = this
                  const currentShape = tileConfigs[tileId]?.shapeIds?.find(shapeId => shapeId.id === 'passlineCoord')

                  if (newLayout.shapes) {
                    newLayout.shapes.unshift(Helpers.getLineShape({
                      flipAxes,
                      currentVariablePosition: currentPasslnPosition,
                      currentShape,
                      name: 'passlineCoord',
                    }))
                  }
                  else {
                    newLayout.shapes = [
                      Helpers.getLineShape({
                        flipAxes,
                        currentVariablePosition: currentPasslnPosition,
                        currentShape,
                        name: 'passlineCoord',
                      }),
                    ]
                  }

                  hasCurrentPasslinePosition = true
                }
              }
              else if (shapeIds?.includes('currentCasterTime')) {
                if (!hasCurrentCasterTime) {
                  const { currentCasterTime } = this
                  const currentShape = tileConfigs[tileId]?.shapeIds
                    ?.find(shapeId => shapeId.id === 'currentCasterTime')

                  if (newLayout.shapes) {
                    newLayout.shapes.unshift(Helpers.getLineShape({
                      flipAxes,
                      currentVariablePosition: currentCasterTime,
                      currentShape,
                      name: 'currentCasterTime',
                    }))
                  }
                  else {
                    newLayout.shapes = [
                      Helpers.getLineShape({
                        flipAxes,
                        currentVariablePosition: currentCasterTime,
                        currentShape,
                        name: 'currentCasterTime',
                      }),
                    ]
                  }

                  hasCurrentCasterTime = true
                }
              }
              else if (shapeIds?.some(shape => filterControlVariables.includes(shape))) {
                const variableContainedInShapes = shapeIds.find(shape => filterControlVariables.includes(shape))

                if (variableContainedInShapes && !hasVariables[variableContainedInShapes]) {
                  const currentShape = tileConfigs[tileId]
                    ?.shapeIds
                    ?.find(shapeId => shapeId.id === variableContainedInShapes)

                  if (newLayout.shapes) {
                    newLayout.shapes.unshift(Helpers.getLineShape({
                      flipAxes,
                      currentVariablePosition: this.filterControlVariableValues[variableContainedInShapes] ?? 0,
                      currentShape,
                    }))
                  }
                  else {
                    newLayout.shapes = [
                      Helpers.getLineShape({
                        flipAxes,
                        currentVariablePosition: this.filterControlVariableValues[variableContainedInShapes] ?? 0,
                        currentShape,
                      }),
                    ]
                  }

                  hasVariables[variableContainedInShapes] = true
                }
              }
              else {
                const shapeData = this.props.shapeData ?? []
                const currentShape = tileConfigs[tileId]?.shapeIds?.find(shapeId => shapeId.id === dataConfigId)
                const currentShapeData = shapeData.find(data => data.shapeId === dataConfigId)?.data
                const xValues = currentShapeData?.map((el: any) => el.x) ?? []

                if (
                  currentShape === undefined &&
                  !(shapeIds?.includes('passlineCoord') || shapeIds?.includes('currentCasterTime'))
                ) {
                  return
                }

                if (currentShape?.type === 'rect') {
                  shapes = []

                  Helpers.pushRectShapes(xValues, flipAxes, shapes, currentShape)
                }
                else {
                  shapes = currentShapeData
                    .map((value: any) => (Helpers.getLineShape({ flipAxes, currentShape, value: value.x }, true)))
                }
              }

              newLayout.shapes = [
                ...(newLayout.shapes ?? []),
                ...shapes,
              ]
            }

            const {
              tooltip,
              color,
              hide,
              hideLine,
              markerSymbol,
              markerSize,
              markerColor,
            } = legendOptions?.[config.id] ?? {}

            const { showLegend = false } = tileConfigs[tileId] ?? {}
            const hovertemplate = hide ? '' : '%{y} - %{text}'
            const text = tooltip ?? ''
            let yAxisIndex: any = (additionalYAxes ?? [])
              .map((additionalYAxis: any) => additionalYAxis.id)
              .indexOf(config.id) + 2

            yAxisIndex = yAxisIndex < 2 ? '' : yAxisIndex

            const newData =
              Helpers.getNewData(defaultData[0], hide, Boolean(showLegend), fallbackColor, newLayout, color)

            if (type === 'bar') {
              const tracesXs: any = []
              const tracesYs: any = []
              const tracesCasters: any[] = []

              for (let i = 0; i < x.length; i++) {
                let traceIndex = 0
                const elementX = x[i]
                const elementY = rawValues[i]
                const casterTrace = casterNames[i]

                while (tracesXs[traceIndex] && tracesXs[traceIndex].includes(elementX)) {
                  traceIndex++
                }

                if (!tracesXs[traceIndex]) {
                  tracesXs[traceIndex] = []
                  tracesYs[traceIndex] = []
                  tracesCasters[traceIndex] = casterTrace
                }

                tracesXs[traceIndex].push(elementX)
                tracesYs[traceIndex].push(elementY)
              }

              for (let index = 0; index < tracesXs.length; index++) {
                const xList = tracesXs[index]
                const yList = tracesYs[index]
                let traceText = text ?? ''
                const casterName = tracesCasters[index] ? String(tracesCasters[index]) : referenceCasterTooltip

                if (traceText.length) {
                  traceText += ' -'
                }

                traceText += ` ${casterName}`

                const legendName = this.getLegendName(
                  config,
                  legendOptions,
                  [ casterName ],
                  showLegend,
                  referenceCasterTooltip,
                )

                if (!flipAxes) {
                  const newTrace = {
                    ...newData,
                    y: yList,
                    x: xList,
                    textposition: 'none',
                    yaxis: `y${yAxisIndex}`,
                    uid: `${uid}-${index}`,
                    comparisonLineIndex: currentDefinition.comparisonLineIndex,
                    text: xList.map(() => traceText),
                    hovertemplate,
                    name: legendName,
                  }

                  data.push(newTrace)
                }
                else {
                  const newTrace = {
                    ...newData,
                    x: yList,
                    y: xList,
                    textposition: 'none', 
                    xaxis: `x${yAxisIndex}`,
                    uid: `${uid}-${index}`,
                    comparisonLineIndex: currentDefinition.comparisonLineIndex,
                    text: yList.map(() => traceText),
                    hovertemplate,
                    name: legendName,
                  }

                  data.push(newTrace)
                }
              }
            }
            else {
              const newText = []

              const legendName = this.getLegendName(
                config,
                legendOptions,
                casterNames,
                showLegend,
                referenceCasterTooltip,
              )

              for (let i = 0; i < casterNames.length; i++) {
                const casterName = casterNames[i] ? casterNames[i] : referenceCasterTooltip

                newText.push(text ? `${text} - ${casterName}` : casterName)
              }

              let trace = {
                ...newData,
                mode: 'lines',
                text: (newText.length > 0) ? newText : text,
                uid,
                comparisonLineIndex: currentDefinition.comparisonLineIndex,
                hovertemplate,
                name: legendName,
              }

              trace.text = []

              for (let i = 0; i < x.length; i++) {
                const casterName = casterNames[i] ? casterNames[i] : referenceCasterTooltip

                trace.text.push(text ? `${text} - ${casterName}` : casterName)
              }

              const theta = x.map((value: any) => `(${value})`)
              const rValues = rawValues.map((value: any) => value)

              if (type === 'radar') {
                theta.push(theta[0])
                rValues.push(rValues[0])
                trace = {
                  ...trace,
                  type: 'scatterpolar',
                  r: rValues,
                  theta,
                }
              }

              if (type === 'line') {
                trace = {
                  ...trace,
                  mode: hideLine
                    ? 'markers'
                    : (markerSymbol === undefined || markerSymbol === 'none'
                      ? 'lines'
                      : 'lines+markers'),
                  marker: {
                    symbol: markerSymbol,
                    size: markerSize || 8,
                    color: markerColor || color,
                  },
                }
              }

              if (!flipAxes) {
                data.push({
                  ...trace,
                  y: rawValues,
                  x,
                  yaxis: `y${yAxisIndex}`,
                })
              }
              else {
                data.push({
                  ...trace,
                  x: rawValues,
                  y: x,
                  xaxis: `x${yAxisIndex}`,
                })
              }
            }
          }

          break
        case 'pie':
          data.push({ ...defaultData[0], values: rawValues, name: dataConfigId, type })
          break
        case 'contour':
          {
            const { colorBar } = tileConfigs[tileId] ?? {}

            const d = data[0] ?? {}

            data[0] = {
              ...defaultData[0],
              x: d.x,
              y: d.y,
              z: d.z,
              zTmp: d.zTmp,
              showscale: colorBar ?? false,
            }

            if (colorBar) {
              data[0] = {
                ...data[0],
                colorbar: {
                  tickfont: {
                    color: '#a2a6a9',
                  },
                },
              }
            }

            if (config?.key?.startsWith('xCoordinates_')) {
              data[0].x = rawValues
            }
            else if (config?.key?.startsWith('yCoordinates_')) {
              data[0].y = rawValues
            }
            else {
              data[0].zTmp = rawValues
            }

            const { x: xValues, y: yValues, zTmp } = data[0]

            if (xValues && yValues && zTmp) {
              data[0].z = []

              for (let yIndex = 0; yIndex < yValues.length; yIndex++) {
                data[0].z[yIndex] = []

                for (let xIndex = 0; xIndex < xValues.length; xIndex++) {
                  data[0].z[yIndex][xIndex] = zTmp[xIndex + (yIndex * xValues.length)]
                }
              }

              delete data[0].zTmp
            }
          }

          break
        default:
      }
    }

    const plotElement = document.getElementById(`plot_${tileId}${additionalId ? `_${additionalId}` : ''}`)

    if (data && (data.length > 0) && plotElement) {
      if (!plotElement.getElementsByClassName('plot-container').length) {
        plotElement.innerHTML = ''

        const configWithPermissions = logic.getConfigWithPermissions(config, featureFlags, viewOnly)

        try {
          Plotly.newPlot(
            `plot_${tileId}${additionalId ? `_${additionalId}` : ''}`,
            defaultData,
            defaultLayout,
            configWithPermissions,
          )
          
          // Check if this is a time plot and apply proper date range immediately after creation
          if (this.props.configId && 
              this.props.plotConfigs[this.props.configId]?.selectedX?.includes('dataOverTimeByFieldUUID')) {
            // Give the plot a moment to fully initialize
            setTimeout(() => {
              const plotElement = document.getElementById(`plot_${tileId}${additionalId ? `_${additionalId}` : ''}`)

              if (plotElement) {
                this.handleResetAxesForTimeData(plotElement)
                
                // Add direct event listener for the reset axes button after a short delay to ensure DOM is ready
                setTimeout(() => {
                  try {
                    // Find the reset axes button and add a direct click handler
                    const resetButton = plotElement.querySelector('.modebar-btn[data-title="Reset axes"]')

                    if (resetButton) {
                      // Remove existing listener if any to avoid duplicates
                      resetButton.removeEventListener('click', this.handleResetAxesButtonClick as EventListener)
                      
                      // Add the event listener
                      resetButton.addEventListener('click', this.handleResetAxesButtonClick as EventListener)
                    }
                  }
                  catch (error) {
                    // eslint-disable-next-line no-console
                    console.error('Error setting up reset button handler:', error)
                  }
                }, 500)
              }
            }, 100)
          }
        }
        catch (e) {}
      }

      document.querySelectorAll(`[id="plot_${tileId}${additionalId ? `_${additionalId}` : ''}"]`)
        .forEach((plot: any) => {
          // for some reason the axes are swapped so we need to set old.x = new.y and old.y = new.x
          try {
            plot._fullLayout.xaxis._anchorAxis._rangeInitial = defaultLayout.yaxis.range
            plot._fullLayout.yaxis._anchorAxis._rangeInitial = defaultLayout.xaxis.range

            /*
              on relayout (zoom, pan, etc.) we should redraw current passline position
              because of how we redraw it on pos. change
            */
            if (!plot.getAttribute('data-relayout-bound')) {
              plot.on('plotly_relayout', (event: any) => {
                // Check if this is a reset axes operation (autorange is true for both axes)
                if (event['xaxis.autorange'] === true && event['yaxis.autorange'] === true) {
                  this.handleResetAxesForTimeData(plot)
                } 
                // Check for other possible reset axes signatures
                else if (event['xaxis.range'] === undefined && event['yaxis.range'] === undefined &&
                        (event.autosize === true || ('width' in event && 'height' in event))) {
                  this.handleResetAxesForTimeData(plot)
                }
                // Check for direct range reset
                else if (Array.isArray(event['xaxis.range']) || Array.isArray(event['yaxis.range'])) {
                  const hasTimeData = this.props.configId && 
                    this.props.plotConfigs[this.props.configId]?.selectedX?.includes('dataOverTimeByFieldUUID')
                  
                  if (hasTimeData) {
                    // Check if the x range is set to something close to 1970
                    const xRange = event['xaxis.range']

                    if (xRange && Array.isArray(xRange)) {
                      const startDate = new Date(xRange[0])

                      if (startDate.getFullYear() < 1980) {
                        this.handleResetAxesForTimeData(plot)
                      }
                    }
                  }
                }
                
                this.handlePlotlyWrapperRelayout({ detail: { tileId, type: 'CurrentPasslnPositionChanged' } }, [])
                this.handlePlotlyWrapperRelayout({ detail: { tileId, type: 'CurrentCasterTimeChanged' } }, [])
              })
              
              plot.setAttribute('data-relayout-bound', true)
            }

            data.forEach((d, i) => {
              if (!d.line) {
                return
              }

              if (!d.line.color) {
                d.line.color = VisUtil.TRACE_COLORS[i]
              }

              if (d.mode === 'lines+markers') {
                d.hoverlabel = { bgcolor: d.line.color }
              }

              if (d.comparisonLineIndex) {
                const originalLineIndex = data.findIndex(line => (line.uid === d.uid && line.comparisonLineIndex === 0))

                if (originalLineIndex !== -1) {
                  // get line color and dashed style
                  d.line = logic.getLineStyle(
                    data[originalLineIndex].line.color || VisUtil.TRACE_COLORS[originalLineIndex],
                    d.comparisonLineIndex,
                  )

                  if (d.mode === 'lines+markers') {
                    d.hoverlabel = { bgcolor: d.line.color }
                  }

                  if (d.mode === 'lines+markers' || d.mode === 'markers') {
                    d.marker.color = logic.lightenDarkenColor(
                      data[originalLineIndex].marker.color,
                      50 * (d.comparisonLineIndex % 4),
                    )
                  }

                  if (d.type === 'bar') {
                    d.marker = logic.getLineStyle(
                      data[originalLineIndex].line.color || VisUtil.TRACE_COLORS[originalLineIndex],
                      d.comparisonLineIndex,
                    )
                  }
                }
              }

              if (d.line.color === 'transparent') {
                d.marker.color = 'transparent'
                d.hoverinfo = 'none'
              }
              else {
                d.hoverinfo = defaultData[i]?.hoverinfo ?? 'x+y+text'
              }
            })
          }
          catch (error: any) {
          // eslint-disable-next-line no-console
            console.error(error)
          }

          const trueXLabel = !flipAxes ? xLabel : yLabel
          const trueYLabel = !flipAxes ? yLabel : xLabel

          const margin = {
            l: (trueYLabel?.length ?? 0) > 0 ? 60 : 40,
            b: (trueXLabel?.length ?? 0) > 0 ? 60 : 40,
          }

          const { showLine } = tileConfigs[tileId] ?? {}

          const plotLayout = plot.layout ?? {}

          newLayout = {
            ...plotLayout,
            ...defaultLayout,
            margin: {
              ...defaultLayout.margin,
              ...margin,
            },
            xaxis: {
              ...plotLayout.xaxis,
              title: {
                text: trueXLabel,
                font: {
                  family: 'Roboto, sans-serif',
                  size: 14,
                  color: '#a2a6a9',
                },
              },
              domain: defaultLayout.xaxis.domain,
              range: defaultLayout.xaxis.range,
            },
            yaxis: {
              ...plotLayout.yaxis,
              title: {
                text: trueYLabel,
                font: {
                  family: 'Roboto, sans-serif',
                  size: 14,
                  color: '#a2a6a9',
                },
              },
              showgrid: showLine ?? false,
              domain: defaultLayout.yaxis.domain,
              range: defaultLayout.yaxis.range,
            },
            polar: {
              ...plotLayout.polar,
              angularaxis: {
                tickfont: {
                  color: '#a2a6a9',
                },
              },
            },
            ...newLayout,
          }

          // Configure layout for full size plots
          if (fullSize) {
            newLayout.margin = {
              ...newLayout.margin,
              l: 100, // left margin
              b: 100, // bottom margin
            }

            const FULL_SIZE_FONT = 28

            // Configure X-axis
            newLayout.xaxis = {
              ...newLayout.xaxis,
              title: {
                ...newLayout.xaxis.title,
                standoff: 50, // Space between axis and title
                font: {
                  ...newLayout.xaxis.title.font,
                  size: FULL_SIZE_FONT,
                },
              },
              automargin: true,
              tickfont: {
                ...newLayout.xaxis.tickfont,
                size: FULL_SIZE_FONT,
              },
            }

            // Configure Y-axis
            newLayout.yaxis = {
              ...newLayout.yaxis,
              title: {
                ...newLayout.yaxis.title,
                standoff: 50, // Space between axis and title
                font: {
                  ...newLayout.yaxis.title.font,
                  size: FULL_SIZE_FONT,
                },
              },
              automargin: true,
              tickfont: {
                ...newLayout.yaxis.tickfont,
                size: FULL_SIZE_FONT,
              },
            }
          }

          if (hasNoData) {
            data = []
          }

          let didUsePlotly = false

          // eslint-disable-next-line no-undef
          const start = performance.now()

          try {
          // if (
          //   forceFast ||
          //   (
          //     isDataSourceServer &&
          //     /(line|area|bar)/.test(type) &&
          //     !overWrapper &&
          //     this.isPlotUpToDate
          //   )
          // ) {
          //   if (/(line|area)/.test(type)) {
          //     this.line.draw(plot, data, xDomain, yDomain, flipAxes)

            //     if (type === 'area') {
            //       this.fill.draw(plot, data, xDomain, yDomain, flipAxes)
            //     }
            //   }
            //   else if (type === 'bar') {
            //     this.bar.draw(plot, data, xDomain, yDomain, flipAxes)
            //   }

            //   this.shape.draw(plot, data, xDomain, yDomain, flipAxes, newLayout)
            // }
            // else {
            // this.isPlotUpToDate = true
            didUsePlotly = true

            Plotly.react(plot, data, newLayout)
          // }
          }
          catch (e: any) {
          // eslint-disable-next-line no-console
            console.log(e)

            if (e.message === FastBase.TRACE_MISSING) {
              didUsePlotly = true

              Plotly.react(plot, data, newLayout)
            }
          }

          // eslint-disable-next-line no-undef
          const end = performance.now()

          if ((window as any).logPlot) {
          // eslint-disable-next-line no-console
            console
              .log(tileConfigs[tileId]?.name, 'drawn in', end - start, 'ms using', didUsePlotly ? 'Plotly' : 'Fast')
          }
        })
    }
  }

  private readonly getLegendName = (
    config: any, 
    legendOptions: any, 
    casterNames: string[], 
    showLegend: boolean,
    referenceCasterTooltip: string,
  ): string => {
    if (!showLegend) {
      return ''
    }

    // Get base legend name from options or config
    let legendName = legendOptions?.[config.id]?.label?.length
      ? legendOptions?.[config.id]?.label
      : config.name

    // Add caster name if showing legend and we have caster names
    if (casterNames.length > 0 && showLegend) {
      const correspondingCasterName = casterNames[0]?.length ? casterNames[0] : referenceCasterTooltip

      if (correspondingCasterName) {
        legendName = `${legendName} - ${correspondingCasterName}`
      }
    }

    return legendName
  }

  private readonly handleResetAxesForTimeData = (plot: any): void => {
    // Get current plot config and data
    const configId = this.props.configId

    if (!configId) {
      return
    }

    const plotConfig = this.props.plotConfigs[configId]
    
    if (!plotConfig?.selectedX?.includes('dataOverTimeByFieldUUID')) {
      return
    }

    // Get tile config to respect user's padding settings
    const tileConfig = this.props.tileConfigs[this.props.tileId]
    const xAxisPadding = Number(tileConfig?.xAxisPadding ?? 0)
    const yAxisPadding = Number(tileConfig?.yAxisPadding ?? 0)
    
    // Log the actual data
    const dataTraces = plot.data

    if (!dataTraces || dataTraces.length === 0) {
      return
    }

    const firstTrace = dataTraces[0]
    
    // If the data contains valid dates, set the x range to the data range
    if (!firstTrace.x || firstTrace.x.length === 0) {
      return
    }

    try {
      // Get first and last valid dates to set the range
      const xDates = firstTrace.x.filter((date: any) => date && new Date(date).getTime() > 0)
      
      if (xDates.length === 0) {
        return
      }

      const minDate = new Date(Math.min(...xDates.map((d: any) => new Date(d).getTime())))
      const maxDate = new Date(Math.max(...xDates.map((d: any) => new Date(d).getTime())))
      
      // If we have valid dates, update the x-axis range
      if (isNaN(minDate.getTime()) || isNaN(maxDate.getTime())) {
        return
      }

      // Calculate the Y axis range too based on visible data
      // Find y values that correspond to the valid x dates
      const yValues: number[] = []

      for (let i = 0; i < firstTrace.x.length; i++) {
        const xPoint = firstTrace.x[i]

        if (xPoint && new Date(xPoint).getTime() > 0) {
          const yVal = firstTrace.y[i]

          if (yVal !== undefined && yVal !== null) {
            yValues.push(yVal)
          }
        }
      }

      // Sort y values and calculate min/max with defaults if empty
      const sortedYValues = [ ...yValues ].sort((a, b) => a - b)
      // Default to 0-1 range if no valid values
      const minY = sortedYValues.length > 0 ? sortedYValues[0]! : 0
      const maxY = sortedYValues.length > 0 ? sortedYValues[sortedYValues.length - 1]! : 1
      
      // Use the user's yAxisPadding setting instead of hardcoded 10%
      const yMin = minY - yAxisPadding
      const yMax = maxY + yAxisPadding
      
      // If time axis, add small padding to x-axis to ensure visibility
      // This helps especially with single-point timeseries
      let xMin = minDate
      let xMax = maxDate
      
      // Only add padding if we have more than one point
      if (xDates.length > 1 && xAxisPadding > 0) {
        // Calculate reasonable padding based on time range
        const timeRangeMs = maxDate.getTime() - minDate.getTime()
        const xPaddingMs = timeRangeMs * (xAxisPadding / 100)

        xMin = new Date(minDate.getTime() - xPaddingMs)
        xMax = new Date(maxDate.getTime() + xPaddingMs)
      }

      // Temporarily stop event handling to avoid recursive calls
      const wasAutomaticUpdate = this.pauseDraw

      this.pauseDraw = true
      
      // Use Plotly's relayout to update both x-axis and y-axis ranges
      Plotly
        .relayout(plot, {
          'xaxis.autorange': false,
          'xaxis.range': [ xMin, xMax ],
          'yaxis.autorange': false,
          'yaxis.range': [ yMin, yMax ],
        })
        .then(() => {
          this.pauseDraw = wasAutomaticUpdate
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.error('Error updating axis ranges:', err)
          this.pauseDraw = wasAutomaticUpdate
        })
    }
    catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error in handleResetAxesForTimeData:', error)
    }
  }

  // Add a new handler method for direct reset button clicks
  private readonly handleResetAxesButtonClick = (event: Event) => {
    // Find the associated plot
    const button = event.currentTarget as HTMLElement
    const modebar = button.closest('.modebar-container')

    if (!modebar) {
      return
    }
    
    // Get the plot element
    const plotId = modebar.getAttribute('data-for')

    if (!plotId) {
      return
    }
    
    const plotElement = document.getElementById(plotId)

    if (!plotElement) {
      return
    }
    
    // Check if this is a time plot that needs fixing
    if (
      this.props.configId && 
      this.props.plotConfigs[this.props.configId]?.selectedX?.includes('dataOverTimeByFieldUUID')
    ) {
      // Wait a moment for Plotly's internal reset to complete
      setTimeout(() => {
        this.handleResetAxesForTimeData(plotElement)
      }, 50)
    }
  }

  public override render () {
    const { hasNoData } = this.state
    const { tileId, additionalId } = this.props

    return (
      <>
        <Wrapper
          id={`plot_${tileId}${additionalId ? `_${additionalId}` : ''}`}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
        />
        {hasNoData && <PlotInfo>No Data available</PlotInfo>}
      </>
    )
  }
}

export default compose<any>(connector)(PlotlyWrapper)
