import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import {
  deleteDocumentationFile,
  downloadDocumentationFile,
  editDocumentationFileName,
  getAllDocumentationFilesByDefinitionId,
} from '@/api/executable-documentation-file'
import Util from '@/logicHandlers/ServerLogic/actions/Util'
import Button from '@/react/components/Button'

import EditDocumentationFileNameDialog from './EditDocumentationFileNameDialog'
import { ExpandableSection } from './ExpandableSection'

type Props = {
  definitionId: string
  currentProjectId: string
  currentSimulationCaseId: string
  expanded: boolean
  definitionCaseId: string
  onToggleStep: () => void
}

const StyledUl = styled.ul`
  padding-left: 15px;
  margin-top: 10px;
`

const StyledFileListItem = styled.li`
  list-style: disc;
  margin-left: 5px;
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

const StyledFilesSection = styled.div`
  display: flex;
  flex-direction: column;
  color: #a2a6a9;
  padding: 0 30px;
`

const StyledFilesSectionHeader = styled.h4`
  margin-top: 10px;
  margin-bottom: 0;
`

const StyledFileName = styled.span`
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

const StyledAddFileButtonContainer = styled.li`
  width: 250px;
  margin-top: 10px;
  list-style: none;
  display: flex;
  justify-content: flex-start;
`

export default function DocumentationFilesSection ({
  definitionId,
  currentProjectId,
  currentSimulationCaseId,
  expanded,
  definitionCaseId,
  onToggleStep,
}: Props) {
  const { t } = useTranslation('application', { keyPrefix: 'executableDialog' })
  const [ documentationFiles, setDocumentationFiles ] = useState<DocumentationFile[]>([])
  const [ showEditFileNameDialog, setShowEditFileNameDialog ] = useState<boolean>(false)
  const [ editingFileName, setEditingFileName ] = useState<string | null>(null)
  const [ editingFileNameValue, setEditingFileNameValue ] = useState<string | null>(null)

  useEffect(() => {
    getAllDocumentationFilesByDefinitionId(definitionId)
      .then((files) => {
        if (files) {
          setDocumentationFiles(files)
        }
      })
  }, [])

  const handleEditFileNameSubmit = async (newFileName: string, fileId: string) => {
    await editDocumentationFileName(fileId, newFileName)
    setShowEditFileNameDialog(false)
    setDocumentationFiles(documentationFiles.map((file) => file.id === fileId ? { ...file, name: newFileName } : file))
  }

  const handleEditFileNameClick = (e: React.MouseEvent<SVGSVGElement>, fileId: string, fileName: string) => {
    e.stopPropagation()
    setEditingFileName(fileId)
    setEditingFileNameValue(fileName)
    setShowEditFileNameDialog(true)
  }

  const onFileUpload = async (caseDependent: boolean) => {
    const result = await Util.openUploadFileDialog(
      '',
      '/executable-documentation-file/upload',
      'post',
      (formData: FormData) => {
        formData.append('projectId', currentProjectId)
        formData.append('simulationCaseId', currentSimulationCaseId)
        formData.append('definitionId', definitionId)

        if (caseDependent) {
          formData.append('definitionCaseId', definitionCaseId)
        }
      },
      // set uploadingFile to true callback, cannot be done before because the module cant detect the 'cancel' event
      // and it will not be able to set the uploadingFile to false 
      () => {},
    )

    if (result.id) {
      setDocumentationFiles([ ...documentationFiles, result ])
    }
  }

  const onDeleteFile = (e: React.MouseEvent<SVGSVGElement>, fileId: string) => {
    /* show native confirm dialog */
    if (confirm('Are you sure you want to delete this file?')) {
      e.stopPropagation()
      deleteDocumentationFile(fileId)
      setDocumentationFiles(documentationFiles.filter((file) => file.id !== fileId))
    }
  }

  const moduleDocumentationFiles: DocumentationFile[] = []
  const caseDocumentationFiles: DocumentationFile[] = []

  documentationFiles.forEach((file) => {
    if (!file.definitionCaseId) {
      moduleDocumentationFiles.push(file)
    }
    else if (file.definitionCaseId === definitionCaseId) {
      caseDocumentationFiles.push(file)
    }
  })

  return (
    <ExpandableSection
      expanded={expanded}
      sectionName='Documentation Files'
      sectionIndex={0}
      onToggleStep={onToggleStep}
    >
      <StyledFilesSection>
        <StyledFilesSectionHeader>Module Documentation Files</StyledFilesSectionHeader>
        <StyledUl>
          {
            moduleDocumentationFiles.map((file) => (
              <StyledFileListItem key={file.id}>
                <div>
                  <StyledFileName
                    title={file.name}
                    onClick={
                      () => {
                        downloadDocumentationFile(file.id)
                      }
                    }
                  >
                    {file.name}
                  </StyledFileName>
                  <div>
                    <FontAwesomeIcon
                      icon={faTrash}
                      onClick={(e) => onDeleteFile(e, file.id)}
                      title='Delete'
                      style={{ cursor: 'pointer', marginRight: '10px' }}
                    />
                    <FontAwesomeIcon
                      icon={faPencilAlt}
                      onClick={(e) => handleEditFileNameClick(e, file.id, file.name)}
                      title='Edit Name'
                      style={{ cursor: 'pointer' }}
                    />
                  </div>
                </div>
              </StyledFileListItem>
            ))
          }
          <StyledAddFileButtonContainer>
            <Button
              noMargin
              height='30'
              oneThird
              onClick={
                () => {
                  onFileUpload(false)
                }
              }
            >
              {t('input.addFile.label')}
            </Button>
          </StyledAddFileButtonContainer>
        </StyledUl>
      </StyledFilesSection>
      <StyledFilesSection>
        <StyledFilesSectionHeader>Case Documentation Files</StyledFilesSectionHeader>
        <StyledUl>
          {
            caseDocumentationFiles.map((file) => (
              <StyledFileListItem key={file.id}>
                <div>
                  <StyledFileName
                    title={file.name}
                    onClick={
                      () => {
                        downloadDocumentationFile(file.id)
                      }
                    }
                  >
                    {file.name}
                  </StyledFileName>
                  <div>
                    <FontAwesomeIcon
                      icon={faTrash}
                      onClick={(e) => onDeleteFile(e, file.id)}
                      title='Delete'
                      style={{ cursor: 'pointer', marginRight: '10px' }}
                    />
                    <FontAwesomeIcon
                      icon={faPencilAlt}
                      onClick={(e) => handleEditFileNameClick(e, file.id, file.name)}
                      title='Edit Name'
                      style={{ cursor: 'pointer' }}
                    />
                  </div>
                </div>
              </StyledFileListItem>
            ))
          }
          <StyledAddFileButtonContainer>
            <Button
              noMargin
              height='30'
              oneThird
              onClick={
                () => {
                  onFileUpload(true)
                }
              }
            >
              {t('input.addFile.label')}
            </Button>
          </StyledAddFileButtonContainer>
        </StyledUl>
      </StyledFilesSection>
      {
        showEditFileNameDialog && editingFileName && editingFileNameValue && (
          <EditDocumentationFileNameDialog
            fileId={editingFileName}
            fileName={editingFileNameValue}
            onClose={() => setShowEditFileNameDialog(false)}
            onSubmit={handleEditFileNameSubmit}
          />
        )
      }
    </ExpandableSection>
  )
}
