import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import styled from 'styled-components'

import LogicUtil from '@/logic/Util'
import DataActions from '@/store/data/actions'
import { getElementMapsObject } from '@/store/elements/logic'
import Util from '@/three/logic/Util'
import type { DefaultState } from '@/types/state'
import type { Translation } from '@/types/translation'
import { ElementMapsUtil } from '@/Util/ElementMapsUtil'

import Button from './Button'
import { Buttons, CardBody, CardContainer, CardHeader, DialogWrapper } from './Dialog'
import SecondaryButton from './SecondaryButton'

const ListElement = styled.div`
  margin: 2px 0;
`

const connector = connect((state: DefaultState) => ({
  pendingDeleteList: state.data.pendingDeleteList,
  hidePaths: state.data.hidePaths,
  timestamps: state.timestamps,
  ...getElementMapsObject(state),
}), {
  addPendingDeleteList: DataActions.addPendingDeleteList,
  addDirtyPath: DataActions.addDirtyPath,
  addDirtyDeletePath: DataActions.addDirtyDeletePath,
  saveElement: DataActions.saveElement,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t: Translation
}
export class DeleteDialog extends Component<Props> {
  private readonly handleDeletePendingElements = () => {
    const { saveElement, pendingDeleteList, addPendingDeleteList } = this.props

    if (pendingDeleteList.length === 0 || !pendingDeleteList[0]) {
      return
    }

    addPendingDeleteList()

    const { type } = Util.getElementInfo(pendingDeleteList[0])

    saveElement(pendingDeleteList, [], 'delete', type) // TODO: needs to send elementType too
  }

  private readonly handleDeletePendingList = () => {
    const { addPendingDeleteList, pendingDeleteList, addDirtyPath, addDirtyDeletePath } = this.props
    const elementMaps = getElementMapsObject(this.props)
    const allChildren = [ ...pendingDeleteList ]

    pendingDeleteList.forEach(path => {
      const { type } = Util.getElementInfo(path)

      if (type === 'Roller') {
        const element = ElementMapsUtil.getFullCasterElementByPath<RollerSlot, RollerMountLog>(path, elementMaps)

        if (element) {
          allChildren.push(...LogicUtil.getRollerChildren(element as unknown as RollerMountLog, elementMaps))
        }

        return
      }

      if (type === 'RollerBody' || type === 'RollerBearing') {
        const parentPath = Util.getParentInfo(path).path
        const parentElement =
          ElementMapsUtil.getFullCasterElementByPath<RollerSlot, RollerMountLog>(parentPath, elementMaps)

        if (!parentElement) {
          return
        }
        
        const rollerChildren = LogicUtil.getRollerChildren(parentElement as unknown as RollerMountLog, elementMaps)

        if (rollerChildren.length === 1 && rollerChildren[0] === path) { // if this element is the only roller child
          allChildren.push(parentPath) // because parent isn't in pending delete list, only in dirtyDeleteList
        }
      }
    })

    addDirtyPath(allChildren)
    addDirtyDeletePath(allChildren) // if path is already in array it removes it from the array
    addPendingDeleteList()
  }
  
  public override render () {
    const { pendingDeleteList, t } = this.props

    return (
      <DialogWrapper>
        <CardHeader>
          <h3>{t('specific.deleteDialog.title')}</h3>
        </CardHeader>
        <CardContainer>
          <CardBody>
            {
              pendingDeleteList.map(path => (
                <ListElement key={path}>
                  {path}
                </ListElement>
              ))
            }
          </CardBody>
        </CardContainer>
        <Buttons>
          <Button
            onClick={this.handleDeletePendingElements}
            value={t('specific.deleteDialog.button')}
            small
          />
          <SecondaryButton
            onClick={this.handleDeletePendingList}
            value={t('specific.deleteDialog.secondaryButton')}
            small
          />
        </Buttons>
      </DialogWrapper>
    )
  }
}

export default compose<any>(withTranslation('caster'), connector)(DeleteDialog)
