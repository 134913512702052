import FeatureFlags from '@/react/FeatureFlags'
import Util from '@/three/logic/Util'
import type { ElementCacheKey } from '@/three/objects'
import PhantomObject from '@/three/objects/PhantomObject'
import type { CasterElementNames } from '@/types/data'
import { ElementMapsUtil } from '@/Util/ElementMapsUtil'

import MainView from '.'

export default class PhantomElementHandler {
  public static setPhantomData (view: MainView, data: any) {
    const { editValues, editElements, featureFlags, selectedPaths, createValid } = data

    if (!featureFlags || !selectedPaths.size || selectedPaths.size >= 20) {
      // reset
      Object.values(view.phantomElementList).forEach((elementType: any) => {
        for (const key in elementType) {
          if (elementType[key] && elementType[key].dispose) {
            elementType[key].dispose(view.scene)
          } // TODO: make sure it works

          delete elementType[key]
        }
      })

      return
    }
    else if (selectedPaths.size < 20) {
      Object.values(view.phantomElementList).forEach((elementType: any) => {
        for (const key in elementType) {
          if (!selectedPaths.has(key)) {
            elementType[key].dispose?.()

            delete elementType[key]
          }
        }
      })
    }

    // create new phantom
    for (const selectedPath of selectedPaths) {
      const { type } = Util.getElementInfo(selectedPath)

      if (Util.isPhantom(type, view.sectionDetail) && editElements[selectedPath]) {
        PhantomElementHandler.handlePhantom(view, selectedPath, type as ElementCacheKey, editElements[selectedPath])
      }
    }

    const keys = Object.keys(editValues) as CasterElementNames[]

    keys.forEach(type => {
      const { parentPath } = editValues[type] ?? {}

      // handle new element
      if (createValid[type] && parentPath && FeatureFlags.canEditElement(type, featureFlags)) {
        const parentKeys = Object.keys(parentPath) ?? []
        let path = ''

        if (parentKeys.length > 1) {
          for (let i = 0; i < parentKeys.length; i++) {
            path = `${path.length > 0 ? `${path}/` : path}${parentKeys[i]}:${parentPath[parentKeys[i]!]}`
          }

          const { type: rawParentType } = Util.getElementInfo(path)
          const parentType = rawParentType as ElementCacheKey

          if (view.elementList && view.elementList[parentType]?.[path]) {
            path = `${path}/${type}:new`

            PhantomElementHandler.handlePhantom(view, path, type as ElementCacheKey, editValues[type], true)
          }
        }
      }
      else {
        const phantom = view.phantomElementList[type as ElementCacheKey]

        if (phantom?.['new']) {
          phantom['new'].hide()
        }
      }
    })
  }

  private static handlePhantom (view: MainView, path: string, type: ElementCacheKey, element: any, newElement = false) {
    if (!(Object.keys(view.elementList).length > 0)) {
      return
    }

    const pathOrNew = newElement ? 'new' : path

    const phantomElement = view.phantomElementList[type]?.[pathOrNew]
      ? view.phantomElementList[type]?.[pathOrNew]
      : PhantomObject.createPhantomObject(type, path, view.elementList, view.phantoms, newElement)

    if (!phantomElement) {
      return
    }

    phantomElement.setValues({
      elementData: element as never, // TODO: fix the never type
      path,
      // TODO: is this correct? Usually hideList is not for isDeleted but for isHidden
      isDeleted: view.deleteList?.includes(path) ?? false,
      isHidden: false,
      isPhantom: true,
      view,
    })

    phantomElement.show()

    let phantomType = view.phantomElementList[type]

    if (!phantomType) {
      phantomType = view.phantomElementList[type] = {}
    }

    phantomType[pathOrNew] = phantomElement

    const { type: parentType } = Util.getParentInfo(path)

    if (parentType === 'Segment' && newElement) {
      const parentPath = path.substring(0, path.lastIndexOf('/'))
      const { side } = ElementMapsUtil.getSlotByPath<SegmentSlot>(parentPath, view.elementMaps) ?? {}

      phantomElement.container.userData['side'] = side

      if ((phantomElement as any).updateTransform) {
        ;(phantomElement as any).updateTransform()
      }
    }
    else {
      // TODO: this makes no sense since the objects are the same
      phantomElement.container.userData['side'] = phantomType[pathOrNew].container.userData['side']
    }
  }
}
