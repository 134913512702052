import isEqual from 'lodash/isEqual'
import { Component, type RefObject } from 'react'
import { connect, type ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import { ResizeData } from '@/react/ResizeDetector'
import type { DefaultState } from '@/types/state'

import { Label, TextContainer, Value, Wrapper } from './styles'

const connector = connect(({ visualization, application }: DefaultState) => ({
  tileConfigs: visualization.tileConfigs,
  plotConfigs: visualization.plotConfigs,
  data: visualization.data,
  networkStatus: application.main.networkStatus,
}), {
})

type PropsFromRedux = ConnectedProps<typeof connector>

export interface Props extends PropsFromRedux {
  dynamicData: Array<any>
  dynamicDataList: Array<Array<any>>
  tileId: string
  /**
   * PlotConfig ID
   */
  configId: string
  viewId: string
  xLabel: string
  yLabel: string
  type: 'area' | 'bar' | 'contour' | 'gage' | 'line' | 'pie' | 'text'
  configIds: string[]
  yDomain: Domain
  xDomain: Domain
  xRange: Domain
  valueRange: Domain
  shapeIds?: number[]
  radiusDomain?: any[]
  radius0?: number
  radius?: number
  frequency: number
  flipAxes: boolean
  isMergedDynamicData: boolean
  isLiveDataEnabled?: boolean | undefined
  indexOfLiveData?: number | undefined
}

type State = {
  data: {
    name: string
    attribute: string
    value: string
  }[]
}

class TextWrapper extends Component<Props, State> {
  private wrapperRef?: RefObject<HTMLElement>

  public override state: State = {
    data: [],
  }

  public override componentDidMount () {
    this.setData()
  }

  public override componentDidUpdate (prevProps: Props) {
    if (!isEqual(prevProps, this.props)) { // don't setState on state update
      this.setData()
    }

    this.handleResize({ ref: this.wrapperRef } as ResizeData)
  }

  private readonly handleMouseDown = (event: any) => {
    if (!this.wrapperRef?.current) {
      return
    }

    const offset = event.pageY - this.wrapperRef.current.getBoundingClientRect().top
    const containerHeight = this.wrapperRef.current.getBoundingClientRect().height

    if (event.button === 0) {
      if (offset <= 25) {
        this.handleWheel({
          target: this.wrapperRef.current,
          deltaY: -10,
        })
      }

      if (containerHeight - offset <= 25) {
        this.handleWheel({
          target: this.wrapperRef.current,
          deltaY: 10,
        })
      }
    }
  }

  private readonly handleWheel = (event: any) => {
    if (!this.wrapperRef?.current) {
      return
    }

    const target = this.wrapperRef.current

    target.scrollTop += event.deltaY

    if (target.scrollTop === 0) {
      target.setAttribute('data-arrow-up', 'off')
    }
    else {
      target.setAttribute('data-arrow-up', 'on')
    }

    if (target.offsetHeight + target.scrollTop === target.scrollHeight) {
      target.setAttribute('data-arrow-down', 'off')
    }
    else {
      target.setAttribute('data-arrow-down', 'on')
    }
  }

  private readonly handleResize = ({ ref }: ResizeData) => {
    this.wrapperRef = ref ?? this.wrapperRef

    this.handleWheel({
      target: this.wrapperRef?.current,
      deltaY: 0,
    })
  }

  private readonly setData = () => {
    const {
      dynamicData,
      dynamicDataList,
      configId,
      plotConfigs,
      isMergedDynamicData,
      isLiveDataEnabled,
      indexOfLiveData,
      tileConfigs,
      tileId,
    } = this.props

    const data = []
    const tileConfig = tileConfigs[tileId]

    if (!tileConfig) {
      return
    }

    if (isMergedDynamicData) {
      const mergedPlotConfig = plotConfigs[configId]

      dynamicDataList.forEach((linesPerDataSource, index) => {
        const singlePlotConfig = mergedPlotConfig?.configs[index]
        const plotConfigId = singlePlotConfig?.id ?? ''
        const selectedAxis = tileConfig.shownAxisValue?.[plotConfigId] ?? 'y'
        const selected = singlePlotConfig?.[selectedAxis === 'x' ? 'selectedX' : 'selectedY']
        const attribute = selected?.split('|')[1]

        const mainLine = isLiveDataEnabled && indexOfLiveData
          ? linesPerDataSource[indexOfLiveData] ?? []
          : linesPerDataSource[0] ?? []
        const value = (selectedAxis === 'x' ? mainLine[0]?.x : mainLine[0]?.y)

        data.push({
          name: plotConfigId,
          attribute,
          value,
        })
      })
    }
    else {
      const plotConfig = plotConfigs[configId]
      const selectedAxis = tileConfig.shownAxisValue?.[configId] ?? 'y'
      const selected = plotConfig?.[selectedAxis === 'x' ? 'selectedX' : 'selectedY']
      const attribute = selected?.split('|')[1] ?? ''

      if (isLiveDataEnabled && indexOfLiveData) {
        const liveData = dynamicData[indexOfLiveData]
        const value = selectedAxis === 'x' ? liveData[0]?.x : liveData[0]?.y

        data.push({
          name: configId,
          attribute,
          value,
        })
      }
      else {
        const value = (selectedAxis === 'x' ? dynamicData[0]?.x : dynamicData[0]?.y)

        data.push({
          name: configId,
          attribute,
          value,
        })
      }
    }

    this.setState({
      data,
    })
  }
  
  public override render () {
    const { data } = this.state
    const { tileConfigs, tileId } = this.props

    const tileConfig = tileConfigs[tileId]

    return (
      <Wrapper
        id={`text_${tileId}`}
        onWheel={this.handleWheel}
        onResize={this.handleResize}
        onMouseDown={this.handleMouseDown}
      >
        {
          Boolean(data) && data.map((val, i) => {
            const tileConfigLabel = tileConfig?.label?.[val.name] ?? ''
            const label = tileConfigLabel.length ? tileConfigLabel : val.attribute

            const value = /^[0-9]/.test(val.value) ? Number(val.value).toFixed(3) : val.value ?? '-'

            return (
              <TextContainer key={i} className='data-export' data-export-label={label} data-export-value={value}>
                <Label title={label}>{label}</Label>
                <Value>{value}</Value>
              </TextContainer>
            )
          })
        }
      </Wrapper>
    )
  }
}

export default compose<any>(connector)(TextWrapper)
