import { request } from '@/api'
import DownloadUtil from '@/logicHandlers/ServerLogic/actions/Util'

const PATH = 'executable-documentation-file'

export async function downloadDocumentationFile (id: string) {
  const errorText = 'Failed to download documentation file' // TODO: translate message

  const { fileName, fileData } = await request('get', `${PATH}/download/${id}`, errorText) as {
    fileName: string
    fileData: string
  }

  try {
    await DownloadUtil.openDownloadFileDialogBase64(fileData, fileName)
  }
  catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }
}

export function getAllDocumentationFilesByDefinitionId (definitionId: string): Promise<DocumentationFile[] | null> {
  const errorText = 'Failed to get all documentation files' // TODO: translate message

  return request<DocumentationFile[]>('get', `${PATH}/definition/${definitionId}`, errorText)
}

export function deleteDocumentationFile (id: string) {
  const errorText = 'Failed to delete documentation file' // TODO: translate message

  return request('del', `${PATH}/${id}`, errorText)
}

export function editDocumentationFileName (id: string, newFileName: string) {
  const errorText = 'Failed to edit documentation file name' // TODO: translate message

  return request('patch', `${PATH}/${id}`, errorText, { data: { name: newFileName } })
}
