import * as THREE from 'three'
import { Mesh, Texture } from 'three'

import FeatureFlags from '@/react/FeatureFlags'
import SectionLogic from '@/three/logic/SectionLogic'
import TextureUtil from '@/three/logic/TextureUtil'
import Util from '@/three/logic/Util'
import Mold from '@/three/objects/Mold'
import { StrandSides } from '@/types/elements/enum'

import SectionView from '.'
import CalculationUtil from './CalculationUtil'
import ConditionUtil from './ConditionUtil'
import Getters from './Getters'
import UIView from '../UIView'

export default class DrawHandlers {
  private static drawLine (x1: number, x2: number, y: number, name: string, strand: Mesh) {
    Util.drawLine(strand, x1, y, 0.0001, x2, y, 0.0001, name, SectionView.labelLineMaterial)
  }

  private static drawLabel (text: string, x: number, y: number, strand: Mesh) {
    const label = Util.getText(text, 0.0375, true, true, true)

    if (!label) {
      // eslint-disable-next-line no-console
      console.warn('label is not defined')

      return
    }

    label.name = `Label_${text}`
    label.position.set(x, y, 0.0001)

    Util.addOrReplace(strand, label)
  }

  private static drawLineAndLabel (width: number, y: number, strand: Mesh) {
    DrawHandlers.drawLine(-width + 0.005, -width + 0.03, y, `Line_${width}_${y}_L`, strand)
    DrawHandlers.drawLine(-0.03, -0.005, y, `Line_${width}_${y}_R`, strand)
    DrawHandlers.drawLabel(String(Math.round(y * 1000)), -width / 2, y, strand)
  }

  private static drawRuler (width: number, thickness: number, increment: number, strand: Mesh) {
    for (let i = 0; i < width / 2; i += increment) {
      DrawHandlers.drawLineAndLabel(thickness, -i, strand)

      if (i > 0) {
        DrawHandlers.drawLineAndLabel(thickness, i, strand)
      }
    }
  }

  public static drawPassLineCoordinates (view: SectionView) {
    // Draws the number which indicates section height
    if (
      (
        !SectionView.currentSegmentGroupNameChanged &&
        ConditionUtil.scrollValueNotUpdatedOr0(view.lastDrawnScrollValue ?? 0, view.views?.uiView?.scrollValue ?? 0)
      ) ||
      !view.views?.uiView?.scrollValue ||
      !view.sectionPlaneWidth ||
      !view.sectionPlaneHeight
    ) {
      return
    }

    if (SectionView.currentSegmentGroupNameChanged) {
      SectionView.currentSegmentGroupNameChanged = false
    }

    view.lastDrawnScrollValue = view.views.uiView.scrollValue ?? 0

    const x = view.sectionPlaneWidth / 2
    const currentSegmentGroup = CalculationUtil.getActualSegmentGroup(
      view,
      UIView.currentPasslnPosition,
    )

    if (view.jumpOver === 'Roller') {
      SectionView.currentFixedSideRollerNumber = CalculationUtil.getCurrentFixedSideRollerNumber(
        view,
        UIView.currentPasslnPosition,
      )
    }

    SectionView.currentSegmentGroup = currentSegmentGroup

    const text = view.jumpOver === 'Roller'
      ? `${
        (view.plHeight * 1000 * view.views.uiView.scrollValue).toFixed(2)
      } | ${currentSegmentGroup.name} | RO#${SectionView.currentFixedSideRollerNumber}`
      : `${(view.plHeight * 1000 * view.views.uiView.scrollValue).toFixed(2)} | ${currentSegmentGroup.name}`
    const passLineCoordinates = Util.getText(text, 0.05, true, true, false)

    if (!passLineCoordinates) {
      // eslint-disable-next-line no-console
      console.warn('passLineCoordinates is not defined')

      return
    }

    passLineCoordinates.name = 'passLineCoordinates'

    passLineCoordinates.position.set(-x, view.sectionPlaneHeight / 2 + 0.13, 0.0002)

    view.passLineCoordinates = passLineCoordinates

    Util.addOrReplace(view.sectionPlaneFolded, passLineCoordinates)

    DrawHandlers.drawNextElements(view)
  }

  public static drawStrandAndRuler (
    view: SectionView,
    thickness: number,
    width: number,
    minWidth?: number,
    maxWidth?: number,
  ) {
    const featureFlags = view.featureFlags
    // Draws the expanded section and the ruler for the measurements
    const strand = Getters.getStrand(width, thickness, SectionView.strandMaterial)
    const minStrand = (minWidth && FeatureFlags.canViewMoldMinWidth(featureFlags))
      ? Getters.getMinStrandLines(minWidth, thickness)
      : undefined
    const maxStrand = (maxWidth && FeatureFlags.canViewMoldMaxWidth(featureFlags))
      ? Getters.getMaxStrand(maxWidth, thickness)
      : undefined

    strand.name = 'Strand'
    Util.addOrReplace(view.sectionPlaneFolded, strand)

    if (minStrand) {
      minStrand.name = 'minStrand'
      Util.addOrReplace(view.sectionPlaneFolded, minStrand)
      minStrand.visible = view.strand ? view.strand.visible : false
      view.minStrand = minStrand
    }
    else {
      const minStrandObject = view.sectionPlaneFolded?.getObjectByName('minStrand')

      if (minStrandObject) {
        view.sectionPlaneFolded?.remove(minStrandObject)
      }
    }

    if (maxStrand) {
      maxStrand.name = 'maxStrand'
      Util.addOrReplace(view.sectionPlaneFolded, maxStrand)
      maxStrand.visible = view.strand ? view.strand.visible : false
      view.maxStrand = maxStrand
    }
    else {
      const maxStrandObject = view.sectionPlaneFolded?.getObjectByName('maxStrand')

      if (maxStrandObject) {
        view.sectionPlaneFolded?.remove(maxStrandObject)
      }
    }

    strand.visible = view.strand ? view.strand.visible : true
    view.strand = strand
    DrawHandlers.drawRuler((maxStrand && maxWidth) ?? width, thickness, 0.2, view.strand)
  }

  public static drawNextElements (view: SectionView) {
    // Draws rollers or nozzles in the section view
    if (!view.elementList || !view.elementList.Roller || !view.elementList.Nozzle) {
      return
    }

    if (view.additionalLayerType === 'Roller') {
      return SectionLogic.handleRoller(view.views, view.elementMaps)
    }

    SectionLogic.handleNozzle(view.views)
  }

  public static drawAllSectionViewSwitches (view: SectionView) {
    const size = 0.15
    const space = 0.05
    const x = -Mold.thickness / 2 + (view.sectionPlaneWidth ?? 0) / 2
    const y = (view.sectionPlaneHeight ?? 0) / 2

    DrawHandlers.drawSwitcherIcon(
      view,
      x,
      y + 0.14,
      'expandSectionViewButton',
      'expandSectionView',
      view.sectionViewExpanded,
      TextureUtil.load('textures/ui/collapse.png'),
      TextureUtil.load('textures/ui/expand.png'),
      'Expand',
      false,
    )

    DrawHandlers.drawSwitcherIcon(
      view,
      x - (size + space),
      y + 0.14,
      'ViewMode',
      'toggleViewMode',
      view.viewMode,
      TextureUtil.load('textures/ui/eyeOpen.png'),
      TextureUtil.load('textures/ui/eyeClose.png'),
      'Details',
      false,
    )

    DrawHandlers.drawSwitcherWithBackground(
      view,
      x,
      y - 0.09,
      'switcherPrevNextButton',
      'togglePrevNextElements',
      view.statusPrevNext,
      `Additional Layer: ${!view.statusPrevNext ? 'Next' : 'Prev'}`,
      TextureUtil.load('textures/ui/toggle-prev.png'),
      TextureUtil.load('textures/ui/toggle-next.png'),
      view.viewMode,
    )

    DrawHandlers.drawSwitcherWithBackground(
      view,
      x,
      y - 0.29,
      'additionalLayerTypeThreeJSGroup',
      'toggleShowedElements',
      view.additionalLayerType === 'Roller',
      `Additional Layer Type: ${view.additionalLayerType}`,
      TextureUtil.load('textures/ui/toggle-roller.png'),
      TextureUtil.load('textures/ui/toggle-nozzle.png'),
      view.viewMode,
    )

    if (!FeatureFlags.canToggleSectionSides(view.featureFlags)) {
      return
    }

    DrawHandlers.drawSwitcher(
      view,
      x - 0.1,
      0,
      StrandSides.Loose,
      `toggle${StrandSides.Loose}`,
      view.side.loose,
      `Use passLineCoordinates, ${StrandSides.Loose}`,
      true,
    )

    DrawHandlers.drawSwitcher(
      view,
      -x - Mold.thickness + 0.1,
      0,
      StrandSides.Fixed,
      `toggle${StrandSides.Fixed}`,
      view.side.fixed,
      `Use passLineCoordinates, ${StrandSides.Fixed}`,
      true,
    )

    DrawHandlers.drawSwitcher(
      view,
      -Mold.thickness / 2,
      -(y - 0.3),
      StrandSides.Right,
      `toggle${StrandSides.Right}`,
      view.side.right,
      `Use passLineCoordinates, ${StrandSides.Right}`,
      true,
    )

    DrawHandlers.drawSwitcher(
      view,
      -Mold.thickness / 2,
      y - 0.3,
      StrandSides.Left,
      `toggle${StrandSides.Left}`,
      view.side.left,
      `Use passLineCoordinates, ${StrandSides.Left}`,
      true,
    )
  }

  public static drawSwitcherWithBackground (
    view: SectionView,
    x: number | null,
    y: number | null,
    name: string,
    action: any,
    switcherValue = false,
    tooltip: string,
    textureA: Texture,
    textureB: Texture,
    visible = true,
    pivot = 'right',
  ) {
    if (view.clickableObjects.includes(view.buttons[name])) {
      return
    }

    const size = 0.4
    const space = 0.02
    const switcherGeometry = new THREE.PlaneGeometry(size, size / 2, 1)
    const xCoordinate = x ?? view.buttons[name]?.position?.x ?? 0
    const yCoordinate = y ?? view.buttons[name]?.position?.y ?? 0

    const buttonGroup = new THREE.Group()

    buttonGroup.name = name
    buttonGroup.position.set(xCoordinate, yCoordinate, 0.0002)

    if (x !== null) {
      switch (pivot) {
        case 'right':
          buttonGroup.position.x = xCoordinate - size / 2
          break
        case 'left':
          buttonGroup.position.x = xCoordinate + size / 2
          break
        default:
      }
    }

    buttonGroup.visible = visible

    const backgroundSize = size + space
    const buttonBackgroundGeometry = new THREE.PlaneGeometry(backgroundSize, 0.2, 1)

    const backgroundOffset = space / 2

    buttonBackgroundGeometry.translate(-backgroundOffset, 0, -0.00001)
    view.buttonBackground = new THREE.Mesh(buttonBackgroundGeometry, SectionView.planeHeaderMaterial)
    view.buttonBackground.name = `${name}Background`

    const buttonBackgroundLineGeometry = new THREE.PlaneGeometry(backgroundSize + 0.01, 0.21, 1)

    buttonBackgroundLineGeometry.translate(-backgroundOffset - 0.01, -0.005, -0.00002)
    view.buttonBackgroundLine = new THREE.Mesh(buttonBackgroundLineGeometry, SectionView.headerPartingMaterial)
    view.buttonBackgroundLine.name = `${name}BackgroundLine`

    Util.addOrReplace(buttonGroup, view.buttonBackground)
    Util.addOrReplace(buttonGroup, view.buttonBackgroundLine)

    const texture = switcherValue ? textureA : textureB

    texture.repeat.set(1, 1)
    texture.offset.set(0, 0)

    const material = new THREE.MeshBasicMaterial({ map: texture, transparent: true })
    const switcher = new THREE.Mesh(switcherGeometry, material)

    switcher.name = `${name}Switcher`
    switcher.userData['type'] = 'Button'
    switcher.userData['action'] = action
    switcher.position.x -= space
    switcher.visible = visible

    if (tooltip) {
      switcher.userData['tooltip'] = tooltip
    }

    if (view.buttons[name]) {
      const oldElement = view.buttons[name].children.filter((child: any) => child.name === `${name}Switcher`)[0]

      Util.addOrReplaceInList(oldElement, switcher, view.tooltipObjects)
      Util.addOrReplaceInList(oldElement, switcher, view.clickableObjects)
    }

    Util.addOrReplace(buttonGroup, switcher)

    Util.addOrReplace(view.sectionPlaneFolded, buttonGroup)
    view.buttons = {
      ...view.buttons,
      [name]: buttonGroup,
    }
  }

  public static drawSwitcherIcon (
    view: SectionView,
    x: number | null,
    y: number | null,
    name: string,
    action: any,
    switcherValue = false,
    enableIcon: any,
    disableIcon: any,
    tooltip: string,
    iconNextToSwitcher = true,
    visible = true,
    pivot: 'left' | 'right' = 'right',
  ) {
    if (view.clickableObjects.includes(view.buttons[name])) {
      return
    }

    const size = 0.15
    const buttonGroup = Getters.getButtonGroup(x, y, name, size, visible, view.buttons, pivot)

    buttonGroup.visible = visible

    const switcher = Getters.getSwitcherWithIcon(
      x,
      y,
      size,
      name,
      action,
      switcherValue,
      tooltip,
      view.buttons,
      pivot,
      iconNextToSwitcher,
      enableIcon,
      disableIcon,
      visible,
      buttonGroup,
    )

    if (view.buttons[name]) {
      const oldElement = view.buttons[name].children.filter((child: any) => child.name === `${name}Switcher`)[0]

      Util.addOrReplaceInList(oldElement, switcher, view.tooltipObjects)
      Util.addOrReplaceInList(oldElement, switcher, view.clickableObjects)
    }

    Util.addOrReplace(buttonGroup, switcher)

    Util.addOrReplace(view.sectionPlaneFolded, buttonGroup)

    view.buttons = {
      ...view.buttons,
      [name]: buttonGroup,
    }
  }

  public static drawSwitcher (
    view: SectionView,
    x: number | null,
    y: number,
    name: string,
    action: any,
    switcherValue = false,
    tooltip: string,
    visible = false,
  ) {
    const width = 0.15
    const switcherDto = {
      x,
      y,
      width,
      height: width,
      name,
      action,
      switcherValue,
      tooltip,
      buttons: view.buttons,
      setPosition: true,
      visible,
    }

    const switcher = Getters.getSwitcher(switcherDto)

    Util.addOrReplaceInList(view.buttons[name], switcher, view.tooltipObjects)
    Util.addOrReplace(view.sectionPlaneFolded, switcher, view.clickableObjects)

    view.buttons = {
      ...view.buttons,
      [name]: switcher,
    }
  }
}
