export default class Helpers {
  public static readonly colorscale = [
    [ 0.0, '#000083' ],
    [ 0.1111111111111111, '#0000ef' ],
    [ 0.2222222222222222, '#005bff' ],
    [ 0.3333333333333333, '#00cfff' ],
    [ 0.4444444444444444, '#3bffc3' ],
    [ 0.5555555555555556, '#afff4f' ],
    [ 0.6666666666666666, '#ffdf00' ],
    [ 0.7777777777777777, '#fe6b00' ],
    [ 0.8888888888888888, '#fa0000' ],
    [ 1.0, '#830000' ],
  ]

  public static getNewAxis (flipAxes: boolean, yR: number[], prefix: string) {
    return {
      showline: true,
      showgrid: false,
      zeroline: false,
      gridcolor: '#424d58',
      color: '#a2a6a9',
      ticks: 'inside',
      ticklen: 8,
      tickwidth: 1,
      tickcolor: '#a2a6a9',
      tickfont: {
        color: '#a2a6a9',
      },
      side: !flipAxes ? 'right' : 'top',
      range: yR,
      overlaying: prefix,
      title: {
        font: {
          family: 'Roboto, sans-serif',
          size: 14,
          color: '#a2a6a9',
        },
      },
    }
  }

  public static getNewAnnotation () {
    return {
      xref: 'paper',
      yref: 'paper',
      x: 0.5,
      showarrow: false,
      font: {
        family: 'Roboto, sans-serif',
        size: 14,
        color: '#a2a6a9',
      },
    }
  }

  public static getLineShape (
    {
      flipAxes,
      currentVariablePosition,
      currentShape,
      value,
      name,
    }: {
      flipAxes?: boolean
      currentVariablePosition?: number
      currentShape?: any
      value?: number
      name?: string
    },
    doesntIncludeVariable?: boolean,
  ) {
    if (doesntIncludeVariable) {
      return {
        type: 'line',
        xref: !flipAxes ? 'x' : 'paper',
        yref: !flipAxes ? 'paper' : 'y',
        x0: !flipAxes ? (value ?? 0) : 0,
        y0: !flipAxes ? 0 : (value ?? 0),
        x1: !flipAxes ? (value ?? 0) : 1,
        y1: !flipAxes ? 1 : (value ?? 0),
        opacity: 0.15,
        line: {
          width: Number(currentShape?.thickness ?? 1),
          color: currentShape?.color ?? '#a2a6a9',
        },
        name,
      }
    }

    return {
      type: 'line',
      xref: !flipAxes ? 'x' : 'paper',
      yref: !flipAxes ? 'paper' : 'y',
      x0: !flipAxes ? (currentVariablePosition ?? 0) : 0,
      y0: !flipAxes ? 0 : (currentVariablePosition ?? 0),
      x1: !flipAxes ? (currentVariablePosition ?? 0) : 1,
      y1: !flipAxes ? 1 : (currentVariablePosition ?? 0),
      opacity: 0.4,
      line: {
        width: Number(currentShape?.thickness || 1),
        color: currentShape?.color ?? '#a2a6a9',
      },
      name,
    }
  }

  private static getRectShape (
    flipAxes: boolean,
    rawValues: any,
    i: number,
    currentShape: any,
  ) {
    return {
      type: 'rect',
      xref: !flipAxes ? 'x' : 'paper',
      yref: !flipAxes ? 'paper' : 'y',
      x0: !flipAxes ? rawValues[i] : 0,
      y0: !flipAxes ? 0 : rawValues[i],
      x1: !flipAxes ? rawValues[i + 1] : 1,
      y1: !flipAxes ? 1 : rawValues[i + 1],
      fillcolor: currentShape.color ?? '#a2a6a9',
      opacity: 0.1,
      line: {
        width: 0,
      },
    }
  }

  public static getNewData (
    data: any,
    hide: boolean,
    showLegend: boolean,
    fallbackColor: string,
    newLayout: any,
    color: string,
  ) {
    let newData = { ...data }

    newData.hoverlabel = {
      namelength: 0, // hide legend name on hover
    }

    newLayout.showlegend = showLegend

    if (!hide) {
      newData.line = {
        ...newData.line,
        color: color ?? fallbackColor,
        width: 1,
      }

      newData = {
        ...newData,
        marker: {
          color: color ?? fallbackColor,
        },
      }
    }
    else if (hide) {
      newData = {
        ...newData,
        line: {
          ...newData.line,
          color: 'transparent',
        },
        marker: {
          color: 'transparent',
        },
      }
    }

    return newData
  }

  public static pushRectShapes (rawValues: any, flipAxes: boolean, shapes: any[], currentShape: any) {
    for (let i = 0; i <= rawValues.length - 1; i++) {
      if (rawValues[i + 1] === undefined) {
        break
      }

      shapes.push(Helpers.getRectShape(flipAxes, rawValues, i, currentShape))
    }
  }

  public static updateDefaultData (defaultData: any, dynamicDataArray: any) {
    defaultData.forEach((_data: any, index: number) => {
      defaultData[index].x = dynamicDataArray.map((el: any) => el.x)
      defaultData[index].y = dynamicDataArray.map((el: any) => el.y)
    })
  }
}
