import isEqual from 'lodash/isEqual'
import { Vector2, Vector3 } from 'three'

import MainView from '.'

export default class ConditionUtil {
  public static shouldBeRedrawing (
    redraw: boolean | undefined,
    caster: Caster | undefined,
    incomingData: any,
    hasChanges: boolean | undefined,
    isRedrawing: boolean,
    elementMapsChanged = false,
  ) {
    return (
      (
        Boolean(redraw) ||
        !caster ||
        (hasChanges !== undefined && hasChanges && !incomingData.hasChanges)
      ) &&
      !isRedrawing &&
      elementMapsChanged
    )
  }

  public static isCameraInPlace (pos: Vector3, target: Vector3, position: Vector3, center: Vector3) {
    return (pos.clone().sub(position).length() < 0.0000001 && target.clone().sub(center).length() < 0.0000001)
  }

  public static selectionIsOnScreen (min: Coord, max: Coord, posOnScreen: Vector2) {
    return (posOnScreen.x >= min.x && posOnScreen.x <= max.x && posOnScreen.y >= min.y && posOnScreen.y <= max.y)
  }

  public static editElementsOrParentPathAreDifferent (view: MainView, data: any) {
    const { editElements: viewEditElements, parentPath: viewParentPath } = view
    const { editElements, parentPath } = data

    return (!isEqual(editElements, viewEditElements) || !isEqual(parentPath, viewParentPath))
  }
}
