import { faCrosshairs, faFilter, faListAlt, faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC } from 'react'
import { withTranslation } from 'react-i18next'
import { type ConnectedProps, connect } from 'react-redux'
import { compose } from 'redux'

import { openDialog } from '@/store/application/main/actions'
import type { DefaultState } from '@/types/state'
import type { Vector2D } from '@/types/visualization'

import { MenuID, TreeViewID } from '../driver/DriverID'
import FeatureFlags from '../FeatureFlags'
import { ContextMenu, ContextMenuBackdrop, MenuEntry } from './TreeViewContextMenu'

const connector = connect(
  (state: DefaultState) => ({
    currentSimulationCase: state.application.main.currentSimulationCase,
    featureFlags: FeatureFlags.getRealFeatureFlags(state),
  }), 
  {
    openDialog,
  },
)

type Props = {
  mousePosition: Vector2D | null
  onClose: () => void
  onSetFilter: (event: any) => void
  handleJumpToElement: (event: any) => void
  handleOpenHistoryDetailDialog: (event: any) => void
  active: boolean
  target: boolean
  type: string
  isRenaming: boolean
  handleSetRenaming: (event: any) => void
}

type FullProps = ConnectedProps<typeof connector> & Props & { t: (key: string) => string }

const { Backdrop, JumpToElement, SetFilter, OpenHistoryDetailDialog } = MenuID.ActionsMenuTreeView
const { RenameSegmentGroupButton } = TreeViewID
const T = 'treeView'

const TreeViewContextMenu: FC<FullProps> = (props) => {
  const {
    mousePosition,
    featureFlags,
    onClose: handleClose,
    onSetFilter: handleSetFilter,
    handleJumpToElement,
    handleOpenHistoryDetailDialog,
    handleSetRenaming,
    target,
    active,
    type,
    isRenaming,
    t,
  } = props

  if (!mousePosition) {
    return null
  }

  const canJumpToElement = FeatureFlags.canJumpToElement(featureFlags)
  const canSetFilterAndJumpToElement = FeatureFlags.canSetFilterAndJumpToElement(featureFlags)
  const canViewAndUseHistoryDialogButton = FeatureFlags.canViewAndUseHistoryDialogButton(featureFlags)
  const canRenameElements = FeatureFlags.canRenameElements(featureFlags)
  const baseIconStyle = { marginRight: '8px' }

  return (
    <ContextMenuBackdrop id={Backdrop} onClick={handleClose} onContextMenu={handleClose}>
      <ContextMenu $mousePosition={mousePosition}>
        {
          canJumpToElement && (
            <MenuEntry id={JumpToElement} onClick={handleJumpToElement}>
              <FontAwesomeIcon
                icon={faCrosshairs}
                style={{ color: target ? '#BB1B1B' : '#FFFFFF', ...baseIconStyle }}
              />
              {t(`${T}.jumpToElement`)}
            </MenuEntry>
          )
        }
        {
          canSetFilterAndJumpToElement && (
            <MenuEntry id={SetFilter} onClick={handleSetFilter}>
              <FontAwesomeIcon
                icon={faFilter}
                style={{ color: active ? '#BB1B1B' : '#FFFFFF', ...baseIconStyle }}
              />
              {t(`${T}.setFilter`)}
            </MenuEntry>
          )
        }
        {
          canViewAndUseHistoryDialogButton && (
            <MenuEntry id={OpenHistoryDetailDialog} onClick={handleOpenHistoryDetailDialog}>
              <FontAwesomeIcon icon={faListAlt} width={16} height={16} style={{ marginRight: '8px' }} />
              {t(`${T}.openElementHistoryDialog`)}
            </MenuEntry>
          )
        }
        {
          (type === 'Segment' || type === 'SegmentGroup') && canRenameElements && (
            <MenuEntry id={RenameSegmentGroupButton} onClick={handleSetRenaming}>
              <FontAwesomeIcon
                icon={faPencilAlt}
                style={{ color: isRenaming ? '#BB1B1B' : '#FFFFFF', ...baseIconStyle }}
              />
              {t(`${T}.renameElement`)}
            </MenuEntry>
          )
        }
      </ContextMenu>
    </ContextMenuBackdrop>
  )
}

export default compose<FC<Props>>(withTranslation('caster'), connector)(TreeViewContextMenu)
