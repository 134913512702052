import styled, { css } from 'styled-components'

export const RenameInput = styled.input`${() =>
  css`
  width: 50%;
  height: 12px;
  font-size: 13px;
  background-color: transparent;
  color: white;
  border: none;
  margin: 0 0 -2px 0;

  &:focus {
    outline: 1px solid #BB1B1B;
  }   
`}`

type ElementsProps = {
  $spacer?: number
  $selected?: boolean
  $targetButtonFilterAndJump?: boolean
  $targetButtonJump?: boolean
  $renameButton?: boolean
  $isActionsMenuOpen?: boolean
}

export const Center = styled.div`
  display: flex;
  place-content: center;
`

export const Actions = styled.div`
  display: inline-flex;
`

export const Elements = styled.div<ElementsProps>`${({
  $spacer,
  $selected,
  $isActionsMenuOpen,
}) =>
  css`
  background: ${$selected ? '#424345' : 'transparent'};
  display: flex;
  justify-content: space-between;
  margin: 3px 0 3px ${$spacer ?? 0}px;
  color: #FFFFFF;
  padding: 3px 0 3px 5px;
  cursor: pointer;
  user-select: none;
  font-size: 0.9em;
  
  .elementName {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
  }

  /* If actions menu is open, leave the hover style on */
  ${
    $isActionsMenuOpen && `
    background: #6b6d71;
    
    ${Actions} > * {
      display: inline-flex;
    }
  `
  }

  &:hover {
    background: #6b6d71;
    
    ${Actions} > * {
      display: inline-flex;
    }
  }

  button {
    padding-left: 6px;
    padding-right: 6px;
  }
`}`

export const Counter = styled.div`
  display: inline-block;
  min-width: 35px;
  text-align: right;
  border-left: #424345 solid 2px;
  padding: 0 13px;
`

export const Spacer = styled.div`
  display: inline-block;
  width: 10px;
  text-align: center;
`

export const TargetButton = styled.button<{ $active?: boolean }>`${({ $active }) =>
  css`
  color: ${$active ? '#BB1B1B' : '#FFFFFF'};
  border: none;
  background-color: inherit;
  cursor: pointer;

  &:focus {
    outline: 0;
  }
`}`

export const RenameButton = styled.button<{ $active?: boolean }>`${({ $active }) =>
  css`
  display: none;
  text-align: center;
  color: ${$active ? '#BB1B1B' : '#CCCCCC'};
  border: none;
  background-color: inherit;
  cursor: pointer;

  &:focus {
    outline: 0;
  }

  &:hover {
    color: #FFFFFF;
  }
`}`

export const OptionsButton = styled.button`
  display: none;
  text-align: center;
  color: #CCCCCC;
  border: none;
  background-color: inherit;
  cursor: pointer;
`
