import { Component } from 'react'

interface Props {
  expanded: boolean
  sectionName: string
  sectionIndex: number
  children: React.ReactNode
  onToggleStep: (sectionIndex: number) => void
}
export class ExpandableSection extends Component<Props> {
  private readonly handleToggleStep = () => {
    this.props.onToggleStep(this.props.sectionIndex)
  }

  public override render () {
    const { expanded, sectionName, sectionIndex, children } = this.props

    return (
      <div>
        <div
          onClick={this.handleToggleStep}
          style={{ color: '#a2a6a9', fontSize: '20px', marginTop: '20px', cursor: 'pointer' }}
        >
          {expanded ? <span>&#9662;</span> : <span>&#9656;</span>}
          <span style={{ marginLeft: '5px' }}>{sectionName || `Step ${sectionIndex}`}</span>
        </div>
        {
          expanded && (
            <>
              {children}
            </>
          )
        }
      </div>
    )
  }
}
