export class LiveDataCache {
  private static liveData: Record<string, number> = {}

  private static isLiveDataEnabled: boolean = false

  private static readonly subscribers: Set<() => void> = new Set()

  public static setData (data: Record<string, number>) {
    this.liveData = data
    this.notifySubscribers()
  }

  public static setIsLiveDataEnabled (isLiveDataEnabled: boolean) {
    this.isLiveDataEnabled = isLiveDataEnabled
    this.notifySubscribers()
  }

  public static getData (): Record<string, number> {
    return this.liveData
  }

  public static getIsLiveDataEnabled (): boolean {
    return this.isLiveDataEnabled
  }

  public static subscribe (callback: () => void) {
    this.subscribers.add(callback)

    return () => this.subscribers.delete(callback)
  }

  private static notifySubscribers () {
    this.subscribers.forEach(callback => callback())
  }

  public static clear () {
    this.liveData = {}
    this.notifySubscribers()
  }
}
