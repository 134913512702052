import { getDataByUUIDs } from '@/api/caster-data-server'
import { getCurrentDashboardEntry } from '@/App/util'
import { getElementMapsObject } from '@/store/elements/logic'
import FilterHandler from '@/three/logic/FilterHandler'
import { PlotConfig } from '@/types/visualization'
import { ElementMapsUtil } from '@/Util/ElementMapsUtil'

import { SimpleDashboardProps } from './index'

export default class CasterDataServerHandler {
  public static readonly plant = 'NucorBrandenburg'

  public static fetchCDSDataByUUIDs (props: SimpleDashboardProps, liveMode: boolean) {
    const uuidsToFetch = this.getCDSUUIDsToFetch(props, liveMode)

    if (!uuidsToFetch?.length) {
      return
    }

    return getDataByUUIDs(CasterDataServerHandler.plant, uuidsToFetch)
  }

  public static getCDSUUIDsToFetch (props: SimpleDashboardProps, liveMode: boolean) {
    const { currentDashboard, viewsObject } = props
    const { viewId, dashboardId } = getCurrentDashboardEntry(currentDashboard, viewsObject)

    if (!viewId || !dashboardId) {
      return []
    }

    const tileIds = viewsObject[viewId]?.dashboards?.[dashboardId]?.tileIds ?? []

    return this.getUUIDsFromTiles(props, tileIds, liveMode)
  }

  private static getUUIDsFromTiles (props: SimpleDashboardProps, tileIds: (string | undefined)[], liveMode: boolean) {
    const { tileConfigs, plotConfigs } = props
    const uuidsToFetch: string[] = []

    for (const tileId of tileIds) {
      if (!tileId) {
        continue
      }

      const configId = tileConfigs[tileId]?.configId

      if (!configId) {
        continue
      }

      this.processPlotConfig(props, configId, plotConfigs, uuidsToFetch, liveMode)
    }

    return uuidsToFetch
  }

  private static processPlotConfig (
    props: SimpleDashboardProps,
    configId: string,
    plotConfigs: Record<string, PlotConfig>,
    uuidsToFetch: string[],
    liveMode: boolean,
  ) {
    const plotConfig = plotConfigs[configId]

    if (!plotConfig) {
      return
    }

    if (plotConfig.configIds) {
      for (const configId of plotConfig.configIds) {
        this.processPlotConfig(props, configId, plotConfigs, uuidsToFetch, liveMode)
      }

      // return here because mergedPlotConfigs don't have a selectedX, each individual plotConfig will be processed
      return
    }

    const key = plotConfig.selectedX.split('|')?.[1]

    if (key === 'dataOverTimeByFieldUUID' || liveMode) {
      this.processDataOverTimePlot(props, plotConfig, uuidsToFetch, liveMode)
    }
  }

  private static processDataOverTimePlot (
    props: SimpleDashboardProps,
    plotConfig: any,
    uuidsToFetch: string[],
    liveMode: boolean,
  ) {
    const { selectedY, filter } = plotConfig

    if (!selectedY || !filter) {
      return
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [ _, yField ] = selectedY.split('|') as [string, string]

    if (!yField) {
      return
    }

    const elementMaps = getElementMapsObject(props)
    const elementPaths = Object.keys(FilterHandler.getFilteredElements(elementMaps, filter, false) ?? {})

    if (!elementPaths?.length) {
      return
    }

    if (liveMode) {
      for (const elementPath of elementPaths) {
        const element = ElementMapsUtil.getFullCasterElementByPath(elementPath, elementMaps)

        if (!element) {
          continue
        }

        const snakeCaseField = yField.replace(/([A-Z])/g, '_$1').toLowerCase()
        const snakeCaseFieldUUID = `${snakeCaseField}_uuid`
        const fieldUUID = element?.additionalData?.[snakeCaseFieldUUID]

        if (fieldUUID) {
          uuidsToFetch.push(fieldUUID as string)
        }
      }
    }
    else {
      const elementPath = elementPaths[0]

      if (!elementPath) {
        return
      }

      const element = ElementMapsUtil.getFullCasterElementByPath(elementPath, elementMaps)

      const yValue = element?.[yField as keyof typeof element] ?? element?.additionalData?.[yField]

      if (yValue != null && !props.cdsDataByUUID?.[yValue as string]) {
        uuidsToFetch.push(yValue as string)
      }
    }
  }
}
